/**
 * CompanySettingsView
 */

import React, { useContext } from 'react'

import { DVR_ENABLED } from 'src/constants/config'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { UserContext } from 'src/core/providers'

import { CompanyAccessSettingsView } from './CompanyAccessSettingsView'
import { CompanyDvrSettingsView } from './CompanyDvrSettingsView'
import { CompanyIdleSettingsView } from './CompanyIdleSettingsView'
import { CompanyProgramSettingsView } from './CompanyProgramSettingsView'
import { CompanyProjectSettingsView } from './CompanyProjectSettingsView'
import { CompanyUserSettingsView } from './CompanyUserSettingsView'

import styles from './CompanySettingsView.module.css'

export interface CompanySettingsViewProps {}

const CompanySettingsView = (_props: CompanySettingsViewProps) => {
  const userContext = useContext(UserContext)
  const company = userContext.store.selectedCompany
  if (!company) return null
  return (
    <div className={styles.settingsPage}>
      <div>
        <CompanyProgramSettingsView />
      </div>
      <div>
        <CompanyAccessSettingsView company={company} />
        <CompanyIdleSettingsView company={company} />
        <CompanyUserSettingsView company={company} />
        <CompanyProjectSettingsView company={company} />
        {DVR_ENABLED && (
          <>
            <ArkSpacer />
            <CompanyDvrSettingsView company={company} />
          </>
        )}
      </div>
    </div>
  )
}

export default CompanySettingsView
