import React, { ReactNode } from 'react'

import ArkPopup from 'src/core/components/ArkPopup'
import ArkSlider from 'src/core/components/ArkSlider'

import styles from './ProgramTimelineScalePopup.module.css'

const DEBUG_ALWAYS_OPEN: boolean = false

interface ProgramTimelineScalePopupProps {
  open: boolean
  trigger: ReactNode
  value: number
  onChange: (value: number) => void
  onClose: () => void
  onOpen: () => void
}

const ProgramTimelineScalePopup = (props: ProgramTimelineScalePopupProps) => {
  /**
   * utilities
   */

  const scaleToValue = (scale: number): number => {
    switch (scale) {
      case 4: return 3
      case 8: return 4
      default: return scale
    }
  }

  const valueToScale = (value: number): number => {
    switch (value) {
      case 3: return 4
      case 4: return 8
      default: return value
    }
  }

  /**
   * render
   */

  return (
    <ArkPopup
      className={styles.popup}
      hideOnScroll={false}
      open={DEBUG_ALWAYS_OPEN || props.open}
      position='top center'
      title='TIMELINE ZOOM'
      trigger={props.trigger}
      onClose={props.onClose}
      onOpen={props.onOpen}
    >
      <ArkSlider
        barColor='transparent'
        markers={['1x', '2x', '4x', '8x']}
        max={4}
        min={1}
        value={scaleToValue(props.value)}
        width={200}
        onChange={(value) => props.onChange(valueToScale(value))}
      />
    </ArkPopup>
  )
}

export default ProgramTimelineScalePopup
