import React, { useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import { STATUS_ENABLED } from 'src/constants/config'
import * as ROUTES from 'src/constants/routes'
import {
  OBJECT_PROGRAM_NAME,
  OBJECT_PROGRAM_NAME_PLURAL,
  OBJECT_PROJECT_NAME,
  SECTION_MANAGER_SUFFIX_NAME
} from 'src/constants/strings'
import {
  ProjectAdminContext,
  ServerConfigContext,
  useProjectStatus,
  UserContext,
  withProjectStatusContext
} from 'src/core/providers'
import { useProjectAdmin } from 'src/core/providers/ProjectAdminProvider'
import { Program, Project, UserCompany } from 'src/core/models'
import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkManagerListView, { ArkManagerFilteredItem } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import ArkModal from 'src/core/components/ArkModal'
import ArkProjectStatusBanner from 'src/core/components/ArkProjectStatusBanner'

import ArkProjectManagerPage from '../../components/ArkProjectManagerPage/ArkProjectManagerPage'
import ProgramForm, { ProgramFormMode } from './ProgramForm'
import ProjectProgramListItem from './ProjectProgramListItem'
import ProjectProgramSidebar from './ProjectProgramSidebar'

interface IProps {}

const ProjectProgramsPage = (_props: IProps) => {
  const projectAdmin = useProjectAdmin()
  const projectStatus = useProjectStatus()

  const mounted = useRef(false)

  const { actions: userActions, store: userStore } = useContext(UserContext)
  const { actions: projectAdminActions } = useContext(ProjectAdminContext) // store: projectAdminStore
  const { store: serverConfigStore } = useContext(ServerConfigContext) // actions: serverConfigActions

  // programs listing
  const [loading, setLoading] = useState<boolean>(false)
  const [programs, setPrograms] = useState<Array<Program>>([])
  const [filteredPrograms, setFilteredPrograms] = useState<Array<ArkManagerFilteredItem<Program>> | undefined>()
  const [filter, setFilter] = useState<string | undefined>()
  const [loadingError, setLoadingError] = useState<Error | undefined>()
  const [selectedProgram, setSelectedProgram] = useState<Program | undefined>()
  // add/edit program form
  const [editProgram, setEditProgram] = useState<Program | undefined>()
  const [showProgramFormModal, setShowProgramFormModal] = useState<boolean>(false)
  // user
  const isSiteAdmin = userActions.isSiteAdmin()

  const company: UserCompany | undefined = userStore.selectedCompany
  const project: Project | undefined = userStore.selectedProject

  if (!company || !project) return null

  // -------

  const selectProgram = (program?: Program) => {
    setSelectedProgram(program)
  }

  const filterPrograms = (_filter: string) => {
    if (loading) return
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredPrograms = filter
      ? programs.reduce<Array<ArkManagerFilteredItem<Program>>>((r, program) => {
        let nameMatch = false
        let shortNameMatch = false
        if (program.name.toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        if (program.shortName?.toLowerCase().includes(filter.toLowerCase())) {
          shortNameMatch = true
        }
        if (nameMatch || shortNameMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          if (shortNameMatch) matchingFields.push('shortName')
          const filteredUser: ArkManagerFilteredItem<Program> = {
            item: program,
            matchingFields
          }
          r.push(filteredUser)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<Program>>)
      : undefined
    if (selectedProgram && (!(filteredPrograms?.find((filteredProgram) => filteredProgram.item.id === selectedProgram.id)))) {
      selectProgram(undefined) // if a user was selected but isn't in the filtered list deselect them
    }
    setFilter(filter)
    setFilteredPrograms(filteredPrograms)
  }

  // const clearFilteredPrograms = () => {
  //   setFilter(undefined)
  //   setFilteredPrograms(undefined)
  // }

  // -------

  const loadPrograms = async (): Promise<Array<Program> | undefined> => {
    if (loading === true) return undefined
    try {
      setLoading(true)
      if (loadingError) setLoadingError(undefined)
      const programs = await projectAdminActions.getAllCompanyProjectPrograms(company.id, project.id)
      if (mounted.current) {
        setLoading(false)
        setPrograms(programs || [])
        setFilteredPrograms(undefined)
      }
      if (mounted.current && filter) filterPrograms(filter) // re-filter if it was active
      return programs ?? undefined // NB: now also returning the programs so we can use it in the calling code before the setPrograms status update has completed
    } catch (error) {
      console.error('ProjectProgramsPage - loadPrograms - error: ', error)
      if (mounted.current) {
        setLoading(false)
        setLoadingError(error)
        setPrograms([])
        setFilteredPrograms(undefined)
      }
      return undefined
    }
  }

  // triggered after a program is added/edited/deleted etc. to reload the programs list & selected program (if it was the one edited/deleted)
  const reloadPrograms = async (program?: Program) => {
    // console.log('ProjectProgramsPage - reloadPrograms - program:', program)
    // trigger a programs data re-load so the recreated program new config version is shown/used
    const _programs = await loadPrograms()
    // re-select with the updated program so any changes are shown/used
    // UPDATE: the `programs` state var doesn't seem to be updated when this runs, so we now get the array of programs from the `loadPrograms` call directly
    if (selectedProgram && program && selectedProgram.id === program.id && _programs) {
      const updatedProgram = _.find(_programs, (prog) => prog.id === selectedProgram?.id)
      // console.log('ProjectProgramsPage - reloadPrograms - updatedProgram: ', updatedProgram)
      selectProgram(updatedProgram)
    }
  }

  // -------

  useEffect(() => {
    // mount
    console.log('ProjectProgramsPage - mount')
    mounted.current = true
    loadPrograms()
    // unmount
    return () => {
      console.log('ProjectProgramsPage - unmount')
      mounted.current = false
    }
  }, [])

  // -------

  const showAddProgramModal = () => {
    setShowProgramFormModal(true)
  }

  const showEditProgramModal = (program: Program) => {
    setShowProgramFormModal(true)
    setEditProgram(program)
  }

  // call directly to initiate a modal callback (used when the user clicks custom buttons to close the modal instead of the modals own 'x' button etc.)
  const hideProgramModal = () => {
    console.log('ProjectProgramsPage - hideProgramModal')
    // NB: DON'T clear/reset `editProgram` here, see `didHideProgramModal` which is called once the modal has actually closed & we can safely reset it then
    // NB: this stops a saved edit form flipping the 'updated' success text to 'created' briefly while the modal closes if we reset it straight away here
    // NB: & also stops the form title flipping from edit to add as it closes via the cancel button
    setShowProgramFormModal(false)
  }

  // triggered via modal callbacks once the modal has already closed
  const didHideProgramModal = () => {
    console.log('ProjectProgramsPage - didHideProgramModal')
    setShowProgramFormModal(false)
    setEditProgram(undefined)
  }

  // -------

  const onEdit = (_program: Program) => {
    showEditProgramModal(_program)
  }

  const onDidDelete = (_program: Program) => {
    // trigger a programs data re-load so the deleted program no longer shows
    loadPrograms()
    selectProgram(undefined)
  }

  const onDidRecreate = async (_program: Program) => {
    await reloadPrograms(_program)
  }

  const onProgramChange = async (program: Program, data: Partial<Program>) => {
    // console.log('ProjectProgramsPage - onProgramChange - program:', program, 'data:', data)
    await projectAdmin.actions.updateCompanyProjectProgram(company.id, project.id, program.id, data)
    await reloadPrograms(program)
  }

  // -------

  const renderProgramFilterForm = () => {
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        filterTitle='Filter by name'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          filterPrograms(filter)
        }}
      />
    )
  }

  // -------

  const renderProgramTableRowContent = (program: Program, isSelected: boolean, filter?: string) => (
    <ProjectProgramListItem
      companyId={company.id}
      project={project}
      program={program}
      active={isSelected}
      activeProgram={selectedProgram}
      filter={filter}
      serverProgramConfigVersion={isSiteAdmin ? serverConfigStore.serverConfig?.programConfigVersion : undefined} // NB: don't pass a value if the user isn't a site admin (so we don't show anything for normal users)
      key={program.id}
      onClick={() => selectProgram(program)}
      // onEditClick={() => this.showEditProgramModal(program)}
      onlineStatus={STATUS_ENABLED ? projectStatus.getProgramOnlineStatus(program.id) : undefined}
    />
  )

  // -------

  const renderProgramAddEditForm = (program?: Program) => (
    <ProgramForm
      mode={editProgram ? ProgramFormMode.Edit : ProgramFormMode.Add}
      companyId={company.id}
      project={project}
      program={program}
      onCancel={() => { hideProgramModal() }}
      onSave={async () => {
        console.log('ProjectProgramsPage - ProgramForm - onSave')
        await reloadPrograms(program)
        // NB: we don't auto close/hide the modal form when it saves, leave it up for a success message to show & the user to dismiss manually
      }}
      onClose={() => { hideProgramModal() }}
      insideModal={true}
    />
  )

  const renderAddProgramModal = () => {
    return (
      <ArkModal
        open={showProgramFormModal}
        onClose={() => didHideProgramModal()}
        // NB: disable close on `escape` key press (as the program form can trigger a ports 2nd modal form which would also dismiss on esc, so for now we just ignore it completely)
        // TODO: ideally we'd only disable esc close if a sub-modal/form is showing over this one, but as this modal is handled externally to the program form thats not easy currently...
        closeOnEscape={false}
      >
        {renderProgramAddEditForm(editProgram)}
      </ArkModal>
    )
  }

  // -------

  const rightSidebar = selectedProgram && (
    <ProjectProgramSidebar
      companyId={company.id}
      project={project}
      program={selectedProgram}
      serverProgramConfigVersion={serverConfigStore.serverConfig?.programConfigVersion}
      onEdit={onEdit}
      onDidDelete={onDidDelete}
      onDidRecreate={onDidRecreate}
      onProgramChange={onProgramChange}
    />
  )

  return (
    <ArkProjectManagerPage
      onRightSidebarClose={() => setSelectedProgram(undefined)}
      rightSidebar={rightSidebar}
    >
      <ArkManagerContentView
        title={OBJECT_PROGRAM_NAME_PLURAL}
        breadcrumb={[{
          path: ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_VIEW, project.id),
          title: ROUTES.formatBreadcrumbRootTitle(project.name, `${OBJECT_PROJECT_NAME} ${SECTION_MANAGER_SUFFIX_NAME}`)
        }]}
      >
        <ArkProjectStatusBanner />
        <ArkManagerListView
          loading={loading}
          items={programs}
          selectedItem={selectedProgram}
          itemRow={(program: Program, isSelected: boolean) => {
            return renderProgramTableRowContent(program, isSelected, filter)
          }}
          errors={loadingError ? [loadingError] : undefined}
          // topbar={this.topBarContent}
          topbarAddItemTitle={'CREATE ' + OBJECT_PROGRAM_NAME}
          onAdd={() => showAddProgramModal()}
          // FILTERING:
          filter={filter}
          filteredItems={filteredPrograms}
          filterForm={renderProgramFilterForm()}
          onClearFilter={() => filterPrograms('') }
        />
        {renderAddProgramModal()}
      </ArkManagerContentView>
    </ArkProjectManagerPage>
  )
}

export default withProjectStatusContext(ProjectProgramsPage)
