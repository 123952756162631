import React, { useContext } from 'react'

import { withAuthContext, withUserContext, withNavContext, IAuthMultiContext, IUserMultiContext, INavMultiContext } from '../../core/providers'
import ServerAPIClient from '../services/ServerAPIClient'
import ServerAuthAPI from '../services/ServerAuthAPI'
import ServerCompanyAPI from '../services/ServerCompanyAPI'
import { ServerCompanyInviteAlreadyAcceptedError, ServerCompanyInviteExpiredError } from '../services/ServerAPIErrors'

import { parseJwt } from '../utilities/token'

import { CompanyInviteUserDetails, CompanyInviteUserStatus, UserAccountStatus } from '../../core/models'
import { NavSection } from './NavProvider'

export interface ICompanyInviteStore {
  inviteToken?: string
  inviteDetails?: CompanyInviteUserDetails
  inviteDetailsLoading: boolean
  inviteExpired: boolean
  inviteAlreadyAccepted: boolean
  loginRequired: boolean
  registrationRequired: boolean
  isSubmitting: boolean
  success: boolean
  companyId?: number
  error?: Error
}

export interface ICompanyInviteActions {
  lookupInviteTokenAndProcess: (inviteToken?: string) => Promise<void>
  // lookupInviteToken: (inviteToken?: string) => Promise<void>
  // TODO: re-work these to just update the provider state instead of return (although need it available at a specific time during login)
  cacheCompanyInvite: (inviteToken: string) => void
  getCachedInvite: () => {[key: string]: any} | undefined
  hasCachedInviteForEmail: (email: string) => boolean
  clearCachedInvite: () => void
}

export interface ICompanyInviteContext {
  actions: ICompanyInviteActions
  store: ICompanyInviteStore
}

export interface ICompanyInviteMultiContext {
  companyInviteContext: ICompanyInviteContext
}

export const CompanyInviteContext = React.createContext<ICompanyInviteContext>({} as ICompanyInviteContext)

export const useCompanyInvite = () => useContext(CompanyInviteContext)

export interface CompanyInviteProviderProps extends IAuthMultiContext, IUserMultiContext, INavMultiContext {
  apiClient: ServerAPIClient
  authApi: ServerAuthAPI
  children?: React.ReactNode
}
export interface CompanyInviteProviderState extends ICompanyInviteStore {
}

class CompanyInviteProvider extends React.Component<CompanyInviteProviderProps, CompanyInviteProviderState> {
  private _companyApi: ServerCompanyAPI

  constructor (props: CompanyInviteProviderProps) {
    super(props)
    this._companyApi = new ServerCompanyAPI(props.apiClient)
    this.state = {
      inviteToken: undefined,
      inviteDetails: undefined,
      inviteDetailsLoading: false,
      inviteExpired: false,
      inviteAlreadyAccepted: false,
      loginRequired: false,
      registrationRequired: false,
      isSubmitting: false,
      success: false,
      companyId: undefined,
      error: undefined
    }
  }

  // -------

  clearInviteData = () => {
    this.setState({
      inviteToken: undefined,
      inviteDetails: undefined,
      inviteDetailsLoading: false,
      inviteExpired: false,
      inviteAlreadyAccepted: false,
      loginRequired: false,
      registrationRequired: false,
      isSubmitting: false,
      success: false,
      companyId: undefined,
      error: undefined
    })
  }

  // -------

  // main entry point
  lookupInviteTokenAndProcess = async (inviteToken?: string) => {
    const isBusy = this.state.inviteDetailsLoading || this.state.isSubmitting
    console.log('CompanyInviteProvider - lookupInviteTokenAndProcess - inviteToken:', inviteToken, ' isBusy:', isBusy)
    if (isBusy) return // don't (re)run if its already running
    this.clearInviteData() // clear the local state vars before we start
    // TESTING HERE: clear the cached invite if one exists & we reach here, this means it doesn't remain cached & cause issues if errors are hit below before it can be accepted
    this.clearCachedInvite()
    await this.lookupInviteToken(inviteToken)
    console.log('CompanyInviteProvider - lookupInviteTokenAndProcess - inviteToken:', inviteToken, ' state - inviteDetailsLoading:', this.state.inviteDetailsLoading, ' inviteDetails:', this.state.inviteDetails, ' inviteAlreadyAccepted:', this.state.inviteAlreadyAccepted)
    if (inviteToken && !this.state.inviteDetailsLoading && this.state.inviteDetails && !this.state.inviteAlreadyAccepted) {
      await this.processInviteDetails(inviteToken)
    }
  }

  // queries the api for the invite token & notes the response result in the state inviteDetails ref
  lookupInviteToken = async (inviteToken?: string) => {
    // console.log('CompanyInviteProvider - lookupInviteToken - inviteToken:', inviteToken)
    if (inviteToken) {
      try {
        await new Promise((resolve) => this.setState({ inviteDetailsLoading: true, inviteDetails: undefined }, () => { resolve(true) }))

        // query the invite token to get back details on it (if the tokens expired it will throw an error & show it on screen)
        const inviteDetails = await this._companyApi.lookupUserCompanyInvite(inviteToken)
        // new 'already accepted' invite details response now returns with status 200 & a new inviteStatus value instead of throwing a specific error
        if (inviteDetails?.inviteStatus === CompanyInviteUserStatus.accepted) {
          await new Promise((resolve) => this.setState({
            inviteDetailsLoading: false,
            inviteDetails: inviteDetails ?? undefined,
            inviteAlreadyAccepted: true, // NB: instead of display the raw error, we display custom UI for already accepted invites (to give more detail)
            companyId: inviteDetails?.companyId
          }, () => { resolve(true) }))
        } else {
          // invite details loaded ok (not 'already accepted' & didn't throw an error), should be ok to proceed to accept the invite (triggered after we update the state)
          await new Promise((resolve) => this.setState({ inviteDetailsLoading: false, inviteDetails: inviteDetails ?? undefined }, () => { resolve(true) }))
        }
      } catch (error) {
        console.error('CompanyInviteProvider - lookupInviteToken - error: ', error)
        if (error instanceof ServerCompanyInviteExpiredError) {
          await new Promise((resolve) => this.setState({ inviteDetailsLoading: false, inviteExpired: true }, () => { resolve(true) })) // NB: instead of display the raw error, we display custom UI for expired invites (to give more detail)
        } else {
          await new Promise((resolve) => this.setState({ inviteDetailsLoading: false, error }, () => { resolve(true) })) // NB: currently re-using the invite submit error var
        }
      }
    } else {
      await new Promise((resolve) => this.setState({ inviteDetailsLoading: false, error: Error('Invite token missing') }, () => { resolve(true) }))
    }
  }

  processInviteDetails = async (inviteToken: string) => {
    // console.log('CompanyInviteProvider - processInviteDetails - inviteToken:', inviteToken)
    const { inviteDetails } = this.state

    if (!inviteDetails) {
      await new Promise((resolve) => this.setState({ error: Error('Invalid or unknown invite token') }, () => { resolve(true) }))
      return
    }

    if (this.props.authContext.actions.isLoggedIn()) {
      // halt if the invite is for a different user/email
      if (inviteDetails.email !== this.props.userContext.store.user?.email) {
        await new Promise((resolve) => this.setState({ error: Error('Invite is for a different user') }, () => { resolve(true) }))
        return
      }

      // TODO: also catch if the user is already a member & auto select & redirect to that company?
      // this.props.navContext.actions.gotoSection(NavSection.viewer)

      // logged in user matches invite, attempt to verify/accept the invite...
      this.setState({ loginRequired: false, registrationRequired: false }) // reset these incase we just logged in & redirected back to the invite accept page
      await this.acceptInviteToken(inviteToken, inviteDetails)
    } else {
      await this.cacheInviteTokenAndLogin(inviteToken, inviteDetails)
    }
  }

  cacheInviteTokenAndLogin = async (inviteToken: string, inviteDetails: CompanyInviteUserDetails) => {
    // console.log('CompanyInviteProvider - cacheInviteTokenAndLogin - inviteToken:', inviteToken, ' inviteDetails:', inviteDetails)
    if (inviteToken) {
      this.cacheCompanyInvite(inviteToken)

      // check if the invite is for an existing or new user & redirect to login/registration accordingly
      // NB: tapping into the auth providers login/registration cache to pre-fill the email for login & email & name for registration
      // NB: the user status will be 'active' if they have an account they can login with, 'pending' if they have a partial account (email not verified, & maybe other steps not complete?)
      const userHasAccount = inviteDetails.userStatus === UserAccountStatus.active || inviteDetails.userStatus === UserAccountStatus.pending
      if (userHasAccount) {
        // console.log('CompanyInviteProvider - cacheInviteTokenAndLogin - userHasAccount - loginRequired...')
        this.props.authContext.actions.cacheLoginEmail(inviteDetails.email)
        await new Promise((resolve) => this.setState({ loginRequired: true, registrationRequired: false }, () => { resolve(true) }))
      } else {
        // console.log('CompanyInviteProvider - cacheInviteTokenAndLogin - !userHasAccount - registrationRequired...')
        this.props.authContext.actions.cacheRegisterEmail(inviteDetails.email, inviteDetails.firstName, inviteDetails.lastName)
        await new Promise((resolve) => this.setState({ loginRequired: false, registrationRequired: true }, () => { resolve(true) }))
      }
    }
  }

  // NB: this is only run if/once the user is logged in
  acceptInviteToken = async (inviteToken: string, _inviteDetails: CompanyInviteUserDetails) => {
    // console.log('CompanyInviteProvider - acceptInviteToken - inviteToken:', inviteToken, ' _inviteDetails:', _inviteDetails)
    if (inviteToken) {
      // clear the cached invite (even if it fails below, we won't need it cached anymore)
      // NB: we now also do this before `acceptInviteToken` is even called, so its cleared if errors happen, leaving this here as a fallback for now
      this.clearCachedInvite()

      await new Promise((resolve) => this.setState({
        inviteToken: inviteToken,
        isSubmitting: true
      }, () => { resolve(true) }))

      try {
        const userCompany = await this._companyApi.acceptUserCompanyInvite(inviteToken)
        if (userCompany) {
          // re-load the user data after accepting the company invite, so the user has it in their companies list
          await this.props.userContext.actions.reloadUserData()

          await new Promise((resolve) => this.setState({
            isSubmitting: false,
            success: true,
            companyId: userCompany.id
          }, () => { resolve(true) }))
          // this.props.history.push(ROUTES.VIEWER) // TODO: commented out the redirect for now to show a msg, ideally auto-redirect AND show success message of some sort?
        } else {
          throw new Error('Invalid response')
        }
      } catch (error) {
        console.error('CompanyInviteProvider - acceptInviteToken - error: ', error)

        if (error instanceof ServerCompanyInviteAlreadyAcceptedError) {
          console.error('CompanyInviteProvider - acceptInviteToken - ServerCompanyInviteAlreadyAcceptedError - redirect to the company?....')
          // TESTING: redirect to the viewer without showing the 'invite already accepted' error
          // NB: this stops the user getting stuck on the invite accept page & refreshing would restart the whole process, caching the token & trying to accept it again
          // TODO: auto select the company if its not already? we don't currently know the company id if the accept call failed (even if its already accepted)
          // TODO: once we implement support for the new ..invite/details/{token} endpoint, we could use the details from that to handle it properly here?
          // TODO: this.props.server.selectCompany(TODO)
          this.props.navContext.actions.gotoSection(NavSection.viewer)
          // this.props.userContext.actions.selectCompany(inviteDetails.) // NB: we don't have the company id in the invite details data currently, add it?
        }

        await new Promise((resolve) => this.setState({
          isSubmitting: false,
          success: false,
          error
        }, () => { resolve(true) }))
      }
    }
  }

  // -------
  // Invite cache (used when the user is logged out)

  // loadCachedCompanyInvite = () => {
  //   const companyInvite = this.getCachedInvite()
  //   this.setState({ inviteToken: companyInvite?.companyInvite })
  // }

  // NB: this is currently handled via the main AppRouter checking if a cached invite exists after a direct login action
  // NB: & if so it auto redirects to the relevant invite page to process & clear the cached value
  cacheCompanyInvite = (inviteToken: string) => {
    // console.log('CompanyInviteProvider - cacheCompanyInvite - inviteToken:', inviteToken)
    // NB: only cache a single invite at a time (regardless of type)
    localStorage.setItem('invite', JSON.stringify({
      companyInvite: inviteToken
    }))
  }

  getCachedInvite = () : {[key: string]: any} | undefined => {
    const jsonData = localStorage.getItem('invite')
    const inviteData = jsonData ? JSON.parse(jsonData) : null
    if (inviteData) {
      return inviteData // NB: this returns the cached object, so the calling code can check the type based on which key is set/used
    }
    return undefined
  }

  hasCachedInviteForEmail = (email: string): boolean => {
    const cachedInvite = this.getCachedInvite()
    const inviteToken = cachedInvite && cachedInvite.companyInvite ? cachedInvite.companyInvite as string : undefined
    // console.log('CompanyInviteProvider - hasCachedInviteForEmail - inviteToken:', inviteToken)
    const decodedToken = inviteToken ? parseJwt(inviteToken) : undefined // NB: this doesn't validate the token, just decodes it
    // console.log('CompanyInviteProvider - hasCachedInviteForEmail - decodedToken:', decodedToken)
    const inviteEmail = decodedToken ? decodedToken.email : undefined
    // console.log('CompanyInviteProvider - hasCachedInviteForEmail - inviteEmail:', inviteEmail)
    if (inviteEmail === email) {
      // console.log('CompanyInviteProvider - hasCachedInviteForEmail - EMAIL MATCH - TREAT AS INVITE REGISTRATION...')
      return true
    } else if (inviteEmail !== undefined) {
      // console.log('CompanyInviteProvider - hasCachedInviteForEmail - EMAIL MISMATCH - NOT AN INVITE REGISTRATION (invite for a different email)...')
    } else {
      // console.log('CompanyInviteProvider - hasCachedInviteForEmail - NO INVITE CACHED...')
    }
    return false
  }

  clearCachedInvite = () => {
    // console.log('CompanyInviteProvider - clearCachedInvite')
    localStorage.removeItem('invite')
  }

  // -------

  actions: ICompanyInviteActions = {
    lookupInviteTokenAndProcess: this.lookupInviteTokenAndProcess,
    cacheCompanyInvite: this.cacheCompanyInvite,
    getCachedInvite: this.getCachedInvite,
    hasCachedInviteForEmail: this.hasCachedInviteForEmail,
    clearCachedInvite: this.clearCachedInvite
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: ICompanyInviteStore = {
  //  ...
  // }

  render () {
    return (
      <CompanyInviteContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for ICompanyInviteStore */ }}
      >
        {this.props.children}
      </CompanyInviteContext.Provider>
    )
  }
}

const withCompanyInviteContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withCompanyInviteContextHOC = (props: any) => (
    <CompanyInviteContext.Consumer>
      {(companyInviteContext) => {
        if (companyInviteContext === null) {
          throw new Error('CompanyInviteConsumer must be used within an CompanyInviteProvider')
        }
        // console.log('withCompanyInviteContext - render - CompanyInviteContext.Consumer - companyInviteContext.store: ', companyInviteContext.store)
        return (<Component {...props} {...{ companyInviteContext: companyInviteContext }} />)
      }}
    </CompanyInviteContext.Consumer>
  )
  return withCompanyInviteContextHOC
}

const CompanyInviteProviderWithContext = withUserContext(withAuthContext(withNavContext(CompanyInviteProvider)))

export { CompanyInviteProviderWithContext as CompanyInviteProvider }
export { withCompanyInviteContext }
