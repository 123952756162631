import React from 'react'

import { Helmet } from 'react-helmet'
import { BrowserRouter as Router } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'

// import ServerAPIClient from './core/services/ServerAPIClient'
// import ServerAuthAPI from './core/services/ServerAuthAPI'
import AppRouter from './AppRouter'

import { ServerProvider, ServerContext, NavProvider, APIProvider, AuthProvider, UserProvider, User2FAProvider, CompanyInviteProvider, ServerConfigProvider, ServerConfigContext, SectionSwitcherProvider, GlobalConfigProvider, GlobalConfigContext } from './core/providers'
import IdleProvider from './core/providers/IdleProvider'
import LayoutProvider from './core/providers/LayoutProvider'
import LocalConfigProvider from './core/providers/LocalConfigProvider'
import MockAPIProvider from './core/providers/MockAPIProvider'
import OnboardingProvider from './core/providers/OnboardingProvider'
import ResponsiveProvider from './core/providers/ResponsiveProvider'

import ArkBrowserNotice from './core/components/ArkBrowserNotice'
import ArkLoaderView from './core/components/ArkLoaderView'
import ArkOnboarding from './core/components/ArkOnboarding'
import ArkPage from './core/components/ArkPage/ArkPage'

import { initSentry } from './sentry'

import './App.css'

initSentry()

interface IProps {}
interface IState {}

class App extends React.Component<IProps, IState> {
  private _loadingTimer: ReturnType<typeof setInterval> | undefined

  constructor (props: IProps) {
    super(props)
    this.state = {}
  }

  render () {
    return (
      <>
        <GlobalConfigProvider>
          <GlobalConfigContext.Consumer>
            {(configContext) => {
              const { loading, config } = configContext.store
              // show a full page loading message while we load the site/app global config
              if (loading) {
                // NB: we can't use <LoadingPage /> directly here, as it includes ArkPageHeader which relies on various providers not available here
                // NB: we also can't use ArkPage here currently, as it refers to `GlobalConfig` which isn't ready yet
                // NB: see the notes further down around the `ServerContext` usage for more details
                return (<ArkLoaderView message={'Loading'} />)
              }
              return (
                <>
                  <Helmet>
                    <link href={config.favicon} rel="icon" />
                    <title>{config.appName}</title>
                  </Helmet>
                  <Router>
                    <OnboardingProvider>
                      <LayoutProvider>
                        <ResponsiveProvider>
                          <NavProvider>
                            <ServerProvider>
                              <ServerContext.Consumer>
                                {(serverContext) => {
                                  console.log('App - render - serverContext: ', serverContext)
                                  const { apiClient, authApi, serverConfigApi } = serverContext.store
                                  // TESTING: should only happen if loading additional servers async (via code splitting)
                                  if (!apiClient || !authApi) {
                                    return (
                                      // NB: we can't use <LoadingPage /> directly here, as it includes ArkPageHeader which relies on various providers
                                      // NB: ..those providers that aren't setup until the servers data is available & _load has been called above
                                      // NB: ..so we mimic the loading page for this scenario (currently without any header shown at all)
                                      // TODO: should we show a dummy header with just the logo in its place (but no provider/server related usage)?
                                      <Router>
                                        <ArkPage>
                                          <ArkLoaderView message={`Loading ${config.appName}`} />
                                        </ArkPage>
                                      </Router>
                                    )
                                  }
                                  return (
                                    <>
                                      <MockAPIProvider>
                                        <APIProvider apiClient={apiClient}>
                                          <ServerConfigProvider apiClient={apiClient} serverConfigApi={serverConfigApi}>
                                            <ServerConfigContext.Consumer>{(serverConfigContext) => {
                                              return (
                                                <AuthProvider apiClient={apiClient} authApi={authApi}>
                                                  <UserProvider apiClient={apiClient} authApi={authApi} serverConfigContext={serverConfigContext}>
                                                    <User2FAProvider apiClient={apiClient} authApi={authApi}>
                                                      <CompanyInviteProvider apiClient={apiClient} authApi={authApi}>
                                                        <LocalConfigProvider>
                                                          <IdleProvider>
                                                            <SectionSwitcherProvider>
                                                              <ArkBrowserNotice>
                                                                <ArkOnboarding>
                                                                  <AppRouter />
                                                                </ArkOnboarding>
                                                              </ArkBrowserNotice>
                                                            </SectionSwitcherProvider>
                                                          </IdleProvider>
                                                        </LocalConfigProvider>
                                                      </CompanyInviteProvider>
                                                    </User2FAProvider>
                                                  </UserProvider>
                                                </AuthProvider>
                                              )
                                            }}
                                            </ServerConfigContext.Consumer>
                                          </ServerConfigProvider>
                                        </APIProvider>
                                      </MockAPIProvider>
                                    </>
                                  )
                                }}
                              </ServerContext.Consumer>
                            </ServerProvider>
                          </NavProvider>
                        </ResponsiveProvider>
                      </LayoutProvider>
                    </OnboardingProvider>
                  </Router>
                </>
              )
            }}
          </GlobalConfigContext.Consumer>
        </GlobalConfigProvider>
      </>
    )
  }
}

export default App
