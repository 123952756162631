import React from 'react'

import ArkAudioMeterToolbar from '../ArkAudioMeterToolbar'
import ArkIconButton from '../ArkIconButton'
import ArkSpacer from '../ArkSpacer'

export interface ArkAudioLevelsButtonProps {
  onClick?: () => void
  onMouseEnter?: () => void // for ArkTooltip
  onMouseLeave?: () => void // for ArkTooltip
  postDisabled?: boolean
  postValue: number
  preDisabled?: boolean
  preValue: number
  width?: number
}

const ArkAudioLevelsButton = (props: ArkAudioLevelsButtonProps) => (
  <ArkIconButton
    onClick={props.onClick}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    width={props.width}
  >
    <ArkAudioMeterToolbar
      color='var(--yellow)'
      disabled={props.preDisabled}
      value={props.preValue}
    />
    <ArkSpacer small />
    <ArkAudioMeterToolbar
      color='var(--green)'
      disabled={props.postDisabled}
      value={props.postValue}
    />
  </ArkIconButton>
)

export default ArkAudioLevelsButton
