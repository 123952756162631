import React, { ReactNode } from 'react'

import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkPopup from 'src/core/components/ArkPopup'
import ArkVolumeInput from 'src/core/components/ArkVolumeInput'

import styles from './ProgramAudioPopup.module.css'

const DEBUG_ALWAYS_SHOW: boolean = false

interface ProgramAudioPopupProps {
  mute: boolean
  open: boolean
  trigger: ReactNode
  volume: number
  onClose: () => void
  onMuteClick: () => void
  onOpen: () => void
  onVolumeChange: (volume: number) => void
}

const ProgramAudioPopup = (props: ProgramAudioPopupProps): ReactNode => (
  <ArkPopup
    className={styles.popup}
    open={DEBUG_ALWAYS_SHOW || props.open}
    position='top center'
    trigger={props.trigger}
    onClose={props.onClose}
    onOpen={props.onOpen}
  >
    <div className={styles.body}>
      <ArkVolumeInput
        value={props.volume}
        vertical
        onChange={props.onVolumeChange}
      />
      <ArkIconButton
        active={props.mute}
        activeColor='var(--red)'
        name='volume-mute'
        size={24}
        onClick={props.onMuteClick}
      />
    </div>
  </ArkPopup>
)

export default ProgramAudioPopup
