import { Program } from 'src/core/models'
import { PlayerResolution } from 'src/core/types/player'
import { ProgramError, ProgramWatchdogStatus } from './types'

export const formatPlayerError = (error: string) => {
  if (error === 'NO_PLAYABLE_SOURCE_FOUND') return 'Error: No playable source found'
  return `Error: ${error}`
}

export const formatTime = (time: number) => {
  const hours = Math.floor(time / 3600)
  const hoursStr = (hours >= 10) ? '' + hours : '0' + hours
  const minutes = Math.floor((time % 3600) / 60)
  const minutesStr = (minutes >= 10) ? '' + minutes : '0' + minutes
  const seconds = Math.floor(time % 60)
  const secondsStr = (seconds >= 10) ? '' + seconds : '0' + seconds
  return hoursStr + ':' + minutesStr + ':' + secondsStr
}

// TODO #999 dvr - move to types

export const getProgramError = (program: Program, passthrough: boolean): ProgramError | undefined => {
  const abrUrl: string | undefined = program.getSLDPOutputURL(false)
  const passthroughUrl: string | undefined = program.getSLDPOutputURL(true)
  if (!passthrough && !abrUrl) {
    return ProgramError.NoAbrStream
  }
  if (passthrough && !passthroughUrl) {
    return ProgramError.NoPassthroughStream
  }
  if (!abrUrl && !passthroughUrl) {
    return ProgramError.NoStream
  }
}

export const getProgramErrorText = (programError: ProgramError): string => {
  switch (programError) {
    case ProgramError.NoAbrStream:
      return 'Transcoder stream not available'
    case ProgramError.NoPassthroughStream:
      return 'Passthrough stream not available'
    case ProgramError.NoStream:
      return 'No streams available'
  }
}

export const getResolutionText = (resolution: PlayerResolution, abr: boolean, passthrough: boolean): string => {
  if (passthrough) return 'Passthrough'
  if (abr) return `Up to ${resolution}`
  return resolution
}

export const getSecondsToday = (ms: number): number => {
  const date: Date = new Date(ms)
  return (date.getHours() * 60 * 60) + (date.getMinutes() * 60) + date.getSeconds()
}

export const getWatchdogStatusMessage = (status: ProgramWatchdogStatus): string => {
  switch (status) {
    case ProgramWatchdogStatus.Connecting:
      return 'Connecting...'
    case ProgramWatchdogStatus.Playing:
      return 'Playing'
    case ProgramWatchdogStatus.Reconnecting:
      return 'Reconnecting...'
    case ProgramWatchdogStatus.Restarting:
      return 'Restarting...'
    default:
      return ''
  }
}

export const parseAdvancedParameters = (parameters: string): any => {
  try {
    return JSON.parse(parameters)
  } catch {
    return {}
  }
}

/**
 * timeline
 */

export const getTimelineMarkers = (length: number, scale: number): string[] => {
  const hours: number = length / 3600
  const scaledHours: number = hours / scale
  const markers: string[] = []
  for (let hour: number = hours; hour > 0; hour--) {
    const skipOneHour: boolean = !!(hour % 2) && scaledHours >= 10 // 2, 4, 6, etc.
    const skipThreeHours: boolean = !!(hour % 4) && scaledHours >= 20 // 4, 8, 12, etc.

    const addHour: boolean = hour >= 1 && !skipOneHour && !skipThreeHours
    const addThreeQuarters: boolean = hour >= 0.75 && scaledHours <= 1
    const addHalf: boolean = hour >= 0.5 && scaledHours <= 4
    const addQuarter: boolean = hour >= 0.25 && scaledHours <= 1

    // 1 hour
    if (addHour) {
      markers.push(`-${hour}h`)
    }

    // 45 minutes
    if (addThreeQuarters) {
      markers.push(`-${hour - 1}h 45m`)
    }

    // 30 minutes
    if (addHalf) {
      markers.push(`-${Math.max(0, hour - 1)}h 30m`)
    }

    // 15 minutes
    if (addQuarter) {
      markers.push(`-${Math.max(0, hour - 1)}h 15m`)
    }
  }
  markers.push('LIVE')
  return markers
}
