import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { DVR_ENABLED, STATUS_ENABLED } from 'src/constants/config'
import * as ROUTES from 'src/constants/routes'
import { FEATURE_DVR_NAME, OBJECT_PROGRAM_NAME } from 'src/constants/strings'
import ArkHint from 'src/core/components/ArkHint'
import ArkIcon from 'src/core/components/ArkIcon'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkLoadingCheckbox from 'src/core/components/ArkLoadingCheckbox'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkProgramIcon from 'src/core/components/ArkProgramIcon'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { OnlineStatus, Program, Project, UserProject } from 'src/core/models'
import { useProjectStatus, UserContext } from 'src/core/providers'
import { getOnlineStatusText } from 'src/core/utilities/status'

import ProgramDeleteView, { ProjectProgramDeleteCallback } from './ProgramDeleteView'
import ProjectProgramProtocolURLsView from './ProjectProgramProtocolURLsView'
import ProgramRecreateView, { ProjectProgramRecreateCallback } from './ProgramRecreateView'

import styles from './ProjectProgramSidebar.module.css'

export type ProjectProgramEditCallback = (program: Program) => void

interface IProps {
  companyId: number
  project: UserProject | Project
  program?: Program
  serverProgramConfigVersion?: number // the current program config version the server uses (so we can check if the program is older & can/should be re-created/updated)
  onEdit?: ProjectProgramEditCallback
  onDidDelete?: ProjectProgramDeleteCallback
  onDidRecreate?: ProjectProgramRecreateCallback
  onProgramChange: (program: Program, data: Partial<Program>) => void
}

const ProjectProgramSidebar = (props: IProps) => {
  const projectStatus = useProjectStatus()

  const { companyId, project, program, serverProgramConfigVersion, onEdit: _onEdit, onDidDelete, onDidRecreate } = props
  if (!companyId || !project || !program) return (<></>)

  const { actions: userActions } = useContext(UserContext)
  const isSiteAdmin = userActions.isSiteAdmin()

  const showAudioOnlyNoProtocolWarning = (program.isAudioOnly && project.transcoderSettings !== undefined && !project.transcoderSettings.hasAudioOnlyProtocolsEnabled())
  const showAudioOnlyAnd360VideoWarning = (program.isAudioOnly && program.threeSixty)

  const programUpdateAvailable = isSiteAdmin && program.shouldUpdateConfigVersion(serverProgramConfigVersion) // NB: skip if the user isn't a site admin

  const onEdit = () => {
    if (_onEdit && program) _onEdit(program)
  }

  const onlineStatus: OnlineStatus | undefined = STATUS_ENABLED
    ? projectStatus.getProgramOnlineStatus(program.id)
    : undefined

  // console.log('ProjectProgramSidebar - program:', props.program)

  return (
    <>

      <ArkPanel bordered className={styles.sidebarHeader}>
        <ArkPanel.Header
          className={styles.sidebarTitle}
          title={program.name}
          icon={
            <ArkProgramIcon
              color={program.colour}
              initials={program.shortNameCapitalised()}
              onlineStatus={onlineStatus}
            />
          }
        />
        <ArkIconButton
          className={styles.menuButton}
          name='settings'
          onClick={onEdit}
          size={24}
        />
      </ArkPanel>

      {/* <ArkButton type="button" fluid size="large" onClick={onEdit}>EDIT {OBJECT_PROGRAM_NAME}</ArkButton> */}

      <ArkSpacer />

      <ArkPanel bordered>
        <ArkPanel.Properties>
          <ArkPanel.PropertySection title={OBJECT_PROGRAM_NAME + ' Details'} titleClassName={styles.panelTitle}>
            <ArkPanel.PropertyRow
              title='ID:'
              titleSize='large'
              value={program.id}
            />
            <ArkPanel.PropertyRow
              title='Name:'
              titleSize='large'
              value={program.name}
            />
            <ArkPanel.PropertyRow
              title='Short Name:'
              titleSize='large'
              value={program.shortName}
            />
            {onlineStatus && (
              <ArkPanel.PropertyRow
                title='Online Status:'
                titleSize='large'
                value={getOnlineStatusText(onlineStatus)}
              />
            )}
            {DVR_ENABLED && (
              <ArkPanel.PropertyRow
                title={`${FEATURE_DVR_NAME}:`}
                titleSize='large'
                value={(
                  <ArkLoadingCheckbox
                    checked={program.dvrEnabled}
                    disabled={!project.dvrEnabled}
                    onChange={() => props.onProgramChange(program, { dvrEnabled: !program.dvrEnabled })}
                    toggle
                  />
                )}
              />
            )}
          </ArkPanel.PropertySection>
          {(program.isAudioOnly || program.threeSixty) && (
            <ArkPanel.PropertyRow
              title='Flags:'
              value={
                <>
                  {(program.isAudioOnly && (<div>Audio Only</div>))}
                  {(program.threeSixty && (<div>360° Video</div>))}
                </>
              }
              titleClassName={styles.programFlagTitle}
              valueClassName={styles.programFlagValues}
            />
          )}
          {(showAudioOnlyNoProtocolWarning || showAudioOnlyAnd360VideoWarning) && (
            <ArkPanel.PropertyRow>
              <ArkPanel.PropertyRowContent>
                {showAudioOnlyNoProtocolWarning && (
                  <div className={styles.programFlagWarningNotice}>
                    <div className={styles.warningIcon}><ArkIcon name='warning' size={28} color={'#f3be0e'} /></div>
                    <div className={styles.warningText}>
                      Audio only program without audio only protocol set.
                      &nbsp;<Link to={ROUTES.getProjectRoute(ROUTES.PROJECT_MANAGER_SETTINGS, project.id)}>View project settings</Link>
                    </div>
                  </div>
                )}
                {showAudioOnlyAnd360VideoWarning && (
                  <div className={styles.programFlagWarningNotice}>
                    <div className={styles.warningIcon}><ArkIcon name='warning' size={28} color={'#f3be0e'} /></div>
                    <div className={styles.warningText}>Audio only will override 360 video and no video will be shown.</div>
                  </div>
                )}
              </ArkPanel.PropertyRowContent>
            </ArkPanel.PropertyRow>
          )}
          {programUpdateAvailable && (
            <ArkPanel.PropertyRow>
              <ArkPanel.PropertyRowContent>
                <div className={styles.programUpdateAvailableNotice}>
                  <div className={styles.warningIcon}><ArkIcon name='warning' size={28} color={'#f3be0e'} /></div>
                  <div className={styles.warningText}>
                    Program version update available: v{program.programConfigVersion} &gt; v{serverProgramConfigVersion}
                  </div>
                  <div className={styles.warningHint}>
                    <ArkHint
                      iconSize={16}
                      message={'There are program config changes available that may improve or fix performance of this program. If you are unsure then please contact support.'}
                      popupPosition='bottom right'
                      popupSize='small'
                      title={'Config Changes Available'}
                      type='info-circle'
                    />
                  </div>
                </div>
                <div className={styles.programUpdateAction}>
                  <ProgramRecreateView
                    companyId={companyId}
                    projectId={project.id}
                    program={program}
                    onDidRecreate={onDidRecreate}
                  />
                </div>
              </ArkPanel.PropertyRowContent>
            </ArkPanel.PropertyRow>
          )}
          <ArkPanel.PropertyDivider />

          {program && (<ProjectProgramProtocolURLsView program={program} />)}

          <ArkPanel.PropertySpacer small />
        </ArkPanel.Properties>
      </ArkPanel>

      <ArkSpacer grow />

      <ProgramDeleteView
        companyId={companyId}
        projectId={project.id}
        program={program}
        onDidDelete={onDidDelete}
      />
    </>
  )
}

export default ProjectProgramSidebar
