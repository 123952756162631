import _ from 'lodash'

import { IDLE_TIMEOUT_DEFAULT_MS, IDLE_TIMEOUT_MAX_MS, IDLE_TIMEOUT_MIN_MS } from 'src/constants/config'
import { ROLE_ADMIN_NAME, ROLE_MEMBER_NAME } from 'src/constants/strings'

import { millisecondsToSeconds } from '../utilities/time'
import { CompanyCounts, CompanyTranscoderSettings, UserCompanyRole, UserAccountStatus } from './'
import { BaseModel } from './base_model'

// FIXME remove optional parameters from constructors

export interface ICompanyUpdateData {
  name?: string
  isActive?: boolean // site-admin only
  isDemo?: boolean // site-admin only
  maxUsers?: number // site-admin only
  projectDefaultMaxUsers?: number // site-admin only
  dvrEnabled?: boolean
  // TODO: other updatable fields...
}
export interface ICompanyAddData extends ICompanyUpdateData {
  name: string // company name is required when adding
  // TODO: any other required fields needed when adding a company?
}

// base Company data model used at the site-admin & company admin (& manager?) level
// NB: see UserCompany for the extended version used to represent a company for a logged in user
export class Company extends BaseModel {
  id: number
  name: string

  isActive: boolean
  isDemo: boolean

  maxUsers: number // company/org wide max user count

  force2fa: boolean
  force2faAllProjects: boolean
  allowManualPrograms: boolean
  allowConferencing: boolean

  maxGuestsPerProject: number
  projectDefaultMaxUsers?: number // the default project specific max user count (can be overridden per project after creation) NB: currently optional as isn't supported by all deployed api versions yet

  // TODO: "flag_share_link": false,
  // TODO: // "custom_data": null,

  transcoderSettings?: CompanyTranscoderSettings
  counts?: CompanyCounts // company info only (not set/used for all endpoints)

  loginServices?: Array<CompanyLoginServiceMinimal> // site-admin only (for the 'all orgs/companies' manager section)

  idleTimeoutManagement: boolean
  idleTimeoutManagementTime: number

  dvrEnabled: boolean

  constructor (
    id: number,
    name: string,
    isActive: boolean,
    isDemo: boolean,
    maxUsers: number,
    force2fa: boolean = false,
    force2faAllProjects: boolean = false,
    allowManualPrograms: boolean = false,
    allowConferencing: boolean = false,
    maxGuestsPerProject: number = 0,
    projectDefaultMaxUsers: number | undefined,
    transcoderSettings: CompanyTranscoderSettings | undefined,
    counts: CompanyCounts | undefined,
    loginServices: Array<CompanyLoginServiceMinimal> | undefined,
    idleTimeoutManagement: boolean | undefined,
    idleTimeoutManagementTime: number | undefined,
    dvrEnabled: boolean

  ) {
    super()
    this.id = id
    this.name = name
    this.isActive = isActive
    this.isDemo = isDemo
    this.maxUsers = maxUsers
    this.force2fa = force2fa
    this.force2faAllProjects = force2faAllProjects
    this.allowManualPrograms = allowManualPrograms
    this.allowConferencing = allowConferencing
    this.maxGuestsPerProject = maxGuestsPerProject
    this.projectDefaultMaxUsers = projectDefaultMaxUsers
    this.transcoderSettings = transcoderSettings
    this.counts = counts
    this.loginServices = loginServices
    this.idleTimeoutManagement = !!idleTimeoutManagement
    this.idleTimeoutManagementTime = idleTimeoutManagementTime
      ? _.clamp(idleTimeoutManagementTime, millisecondsToSeconds(IDLE_TIMEOUT_MIN_MS), millisecondsToSeconds(IDLE_TIMEOUT_MAX_MS))
      : millisecondsToSeconds(IDLE_TIMEOUT_DEFAULT_MS)
    this.dvrEnabled = dvrEnabled
  }

  getJSON () : string {
    return JSON.stringify(this)
  }

  static fromJSON (id: number, json: any) : Company | null {
    if (!json) {
      return null
    }
    const transcoderSettings = CompanyTranscoderSettings.fromJSON(json) ?? undefined
    const counts = CompanyCounts.fromJSON(json) ?? undefined
    let loginServices: Array<CompanyLoginServiceMinimal> | undefined
    if (json.company_login_services && Array.isArray(json.company_login_services)) {
      loginServices = json.company_login_services.map((loginService: any) => CompanyLoginServiceMinimal.fromJSON(loginService)).filter((loginService: CompanyLoginServiceMinimal | null) => loginService !== null) as Array<CompanyLoginServiceMinimal>
    }
    return new Company(
      id,
      json.name,
      json.flag_is_active,
      json.flag_demo,
      json.max_users,
      json.enforce_2fa,
      json.flag_enforce_2fa_all_projects,
      json.flag_allow_manual_programs,
      json.flag_allow_conferencing,
      json.max_guests_per_project,
      json.default_max_users_project ?? undefined, // TODO: check this is the correct field name (& update all occurrences if not)
      transcoderSettings,
      counts,
      loginServices,
      json.idle_timeout_management,
      json.idle_timeout_management_time,
      json.dvr_enabled
    )
  }

  static propertyToJSONKeyMap () {
    return {
      id: 'id',
      name: 'name',
      isActive: 'flag_is_active',
      isDemo: 'flag_demo',
      maxUsers: 'max_users',
      force2fa: 'enforce_2fa',
      force2faAllProjects: 'flag_enforce_2fa_all_projects',
      allowManualPrograms: 'flag_allow_manual_programs',
      allowConferencing: 'flag_allow_conferencing',
      maxGuestsPerProject: 'max_guests_per_project',
      projectDefaultMaxUsers: 'default_max_users_project',
      idleTimeoutManagement: 'idle_timeout_management',
      idleTimeoutManagementTime: 'idle_timeout_management_time',
      dvrEnabled: 'dvr_enabled'
    }
  }

  // helper to convert a base `Company` model to a `UserCompany`
  // for scenarios where a `UserCompany` is required but the data was loaded from an admin specific endpoint instead of user/viewer (we just need to add the company role field to the existing data)
  asUserCompany (userCompanyRole?: UserCompanyRole) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new UserCompany(
      this.id,
      this.name,
      this.isActive,
      this.isDemo,
      this.maxUsers,
      this.force2fa,
      this.force2faAllProjects,
      this.allowManualPrograms,
      this.allowConferencing,
      this.maxGuestsPerProject,
      this.projectDefaultMaxUsers,
      this.transcoderSettings,
      this.counts,
      userCompanyRole,
      this.idleTimeoutManagement,
      this.idleTimeoutManagementTime,
      this.dvrEnabled
    )
  }
}

export class UserCompany extends Company {
  userCompanyRole?: UserCompanyRole

  constructor (
    // Company
    id: number,
    name: string,
    isActive: boolean,
    isDemo: boolean,
    maxUsers: number,
    force2fa: boolean = false,
    force2faAllProjects: boolean = false,
    allowManualPrograms: boolean = false,
    allowConferencing: boolean = false,
    maxGuestsPerProject: number = 0,
    projectDefaultMaxUsers: number | undefined,
    transcoderSettings: CompanyTranscoderSettings | undefined,
    counts: CompanyCounts | undefined,
    // UserCompany
    userCompanyRole: UserCompanyRole | undefined,
    idleTimeoutManagement: boolean | undefined,
    idleTimeoutManagementTime: number | undefined,
    dvrEnabled: boolean
  ) {
    super(id, name, isActive, isDemo, maxUsers, force2fa, force2faAllProjects, allowManualPrograms, allowConferencing, maxGuestsPerProject, projectDefaultMaxUsers, transcoderSettings, counts, undefined, idleTimeoutManagement, idleTimeoutManagementTime, dvrEnabled)
    this.userCompanyRole = userCompanyRole
  }

  static fromJSON (id: number, json: any) : UserCompany | null {
    if (!json) {
      return null
    }
    const transcoderSettings = CompanyTranscoderSettings.fromJSON(json) ?? undefined
    const counts = CompanyCounts.fromJSON(json) ?? undefined
    return new UserCompany(
      id,
      json.name,
      json.flag_is_active,
      json.flag_demo,
      json.max_users,
      json.enforce_2fa,
      json.flag_enforce_2fa_all_projects,
      json.flag_allow_manual_programs,
      json.flag_allow_conferencing,
      json.max_guests_per_project,
      json.default_max_users_project ?? undefined, // TODO: check this is the correct field name (& update all occurrences if not)
      transcoderSettings,
      counts,
      json.company_role?.id ?? UserCompanyRole.unknown,
      json.idle_timeout_management,
      json.idle_timeout_management_time,
      json.dvr_enabled
    )
  }

  static propertyToJSONKeyMap () {
    return {
      ...Company.propertyToJSONKeyMap()
      // userCompanyRole: 'company_role' // TODO: how to deal with sub properties/objects?
    }
  }

  static userCompanyRoleName (userCompanyRole?: UserCompanyRole) {
    if (userCompanyRole) {
      switch (userCompanyRole) {
        case UserCompanyRole.admin: return ROLE_ADMIN_NAME
        case UserCompanyRole.member: return ROLE_MEMBER_NAME
      }
    }
    return 'UNKNOWN'
  }
}

// for the invite details endpoint
export enum CompanyInviteUserStatus {
  unknown = 0,
  accepted = 1
  // TODO: any other values?
}
export interface CompanyInviteUserDetails {
  email: string
  // userId?: number // NB: the api now returns this if the user is logged in, not if logged out, no real need for it client side, the emails enough
  firstName?: string
  lastName?: string

  companyName: string
  companyId?: number // NB: the company id is only returned when the user is logged in
  companyRole?: UserCompanyRole

  // TODO: should we ignore this (site wide) user status & instead get the api to add the company specific user status (UPDATE: see inviteStatus?)
  // TODO: or is that un-needed, as the endpoint will return a specific 'already accepted' response, so it should always be 'pending' if we get here?
  // NB: currently using this to decide whether to show the reigtration or login page when clicking on an invite while logged out
  userStatus?: UserAccountStatus

  inviteStatus?: CompanyInviteUserStatus
}

// TESTING: for the company login service endpoint(s)
export interface CompanyLoginServiceConfigOptions {
}
export interface CompanyLoginServiceConfigOptionsOktaOIDC extends CompanyLoginServiceConfigOptions {
  domain: string
  accessToken: string
  audience: string
  issuer: string
  clientId: string
  nativeClientId?: string
  nativeRedirectURI?: string
}
export interface CompanyLoginServiceConfigOptionsOktaSAML extends CompanyLoginServiceConfigOptions {
  domain: string
  accessToken: string
  issuer: string
  clientId: string
  entryPoint: string
  cert: string
}
export interface CompanyLoginServiceConfigOptionsAuth0 extends CompanyLoginServiceConfigOptions {
  // NB: only a placeholder for now (not implemented)
}

export function isCompanyLoginServiceConfigOptionsOktaOIDC (configOptions: CompanyLoginServiceConfigOptions): configOptions is CompanyLoginServiceConfigOptionsOktaOIDC {
  return (configOptions as CompanyLoginServiceConfigOptionsOktaOIDC).audience !== undefined
}
export function isCompanyLoginServiceConfigOptionsOktaSAML (configOptions: CompanyLoginServiceConfigOptions): configOptions is CompanyLoginServiceConfigOptionsOktaSAML {
  return (configOptions as CompanyLoginServiceConfigOptionsOktaSAML).entryPoint !== undefined
}

export class CompanyLoginService {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    public serviceType: number,
    public enabled: boolean,
    public domain?: string,
    public clientId?: string,
    public clientSecret?: string,
    public restrictionType?: string, // NB: indicates “search behaviours” for the api SSO handling - "for now it’s always `AUTO`", in the future it might be "LOGIN_DOMAINS" (only users of a specific domain), or "MANUAL_SELECTION" (only specific users/groups) etc.
    public instanceId?: number, // the specific id for this service entry within this org/company (used when updating/deleting an existing entry)
    public configOptions?: CompanyLoginServiceConfigOptions
  ) {}

  static fromJSON (json: any) : CompanyLoginService | null {
    if (!json) return null
    let configOptions: CompanyLoginServiceConfigOptions | undefined
    if (json.login_service_domain || json.access_token || json.audience || json.issuer || json.client_id || json.native_client_id || json.native_redirect_uri || json.entry_point || json.cert) {
      // TODO: switch config options depending on the `login_service_id` <<<<
      if (json.audience) { // CompanyLoginServiceConfigOptionsOktaOIDC
        configOptions = {
          domain: json.login_service_domain ?? '',
          accessToken: json.access_token ?? '',
          issuer: json.issuer ?? '',
          clientId: json.client_id ?? '',
          audience: json.audience ?? '',
          nativeClientId: json.native_client_id,
          nativeRedirectURI: json.native_redirect_uri
        }
      }
      if (json.entry_point) { // CompanyLoginServiceConfigOptionsOktaSAML
        configOptions = {
          domain: json.login_service_domain ?? '',
          accessToken: json.access_token ?? '',
          issuer: json.issuer ?? '',
          clientId: json.client_id ?? '',
          entryPoint: json.entry_point ?? '',
          cert: json.cert ?? ''
        }
      }
    }
    return new CompanyLoginService(
      json.login_service_id,
      json.enabled ?? false,
      json.login_service_domain,
      json.client_id,
      json.client_secret,
      json.restriction_type,
      json.id,
      configOptions
    )
  }
}

// NB: minimal version of the CompanyLoginService model used in site-admin only 'all orgs/companies' manager section
export class CompanyLoginServiceMinimal {
  // eslint-disable-next-line no-useless-constructor
  constructor (
    public serviceType: number,
    public enabled: boolean,
    public domain?: string,
    public instanceId?: number // the specific id for this service entry within this org/company (used when updating/deleting an existing entry)
  ) {}

  static fromJSON (json: any) : CompanyLoginServiceMinimal | null {
    if (!json) return null
    return new CompanyLoginService(
      json.login_service_id,
      json.enabled ?? false,
      json.login_service_domain,
      json.id
    )
  }
}
