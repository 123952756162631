import React, { ReactNode, SyntheticEvent } from 'react'
import { Link } from 'react-router-dom'

import { withAuthContext, IAuthMultiContext, withUserContext, IUserMultiContext, UserStatus, withNavContext, INavMultiContext, withGlobalConfigProvider, IGlobalConfigMultiContext } from '../../providers'
import * as ROUTES from '../../../constants/routes'

import ArkAvatar, { ArkAvatarType } from '../ArkAvatar'
import ArkButton from '../ArkButton'
import ArkCompanyDropdown from '../ArkCompanyDropdown/ArkCompanyDropdownRenderer'
import ArkDropdown, { ArkDropdownProps } from '../ArkDropdown'
import ArkProjectDropdown from '../ArkProjectDropdown/ArkProjectDropdownRenderer'
import ArkResponsive from '../ArkResponsive'
import ArkSpacer from '../ArkSpacer'

import { IDLE_ENABLED, PROJECT_GUEST_PROFILE_ACCESS_ENABLED } from 'src/constants/config'
import IdleIndicator from 'src/core/providers/IdleProvider/IdleIndicator'
import { SectionSwitcherProps, withSectionSwitcherContext } from 'src/core/providers/SectionSwitcherProvider'

import styles from './ArkPageHeader.module.css'

interface IProps extends IUserMultiContext, IAuthMultiContext, INavMultiContext, IGlobalConfigMultiContext, SectionSwitcherProps {
  children?: ReactNode
  leftSidebar?: ReactNode
  showSelectCompany?: boolean // NB: will also hide the project select when disabled
  showSelectProject?: boolean // NB: only needed if the company select is set to show & you want to hide the project one only
  projectSelectPath?: string // NB: should include the :projectId placeholder in the url e.g. ROUTES.VIEW_PROJECT
}
interface IState {
}

class ArkPageHeader extends React.Component<IProps, IState> {
  render () {
    const { children } = this.props
    const loading = (this.props.userContext.store === undefined || this.props.userContext.store.userStatus === UserStatus.init || this.props.userContext.store.userStatus === UserStatus.loading)
    const isLoggedInAndVerified = !loading && this.props.authContext.actions.isLoggedInAndVerified()
    const showSelectCompany = !!(isLoggedInAndVerified && this.props.showSelectCompany)
    const showSelectProject = !!(showSelectCompany && this.props.showSelectProject)
    const globalConfig = this.props.configContext.store.config

    return (
      <header className={styles.container}>
        <div className={styles.content}>
          <div className={styles.left}>
            <ArkResponsive desktopOnly>
              <div className={styles.dropdowns} >
                {showSelectCompany && <ArkCompanyDropdown className={styles.dropdown} />}
                {showSelectProject && (
                  <>
                    <ArkSpacer />
                    <ArkProjectDropdown className={styles.dropdown} />
                  </>
                )}
              </div>
            </ArkResponsive>
          </div>
          <div className={styles.center}>
            <Link to={ROUTES.HOME}>
              <img alt={globalConfig.appName} className={styles.logo} src={globalConfig.logo} />
            </Link>
          </div>
          <div className={styles.right}>

            {children}

            {!loading && isLoggedInAndVerified && (
              <>
                {/* <div className="header-search header-item header-btn" onClick={this.onSearchClick}></div> */}
                {/* <div className="header-notifications header-item header-btn" onClick={this.onNotificationClick}></div> */}
              </>
            )}

            {this.renderUser()}

          </div>
        </div>
      </header>
    )
  }

  renderUser = () => {
    const loading = (this.props.userContext.store === undefined || this.props.userContext.store.userStatus === UserStatus.init || this.props.userContext.store.userStatus === UserStatus.loading)
    if (loading) { // } || (this.props.server.isLoggedIn() && this.props.server.isLoggedInAndVerified() === false)) {
      return null
    }
    const isLoggedIn = !loading && this.props.authContext.actions.isLoggedIn()
    if (!isLoggedIn) {
      return <ArkButton circular icon='sign-in' onClick={this.onLoginRedirect} />
    }
    const user = this.props.userContext.store.user
    const options = [
      ...(!PROJECT_GUEST_PROFILE_ACCESS_ENABLED && user?.isGuest ? [] : [{ key: 'account', text: 'Account', value: 'account' }]), // only show the profile account if not a guest user
      { key: 'logout', text: 'Logout', value: 'logout' }
    ]
    return (
      <>
        {IDLE_ENABLED && this.props.sectionSwitcher.store.showManagement && (
          <IdleIndicator autohide tooltips />
        )}
        <ArkSpacer />
        <ArkDropdown
          button
          className={styles.userDropdown}
          data-test-id="ark-account-dropdown" // e2e testing identifier
          direction='left'
          icon={null}
          labeled
          onChange={(_event: SyntheticEvent, data: ArkDropdownProps) => {
            switch (data.value) {
              case 'account': this.onAccountRedirect(); break
              case 'logout': this.onLogout(); break
            }
          }}
          options={options}
          trigger={
            <ArkAvatar
              type={user?.userAvatarType() ?? ArkAvatarType.user} // NB: we only have a base `User` model here NOT a `CompanyUser` or `ProjectUser` to show a user level/type which might not really make sense here (& it would change as the user changes the company/project selection)
              name={user?.name()}
              size="40"
            />}
        />
      </>
    )
  }

  onLoginRedirect = () => {
    this.props.navContext.actions.goto(ROUTES.LOGIN)
  }

  onLogout = () => {
    this.props.authContext.actions.logout()
  }

  onAccountRedirect = () => {
    this.props.navContext.actions.goto(ROUTES.ACCOUNT)
  }

  onSearchClick = () => {}
  onNotificationClick = () => {}
}

export default withGlobalConfigProvider(withNavContext(withUserContext(withAuthContext(withSectionSwitcherContext(ArkPageHeader)))))
