import React from 'react'

import { AUDIO_LEVELS_ENABLED } from 'src/constants/config'
import ArkAudioLevelsButton from 'src/core/components/ArkAudioLevelsButton'
import ArkSpacer from 'src/core/components/ArkSpacer'
import ArkVolumeInput from 'src/core/components/ArkVolumeInput'
import { useAudioLevels } from 'src/viewer/providers/AudioLevelsProvider'
import { useViewer } from 'src/viewer/providers/ViewerProvider'

import styles from './ChannelAudioToolbar.module.css'

interface ChannelAudioToolbarProps {
  disabled?: boolean
}

const ChannelAudioToolbar = (props: ChannelAudioToolbarProps) => {
  const { disabled } = props

  const audioLevels = useAudioLevels()
  const viewer = useViewer()

  return (
    <div className={`${styles.toolbar} ${viewer.fullscreen ? styles.fullscreen : ''}`}>
      <div className={styles.label}>MASTER</div>
      <ArkSpacer />
      <ArkVolumeInput
        disabled={disabled}
        onChange={viewer.setChannelVolume}
        size={120}
        value={viewer.getChannelVolume()}
      />
      {AUDIO_LEVELS_ENABLED
        ? (
          <ArkAudioLevelsButton
            onClick={() => viewer.setShowMixer(true)}
            postDisabled={!viewer.audioStarted || viewer.getChannelMute() || viewer.getChannelVolume() === 0}
            postValue={audioLevels.getChannelAudioLevelPostFader()}
            preValue={audioLevels.getChannelAudioLevelPreFader()}
            width={35}
          />
        )
        : <ArkSpacer />}
    </div>
  )
}

export default ChannelAudioToolbar
