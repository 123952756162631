import React, { useEffect, useRef, useState } from 'react'

import { useAuth, useNav } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import ArkMessage from 'src/core/components/ArkMessage'
import ArkSegment from 'src/core/components/ArkSegment'

import User2FAInputView, { User2FAInputViewMode } from 'src/core/pages/account/User2FAPage/User2FAInputView'

interface IProps {
  className?: string
}

const Login2FAForm = (props: IProps) => {
  const mounted = useRef(false)

  const { className } = props

  const authContext = useAuth()
  const navContext = useNav()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [verifySuccess, setVerifySuccess] = useState<boolean>(false)
  // const [verifyError, setVerifyError] = useState<boolean>(false) // NB: value never read/used, so commented out for now
  const [error, setError] = useState<Error | undefined>()

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  useEffect(() => {
    // redirect to the main login page if the user doesn't have a tfa token from the first part of the login
    // TODO: move this up to at least the main page level, if not a high level provider?
    const authTFAToken = authContext.actions.getAuthTFAToken()
    if (!authTFAToken) {
      navContext.actions.goto(ROUTES.LOGIN)
    }
  }, [])

  const verify2fa = async (otpCode: string) => {
    try {
      setIsLoading(true)
      const result = await authContext.actions.verify2FA(otpCode)
      if (mounted.current) {
        setIsLoading(false)
        if (result) {
          setVerifySuccess(true)
          // setVerifyError(false)
        } else {
          setVerifySuccess(false)
          // setVerifyError(true)
        }
        setError(undefined)
      }
    } catch (error) {
      if (mounted.current) {
        setIsLoading(false)
        setVerifySuccess(false)
        // setVerifyError(true)
        setError(error)
      }
    }
  }

  return (
    <ArkSegment inverted className={className}>
      {verifySuccess && (
        <ArkMessage positive>
          <ArkMessage.Header>2FA Login Success</ArkMessage.Header>
          <ArkMessage.Item>You are now logged in.</ArkMessage.Item>
        </ArkMessage>
      )}
      <User2FAInputView
        inputMode={User2FAInputViewMode.split}
        autoSubmit={true}
        isBusy={isLoading}
        error={error}
        onSubmit={(otpCode) => {
          verify2fa(otpCode)
        }}
      />
    </ArkSegment>
  )
}
export default Login2FAForm
