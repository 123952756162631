import React, { useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import {
  AuthSSOProvider,
  AuthStatus,
  useAuth,
  useGlobalConfig,
  useNav,
  useServer
} from 'src/core/providers'
import { AuthLoginServiceType, IAuthLoginService } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkCenterLayout from 'src/core/components/ArkCenterLayout'
import ArkDivider from 'src/core/components/ArkDivider'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkPage from 'src/core/components/ArkPage/ArkPage'
import ArkSegment from 'src/core/components/ArkSegment'

import { COMPANY_LOGIN_SERVICE_SSO_PROMPT_BEFORE_REDIRECT } from 'src/constants/config'
import * as ROUTES from 'src/constants/routes'

import LoginEmailLookupForm from './LoginEmailLookupForm'
import LoginEmailPasswordForm from './LoginEmailPasswordForm'
import Login2FAForm from './Login2FAForm'
import LoginNotUserView from './LoginNotUserView'
import LoginSSOView from './LoginSSOView'

import { Label } from 'semantic-ui-react'

import styles from './LoginPage.module.css'

enum LoginPageStage {
  email, password, sso, tfa
}

interface IProps {}

const LoginPage = (_props: IProps) => {
  const authContext = useAuth()
  const globalConfigContext = useGlobalConfig()
  const navContext = useNav()
  const serverContext = useServer()

  const location = useLocation()

  const [loginStage, setLoginStage] = useState<LoginPageStage>(LoginPageStage.email)
  const [loginService, setLoginService] = useState<IAuthLoginService | undefined>(undefined)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [promptBeforeSSORedirect, setPromptBeforeSSORedirect] = useState<boolean>(COMPANY_LOGIN_SERVICE_SSO_PROMPT_BEFORE_REDIRECT) // default: false - show a 'login with '<sso-provider>' prompt before redirecting to the SSO login page (mainly for use after idle logout for SSO users, so we don't auto show the SSO login page straight after being logged out)
  const [isSSOCallback, setIsSSOCallback] = useState<boolean>(false)

  useEffect(() => {
    console.log('LoginPage - useEffect[] - cacheEmail:', authContext.store.cacheEmail)

    // load the cached email if it exists from a login attempt (e.g. redirected from the register page, a page refresh mid login, & possibly an SSO callback?)
    let authEmail: string | undefined
    if (authContext.store.cacheEmail && authContext.store.cacheType === 'login') {
      console.log('LoginPage - useEffect[] - cacheEmail set + cacheType === login - clear cache...')
      authEmail = authContext.store.cacheEmail
      // authContext.actions.cacheClear() // NB: no longer clearing the cache here so page refreshes mid login/register resume with the cached email
    }
    if (authEmail) {
      console.log('LoginPage - useEffect[] - authEmail:', authEmail)
      // UPDATE: instead of skipping the email lookup stage when an email was cached (e.g redirected from the register page)
      // UPDATE: ..we auto re-run the lookup so we can check the login service type/provider & handle it accordingly (instead of caching that as well from the register page, to avoid stale cache issues or potential abuse of the cache)
      setEmail(authEmail)
      setLoginStage(LoginPageStage.email)
    }

    // check if this is an sso callback route/path (redirected back from the external okta/auth0 login service/provider)
    // ..if so treat this as an sso login stage & hand over further sso processing to the dedicated sso view & its provider (it figures out which login service etc.)
    // NB: we don't have access to the AuthSSOProvider at this level/commponent, so we manually check the route/path here
    // NB: now only setting to true if theres args in the sso callback url, don't consider it a callback if they aren't (as they've already been parsed?)
    const isSSOCallbackPath = matchPath(location.pathname, { path: ROUTES.LOGIN_SSO, exact: false, strict: false })
    const hasSSOCallbackArgs = location.search !== ''
    console.log('LoginPage - useEffect[] - isSSOCallbackPath:', isSSOCallbackPath, ' hasSSOCallbackArgs:', hasSSOCallbackArgs, ' location:', location)
    if (isSSOCallbackPath && hasSSOCallbackArgs && loginStage !== LoginPageStage.sso) {
      // TODO: reset/update if the page url changes while this page/component remains loaded?
      setLoginStage(LoginPageStage.sso)
      setIsSSOCallback(true)
    } else {
      // idle timeout support for SSO users
      // - prevents auto redirecting to the SSO login page after an idle logout
      // - so the user sees the timeout notice & can choose to trigger the SSO login manually
      // - enable `promptBeforeSSORedirect` if the email is set from the cache above
      // - so it doesn't auto redirect to the sso login page after the email lookup & just shows a button to do so instead
      // TODO: is there any scenaio we'd want to update from the `cacheEmail` value above & NOT enable the `promptBeforeSSORedirect` flag here?
      // TODO: ...if so, might need to extend this further so the `IdleProvider` sets a flag to indicate if the idle logout was the trigger for the cache email & use that instead of assuming its the case
      if (authEmail) {
        console.log('LoginPage - useEffect[] - cacheEmail set - enable promptBeforeSSORedirect...')
        setPromptBeforeSSORedirect(true)
      }
    }
  }, [])

  useEffect(() => {
    // component unmount - cleanup abandoned 2FA login stage (if you navigate away from the 2FA stage without clicking the 'cancel' link, e.g. clicking the site logo/header)
    return () => {
      // TESTING: use `getAuthStatus` method to check the auth status on unmount instead of the store state var directly, as this code block seems to cache the old value (at the time the useEffect was created?)
      const authStatus = authContext.actions.getAuthStatus()
      // console.log('LoginPage - useEffect[unmount] - authStatus:', authStatus, ' (authContext.store.authStatus:', authContext.store.authStatus + ')', ' loginStage:', loginStage)
      if (authStatus === AuthStatus.tfa) {
        console.log('LoginPage - useEffect[unmount] - authStatus === AuthStatus.tfa...')
        authContext.actions.cancelLogin2FA()
      }
    }
  }, [])

  // TESTING: equivalent to the componentDidUpdate method `if (prevProps.authContext.store.authStatus !== AuthStatus.tfa && this.props.authContext.store.authStatus === AuthStatus.tfa) { this.setState({ loginStage: LoginPageStage.tfa }) }`
  useEffect(() => {
    // check if the auth providers status changes to tfa required, if so show the tfa input form
    if (authContext.store.authStatus === AuthStatus.tfa && loginStage !== LoginPageStage.tfa) {
      console.log('LoginPage - useEffect[authStatus] - authStatus CHANGED TO 2FA (was: ' + loginStage + ')...')
      setLoginStage(LoginPageStage.tfa)
    }
  }, [authContext.store.authStatus])

  // -------

  const _onLoginCancel = () => {
    console.log('LoginPage - _onLoginCancel')
    authContext.actions.cacheClear()
    authContext.actions.clearAuthError()
    setLoginStage(LoginPageStage.email)
    setLoginService(undefined)
    setEmail(undefined)
    setIsSSOCallback(false)
  }

  // -------

  const _renderLoginInfoText = () => {
    const globalConfig = globalConfigContext.store.config
    return (
      <>
        <p>{globalConfig.appName} is by invite only.</p>
        <p>New users please check your email for an invite link or contact your {globalConfig.appName} admin to request one.</p>
      </>
    )
  }

  const _renderLoginTroubleLink = () => {
    return (
      <div className={styles.loginHelp}>
        <a className={styles.loginHelpLink} onClick={() => {
          authContext.actions.clearAuthError()
          navContext.actions.goto(ROUTES.LOGIN_HELP)
        }}>
          Trouble logging in?
        </a>
      </div>
    )
  }

  // -------

  const _renderLoginEmailLookupForm = () => {
    return (
      <LoginEmailLookupForm
        email={email}
        autoRun={email !== undefined}
        onEmailLookup={(email: string, loginService: IAuthLoginService) => {
          console.log('LoginPage - onEmailLookup - email:', email, ' loginService:', loginService)
          // UPDATE: #990 auth ux changes - the check-email response no longer indicates if the user is new or not (for security reasons), so we always asume they exist & let the login attempt handle the actual check
          const loginServiceType = loginService.type
          console.log('LoginPage - onEmailLookup - loginServiceType:', loginServiceType)
          if (loginServiceType === AuthLoginServiceType.EmailPassword) {
            setLoginStage(LoginPageStage.password)
            setLoginService(loginService)
            setEmail(email)
          } else if ((loginServiceType === AuthLoginServiceType.SSOOktaOIDC || loginServiceType === AuthLoginServiceType.SSOOktaSAML)) {
            setLoginStage(LoginPageStage.sso)
            setLoginService(loginService)
            setEmail(email)
          } else if (loginServiceType === AuthLoginServiceType.SSOAuth0) {
            setLoginStage(LoginPageStage.sso)
            setLoginService(loginService)
            setEmail(email)
          } else {
            console.log('LoginPage - onEmailLookup - UNKNOWN/UNHANDLED LOGIN SERVICE TYPE <<<<')
            // TODO: throw/show an error?? (NB: low priority as this shouldn't happen under normal use, only during dev if we're adding a new login service & handling hasn't been added here yet)
            // NB: previously we would also catch & redirect to the register page if the user didn't exist, but as of #990 we no longer can tell that & so we always assume they exist & let the login attempt handle the actual check
          }
        }}
      />
    )
  }

  const _renderLoginEmailLookupPage = () => {
    const globalConfig = globalConfigContext.store.config
    return (
      <>
        <ArkHeader as="h2" textAlign="center">Login</ArkHeader>
        <div className={styles.info}>
          {_renderLoginInfoText()}
        </div>
        {_renderLoginEmailLookupForm()}
        {globalConfig.registrationEnabled
          ? <div className={styles.register}>
            <ArkDivider horizontal inverted>Or</ArkDivider>
            <Label size="large">
              <p>Not registered yet?</p>
            </Label>
            <ArkButton fluid size="large" onClick={() => {
              navContext.actions.goto(ROUTES.REGISTER)
            }}>
              Register
            </ArkButton>
          </div>
          : null}
        {_renderLoginTroubleLink()}
      </>
    )
  }

  const _renderLoginEmailPasswordForm = (email: string) => {
    // NB: we don't need to do anything here to handle the result any more (we previously used the `onLoginSuccess={(_result: boolean) => { ... }` callback):
    // - if the login was successful the auth provider will handle update related state vars & so trigger the post login redirect etc.
    // - if 2FA is required the auth provider updates its `authStatus` state var & we catch that in the `useEffect` above & show the 2FA form
    // - if the login fails with an error this password form displays it
    return (<LoginEmailPasswordForm email={email} />)
  }

  const _renderLoginEmailPasswordPage = () => {
    if (!email) return null
    return (
      <>
        <ArkHeader as="h2" textAlign="center">Enter your password</ArkHeader>
        <div className={styles.info}>
          {_renderLoginInfoText()}
          <p className={styles.emailPassEmailTitle}>Enter your password {email ? <>for: <span className={styles.emailPassEmailAddress}>{email}</span></> : null}</p>
        </div>
        {_renderLoginEmailPasswordForm(email)}
        <LoginNotUserView
          className={styles.notUser}
          email={email}
          onClick={() => {
            authContext.actions.cacheClear()
            // NB: now clearing the `email` state here otherwise it'll auto re-run the email lookup & block you from changing the email
            setLoginStage(LoginPageStage.email)
            setLoginService(undefined)
            setEmail(undefined)
            authContext.actions.clearAuthError()
          }}
        />
        {/* <div className={styles.forgotPassword}>
          <a className={styles.forgotPasswordLink} onClick={() => {
            authContext.actions.clearAuthError()
            navContext.actions.goto(ROUTES.LOGIN_PASSWORD_FORGOT)
          }}>
            Forgot your password?
          </a>
        </div> */}
        {_renderLoginTroubleLink()}
      </>
    )
  }

  const _renderLoginSSOPage = (loginService?: IAuthLoginService) => {
    console.log('LoginPage - _renderLoginSSOPage - email:', email, ' loginService:', loginService)
    const apiClient = serverContext.store.apiClient
    const authApi = serverContext.store.authApi
    // NB: we might not have the email available here if we're coming back from the okta/auth0 callback..
    // ..so render/call it regardless & let it check/handle internally (loads the email from the cache if its set etc.)
    if (!apiClient || !authApi) return null
    return (
      <AuthSSOProvider apiClient={apiClient} authApi={authApi}>
        <LoginSSOView
          email={email}
          loginService={loginService}
          isSSOCallback={isSSOCallback}
          promptBeforeSSORedirect={promptBeforeSSORedirect}
          onCancel={_onLoginCancel}
        />
      </AuthSSOProvider>
    )
  }

  const _renderLogin2FAPage = () => {
    if (!email) return null
    return (
      <>
        <Login2FAForm className={styles.tfaForm} />
        <LoginNotUserView
          className={styles.tfaNotUser}
          email={email ?? ''}
          title={'Cancel'}
          onClick={() => {
            authContext.actions.cacheClear()
            // NB: now clearing the `email` state here otherwise it'll auto re-run the email lookup & block you from changing the email
            setLoginStage(LoginPageStage.email)
            setLoginService(undefined)
            setEmail(undefined)
            authContext.actions.cancelLogin2FA()
            authContext.actions.clearAuthError()
          }}
        />
        {_renderLoginTroubleLink()}
      </>
    )
  }

  // -------

  let page: JSX.Element | null = null
  switch (loginStage) {
    case LoginPageStage.email: page = _renderLoginEmailLookupPage(); break
    case LoginPageStage.password: page = _renderLoginEmailPasswordPage(); break
    case LoginPageStage.sso: page = _renderLoginSSOPage(loginService); break
    case LoginPageStage.tfa: page = _renderLogin2FAPage(); break
  }

  return (
    <ArkPage>
      <ArkCenterLayout>
        <ArkSegment inverted>{page}</ArkSegment>
      </ArkCenterLayout>
    </ArkPage>
  )
}

export default LoginPage
