import React, { useContext } from 'react'
import { withRouter, RouteComponentProps, matchPath, match } from 'react-router-dom'
import { StaticContext } from 'react-router'

import * as ROUTES from '../../constants/routes'

// TESTING: declare the custom location state vars (set in the `PrivateRoute` redirect) - ref: https://stackoverflow.com/a/59857898
type LocationState = {
  from: Location
}

export type NavUrlIds = { projectId?: number, channelId?: number }
// export enum NavStatus {
//   init, loading, ready, error
// }

export enum NavSection {
  viewer, project, company, admin, other
}

export interface INavStore {
  currentPath: string
  currentSection: NavSection
  urlIds: NavUrlIds
}

export interface INavActions {
  goto: (path: string | { pathname: string, search?: string, hash?: string, state?: any }, from?: Location) => void
  gotoSection: (section: NavSection) => void
  // helpers
  getCurrentUrlParams: () => any
  getSectionBasePath: (section: NavSection) => string
  getSectionProjectPath: (section: NavSection, projectId: number) => string
  getSectionProjectChannelPath: (section: NavSection, projectId: number, channelId: number) => string
  getSectionProjectSelectPath: (section: NavSection) => string
  canAutoNav: () => boolean // true if ok to nav away from the current url, e.g. user selections loading, don't allow if on an invite page etc.
  matchPath: (pathA: string, pathB: string, exact?: boolean, strict?: boolean) => match | null
  matchCurrentPath: (path: string, exact?: boolean, strict?: boolean) => match | null
  getCurrentLocation: () => Location
  getRedirectPath: () => Location | undefined
}

export interface INavContext {
  actions: INavActions;
  store: INavStore;
}

export interface INavMultiContext {
  navContext: INavContext
}

export const NavContext = React.createContext<INavContext>({} as INavContext)

export const useNav = () => useContext(NavContext)

export interface NavProviderProps extends RouteComponentProps<{}, StaticContext, LocationState> {
  children?: React.ReactNode
}
export interface NavProviderState extends INavStore {
}

class NavProvider extends React.Component<NavProviderProps, NavProviderState> {
  constructor (props: NavProviderProps) {
    super(props)
    const currentPath = window.location.pathname
    const currentSection = this.parseSectionFromUrl(currentPath)
    const urlIds = this.parseIdsFromUrl(currentPath)
    console.log('NavProvider - init - currentPath: ', currentPath, ' currentSection: ', currentSection, ' urlIds: ', urlIds)
    this.state = {
      currentPath,
      currentSection,
      urlIds
    }
  }

  // componentDidMount () {
  //   const currentPath = window.location.pathname
  //   const urlIds = this.parseIdsFromUrl(currentPath)
  //   this.setState({ currentPath, urlIds })
  // }

  componentDidUpdate (prevProps: NavProviderProps, _prevState: NavProviderState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      console.log('NavProvider - componentDidUpdate - ROUTE PATH CHANGED FROM: ', prevProps.location.pathname, ' TO: ', this.props.location.pathname)
      const redirectFrom = this.props.location.state?.from
      console.log('NavProvider - componentDidUpdate - redirectFrom:', redirectFrom)
      const currentPath = window.location.pathname
      const currentSection = this.parseSectionFromUrl(currentPath)
      const urlIds = this.parseIdsFromUrl(currentPath)
      console.log('NavProvider - componentDidUpdate - currentPath: ', currentPath, ' currentSection: ', currentSection, ' urlIds: ', urlIds)
      this.setState({ currentPath, currentSection, urlIds })
    }
  }

  // -------

  // optionally pass in a `from` location which some pages may used to redirect back to after a certain action
  // NB: can now pass in a path object if you want to set specific search/hash/state vars directly
  goto = (path: string | { pathname: string, search?: string, hash?: string, state?: any }, from?: Location) => {
    console.log('NavProvider - goto - path:', path, ' from:', from)
    if (from) {
      if (typeof path === 'string') {
        this.props.history.push({
          pathname: path,
          state: { from }
          // TODO: consider also adding support for `search` (& `hash`?) if needed
        })
      } else {
        const _path = { ...path }
        if (from) _path.state = { from } // TODO: only overwrite if not already set? (or always set/overwrite?)
        this.props.history.push(_path)
      }
    } else {
      this.props.history.push(path)
    }
  }

  gotoSection = (section: NavSection) => {
    console.log('NavProvider - gotoSection - section:', section)
    const sectionPath = this.getSectionBasePath(section)
    this.goto(sectionPath)
  }

  // -------

  getCurrentUrlParams = (): any => {
    return this.props.match
  }

  // -------

  getSectionBasePath = (section: NavSection): string => {
    switch (section) {
      case NavSection.viewer: return ROUTES.VIEWER
      case NavSection.project: return ROUTES.PROJECT_MANAGER_SELECT
      case NavSection.company: return ROUTES.COMPANY_MANAGER
      case NavSection.admin: return ROUTES.ADMIN
    }
    return ROUTES.HOME
  }

  getSectionProjectPath = (section: NavSection, projectId: number): string => {
    switch (section) {
      case NavSection.viewer: return ROUTES.VIEW_PROJECT.replace(':projectId', '' + projectId)
      case NavSection.project: return ROUTES.PROJECT_MANAGER_VIEW.replace(':projectId', '' + projectId)
    }
    return this.getSectionBasePath(section)
  }

  getSectionProjectChannelPath = (section: NavSection, projectId: number, channelId: number): string => {
    switch (section) {
      case NavSection.viewer: return ROUTES.VIEW_PROJECT_CHANNEL.replace(':projectId', '' + projectId).replace(':channelId', '' + channelId)
    }
    return this.getSectionProjectPath(section, projectId)
  }

  getSectionProjectSelectPath = (section: NavSection): string => {
    switch (section) {
      case NavSection.viewer: return ROUTES.VIEWER
      case NavSection.project: return ROUTES.PROJECT_MANAGER_SELECT
    }
    return this.getSectionBasePath(section) // TODO: return the base path, or undefined maybe?
  }

  // -------

  canAutoNav = (): boolean => {
    // check if the current path is one that shouldn't allow auto navigation away from (invite accept page etc.)
    // TOOD: add all paths that should disable auto navigation away from them...
    // NB: matchPath returns an object if a match is found, null if not
    const invitePath = matchPath(this.state.currentPath, { path: ROUTES.USER_ACCEPT_COMPANY_INVITE, exact: false, strict: false })
    if (invitePath) {
      return false
    }
    return true
  }

  matchPath = (path: string, currentPath: string, exact: boolean = true, strict: boolean = false): match | null => {
    // console.log('NavProvider - matchPath - currentPath: \'' + currentPath + '\' path: \'' + path + '\' exact: ', exact, ' strict: ', strict)
    const matchingPath = matchPath(currentPath, { path: path, exact: exact, strict: strict })
    // console.log('NavProvider - matchPath - matchingPath: ', matchingPath)
    return matchingPath // !== null
  }

  matchCurrentPath = (path: string, exact: boolean = true, strict: boolean = false): match | null => {
    // console.log('NavProvider - matchCurrentPath - currentPath: ', this.state.currentPath, ' path: ', path)
    return this.matchPath(path, this.state.currentPath, exact, strict)
  }

  // -------

  parseSectionFromUrl = (url: string): NavSection => {
    if (url.startsWith(ROUTES.VIEWER)) return NavSection.viewer
    if (url.startsWith(ROUTES.PROJECT_MANAGER_SELECT)) return NavSection.project
    if (url.startsWith(ROUTES.COMPANY_MANAGER)) return NavSection.company
    if (url.startsWith(ROUTES.ADMIN)) return NavSection.admin
    return NavSection.other
  }

  // TESTING: extract the current projectId & channelId from the url if on the relevant viewer or manager pages
  // TODO: mot ideal, is there a better way we could handle this without having to manually check for every url that contains ids we need at init time...
  // NB: we need to do this outside of the Router, so we can feed the values to the Server init/setup,
  // NB: so use react-router matchPath on the raw window.location.pathname value (as we can't use withRouter outside/above the Router component)
  parseIdsFromUrl = (url: string) : NavUrlIds /* | undefined */ => {
    const result: { projectId?: number, channelId?: number } = {}

    const currentPath = url

    const viewProject = matchPath(currentPath, { path: ROUTES.VIEW_PROJECT, exact: false, strict: false })
    const viewProjectChannel = matchPath(currentPath, { path: ROUTES.VIEW_PROJECT_CHANNEL, exact: false, strict: false })

    const manageProject = matchPath(currentPath, { path: ROUTES.PROJECT_MANAGER_VIEW, exact: false, strict: false })
    const manageProjectChannel = matchPath(currentPath, { path: ROUTES.PROJECT_MANAGER_CHANNELS, exact: false, strict: false })

    if (viewProjectChannel) {
      const projectIdRaw = (viewProjectChannel.params as any).projectId
      const channelIdRaw = (viewProjectChannel.params as any).channelId
      result.projectId = (typeof projectIdRaw === 'string' ? parseInt(projectIdRaw) : projectIdRaw as number)
      result.channelId = (typeof channelIdRaw === 'string' ? parseInt(channelIdRaw) : channelIdRaw as number)
      return result
    } else if (viewProject) {
      const projectIdRaw = (viewProject.params as any).projectId
      result.projectId = (typeof projectIdRaw === 'string' ? parseInt(projectIdRaw) : projectIdRaw as number)
      result.channelId = undefined
      return result
    }

    if (manageProjectChannel) {
      const projectIdRaw = (manageProjectChannel.params as any).projectId
      const channelIdRaw = (manageProjectChannel.params as any).channelId
      result.projectId = (typeof projectIdRaw === 'string' ? parseInt(projectIdRaw) : projectIdRaw as number)
      result.channelId = (typeof channelIdRaw === 'string' ? parseInt(channelIdRaw) : channelIdRaw as number)
      return result
    } else if (manageProject) {
      const projectIdRaw = (manageProject.params as any).projectId
      result.projectId = (typeof projectIdRaw === 'string' ? parseInt(projectIdRaw) : projectIdRaw as number)
      result.channelId = undefined
      return result
    }

    // return undefined
    return result // TESTING: always return a result, but just with the ids empty?
  }

  // -------

  getCurrentLocation = (): Location => {
    return this.props.history.location as unknown as Location
  }

  getRedirectPath = (): Location | undefined => {
    const redirectFrom = this.props.location.state?.from
    console.log('NavProvider - getRedirectPath - redirectFrom:', redirectFrom)
    return redirectFrom
  }

  // -------

  actions: INavActions = {
    goto: this.goto,
    gotoSection: this.gotoSection,
    // helpers
    getCurrentUrlParams: this.getCurrentUrlParams,
    getSectionBasePath: this.getSectionBasePath,
    getSectionProjectPath: this.getSectionProjectPath,
    getSectionProjectChannelPath: this.getSectionProjectChannelPath,
    getSectionProjectSelectPath: this.getSectionProjectSelectPath,
    canAutoNav: this.canAutoNav,
    matchPath: this.matchPath,
    matchCurrentPath: this.matchCurrentPath,
    getCurrentLocation: this.getCurrentLocation,
    getRedirectPath: this.getRedirectPath
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: IUserStore = {
  //  ...
  // }

  render () {
    return (
      <NavContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for INavStore */ }}
      >
        {this.props.children}
      </NavContext.Provider>
    )
  }
}

const withNavContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withNavContextHOC = (props: any) => (
    <NavContext.Consumer>
      {(navContext) => { // { status }
        if (navContext === null) {
          throw new Error('NavConsumer must be used within a NavProvider')
        }
        // console.log('withNavContext - render - NavContext.Consumer - navContext.store: ', navContext.store)
        return (<Component {...props} {...{ navContext: navContext }} />)
      }}
    </NavContext.Consumer>
  )
  return withNavContextHOC
}

const NavProviderWithRouter = withRouter(NavProvider)

export { NavProviderWithRouter as NavProvider }
export { withNavContext }
