import React from 'react'

import { FEATURE_DVR_NAME } from 'src/constants/strings'

import ArkProgramBadge from '../ArkBadge'

import styles from './ArkDvrBadge.module.css'

export interface ArkDvrBadgeProps {
  onMouseEnter?: () => void // for ArkTooltip
  onMouseLeave?: () => void // for ArkTooltip
}

const ArkDvrBadge = (props: ArkDvrBadgeProps) => (
  <ArkProgramBadge
    color='var(--red)'
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
  >
    <div className={styles.main}>
      <div className={styles.dot} />
      {FEATURE_DVR_NAME}
    </div>
  </ArkProgramBadge>
)

export default ArkDvrBadge
