import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useAuth, useNav } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import ArkCenterLayout from 'src/core/components/ArkCenterLayout'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkPage from 'src/core/components/ArkPage/ArkPage'

import { Message, Segment } from 'semantic-ui-react'

/**
 * Accepts a verifyToken url arg & auto submits it to the api, redirects straight to the viewer on success
 * If the user is already verified they get redirected to the viewer
 */
interface IProps{}

const VerifyEmailInputPage = (_props: IProps) => {
  const mounted = useRef(false)

  const { verifyToken: _verifyToken } = useParams<{ verifyToken: string }>()

  const authContext = useAuth()
  const navContext = useNav()

  const [verifyToken, setVerifyToken] = useState<string | undefined>(undefined)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [verifySuccess, setVerifySuccess] = useState<boolean>(false)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const runVerifyEmailToken = async (_verifyToken: string) => {
    if (_verifyToken) {
      // NB: you do not have to be logged in to call the verify api endpoint,
      // NB: but we check if you are & skip the attempt if already verified
      let runVerify = false
      const isLoggedIn = authContext.actions.isLoggedIn()
      if (isLoggedIn) {
        const isLoggedInAndVerified = authContext.actions.isLoggedInAndVerified()
        if (isLoggedInAndVerified === false) {
          // user is logged in but not yet verified - attempt to verify them with the supplied token
          runVerify = true
        } else { // already verified, ignore this call & redirect to the default logged in page
          navContext.actions.goto(ROUTES.VIEWER)
        }
      } else { // not logged in - send the verify token
        runVerify = true
      }

      if (runVerify) {
        setVerifyToken(_verifyToken)
        setIsSubmitting(true)

        const result = await authContext.actions.verifyEmailToken(_verifyToken)
        // NB: only update the state if the component is still mounted (we haven't already redirected away from the page by the time the result goes to show)
        if (result) {
          if (mounted.current) {
            setIsSubmitting(false)
            setVerifySuccess(true)
          }
          navContext.actions.goto(ROUTES.VIEWER)
        } else {
          if (mounted.current) {
            setIsSubmitting(false)
            setVerifySuccess(false)
          }
        }
      }
    }
  }

  useEffect(() => {
    runVerifyEmailToken(_verifyToken)
  }, [])

  // -------

  return (
    <ArkPage>
      <ArkCenterLayout>
        <Segment inverted>
          {verifyToken && isSubmitting && <ArkLoader message='Verifying' />}
          {verifyToken && isSubmitting === false && verifySuccess && (
            <Message positive>
              <Message.Header>Success</Message.Header>
              <p>You&apos;re now verified!</p>
            </Message>
          )}
          {verifyToken && isSubmitting === false && verifySuccess === false && (
            <Message negative>
              <Message.Header>Error</Message.Header>
              <p>Failed to verify the token</p>
            </Message>
          )}
          {!verifyToken && (
            <Message negative>
              <Message.Header>Error</Message.Header>
              <p>Token missing</p>
            </Message>
          )}
        </Segment>
      </ArkCenterLayout>
    </ArkPage>
  )
}

export default VerifyEmailInputPage
