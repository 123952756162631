import React, { ReactNode, useEffect, useState } from 'react'
import _ from 'lodash'
import { Button, Form, Input } from 'semantic-ui-react'

import {
  VIDEO_PLAYER_SUPPORTED_RESOLUTIONS,
  VIDEO_PLAYER_ABR_RESOLUTIONS,
  PROGRAM_OPTIONS_SHOW_BUFFER,
  PROGRAM_BUFFER_OPTION_FAST,
  PROGRAM_BUFFER_OPTION_BALANCED,
  PROGRAM_BUFFER_OPTION_STABLE,
  PROGRAM_BUFFER_MAX,
  PROGRAM_BUFFER_MIN,
  PROGRAM_BUFFER_STEP,
  PROGRAM_OPTIONS_SHOW_ADVANCED,
  PROGRAM_OPTIONS_SHOW_ADVANCED_PARAMETERS
} from 'src/constants/config'
import ArkButton, { ArkButtonGroup } from 'src/core/components/ArkButton'
import ArkCheckbox from 'src/core/components/ArkCheckbox'
import ArkDropdown from 'src/core/components/ArkDropdown'
import ArkIcon from 'src/core/components/ArkIcon'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkPopup from 'src/core/components/ArkPopup'
import ArkSlider from 'src/core/components/ArkSlider'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { useDebounceEffect } from 'src/core/hooks/debounce'
import { PlayerResolution } from 'src/core/types/player'

import styles from './ProgramOptionsPopup.module.css'

const CHANNEL_DEFAULT: string = 'Channel default'

interface ProgramOptionsPopupProps {
  customAbr: boolean
  customAdvancedParameters: string
  customBuffer: number
  customOptions: boolean
  customPassthrough: boolean
  customResolution: PlayerResolution
  customThreeSixty: boolean
  customThreeSixtyPreferPassthrough: boolean
  open: boolean
  programThreeSixty: boolean
  restarting: boolean
  stopped: boolean
  trigger: ReactNode
  onClose: () => void
  onCustomAbrChange: (value: boolean) => void
  onCustomAdvancedParametersChange: (value: string) => void
  onCustomBufferChange: (value: number) => void
  onCustomOptionsChange: (value: boolean) => void
  onCustomPassthroughChange: (value: boolean) => void
  onCustomResolutionChange: (value: PlayerResolution) => void
  onCustomThreeSixtyOnChange: (value: boolean) => void
  onCustomThreeSixtyPreferPassthroughChange: (value: boolean) => void
  onOpen: () => void
  onRestartClick: () => void
  onStoppedChange: (value: boolean) => void
}

const ProgramOptionsPopup = (props: ProgramOptionsPopupProps) => {
  const [_advancedParameters, _setAdvancedParameters] = useState(props.customAdvancedParameters)
  const [_buffer, _setBuffer] = useState(props.customBuffer)
  const [isAdvancedParametersValid, setIsAdvancedParametersValid] = useState(true)
  const [showAdvanced, setShowAdvanced] = useState(false)

  useDebounceEffect(() => {
    props.onCustomBufferChange(_buffer)
    if (_buffer !== props.customBuffer) props.onRestartClick()
  }, [_buffer], 1000)

  useEffect(() => {
    setIsAdvancedParametersValid(true)
  }, [_advancedParameters])

  useEffect(() => {
    _setAdvancedParameters(props.customAdvancedParameters)
  }, [props.customAdvancedParameters])

  /**
   * actions
   */

  const onAdvancedParametersSubmit = () => {
    console.log('ProgramOptionsPopup - onAdvancedParametersSubmit')
    try {
      if (_advancedParameters) JSON.parse(_advancedParameters)
      setIsAdvancedParametersValid(true)
      props.onCustomAdvancedParametersChange(_advancedParameters)
      props.onRestartClick()
    } catch (_error) {
      setIsAdvancedParametersValid(false)
    }
  }

  /**
   * render
   */

  // resolution
  const resolutionOptions = _.map(VIDEO_PLAYER_SUPPORTED_RESOLUTIONS, value => ({
    active: value === props.customResolution,
    onClick: () => props.onCustomResolutionChange(value),
    text: `${props.customAbr && _.includes(VIDEO_PLAYER_ABR_RESOLUTIONS, value) ? 'Up to ' : ''}${value}`,
    value
  }))

  // playback
  const playbackOptions = [
    {
      active: !props.stopped,
      onClick: () => props.onStoppedChange(false),
      text: !props.stopped ? 'Started' : 'Start',
      value: !props.stopped ? 'Started' : 'Start'
    },
    {
      active: props.stopped,
      onClick: () => props.onStoppedChange(true),
      text: props.stopped ? 'Stopped' : 'Stop',
      value: props.stopped ? 'Stopped' : 'Stop'
    },
    {
      active: props.restarting,
      disabled: props.stopped,
      onClick: !props.stopped ? props.onRestartClick : undefined,
      text: props.restarting ? 'Restarting…' : 'Restart',
      value: props.restarting ? 'Restarting…' : 'Restart'
    }
  ]
  let playbackValue = CHANNEL_DEFAULT
  if (props.restarting) playbackValue = 'Restarting…'
  else if (props.stopped === false) playbackValue = 'Started'
  else if (props.stopped === true) playbackValue = 'Stopped'

  const isThreeSixtyForcePassthrough = props.programThreeSixty && props.customThreeSixty && props.customThreeSixtyPreferPassthrough
  const isPassthrough = props.customPassthrough || isThreeSixtyForcePassthrough

  return (
    <ArkPopup
      className={styles.popup}
      onClose={props.onClose}
      onOpen={props.onOpen}
      open={props.open}
      position='top right'
      title='PROGRAM VIDEO OPTIONS'
      trigger={props.trigger}
    >
      {/* custom options */}
      <Button.Group fluid>
        <ArkButton active={!props.customOptions} onClick={() => props.onCustomOptionsChange(false)}>Default</ArkButton>
        <ArkButton active={props.customOptions} onClick={() => props.onCustomOptionsChange(true)}>Custom</ArkButton>
      </Button.Group>
      <ArkSpacer large />

      {!props.customOptions && (
        <>
          <ArkSpacer size={20} />
          <div className={styles.message}>Default: Video options inherited from channel (top bar)</div>
          <ArkSpacer />
        </>
      )}

      {props.customOptions && (
        <>
          {/* 360 */}
          {props.programThreeSixty && (
            <>
              <div className={styles.label}>360° (BETA):</div>
              <ArkSpacer small />
              <ArkSpacer />
              <ArkCheckbox
                checked={props.customThreeSixty}
                label='360° view'
                onChange={(_event, data) => props.onCustomThreeSixtyOnChange(!!data.checked)}
                size='mini'
                toggle
              />
              <ArkSpacer />
              <ArkCheckbox
                checked={props.customThreeSixtyPreferPassthrough}
                disabled={!props.customThreeSixty}
                label='Prefer passthrough'
                onChange={(_event, data) => props.onCustomThreeSixtyPreferPassthroughChange(!!data.checked)}
                size='mini'
                toggle
              />
              <ArkSpacer large />
            </>
          )}

          <div className={styles.label}>Resolution:</div>
          <ArkSpacer small />

          {/* passthrough */}
          <ArkButtonGroup fluid size='small'>
            <ArkButton
              active={!isPassthrough}
              color={!isPassthrough ? 'blue' : undefined}
              disabled={isThreeSixtyForcePassthrough}
              onClick={() => props.onCustomPassthroughChange(false)}
            >
              TRANSCODER
            </ArkButton>
            <ArkButton
              active={props.customPassthrough}
              color={isPassthrough ? 'blue' : undefined}
              disabled={isThreeSixtyForcePassthrough}
              onClick={() => props.onCustomPassthroughChange(true)}
            >
              PASSTHROUGH
            </ArkButton>
          </ArkButtonGroup>
          <ArkSpacer large />

          {!isPassthrough && (
            <>
              {/* abr */}
              <ArkCheckbox
                checked={props.customAbr && _.includes(VIDEO_PLAYER_ABR_RESOLUTIONS, props.customResolution)}
                label='Automatically adjust quality'
                onChange={(_event, data) => props.onCustomAbrChange(!!data.checked)}
                size='mini'
                toggle
              />
              <ArkSpacer large />

              {/* resolution */}
              <ArkDropdown
                fluid
                options={resolutionOptions}
                selection value={props.customResolution || CHANNEL_DEFAULT}
              />
            </>
          )}

          {/* buffer */}
          {PROGRAM_OPTIONS_SHOW_BUFFER && (
            <>
              <ArkSpacer large />
              <div className={styles.bufferLabelContainer}>
                <div className={styles.label}>Buffer:</div>
                <span className={styles.bufferValue}>
                  {`${_buffer.toLocaleString()} ms`}
                </span>
              </div>
              <ArkSpacer small />
              <Button.Group fluid size='tiny'>
                <ArkButton
                  active={_buffer === PROGRAM_BUFFER_OPTION_FAST}
                  color={_buffer === PROGRAM_BUFFER_OPTION_FAST ? 'blue' : undefined}
                  onClick={() => _setBuffer(PROGRAM_BUFFER_OPTION_FAST)}
                >
                  Fast
                </ArkButton>
                <ArkButton
                  active={_buffer === PROGRAM_BUFFER_OPTION_BALANCED}
                  color={_buffer === PROGRAM_BUFFER_OPTION_BALANCED ? 'blue' : undefined}
                  onClick={() => _setBuffer(PROGRAM_BUFFER_OPTION_BALANCED)}
                >
                  Balanced
                </ArkButton>
                <ArkButton
                  active={_buffer === PROGRAM_BUFFER_OPTION_STABLE}
                  color={_buffer === PROGRAM_BUFFER_OPTION_STABLE ? 'blue' : undefined}
                  onClick={() => _setBuffer(PROGRAM_BUFFER_OPTION_STABLE)}
                >
                  Stable
                </ArkButton>
              </Button.Group>
              <ArkSpacer small />
              <ArkSlider
                max={PROGRAM_BUFFER_MAX}
                min={PROGRAM_BUFFER_MIN}
                onChange={_setBuffer}
                step={PROGRAM_BUFFER_STEP}
                value={_buffer}
              />
            </>
          )}

          {/* advanced */}
          {PROGRAM_OPTIONS_SHOW_ADVANCED && (
            <>
              <ArkSpacer large />
              <div className={styles.advancedButton} onClick={() => setShowAdvanced(!showAdvanced)}>
                <div>Advanced options</div>
                <ArkIcon name={showAdvanced ? 'angle-double-up' : 'angle-double-down'} size={18} />
              </div>
            </>
          )}

          {showAdvanced && (
            <>
              <ArkSpacer small />

              {/* playback */}
              <div className={styles.label}>Playback state:</div>
              <ArkSpacer small />
              <ArkDropdown fluid options={playbackOptions} selection value={playbackValue} />
              <ArkSpacer />

              {/* advanced parameters */}
              {PROGRAM_OPTIONS_SHOW_ADVANCED_PARAMETERS && (
                <>
                  <div className={styles.label}>Advanced parameters (JSON):</div>
                  <ArkSpacer small />
                  <Form className={styles.advancedParametersForm} onSubmit={onAdvancedParametersSubmit}>
                    <Form.Field className={styles.advancedParametersFormField} error={!isAdvancedParametersValid}>
                      <Input
                        className={styles.advancedParametersInput}
                        fluid
                        onChange={event => _setAdvancedParameters(event.target.value)}
                        value={_advancedParameters}
                      />
                    </Form.Field>
                    <ArkIconButton
                      className={styles.advancedParametersButton}
                      color='var(--blue)'
                      name='arrow-right'
                      onClick={onAdvancedParametersSubmit}
                      size={24}
                    />
                  </Form>
                  <ArkSpacer small />
                  <div className={styles.description}>Example: <code>{'{"aspect_ratio":"4:3"}'}</code></div>
                </>
              )}
            </>
          )}
        </>
      )}
    </ArkPopup>
  )
}

export default ProgramOptionsPopup
