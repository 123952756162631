import React from 'react'

import { useSiteAdmin } from 'src/core/providers'

import { Company } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { OBJECT_COMPANY_NAME } from 'src/constants/strings'

export type CompanyDidDeleteCallback = (company: Company) => void

interface IProps {
  company: Company
  onDidDelete?: CompanyDidDeleteCallback
  className?: string
}

const CompanyDeleteView = (props: IProps) => {
  const { className, company, onDidDelete: _onDidDelete } = props

  const siteAdminContext = useSiteAdmin()

  const onDelete = async (_itemId: number): Promise<boolean> => {
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    const deleteResult = await siteAdminContext.actions.deleteCompany(company.id)
    return deleteResult
  }

  if (!company) return null
  return (
    <div className={(className ? ' ' + className : '')}>
      <ArkSpacer />
      <ArkManagerDeleteButton
        itemId={company.id}
        itemName={company.name}
        itemTypeName={OBJECT_COMPANY_NAME}
        buttonTitle={'DELETE ' + OBJECT_COMPANY_NAME}
        onDelete={onDelete}
        onDeleteComplete={() => { if (_onDidDelete) _onDidDelete(company) }}
      />
    </div>
  )
}

export default CompanyDeleteView
