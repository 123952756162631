import React, { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { useCompanyInvite, useNav, UserStatus, useUser } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import { CompanyInviteUserDetails } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkCenterLayout from 'src/core/components/ArkCenterLayout'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkPage from 'src/core/components/ArkPage/ArkPage'

import { Message, Segment } from 'semantic-ui-react'

import { OBJECT_COMPANY_NAME } from 'src/constants/strings'

// NB: this page should be public, we catch & handle if the user clicks an invite link when they're not logged in yet
// NB: we store the invite in localStorage & prompt the user to login/register, & then resume the invite accept call once login finishes

interface IProps {}

const CompanyInviteAcceptPage = (_props: IProps) => {
  const mounted = useRef(false)

  const { inviteToken } = useParams<{ inviteToken: string }>()

  const companyInviteContext = useCompanyInvite()
  const navContext = useNav()
  const userContext = useUser()

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const runLookupInviteToken = async () => {
    await companyInviteContext.actions.lookupInviteTokenAndProcess(inviteToken)
  }

  useEffect(() => {
    runLookupInviteToken()
  }, [])

  // -------

  const renderLoadingInviteDetails = () => {
    return <ArkLoader message='Checking' />
  }

  // -------

  const renderLoginButton = (inviteDetails?: CompanyInviteUserDetails) => {
    return (
      <ArkButton fluid positive onClick={() => {
        navContext.actions.goto({
          pathname: ROUTES.LOGIN,
          search: '', // '?query=abc',
          state: inviteDetails ? { inviteDetails: inviteDetails } : undefined
        })
      }}>
        LOGIN
      </ArkButton>
    )
  }

  const renderRegisterButton = (inviteDetails?: CompanyInviteUserDetails) => {
    return (
      <ArkButton fluid positive onClick={() => {
        navContext.actions.goto({
          pathname: ROUTES.REGISTER,
          search: '', // '?query=abc',
          state: inviteDetails ? { inviteDetails: inviteDetails } : undefined
        })
      }}>
        REGISTER
      </ArkButton>
    )
  }

  const renderOpenViewerButton = () => {
    const { companyId } = companyInviteContext.store
    return (
      <ArkButton
        fluid
        positive
        onClick={() => {
          // if the user is already (email) verified & we have a companyId from the invite details auto select it, otherwise deselect..
          // ..if a company is already selected so when we redirect to the viewer the user is prompted to choose & will see the new company listed
          if (userContext.actions.isVerified() && companyId) {
            userContext.actions.selectCompany(companyId)
          } else if (userContext.store.selectedCompany) {
            userContext.actions.deselectCurrentCompany()
          }
          // NB: manually redirect to the viewer, as the select/deselect calls won't when on the invite page (to stop auto directing away on page load)
          navContext.actions.goto(ROUTES.VIEWER)
        }}
      >
        CONTINUE
      </ArkButton>
    )
  }

  // -------

  const renderError = (error: Error) => {
    const isLoggedIn = userContext.store.userStatus === UserStatus.loggedIn
    return (
      <>
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{error.message}</p>
        </Message>
        {isLoggedIn && renderOpenViewerButton()}
        {!isLoggedIn && renderLoginButton()}
      </>
    )
  }

  const renderInviteExpired = () => {
    const isLoggedIn = userContext.store.userStatus === UserStatus.loggedIn
    return (
      <>
        <Message warning>
          <Message.Header>{OBJECT_COMPANY_NAME} Invite Expired</Message.Header>
          <Message.Content style={{ marginTop: 5, marginBottom: 5 }}>
            <p>Your invitation has expired.<br />Please contact the person who invited you to send a new invite.</p>
            {/* TODO: in later phases we'll likely want to add a way to 'request a re-invite' that notifies the company admins to prod them to do it? */}
            {/* TODO: and/or we may want to allow the user to re-trigger a new invite to be sent on demand? (of a company level setting was enabled to allow it perhaps?) */}
          </Message.Content>
        </Message>
        {/* TODO: showing the same buttons as 'already accepted' for now, just so the user has some prompt to follow */}
        {/* TODO: will at least want to consider what to show when they're logged in, as they may not have any company access yet */}
        {isLoggedIn && renderOpenViewerButton()}
        {!isLoggedIn && renderLoginButton()}
      </>
    )
  }

  const renderInviteAlreadyAccepted = () => {
    const isLoggedIn = userContext.store.userStatus === UserStatus.loggedIn
    return (
      <>
        <Message warning>
          <Message.Header>Company Invite Already Accepted</Message.Header>
          <Message.Content style={{ marginTop: 5, marginBottom: 5 }}>
            <p>You have already accepted this company invite.</p>
          </Message.Content>
        </Message>
        {isLoggedIn && renderOpenViewerButton()}
        {!isLoggedIn && renderLoginButton()}
      </>
    )
  }

  const renderVerifyingInvite = () => {
    return <ArkLoader message='Verifying' />
  }

  const renderRegisterOrLoginPrompt = () => {
    const { inviteDetails, loginRequired, registrationRequired } = companyInviteContext.store
    if (loginRequired) {
      return (
        <>
          <Message info>
            <Message.Header>Login Required</Message.Header>
            <p>Please login to accept the {OBJECT_COMPANY_NAME} invite</p>
          </Message>
          {renderLoginButton(inviteDetails)}
        </>
      )
    } else if (registrationRequired) {
      return (
        <>
          <Message info>
            <Message.Header>Registration Required</Message.Header>
            <p>Please register to accept the {OBJECT_COMPANY_NAME} invite</p>
          </Message>
          {renderRegisterButton(inviteDetails)}
        </>
      )
    }
  }

  const renderInviteAccepted = () => {
    return (
      <>
        <Message positive>
          <Message.Header>Success</Message.Header>
          <p>You&apos;ve accepted the {OBJECT_COMPANY_NAME} invitation.</p>
        </Message>
        {renderOpenViewerButton()}
      </>
    )
  }

  const renderProcessingInviteDetails = () => {
    const {
      inviteExpired,
      inviteAlreadyAccepted,
      loginRequired,
      registrationRequired,
      isSubmitting,
      success,
      error
    } = companyInviteContext.store
    return (
      <>
        {error && renderError(error)}

        {inviteExpired && renderInviteExpired()}
        {inviteAlreadyAccepted && renderInviteAlreadyAccepted()}

        {(loginRequired || registrationRequired) && renderRegisterOrLoginPrompt()}

        {isSubmitting && renderVerifyingInvite()}

        {success && renderInviteAccepted()}
      </>
    )
  }

  // -------

  const inviteDetailsLoading = companyInviteContext.store.inviteDetailsLoading
  return (
    <ArkPage>
      <ArkCenterLayout>
        <Segment inverted>
          {inviteDetailsLoading && renderLoadingInviteDetails()}
          {!inviteDetailsLoading && renderProcessingInviteDetails()}
        </Segment>
      </ArkCenterLayout>
    </ArkPage>
  )
}

export default CompanyInviteAcceptPage
