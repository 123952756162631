import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { Loader, Popup } from 'semantic-ui-react'

import { DVR_SEGMENT_DURATION, DVR_THUMBNAIL_ENABLED, DVR_THUMBNAIL_UPDATE_DELAY } from 'src/constants/config'
import ArkIcon from 'src/core/components/ArkIcon'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { delay } from 'src/core/utilities/delay'

import { formatTime, getSecondsToday } from './utilities'

import styles from './ProgramThumbnailPopup.module.css'

const DEBUG_ALWAYS_OPEN: boolean = false

interface ProgramThumbnailPopupProps {
  hoverSeekTime: number
  noFragment: boolean
  open: boolean
  thumbnailUrl: string
  trigger: ReactNode
}

const ProgramThumbnailPopup = (props: ProgramThumbnailPopupProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const hoverSeekTimeRef = useRef<number>(0)
  const mountedRef = useRef<boolean>(false)
  const openRef = useRef<boolean>(false)
  const videoRef = useRef<HTMLVideoElement>(null)

  const [loading, setLoading] = useState<boolean>(true)
  const [open, setOpen] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [utcTime, setUtcTime] = useState<number>(0)

  useEffect(() => {
    // console.log('ProgramThumbnailPopup - mount')
    mountedRef.current = true
    return () => {
      // console.log('ProgramThumbnailPopup - unmount')
      mountedRef.current = false
    }
  }, [])

  /**
   * open animation
   */

  useEffect(() => {
    openRef.current = props.open
    if (DEBUG_ALWAYS_OPEN || props.open) {
      setOpen(true)
      setTimeout(() => setShow(true))
    } else {
      setShow(false)
      setTimeout(() => {
        if (!openRef.current) setOpen(false)
      }, 300)
    }
  }, [props.open])

  /**
   * update time
   */

  const updateUtcTime = (): void => {
    // console.log('ProgramThumbnailPopup - updateUtcTime')
    let time: number = Math.floor(Date.now() / 1000) - hoverSeekTimeRef.current
    time = Math.floor(time / DVR_SEGMENT_DURATION) * DVR_SEGMENT_DURATION
    // console.log('ProgramThumbnailPopup - updateUtcTime - time:', time)
    setUtcTime(time)
  }

  const debounceUpdateUtcTime = useCallback(_.debounce(
    updateUtcTime,
    DVR_THUMBNAIL_UPDATE_DELAY,
    {
      leading: true,
      maxWait: DVR_THUMBNAIL_UPDATE_DELAY
    }
  ), [])

  useEffect(() => {
    // console.log('ProgramThumbnailPopup - hoverSeekTime:', props.hoverSeekTime)
    hoverSeekTimeRef.current = props.hoverSeekTime
    setLoading(true)
    debounceUpdateUtcTime()
  }, [props.hoverSeekTime])

  useEffect(() => {
    // console.log('ProgramThumbnailPopup - update interval - start')
    const interval: ReturnType<typeof setInterval> = setInterval(updateUtcTime, 1000 * DVR_SEGMENT_DURATION)
    return () => {
      // console.log('ProgramThumbnailPopup - update interval - stop')
      clearInterval(interval)
    }
  }, [])

  /**
   * update thumbnail
   */

  const debounceSetLoading = useCallback(_.debounce(
    setLoading,
    DVR_THUMBNAIL_UPDATE_DELAY
  ), [])

  const onVideoLoadedData = async (): Promise<void> => {
    // console.log('ProgramThumbnailPopup - onVideoLoadedData')
    await delay()
    if (!mountedRef.current || !canvasRef.current || !videoRef.current) return
    const context: CanvasRenderingContext2D | null = canvasRef.current.getContext('2d')
    if (!context) return
    context.drawImage(videoRef.current, 0, 0, context.canvas.width, context.canvas.height)
    debounceSetLoading(false)
  }

  /**
   * utilities
   */

  const getThumbnailUrl = (): string => {
    if (!utcTime) return `${props.thumbnailUrl}?${utcTime}` // live
    const url: string = `${props.thumbnailUrl.replace('.mp4', '')}_${utcTime}.mp4`
    // console.log('ProgramThumbnailPopup - getThumbnailUrl - url:', url)
    return url
  }

  const getTime = (): string => {
    const ms: number = Date.now() - (props.hoverSeekTime * 1000)
    return formatTime(getSecondsToday(ms))
  }

  const getSeekTime = (): string => {
    return `-${formatTime(props.hoverSeekTime)}`
  }

  /**
   * render
   */

  return (
    <Popup
      className={styles.popup}
      inverted
      open={open}
      position='top center'
      style={{ opacity: show ? 1 : 0 }}
      trigger={props.trigger}
    >
      {DVR_THUMBNAIL_ENABLED && (
        <>
          <div className={styles.videoContainer}>
            {props.noFragment
              ? (
                <ArkIcon
                  className={styles.noFragmentIcon}
                  color='var(--tx-muted)'
                  name='offline'
                  size={36}
                />
              )
              : (
                <>
                  <video
                    className={styles.video}
                    muted
                    ref={videoRef}
                    src={getThumbnailUrl()}
                    onLoadedData={onVideoLoadedData}
                  />
                  <canvas
                    className={styles.canvas}
                    height={108}
                    ref={canvasRef}
                    width={192}
                  />
                  <Loader active={loading} />
                </>
              )}
          </div>
          <ArkSpacer />
        </>
      )}
      <div className={styles.time}>{getTime()}</div>
      <div className={styles.seekTime}>{getSeekTime()}</div>
    </Popup>
  )
}

export default ProgramThumbnailPopup
