import React, { useState, useContext } from 'react'
import * as yup from 'yup'

import { AuthContext, UserContext } from 'src/core/providers'
import { ServerAuth2FAOTPRequiredError, ServerAuthInvalidCredentialsError, ServerAuthPasswordPolicyError } from 'src/core/services/ServerAPIErrors'

import ArkButton from 'src/core/components/ArkButton'
import ArkDivider from 'src/core/components/ArkDivider'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkSpacer from 'src/core/components/ArkSpacer'

import User2FAInputView, { User2FAInputViewMode } from '../User2FAPage/User2FAInputView'

import { Message } from 'semantic-ui-react'

import styles from './UserChangePassword.module.css'

const formSchema = yup.object().shape({
  oldPassword: yup.string().min(6).max(40).required().label('Old Password'),
  newPassword: yup.string().min(6).max(40).required().label('New Password'),
  newPasswordConfirm: yup.string().oneOf([yup.ref('newPassword'), undefined], 'New passwords must match').label('Confirm New Password')
})

interface IProps {
  onCancel?: Function
  onDone?: Function
}

const UserChangePasswordForm = ({ ...props }: IProps) => {
  const authContext = useContext(AuthContext)
  const userContext = useContext(UserContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasSaved, setHasSaved] = useState(false)
  const [error, setError] = useState<Error>()

  // 2FA enabled users requires a 2nd step, where we cache the old & new passwords & prompt for their 2FA OTP code before re-submitting
  const [tfaRequired, setTFARequired] = useState<boolean>(false)
  const [oldPassword, setOldPassword] = useState<string | undefined>()
  const [newPassword, setNewPassword] = useState<string | undefined>()

  const user = userContext.store.user

  const submitChangePassword = async (_oldPassword: string, _newPassword: string, otpCode?: string) => {
    console.log('UserChangePasswordForm - submitChangePassword - oldPassword:', _oldPassword, ' newPassword:', _newPassword, ' otpCode:', otpCode)
    if (isSubmitting) return
    setIsSubmitting(true)
    if (hasSaved) setHasSaved(false)
    if (error) setError(undefined)
    // await new Promise(resolve => setTimeout(resolve, 1000)) // TODO: replace with real api call <<<<<
    try {
      await authContext.actions.updateEmailPassword(_oldPassword, _newPassword, otpCode)
      setIsSubmitting(false)
      setHasSaved(true)
      if (tfaRequired) setTFARequired(false)
      if (oldPassword) setOldPassword(undefined)
      if (newPassword) setNewPassword(undefined)
    } catch (error) {
      console.log('UserChangePasswordForm - submitChangePassword - error:', error)
      setIsSubmitting(false)
      // catch if 2FA is required & flip into 2FA OTP entry mode (instead of just showing the error)
      if (error instanceof ServerAuth2FAOTPRequiredError) {
        console.log('UserChangePasswordForm - submitChangePassword - error - ServerAuth2FAOTPRequiredError - error:', error)
        setTFARequired(true)
        setOldPassword(_oldPassword)
        setNewPassword(_newPassword)
      } else if (error instanceof ServerAuthInvalidCredentialsError) {
        setError(new Error('Incorrect old password. Please check & try again.'))
      } else {
        setError(error)
      }
    }
  }

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { oldPassword: _oldPassword, newPassword: _newPassword } = fieldValues
    // console.log('UserChangePasswordForm - oldPassword - oldPassword: ', oldPassword, ' newPassword: ', newPassword, ' otpCode: ', otpCode)
    // submit the change password request
    // NB: if the user has 2FA enabled, the api will return a `ServerAuth2FAOTPRequiredError` error which we catch & trigger the 2FA OTP entry mode, before re-submitting with the OTP code
    await submitChangePassword(_oldPassword, _newPassword)
  }

  const resetForm = () => {
    if (tfaRequired) setTFARequired(false)
    if (oldPassword) setOldPassword(undefined)
    if (newPassword) setNewPassword(undefined)
    if (hasSaved) setHasSaved(false)
    if (error) setError(undefined)
  }

  const onCancel = () => { if (props.onCancel) props.onCancel() }
  const onDone = () => { if (props.onDone) props.onDone() }

  const sectionFormFields: Array<ArkFormField> = []
  // sectionFormFields.push({ type: ArkFormFieldType.Input, key: 'email', label: 'Email Address', required: true, value: user?.email, fieldProps: { disabled: true } })
  if (user?.tfaEnabled) {
    sectionFormFields.push({
      type: ArkFormFieldType.Field,
      key: 'tfaNotice',
      content: (
        <ArkMessage warning visible>
          <ArkMessage.Header>2FA Confirmation</ArkMessage.Header>
          <p><strong>Please Note:</strong> You will be prompted for your 2FA OTP code after submitting your new password.</p>
        </ArkMessage>
      )
    })
  }
  sectionFormFields.push({ type: ArkFormFieldType.Field, key: 'emailPreview', content: (<div className={styles.email}><span className={styles.title}>Email:</span><span className={styles.value}>{user?.email ?? '-'}</span></div>) })
  sectionFormFields.push({ type: ArkFormFieldType.Field, key: 'emailDivider', content: (<ArkDivider className={styles.divider} horizontal section></ArkDivider>) })
  sectionFormFields.push({ type: ArkFormFieldType.Input, key: 'oldPassword', label: 'Old Password', required: true, fieldProps: { type: 'password' } })
  sectionFormFields.push({ type: ArkFormFieldType.Field, key: 'oldPassDivider', content: (<ArkDivider className={styles.divider} horizontal section></ArkDivider>) })
  sectionFormFields.push({ type: ArkFormFieldType.Input, key: 'newPassword', label: 'New Password', required: true, fieldProps: { type: 'password' } })
  sectionFormFields.push({ type: ArkFormFieldType.Input, key: 'newPasswordConfirm', label: 'Confirm New Password', required: true, fieldProps: { type: 'password' } })

  const formFields: Array<ArkFormField> = []
  formFields.push({
    type: ArkFormFieldType.Fieldset,
    key: 'passFieldset',
    label: 'Change Password',
    fields: [...sectionFormFields],
    collapsible: false,
    collapsed: false
  })
  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      { type: ArkFormFieldType.CancelButton, key: 'cancel', label: 'CANCEL', fieldProps: { onClick: onCancel, floated: 'left' } },
      { type: ArkFormFieldType.OKButton, key: 'submit', label: 'SAVE', fieldProps: { loading: isSubmitting, floated: 'right' } }
    ],
    fieldProps: { widths: 'equal' },
    slimline: true
  })

  let passPolicyViolations: Array<string> | undefined
  if (error && error instanceof ServerAuthPasswordPolicyError) {
    passPolicyViolations = error.policyViolations
    console.log('UserChangePasswordForm - render - ServerAuthPasswordPolicyError - passPolicyViolations:', passPolicyViolations)
  }

  // renders the 2FA OTP input view within a stub ArkForm so we can wrap it in a fieldset to keep the styling consistent with the previous password entry form
  // NB: originally used an actual ArkForm & its fieldset, but as `User2FAInputView` is a form in itself, its invalid html syntax to nest a form within a form, so switched to use a mock form fieldset instead
  // NB: this was first spotted on the `LoginPasswordResetStage2Form` 2FA form which duplicated the 2FA usage from here including the ArkForm wrapper, although that did throw the html syntax error when this one didn't seem too, despite the same nested form use, so pro-actively fixed it here at the same time as the other
  const render2FAOTPForm = () => {
    return (
      <div className={`${styles.passwordChangeForm2FA}`}>
        <div className={`${styles.fieldset} ${styles.fieldsetBorder}`}>
          <div className={styles.fieldsetTitle}>Change Password</div>
          <ArkMessage warning visible className={styles.passwordChange2FANotice}>
            <ArkMessage.Header>2FA Confirmation</ArkMessage.Header>
            <p>Enter your 2FA OTP code to confirm your password change</p>
          </ArkMessage>
          <div className={styles.email}>
            <span className={styles.title}>Email:</span>
            <span className={styles.value}>{user?.email ?? '-'}</span>
          </div>
          <ArkDivider className={styles.divider} horizontal section></ArkDivider>
          <User2FAInputView
            inputMode={User2FAInputViewMode.split}
            autoSubmit={true}
            isBusy={isSubmitting}
            error={error}
            onSubmit={async (otpCode) => {
              console.log('UserChangePasswordForm - User2FAInputView - onSubmit - otpCode:', otpCode)
              // halt with an error if we don't have the old & new passwords cached (shouldn't happen in normal usage)
              if (!oldPassword || !newPassword) {
                console.error('UserChangePasswordForm - User2FAInputView - onSubmit - missing oldPassword and/or newPassword')
                setError(new Error('An error occurred. Please try again.'))
                return
              }
              // re-submit the change password request with the 2FA OTP code
              await submitChangePassword(oldPassword, newPassword, otpCode)
            }}
          />
          <div className={styles.tfaCancel}>
            <a href="#" onClick={(event) => {
              event.preventDefault()
              resetForm()
            }}>Cancel</a>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {hasSaved && (
        <>
          <Message positive>
            <Message.Header>Password Changed</Message.Header>
            <p>Your password has been successfully changed.<br />Future logins will require it.</p>
          </Message>
          <ArkSpacer size={20} />
          <ArkButton fluid onClick={onDone}>OK</ArkButton>
        </>
      )}
      {error && passPolicyViolations && (
        <Message negative>
          <Message.Header>Password Policy Error</Message.Header>
          <div>{error.message}</div>
          {passPolicyViolations.map((passPolicyViolation, i) => <Message.Item key={'policy-violation-' + i}>{passPolicyViolation}</Message.Item>)}
        </Message>
      )}
      {tfaRequired && (
        <>
          <ArkSpacer size={30} />
          {render2FAOTPForm()}
        </>
      )}
      {(!hasSaved && !tfaRequired) && (
        <>
          <ArkSpacer size={30} />
          <ArkForm
            formKey="passwordChange"
            inverted
            formError={error && !passPolicyViolations ? error : undefined}
            formFields={formFields}
            formSchema={formSchema}
            onFormSubmit={onFormSubmit}
            showLabels={true}
            insideModal={true}
          />
        </>
      )}
    </>
  )
}

export default UserChangePasswordForm
