import React, { useEffect, useRef } from 'react'
import * as yup from 'yup'

import { AuthStatus, useAuth } from 'src/core/providers'

import ArkForm, { ArkFormField, ArkFormFieldPlaceholder, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import { ServerAuthPasswordPolicyError } from 'src/core/services/ServerAPIErrors'

import { Message } from 'semantic-ui-react'

const formSchema = yup.object().shape({
  // name: yup.string().min(3).max(40).required(),
  firstName: yup.string().min(0).max(255), // .required(), // TODO: set required once api endpoint uses this?
  lastName: yup.string().min(0).max(255), // .required(), // TODO: set required once api endpoint uses this?
  email: yup.string().email().required(),
  phone: yup.string().min(6).max(15).required(),
  password: yup.string().required(), // NB: now leaving password validation for the server (was `.min(6).max(40)`)
  passwordConfirm: yup.string().oneOf([yup.ref('password'), undefined], 'Passwords must match')
})

interface IProps {
  email?: string // if email is specified, then this form won't add it as a field
  firstName?: string
  lastName?: string
}

const RegisterForm = (props: IProps) => {
  const mounted = useRef(false)

  const { email, firstName, lastName } = props

  const authContext = useAuth()

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { firstName, lastName, phone, password } = fieldValues
    const userEmail = email ?? fieldValues.email
    const loading = authContext.store.authStatus === AuthStatus.loading
    if (userEmail && loading === false) {
      // on success the AuthProvider will trigger a redirect away, if an error happens its propagated down from the auth provider in the render function
      await authContext.actions.registerUserWithEmailAndPassword(userEmail, password, firstName, lastName, phone)
    }
  }

  // -------

  const loading = authContext.store.authStatus === AuthStatus.loading
  const err = authContext.store.authError

  const formFields: Array<ArkFormField> = []
  formFields.push({ type: ArkFormFieldType.Input, key: 'firstName', label: 'First Name', required: true, defaultValue: firstName })
  formFields.push({ type: ArkFormFieldType.Input, key: 'lastName', label: 'Last Name', required: true, defaultValue: lastName })
  // formFields.push({ type: ArkFormFieldType.Input, key: 'phone', label: 'Phone Number', required: true }) //, icon: 'phone'
  formFields.push({ type: ArkFormFieldType.PhoneNo, key: 'phone', label: 'Phone Number', required: true })
  formFields.push({ type: ArkFormFieldType.Input, key: 'email', label: 'Email Address', required: true, defaultValue: email, fieldProps: { disabled: (!!email), hidden: (!!email), autoComplete: 'username' } }) // icon: mail // TODO: can we set the field as hidden when an email is passed in?
  formFields.push({ type: ArkFormFieldType.Input, key: 'password', label: 'Password', required: true, fieldProps: { type: 'password' } }) // icon: lock
  formFields.push({ type: ArkFormFieldType.Input, key: 'passwordConfirm', label: 'Confirm Password', required: true, fieldProps: { type: 'password' } })
  formFields.push({ type: ArkFormFieldType.Button, key: 'submit', label: 'Register', fieldProps: { loading: loading, fluid: true } })

  // specify a special form error placeholder so we can place/render it directly in the form where we want it too (so we can customise/change it when a password policy error is detected)
  formFields.push({ type: ArkFormFieldType.FormErrorPlaceholder, key: 'formError' })

  let passPolicyViolations: Array<string> | undefined
  if (err && err instanceof ServerAuthPasswordPolicyError) {
    passPolicyViolations = err.policyViolations
    console.log('RegisterForm - render - ServerAuthPasswordPolicyError - passPolicyViolations: ', passPolicyViolations)
  }

  return (
    <ArkForm
      formKey="register"
      className="register-form"
      inverted
      formError={err && !passPolicyViolations ? err : undefined}
      formFields={formFields}
      formSchema={formSchema}
      onFormSubmit={onFormSubmit}
    >

      <ArkFormFieldPlaceholder fieldKey="formError" />
      {err && passPolicyViolations && (
        <Message negative>
          <Message.Header>Password Policy Error</Message.Header>
          <div>{err.message}</div>
          {passPolicyViolations.map((passPolicyViolation, i) => <Message.Item key={'policy-violation-' + i}>{passPolicyViolation}</Message.Item>)}
        </Message>
      )}

      <ArkFormFieldPlaceholder fieldKey="firstName" />
      <ArkFormFieldPlaceholder fieldKey="lastName" />
      <ArkFormFieldPlaceholder fieldKey="phone" />
      <ArkFormFieldPlaceholder fieldKey="email" />
      <ArkFormFieldPlaceholder fieldKey="password" />
      <ArkFormFieldPlaceholder fieldKey="passwordConfirm" />
      <ArkFormFieldPlaceholder fieldKey="submit" />
    </ArkForm>
  )
}

export default RegisterForm
