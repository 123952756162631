import React from 'react'
import { Modal, TransitionablePortal } from 'semantic-ui-react'

import ArkButton from 'src/core/components/ArkButton'
import ArkInfoView from 'src/core/components/ArkInfoView'

import { useGlobalConfig } from '../GlobalConfigProvider'
import IdleIndicator from './IdleIndicator'

import styles from './IdleLockedView.module.css'

const DEBUG_ALWAYS_OPEN: boolean = false

interface IdleLockedViewProps {
  onClose: () => void
  show: boolean
}

const IdleLockedView = (props: IdleLockedViewProps) => {
  const globalConfig = useGlobalConfig()
  const config = globalConfig.store.config

  return (
    <TransitionablePortal
      open={DEBUG_ALWAYS_OPEN || props.show}
      transition={{
        animation: 'fade up',
        duration: 500
      }}
    >
      <Modal className={styles.modal} open>
        <ArkInfoView
          iconComponent={<IdleIndicator locked size={80} />}
          title='Locked'
          message={
            <>
              <div>{config.appName} has automatically locked due to inactivity.</div>
              <div>Please re-enter your password.</div>
            </>
          }
          buttons={<ArkButton onClick={props.onClose}>OK</ArkButton>}
        />
      </Modal>
    </TransitionablePortal>
  )
}

export default IdleLockedView
