import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { useIdleTimer } from 'react-idle-timer'
import { useLocation } from 'react-router-dom'

import * as ROUTES from 'src/constants/routes'

import {
  IDLE_DEBUG_VIEW,
  IDLE_ENABLED,
  IDLE_PROMPT_MS,
  IDLE_TIMEOUT_DEFAULT_MS,
  IDLE_TIMEOUT_MAX_MS,
  IDLE_TIMEOUT_MIN_MS
} from 'src/constants/config'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { Company } from 'src/core/models'
import { getNavSection, NavSection } from 'src/core/utilities/navigation'

import { AuthStatus, useAuth } from '../AuthProvider'
import { useLocalConfig } from '../LocalConfigProvider'
import { useNav } from '../NavProvider'
import { useUser } from '../UserProvider'
import IdleLockedView from './IdleLockedView'
import IdlePromptView from './IdlePromptView'

import { millisecondsToSeconds, secondsToMilliseconds } from 'src/core/utilities/time'

import styles from './IdleProvider.module.css'

type IdleState = 'unknown' | 'disabled' | 'enabled' | 'prompt' | 'locked'

type IdleProviderProps = {
  children: ReactNode
}

export type IdleContextValue = {
  getLocked: () => boolean
  getPrompted: () => boolean
  getPromptPercent: () => number
  getTimeout: () => number
}

const IdleContext = createContext<IdleContextValue>({} as IdleContextValue)

export const useIdle = () => useContext(IdleContext)

const IdleProvider = (props: IdleProviderProps) => {
  if (!IDLE_ENABLED) return <>{props.children}</>

  const auth = useAuth()
  const localConfig = useLocalConfig()
  const location = useLocation()
  const nav = useNav()
  const user = useUser()

  const actionTime = useRef<number>(new Date().getTime())
  const enabled = useRef<boolean>(false)
  const timeout = useRef<number>(IDLE_TIMEOUT_DEFAULT_MS)

  const [idleState, setIdleState] = useState<IdleState>('unknown')
  const [idleTime, setIdleTime] = useState<number>(0)
  const [prompted, setPrompted] = useState<boolean>(false)

  const company: Company | undefined = user.store.selectedCompany

  useIdleTimer({
    onAction: () => {
      // console.log('IdleProvider - onAction')
      actionTime.current = new Date().getTime()
    },
    throttle: 500
  })

  /**
   * local config
   */

  const prevLocked = useRef<boolean>(false)
  useEffect(() => {
    prevLocked.current = localConfig.getLocked()
  }, [localConfig.getLocked()])

  useEffect(() => {
    if (auth.store.authStatus === AuthStatus.loggedOut) {
      localConfig.setLocked(false)
    }
  }, [auth.store.authStatus])

  /**
   * company settings
   */

  useEffect(() => {
    if (company) {
      enabled.current = company.idleTimeoutManagement
      timeout.current = _.clamp(
        secondsToMilliseconds(company.idleTimeoutManagementTime),
        IDLE_TIMEOUT_MIN_MS,
        IDLE_TIMEOUT_MAX_MS
      )
    } else {
      enabled.current = false
      timeout.current = IDLE_TIMEOUT_DEFAULT_MS
      prevLocked.current = false
    }
  }, [company])

  /**
   * update idle state
   */

  const prevIdleState = useRef<IdleState>(idleState)
  useEffect(() => {
    prevIdleState.current = idleState
  }, [idleState])

  const updateIdleState = (): void => {
    // console.log('IdleProvider - updateIdleState')

    const idleTime: number = new Date().getTime() - actionTime.current
    setIdleTime(idleTime)

    // not locked > locked
    if (prevIdleState.current !== 'locked' && prevLocked.current) {
      setIdleState('locked')
      setPrompted(true)
      return
    }

    // locked
    if (prevIdleState.current === 'locked') {
      return
    }

    // unknown or disabled > enabled
    if (_.includes(['unknown', 'disabled'], prevIdleState.current) && enabled.current) {
      setIdleState('enabled')
      return
    }

    // not disabled > disabled
    if (prevIdleState.current !== 'disabled' && !enabled.current) {
      setIdleState('disabled')
      setPrompted(false)
      return
    }

    // prompt > lock
    const timeoutRemaining: number = Math.max(timeout.current - idleTime, 0)
    if (prevIdleState.current === 'prompt' && !timeoutRemaining) {
      setIdleState('locked')
      localConfig.setLocked(true)
      return
    }

    // enabled > prompt
    const promptRemaining: number = Math.max((timeout.current - IDLE_PROMPT_MS) - idleTime, 0)
    if (prevIdleState.current === 'enabled' && !promptRemaining) {
      setIdleState('prompt')
      setPrompted(true)
      return
    }

    // prompt > enabled
    if (prevIdleState.current === 'prompt' && promptRemaining) {
      setIdleState('enabled')
    }
  }

  /**
   * update timer
   */

  useEffect(() => {
    // console.log('IdleProvider - update timer - start')
    if (!enabled.current) return
    const timer = setInterval(() => {
      // console.log('IdleProvider - update timer - interval')
      updateIdleState()
    }, 1000)
    return () => {
      // console.log('IdleProvider - update timer - stop')
      clearInterval(timer)
    }
  }, [enabled.current])

  /**
   * automatic logout
   */

  const navSection: NavSection | undefined = getNavSection(location.pathname)
  const management: boolean = _.includes(['admin', 'company', 'project'], navSection)

  useEffect(() => {
    if (idleState === 'locked' && management) {
      // console.log('IdleProvider - automatic logout')
      // log out the user
      // - uses the post-logout callback to cache their email, so its pre-filled in the login form to make it easier for them to log back in
      // - & also manually redirect them to the login page (which it would auto do anyway), but specify the current location in the `from` arg so it redirects back there after login
      const email: string | undefined = user.store.user?.email
      auth.actions.logout(async () => {
        auth.actions.cacheLoginEmail(email)
        nav.actions.goto(ROUTES.LOGIN, nav.actions.getCurrentLocation())
      })
    }
  }, [idleState, management])

  /**
   * render
   */

  const promptRemaining: number =
    idleState === 'enabled'
      ? Math.max(timeout.current - IDLE_PROMPT_MS - idleTime, 0)
      : 0
  const timeoutRemaining: number =
    _.includes(['enabled', 'prompt'], idleState)
      ? Math.max(timeout.current - idleTime, 0)
      : 0
  const promptPercent: number =
    idleState === 'prompt'
      ? _.clamp(100 - ((timeoutRemaining - 1) / IDLE_PROMPT_MS) * 100, 0, 100)
      : 0

  const debugComponent = IDLE_DEBUG_VIEW && (
    <div className={styles.debug}>
      <div><strong>IDLE</strong></div>
      <ArkSpacer small />
      <div>{`STATE: ${idleState}`}</div>
      <div>{`TIMEOUT: ${millisecondsToSeconds(timeout.current)}`}</div>
      <div>{`PROMPT: ${millisecondsToSeconds(IDLE_PROMPT_MS)}`}</div>
      <div>{`PROMPTED: ${prompted ? 'YES' : 'NO'}`}</div>
      <div>{`IDLE TIME: ${millisecondsToSeconds(idleTime)}`}</div>
      <div>{`PROMPT REMAINING: ${millisecondsToSeconds(promptRemaining)}`}</div>
      <div>{`TIMEOUT REMAINING: ${millisecondsToSeconds(timeoutRemaining)}`}</div>
    </div>
  )

  return (
    <IdleContext.Provider value={{
      getLocked: () => idleState === 'locked',
      getPrompted: () => prompted,
      getPromptPercent: () => promptPercent,
      getTimeout: () => timeout.current
    }}>
      {props.children}
      <IdlePromptView
        remaining={timeoutRemaining}
        show={idleState === 'prompt' && _.includes(['admin', 'company', 'project'], navSection)}
      />
      <IdleLockedView
        onClose={() => setIdleState('unknown')}
        show={idleState === 'locked' && navSection === 'login'}
      />
      {debugComponent}
    </IdleContext.Provider>
  )
}

export default IdleProvider
