import React, { ReactNode } from 'react'

import { OnlineStatus } from 'src/core/models'
import {
  getChannelOnlineStatusDescription,
  getOnlineStatusColor,
  getOnlineStatusText
} from 'src/core/utilities/status'

import ArkTooltip from '../ArkTooltip'

import styles from './ArkOnlineStatusIndicator.module.css'

export interface ArkOnlineStatusIndicatorProps {
  className?: string
  onlineStatus: OnlineStatus
  size?: number
  tooltip?: boolean;
  variant: 'channel' | 'program'
}

const ArkOnlineStatusIndicator = (props: ArkOnlineStatusIndicatorProps) => {
  const main: ReactNode = (
    <div className={props.className}>
      <div className={styles.container}>
        <div
          className={styles.indicator}
          style={{
            backgroundColor: getOnlineStatusColor(props.onlineStatus),
            ...props.size && {
              borderWidth: props.size / 10,
              height: props.size,
              width: props.size
            }
          }}
        />
      </div>
    </div>
  )

  if (props.tooltip) {
    return (
      <ArkTooltip
        position="right center"
        text={
          props.variant === 'channel'
            ? getChannelOnlineStatusDescription(props.onlineStatus)
            : getOnlineStatusText(props.onlineStatus)
        }
      >
        {main}
      </ArkTooltip>
    )
  }

  return <>{main}</>
}

export default ArkOnlineStatusIndicator
