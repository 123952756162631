import React, { CSSProperties } from 'react'

import { OnlineStatus } from 'src/core/models'

import ArkOnlineStatusIndicator from '../ArkOnlineStatusIndicator'

import styles from './ArkProgramIcon.module.css'

export interface ArkProgramIconProps {
  color?: string
  initials: string
  onlineStatus?: OnlineStatus
  size?: number
}

const ArkProgramIcon = (props: ArkProgramIconProps) => {
  const scale: number = props.size ? props.size / 40 : 1

  const mainStyle: CSSProperties = {
    backgroundColor: props.color,
    borderRadius: 5 * scale, // 12.5% of 40px
    height: 30 * scale,
    width: 40 * scale
  }

  const initialsStyle: CSSProperties = {
    fontSize: 14 * scale // 35% of 40px
  }

  return (
    <div className={styles.main} style={mainStyle}>
      <div className={styles.initials} style={initialsStyle}>
        {props.initials}
      </div>
      {props.onlineStatus && (
        <ArkOnlineStatusIndicator
          className={styles.onlineStatusIndicator}
          onlineStatus={props.onlineStatus}
          tooltip
          variant='program'
        />
      )}
    </div>
  )
}

export default ArkProgramIcon
