import {
  COMPANY_LOGIN_SERVICE_TYPE_EMAIL,
  COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC,
  COMPANY_LOGIN_SERVICE_TYPE_AUTH0,
  COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML
} from 'src/constants/config'

export enum AuthLoginServiceBaseType {
  // NewUser = 0, // UPDATE: option removed for #990 - the api no longer indicates if a user is new or not (new/unknown users now always report as the 'EmailPassword' type instead)
  EmailPassword = COMPANY_LOGIN_SERVICE_TYPE_EMAIL,
}

// NB: was `AuthSSOServiceType`
export enum AuthLoginServiceSSOType {
  SSOOktaOIDC = COMPANY_LOGIN_SERVICE_TYPE_OKTA_OIDC,
  SSOOktaSAML = COMPANY_LOGIN_SERVICE_TYPE_OKTA_SAML,
  SSOAuth0 = COMPANY_LOGIN_SERVICE_TYPE_AUTH0
}

// NB: was `ServerAuthLoginServiceType`
// merge enums (sort of) - ref: https://dev.to/egorovsa/how-to-extend-enum-in-typescript-cj4
export const AuthLoginServiceType = {
  ...AuthLoginServiceBaseType,
  ...AuthLoginServiceSSOType
} as const
// eslint-disable-next-line no-redeclare
export type AuthLoginServiceType = AuthLoginServiceBaseType | AuthLoginServiceSSOType

// NB: was `ServerAuthLoginServiceSSOConfig`
export interface IAuthLoginServiceSSOConfig {
  // NB: added `type` within this object so registrations `AuthLoginServiceType.NewUser` can be set on the parent object & this version used to distinguish which SSO service
  // TODO: can/should this be removed after `#990 auth ux changes` & API `v0.4.0+` removed the `NewUser` type?
  type: AuthLoginServiceType
  companyServiceId: number // company/org specific instance id for this service type
  clientId: string
  issuer: string
  nativeClientId?: string
  nativeRedirectURI?: string
}

// NB: was `ServerAuthLoginService`
export interface IAuthLoginService {
  type: AuthLoginServiceType
  ssoConfig?: IAuthLoginServiceSSOConfig
}
