/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from 'react'

import {
  DVR_ENABLED,
  JSWBW_ENABLED,
  PROGRAM_OVERRIDE_STREAM_URL,
  PROGRAM_OVERRIDE_VOD_URL,
  PROGRAM_THREE_SIXTY_ENABLED,
  PROGRAM_THREE_SIXTY_PREFER_PASSTHROUGH,
  PROGRAM_WATCHDOG_ENABLED,
  STATUS_ENABLED
} from 'src/constants/config'
import VideoPlayer360 from 'src/core/components/ArkVideoPlayer/VideoPlayer360'
import VideoPlayerSLDP, {
  DEFAULT_VIDEO_PLAYER_SLDP_STATUS,
  VideoPlayerSLDPRef,
  VideoPlayerSLDPStatus
} from 'src/core/components/ArkVideoPlayer/VideoPlayerSLDP'
import { Program } from 'src/core/models'
import { useProjectStatus } from 'src/core/providers'
import { useAudioLevels } from 'src/viewer/providers/AudioLevelsProvider'
import { DEFAULT_CHANNEL } from 'src/core/providers/LocalConfigProvider'
import { useViewer } from 'src/viewer/providers/ViewerProvider'
import { PlayerResolution } from 'src/core/types/player'
import { delay } from 'src/core/utilities/delay'

import JswbwSurface from '../JswbwSurface'
import ProgramControlsView, { ProgramControlsDebugInfoItem } from './ProgramControlsView'
import ProgramStatusView from './ProgramStatusView'
import ProgramWatchdog from './ProgramWatchdog'
import { ProgramWatchdogStatus } from './types'
import { getProgramError, getResolutionText, getSecondsToday, parseAdvancedParameters } from './utilities'

import styles from './ProgramView.module.css'

// FIXME move compact from <ProgramControlsView> & pass to <ProgramStatusView>

interface ProgramViewProps {
  program: Program
  thumbnail: boolean
  onClick?: () => void
}

const ProgramView = (props: ProgramViewProps) => {
  const audioLevels = useAudioLevels()
  const projectStatus = useProjectStatus()
  const viewer = useViewer()

  const containerRef = useRef<HTMLDivElement>(null)
  const videoPlayerRef = useRef<VideoPlayerSLDPRef>(null)

  const [customOptions, setCustomOptions] = useState<boolean>(false)
  const [fullscreen, setFullscreen] = useState<boolean>(false)
  const [paused, setPaused] = useState<boolean>(false)
  const [pausedDate, setPausedDate] = useState<number>(0)
  const [playerImageData, setPlayerImageData] = useState<ImageData>() // for 360
  const [playerStatus, setPlayerStatus] = useState<VideoPlayerSLDPStatus>(DEFAULT_VIDEO_PLAYER_SLDP_STATUS)
  const [restarting, setRestarting] = useState<boolean>(false)
  const [seekTime, setSeekTime] = useState<number>(0) // seconds backward
  const [stopped, setStopped] = useState(false)
  const [watchdogStatus, setWatchdogStatus] = useState<ProgramWatchdogStatus>(ProgramWatchdogStatus.Unknown)

  // custom options
  const [customAbr, setCustomAbr] = useState<boolean>(DEFAULT_CHANNEL.abr)
  const [customAdvancedParameters, setCustomAdvancedParameters] = useState<string>(DEFAULT_CHANNEL.advancedParameters)
  const [customBuffer, setCustomBuffer] = useState<number>(DEFAULT_CHANNEL.buffer)
  const [customPassthrough, setCustomPassthrough] = useState<boolean>(DEFAULT_CHANNEL.passthrough)
  const [customResolution, setCustomResolution] = useState<PlayerResolution>(DEFAULT_CHANNEL.resolution)
  const [customThreeSixty, setCustomThreeSixty] = useState<boolean>(PROGRAM_THREE_SIXTY_ENABLED && props.program.threeSixty)
  const [customThreeSixtyPreferPassthrough, setCustomThreeSixtyPreferPassthrough] = useState<boolean>(PROGRAM_THREE_SIXTY_PREFER_PASSTHROUGH)

  /**
   * effects
   */

  // exit fullscreen
  useEffect(() => {
    if (!viewer.fullscreen && fullscreen) {
      // console.log(`ProgramView[${props.program.id}] - exit fullscreen`)
      setFullscreen(false)
    }
  }, [viewer.fullscreen])

  // renew hotlink
  useEffect(() => {
    if (playerStatus.error && props.program.shouldRenewSLDPHotlink()) {
      // console.log(`ProgramView[${props.program.id}] - renew hotlink`)
      viewer.refreshChannel()
    }
  }, [props.program, playerStatus])

  /**
   * actions
   */

  const onClick = () => {
    // console.log(`ProgramView[${props.program.id}] - onClick`)
    if (fullscreen) return
    if (props.onClick) {
      props.onClick()
    } else if (viewer.getChannelAutoSolo()) {
      viewer.setChannelAutoSoloProgram(
        viewer.getChannelAutoSoloProgram() === props.program.id ? undefined : props.program.id
      )
    }
  }

  const onFullscreenClick = () => {
    // console.log(`ProgramView[${props.program.id}] - onFullscreenClick`)
    if (fullscreen) {
      setFullscreen(false)
      document.exitFullscreen()
    } else {
      setFullscreen(true)
      containerRef.current?.requestFullscreen()
    }
  }

  const onPauseClick = () => {
    // console.log(`ProgramView[${props.program.id}] - onPauseClick`)
    setPaused(true)
    setPausedDate(Date.now())
  }

  const onPlayClick = () => {
    // console.log(`ProgramView[${props.program.id}] - onPlayClick`)
    if (seekTime) {
      const elapsed: number = (Date.now() - pausedDate) / 1000
      setSeekTime(seekTime + elapsed)
    }
    setPaused(false)
    setPausedDate(0)
  }

  const onRestartClick = async () => {
    // console.log(`ProgramView[${props.program.id}] - onRestartClick`)
    setRestarting(true)
    await delay(300)
    setRestarting(false)
  }

  const onSeekTimeChange = (time: number) => {
    console.log(`ProgramView[${props.program.id}] - onSeekTimeChange - time:`, time)
    setSeekTime(time)
    if (time) {
      videoPlayerRef.current?.seekVod(playerStatus.vodDuration - time)
    } else {
      videoPlayerRef.current?.seekLive()
    }
  }

  /**
   * utilities
   */

  const getAutoSolo = (): boolean => {
    return viewer.getChannelAutoSoloProgram() === props.program.id
  }

  const getClickable = (): boolean => {
    return !fullscreen && (!!props.onClick || viewer.getChannelAutoSolo())
  }

  const getCombinedAbr = (): boolean => {
    return getCombinedPassthrough() ? false : (customOptions ? customAbr : viewer.getChannelAbr())
  }

  const getCombinedAdvancedParameters = (): any => {
    return parseAdvancedParameters(customOptions ? customAdvancedParameters : viewer.getChannelAdvancedParameters())
  }

  const getCombinedBuffer = (): number => {
    return customOptions ? customBuffer : viewer.getChannelBuffer()
  }

  const getCombinedPassthrough = (): boolean => {
    if (
      PROGRAM_THREE_SIXTY_ENABLED &&
      props.program.threeSixty &&
      (customOptions ? customThreeSixty && customThreeSixtyPreferPassthrough : PROGRAM_THREE_SIXTY_PREFER_PASSTHROUGH)
    ) {
      return true
    }
    return customOptions ? customPassthrough : viewer.getChannelPassthrough()
  }

  const getCombinedResolution = (): PlayerResolution => {
    return customOptions ? customResolution : viewer.getChannelResolution()
  }

  const getCombinedRestarting = (): boolean => {
    return viewer.restarting || restarting
  }

  const getCombinedStopped = (): boolean => {
    return viewer.stopped || stopped
  }

  const getDebugInfo = (): ProgramControlsDebugInfoItem[] => [
    {
      name: 'Channel res',
      value: getResolutionText(viewer.getChannelResolution(), viewer.getChannelAbr(), viewer.getChannelPassthrough())
    },
    {
      name: 'Program res',
      value: customOptions
        ? getResolutionText(getCombinedResolution(), getCombinedAbr(), customPassthrough)
        : 'Inherited'
    },
    {
      name: 'Player res',
      value: playerStatus.rendition || ''
    },
    {
      name: 'Player buffer',
      value: `${getCombinedBuffer().toLocaleString()}ms`
    },
    {
      name: 'VOD duration',
      value: `${playerStatus.vodDuration.toFixed(0)}s`
    },
    {
      name: 'VOD position',
      value: `${playerStatus.vodPosition.toFixed(0)}s`
    }
  ]

  const getDvrEnabled = (): boolean => {
    return DVR_ENABLED && props.program.dvrEnabled
  }

  const getLive = (): boolean => {
    return seekTime === 0
  }

  const getNoFragment = (): boolean => {
    if (
      getLive() ||
      !getDvrEnabled() ||
      playerStatus.vodDuration === 0
    ) {
      return false
    }
    return (playerStatus.vodDuration - seekTime) < 0
  }

  const getOffline = (): boolean => {
    return (
      getLive() &&
      STATUS_ENABLED &&
      projectStatus.getProgramOnlineStatus(props.program.id) !== 'online'
    )
  }

  const getPlay = (): boolean => {
    return !paused && !getNoFragment()
  }

  const getRenderPlayer = (): boolean => {
    return (
      !getCombinedRestarting() &&
      !getCombinedStopped() &&
      !getOffline()
    )
  }

  const getStreamUrl = (): string => {
    return (
      PROGRAM_OVERRIDE_STREAM_URL ??
      props.program.getSLDPOutputURL(getCombinedPassthrough()) ??
      'wss://localhost'
    )
  }

  const getTime = (): number => {
    const date: number = paused ? pausedDate : Date.now()
    const ms: number = date - (seekTime * 1000)
    return getSecondsToday(ms)
  }

  const getVodUrl = (): string | undefined => {
    return (
      PROGRAM_OVERRIDE_VOD_URL ??
      props.program.dvrUrl ??
      undefined
    )
  }

  const getWatchdogDisabled = (): boolean => {
    return (
      !getPlay() ||
      getCombinedRestarting() ||
      getCombinedStopped() ||
      getOffline()
    )
  }

  /**
   * render
   */

  // console.log(`ProgramView[${props.program.id}] - render`)

  return (
    <div
      className={`${styles.container} ${getClickable() ? styles.clickable : ''}`}
      data-program-id={props.program.id.toString()} // e2e testing identifier - additional data
      data-test-id="ark-program-view" // e2e testing identifier
      ref={containerRef}
      onClick={onClick}
    >
      <div className={styles.body}>
        <ProgramWatchdog
          currentTime={playerStatus.currentTime}
          disabled={getWatchdogDisabled()}
          programId={props.program.id}
          onChange={setWatchdogStatus}
        >
          {getRenderPlayer() && (
            <VideoPlayerSLDP
              abr={getCombinedAbr()}
              advancedParameters={getCombinedAdvancedParameters()}
              buffer={getCombinedBuffer()}
              hidden={customThreeSixty}
              id={props.program.id.toString()}
              muted={viewer.getPlayerMute(props.program.id)}
              play={getPlay()}
              ref={videoPlayerRef}
              resolution={getCombinedResolution()}
              streamUrl={getStreamUrl()}
              vodUrl={getVodUrl()}
              volume={viewer.getPlayerVolume(props.program.id)}
              onAudioLevelChange={(level) => audioLevels.setProgramAudioLevel(props.program.id, level)}
              onImageDataChange={customThreeSixty ? setPlayerImageData : undefined}
              onStatusChange={setPlayerStatus}
            />
          )}
        </ProgramWatchdog>
        {customThreeSixty && (
          <VideoPlayer360 active={!props.thumbnail} imageData={playerImageData} />
        )}
        {JSWBW_ENABLED && <JswbwSurface />}
        <ProgramStatusView
          noFragment={getNoFragment()}
          offline={getOffline()}
          playerError={playerStatus.error}
          programError={getProgramError(props.program, getCombinedPassthrough())}
          thumbnail={props.thumbnail}
          watchdogStatus={PROGRAM_WATCHDOG_ENABLED ? watchdogStatus : undefined}
        />
        <ProgramControlsView
          containerRef={containerRef}
          customAbr={customAbr}
          customAdvancedParameters={customAdvancedParameters}
          customBuffer={customBuffer}
          customOptions={customOptions}
          customPassthrough={customPassthrough}
          customResolution={customResolution}
          customThreeSixty={customThreeSixty}
          customThreeSixtyPreferPassthrough={customThreeSixtyPreferPassthrough}
          debugInfo={getDebugInfo()}
          fps={playerStatus.fps}
          fullscreen={fullscreen}
          hidden={props.thumbnail}
          paused={paused}
          playerRendition={playerStatus.rendition}
          playerTime={playerStatus.currentTime}
          program={props.program}
          restarting={restarting}
          seekTime={seekTime}
          stopped={stopped}
          time={getTime()}
          vodDuration={playerStatus.vodDuration}
          volume={viewer.getProgramVolume(props.program.id)}
          onCustomAbrChange={setCustomAbr}
          onCustomAdvancedParametersChange={setCustomAdvancedParameters}
          onCustomBufferChange={setCustomBuffer}
          onCustomOptionsChange={setCustomOptions}
          onCustomPassthroughChange={setCustomPassthrough}
          onCustomResolutionChange={setCustomResolution}
          onCustomThreeSixtyChange={setCustomThreeSixty}
          onCustomThreeSixtyPreferPassthroughChange={setCustomThreeSixtyPreferPassthrough}
          onFullscreenClick={onFullscreenClick}
          onPauseClick={onPauseClick}
          onPlayClick={onPlayClick}
          onRestartClick={onRestartClick}
          onSeekTimeChange={onSeekTimeChange}
          onStoppedChange={setStopped}
        />
      </div>
      {getAutoSolo() && <div className={styles.autoSoloBorder} />}
    </div>
  )
}

export default ProgramView
