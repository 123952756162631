import { API_SERVER_TYPE, APP_ENV, APP_TARGET, APP_VERSION, DEBUG_MODE } from 'src/constants/config'
import { AppEnv, AppTarget } from '../types/config'

/**
 * get app env from build
 */
export const getAppEnvFromBuild = (): AppEnv | undefined => {
  switch (window._env_?.API_SERVER_TYPE) {
    case 'production':
      return 'production'
    case 'staging':
      return 'staging'
    case 'dev':
      return 'development'
    default:
      return undefined
  }
}

/**
 * get app target from build
 */
export const getAppTargetFromBuild = (): AppTarget | undefined => {
  switch (window._env_?.WEB_CONFIG_OVERRIDE) {
    case 'fox':
      return 'revu'
    default:
      return undefined
  }
}

/**
 * get app version text
 */
export const getAppVersionText = (debug?: boolean): string => {
  if (debug) {
    return `${APP_TARGET}-${APP_VERSION}-${APP_ENV} API: ${API_SERVER_TYPE}${DEBUG_MODE ? ' 🐞' : ''}`
  } else {
    return `${APP_VERSION}-${APP_ENV}`
  }
}
