import ServerAPIClient from './ServerAPIClient'
import { ServerError, ServerErrorCodes } from './ServerAPIErrors'
import {
  Project,
  ProjectUser,
  UserProjectRole,
  Channel,
  Program,
  ChannelProgramOperation,
  UserProject,
  ProjectInviteUser,
  ProjectInviteUserType,
  ProjectInviteUserResult,
  ProjectSummary,
  ProjectTranscoderSettings,
  IVideoEnginePortRange
} from '../models'
import { OBJECT_USER_NAME_PLURAL } from 'src/constants/strings'
import { IProgramAddData, IProgramUpdateData } from '../models/program'
import { IProjectAddData, IProjectUpdateData } from '../models/project'

export class ServerProjectRoleAlreadyAssignedError extends ServerError {
  constructor (message?: string) {
    super((message === undefined ? 'User already has the selected role' : message), 200, ServerErrorCodes.userRoleAlreadyAssigned) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

export class ServerProjectWatermarkDoesNotExistError extends ServerError {
  constructor (message?: string) {
    super((message === undefined ? 'Watermark does not exist' : message), 200, ServerErrorCodes.watermarkDoesNotExist) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

export class ServerProjectWatermarkErrorReadingError extends ServerError {
  constructor (message?: string) {
    super((message === undefined ? 'Watermark read error' : message), 200, ServerErrorCodes.watermarkErrorReading) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

export type ServerProjectAvailablePortRanges = { srt?: { input?: Array<IVideoEnginePortRange>, output?: Array<IVideoEnginePortRange> } }

class ServerProjectAPI {
  private _apiClient: ServerAPIClient

  constructor (apiClient: ServerAPIClient) {
    this._apiClient = apiClient
  }

  // -------

  // returns projects only the user has access too
  getUserCompanyProjects = async (companyId: number): Promise<Array<UserProject> | null> => {
    try {
      const response = await this._apiClient.apiGet('/viewer/projects', { 'company-id': companyId })
      const projects: Array<UserProject> = []
      if (response.data && response.data.result && response.data.result) {
        const projectsData = response.data.result
        for (const projectData of projectsData) {
          const project = UserProject.fromJSON(
            projectData.project.id,
            {
              ...projectData.project,
              ...{ project_role: projectData.project_role },
              ...{ access_enabled: projectData.enabled } // user project access enabled status flag
            }
          )
          if (project) {
            projects.push(project)
          }
        }
      }
      // sort/order the projects array by project name
      // projects.sort((a: UserProject, b: UserProject) => a.name.localeCompare(b.name))
      // sort/order the projects array by project name using a more intelligent natural sort
      projects.sort((a: UserProject, b: UserProject) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
      return projects
    } catch (error) {
      console.error('ServerProjectAPI - getUserCompanyProjects - error: ', error)
      throw error
    }
  }

  // returns all projects within a company (if the user has the correct company access level/role)
  getAllCompanyProjects = async (companyId: number): Promise<Array<Project>> => {
    try {
      const response = await this._apiClient.apiGet('/projects', { 'company-id': companyId })
      const projects: Array<Project> = []
      if (response.data && response.data.result && response.data.result) {
        const projectsData = response.data.result
        for (const projectData of projectsData) {
          const project = Project.fromJSON(projectData.id, projectData)
          if (project) {
            projects.push(project)
          }
        }
      }
      // sort/order the projects array by project name
      // projects.sort((a: Project, b: Project) => a.name.localeCompare(b.name))
      // sort/order the projects array by project name using a more intelligent natural sort
      projects.sort((a: Project, b: Project) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
      return projects
    } catch (error) {
      console.error('ServerProjectAPI - getAllCompanyProjects - error: ', error)
      throw error
    }
  }

  getCompanyProject = async (companyId: number, projectId: number): Promise<Project | null> => {
    try {
      const response = await this._apiClient.apiGet('/projects/detail', { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        const projectData = response.data.result
        return Project.fromJSON(projectData.id, projectData)
      }
      return null
    } catch (error) {
      console.error('ServerProjectAPI - getCompanyProject - error: ', error)
      throw error
    }
  }

  addCompanyProject = async (companyId: number, projectData: IProjectAddData): Promise<Project> => {
    const data: {[key: string]: any} = {
      project_name: projectData.name,
      app_protocols: [] // NB: currently opting to NOT support setting protocols at creation time, instead leave the api to apply the default ones, & then this can be edited/updated afterwards
    }
    if (projectData.desc !== undefined) data.info = projectData.desc
    if (projectData.videoEngineId !== undefined) data.streaming_server_id = projectData.videoEngineId >= 0 ? projectData.videoEngineId : null
    if (projectData.maxUsers !== undefined) data.max_users = projectData.maxUsers
    // console.log('ServerProjectAPI - addCompanyProject - data: ', data)
    try {
      const response = await this._apiClient.apiPost('/projects', data, { 'company-id': companyId })
      let project: Project | null = null
      if (response.status === 201 && response.data && response.data.result && response.data.result) {
        const _projectData = response.data.result
        project = Project.fromJSON(_projectData.id, _projectData)
      }
      if (!project) throw new Error('Invalid response')
      return project
      // throw new Error('DEBUG: addCompanyProject is temp disabled')
    } catch (error) {
      console.error('ServerProjectAPI - addCompanyProject - error: ', error)
      throw error
    }
  }

  // TODO: can we set/update the enforce_2fa field via this api call (when its working)? or is/will there be a more dedicated endpoint for that??
  // TODO: add support for `[PUT] /projects: new request body field available: generate_new_app_password. When passed ‘true’, the backend generates a new random app_password.` added in/around the api v0.1.90 or v0.1.93 update
  // TODO: ..(add it directly here? or maybe add in a dedicated function that calls this internally with the special arg? OR maybe add/use via `updateCompanyProjectInfo` instead (if thats suitable at all)? OR just create a dedicated project update call function with that arg used/set only??)
  updateCompanyProject = async (companyId: number, projectId: number, projectData: IProjectUpdateData): Promise<Project> => {
    const data: {[key: string]: any} = {}
    if (projectData.name !== undefined) data.project_name = projectData.name
    if (projectData.desc !== undefined) data.info = projectData.desc
    if (projectData.force2fa !== undefined) data.info = projectData.force2fa // TODO: should we allow this being set here or via a dedicated function/call?
    if (projectData.videoEngineId !== undefined) data.streaming_server_id = projectData.videoEngineId >= 0 ? projectData.videoEngineId : null
    if (projectData.maxUsers !== undefined) data.max_users = projectData.maxUsers
    // console.log('ServerProjectAPI - updateCompanyProject - data: ', data)
    // halt if data is empty
    if (Object.keys(data).length === 0) {
      throw new Error('No valid fields to update')
    }
    try {
      const response = await this._apiClient.apiPut('/projects', data, { 'company-id': companyId, 'project-id': projectId })
      let project: Project | null = null
      if (response.status === 200 && response.data && response.data.result && response.data.result) {
        const _projectData = response.data.result
        project = Project.fromJSON(_projectData.id, _projectData)
      }
      if (!project) throw new Error('Invalid response')
      return project
      // throw new Error('DEBUG: updateCompanyProject is temp disabled')
    } catch (error) {
      console.error('ServerProjectAPI - updateCompanyProject - error: ', error)
      throw error
    }
  }

  // NB: this calls the regular project update endpoint, but specically passes in info fields like transcoder settings instead of the base fields like name
  updateCompanyProjectInfo = async (companyId: number, projectId: number, values: {[key: string]: any}): Promise<Project> => {
    // TESTING: convert the class property keys to their json equivalent
    const projectJSONKeyMap = Project.propertyToJSONKeyMap()
    const transcoderJSONKeyMap = ProjectTranscoderSettings.propertyToJSONKeyMap()
    // console.log('ServerProjectAPI - updateProjectInfo - projectJSONKeyMap: ', projectJSONKeyMap, ' transcoderJSONKeyMap: ', transcoderJSONKeyMap)
    const data: {[key: string]: any} = {}
    for (const fieldName of Object.keys(values)) {
      // console.log('ServerProjectAPI - updateProjectInfo - fieldName: ', fieldName, ' = ', (transcoderJSONKeyMap as any)[fieldName])
      // check if the field has a project transcoder json key mapped
      if (Object.prototype.hasOwnProperty.call(transcoderJSONKeyMap, fieldName)) {
        const jsonKey = transcoderJSONKeyMap[fieldName as keyof typeof transcoderJSONKeyMap]
        data[jsonKey] = values[fieldName]
      } else if (Object.prototype.hasOwnProperty.call(projectJSONKeyMap, fieldName)) {
        // check if the field has a project json key mapped
        const jsonKey = projectJSONKeyMap[fieldName as keyof typeof projectJSONKeyMap]
        data[jsonKey] = values[fieldName]
      } else {
        // no json key map for this value key - use it directly
        data[fieldName] = values[fieldName]
      }
    }
    console.log('ServerProjectAPI - updateProjectInfo - data: ', data, ' length: ', Object.keys(data).length)
    // halt if data is empty
    if (Object.keys(data).length === 0) {
      throw new Error('No valid fields to update')
    }
    // TODO: don't allow certain fields to be edited via this call, force them to use the more standard updateProject function instead? (e.g. project name, force 2fa etc.), if so whitelist or blacklist?
    try {
      const response = await this._apiClient.apiPut('/projects', data, { 'company-id': companyId, 'project-id': projectId })
      console.log('ServerProjectAPI - updateProjectInfo - response: ', response)
      if (response.data && response.data.result && response.data.result) {
        const projectData = response.data.result
        const updatedProject = Project.fromJSON(projectData.id, projectData)
        if (!updatedProject) {
          throw new Error('Failed to parse project data')
        }
        return updatedProject
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerProjectAPI - updateProjectInfo - error: ', error)
      throw error
    }
  }

  deleteCompanyProject = async (companyId: number, projectId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/projects', undefined, { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerProjectAPI - deleteCompanyProject - error: ', error)
      throw error
    }
  }

  // -------

  // returns true if the sync was applied, false if it wasn't needed to be applied (all still ok), throws an error if any issues
  syncCompanyProjectTranscoderSettings = async (companyId: number, projectId: number, force: boolean = false): Promise<boolean> => {
    console.log('ServerProjectAPI - syncCompanyProjectTranscoderSettings - companyId: ', companyId, ' projectId: ', projectId, ' force:', force)
    try {
      const response = await this._apiClient.apiGet('/projects/sync_configuration' + (force ? '?force=true' : ''), { 'company-id': companyId, 'project-id': projectId })
      console.log('ServerProjectAPI - syncCompanyProjectTranscoderSettings - response: ', response)
      if (response.status === 200) {
        return true
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerProjectAPI - syncCompanyProjectTranscoderSettings - error: ', error)
      if (error instanceof ServerError) {
        console.error('ServerProjectAPI - syncCompanyProjectTranscoderSettings - ServerError - error: ', error)
        if (error.statusCode === 409 || error.statusCode === 422) { // TODO: seems to return 422 & not 409 as expected when it doesn't need applying, double check which is correct
          // already syncronised - returning false to indicate it wasn't needed (& always throws an error if any other actual issue)
          return false
        }
      }
      throw error
    }
  }

  // -------

  getProjectWatermarkExists = async (companyId: number, projectId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiGet('/projects/watermark/exists', { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result !== undefined) {
        return !!response.data.result
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerProjectAPI - getProjectWatermarkExists - error: ', error)
      if (error instanceof ServerError) {
        console.error('ServerProjectAPI - getProjectWatermarkExists - error.errorCode: ', error.errorCode)
        if (error.errorCode === ServerErrorCodes.watermarkErrorReading) {
          throw new ServerProjectWatermarkErrorReadingError(error.message)
        }
      }
      throw error
    }
  }

  getProjectWatermarkImageBlob = async (companyId: number, projectId: number, original: boolean = false): Promise<Blob> => {
    try {
      console.log('ServerProjectAPI - getProjectWatermarkImageBlob - original: ', original)
      const queryArgs = original ? '?type=original' : '' // NB: defaults to type=edited so we don't specifically add it
      const response = await this._apiClient.apiGet('/projects/watermark' + queryArgs, { 'company-id': companyId, 'project-id': projectId }, true, 'blob')
      if (response.status === 200 && response.data) {
        console.log('ServerProjectAPI - getProjectWatermarkImageBlob - response: ', response)
        const blob = response.data // new Blob([response.data], { type: response.headers['content-type'] })
        return blob
      }
      throw new Error('Invalid response')
    } catch (error) {
      if (error instanceof ServerError) {
        console.error('ServerProjectAPI - getProjectWatermarkImageBlob - error.errorCode: ', error.errorCode)
        if (error.errorCode === ServerErrorCodes.watermarkDoesNotExist) {
          throw new ServerProjectWatermarkDoesNotExistError(error.message)
        } else if (error.errorCode === ServerErrorCodes.watermarkErrorReading) {
          throw new ServerProjectWatermarkErrorReadingError(error.message)
        }
      }
      console.error('ServerProjectAPI - getProjectWatermarkImageBlob - error: ', error)
      throw error
    }
  }

  getProjectWatermarkImage = async (companyId: number, projectId: number, original: boolean = false): Promise<string> => {
    const imageBlob = await this.getProjectWatermarkImageBlob(companyId, projectId, original)
    const image = URL.createObjectURL(imageBlob)
    console.log('ServerProjectAPI - getProjectWatermarkImage - image: ', image, ' imageBlob.size: ', imageBlob.size)
    return image
  }

  uploadProjectWatermark = async (companyId: number, projectId: number, originalImgBlob: Blob, editedImgBlob: Blob, originalFilename?: string, editedFilename?: string): Promise<boolean> => {
    try {
      // refs:
      //  https://stackoverflow.com/a/48195693
      //  https://stackoverflow.com/a/49383620
      const formData = new FormData()
      formData.append('dataFileOriginal', originalImgBlob, originalFilename)
      formData.append('dataFile', editedImgBlob, editedFilename)
      console.log('ServerProjectAPI - uploadProjectWatermark - formData: ', formData)
      const response = await this._apiClient.apiPost('/projects/watermark', formData, { 'company-id': companyId, 'project-id': projectId, 'Content-Type': 'multipart/form-data' })
      if (response.status === 200) return true
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerProjectAPI - uploadProjectWatermark - error: ', error)
      throw error
    }
  }

  deleteProjectWatermark = async (companyId: number, projectId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/projects/watermark', undefined, { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 200) {
        return true
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerProjectAPI - deleteProjectWatermark - error: ', error)
      throw error
    }
  }

  // -------

  getCompanyProjectUsers = async (companyId: number, projectId: number): Promise<Array<ProjectUser> | null> => {
    try {
      const response = await this._apiClient.apiGet('/projects/users', { 'company-id': companyId, 'project-id': projectId })
      const users: Array<ProjectUser> = []
      if (response.data && response.data.result && response.data.result) {
        const projectUsersData = response.data.result
        for (const projectUserData of projectUsersData) {
          // NB: merging in the additional company_role, company_status, & project_role fields into the main user json so its all parses in one
          if (projectUserData.user) {
            const user = ProjectUser.fromJSON(
              projectUserData.user.id,
              {
                ...projectUserData.user,
                ...{ company_role: projectUserData.company_role },
                ...{ company_status: projectUserData.company_status },
                ...{ project_id: projectId, project_owner: projectUserData.owner, project_role: projectUserData.project_role, project_access_enabled: projectUserData.enabled ?? false },
                ...{ flag_direct_user: projectUserData.flag_direct_user }
              }
            )
            if (user) {
              users.push(user)
            }
          }
        }
      }
      // sort/order the users array by project role & then user name (NB: the User name() returns the full name if set, or email as a fallback)
      users.sort((a: ProjectUser, b: ProjectUser) => {
        const aProjectRole = a.projectRole && a.projectRole > UserProjectRole.unknown ? a.projectRole : UserProjectRole.member
        const bProjectRole = b.projectRole && b.projectRole > UserProjectRole.unknown ? b.projectRole : UserProjectRole.member
        // return aRole - bRole || a.name().localeCompare(b.name())
        // sort/order the users array by user name using a more intelligent natural sort
        // return aProjectRole - bProjectRole || a.name().localeCompare(b.name(), navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
        // TESTING: now also taking all user roles into account: site admin, org admin, project admin, project manager, & guest roles/types
        // nudge the project role up by +10, then set org admin as 5, site-admin/god as 2, project owner as 1 (to bring to the very top), & guest as 20 to push it to the end
        const aRoleAll = a.isProjectOwner() ? 1 : a.isSiteAdmin() ? 2 : (a.isCompanyAdmin() ? 5 : (!a.isGuest ? (aProjectRole + 10) : 20))
        const bRoleAll = b.isProjectOwner() ? 1 : b.isSiteAdmin() ? 2 : (b.isCompanyAdmin() ? 5 : (!b.isGuest ? (bProjectRole + 10) : 20))
        return aRoleAll - bRoleAll || a.name().localeCompare(b.name(), navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true })
      })
      return users
    } catch (error) {
      console.error('ServerProjectAPI - getCompanyProjectUsers - error: ', error)
      throw error
    }
  }

  // accepts both id (existing company users) & email (new user) based user project invites
  inviteUsersToProject = async (companyId: number, projectId: number, invitedUsers: Array<ProjectInviteUser>): Promise<{ ok: Array<ProjectInviteUserResult>, error: Array<ProjectInviteUserResult> }> => {
    const usersData: Array<{[key: string]: any}> = []
    for (const invitedUser of invitedUsers) {
      if (invitedUser.type === ProjectInviteUserType.user && invitedUser.userId) {
        const userData: {[key: string]: any} = { id_type: 'id', user_id: invitedUser.userId }
        if (invitedUser.role) userData.project_role_id = invitedUser.role
        usersData.push(userData)
      } else if (invitedUser.type === ProjectInviteUserType.email && invitedUser.email) {
        const userData: {[key: string]: any} = { id_type: 'email', email: invitedUser.email }
        if (invitedUser.role) userData.project_role_id = invitedUser.role
        usersData.push(userData)
      }
    }
    if (usersData.length === 0) {
      throw new Error('Failed to invite ' + OBJECT_USER_NAME_PLURAL + ' to project')
    }
    try {
      const data: {[key: string]: any} = { users: usersData }
      const response = await this._apiClient.apiPost('/projects/users/invite', data, { 'company-id': companyId, 'project-id': projectId })
      // NB: this endpoint works a little differently than most, as it handles adding/inviting multiple at once, & so some can succeed & some can fail
      if (response.data && response.data.result && response.data.result) {
        const resultData = response.data.result
        // TESTING: parse & return the per-user/invite results (some may of invited ok, some may have errors)
        const usersOk = resultData.usersOk
        const usersKo = resultData.usersKo
        // return !(usersKo && usersKo.length > 0)
        const resultsOK: Array<ProjectInviteUserResult> = []
        const resultsError: Array<ProjectInviteUserResult> = []
        if (usersOk && usersOk.length > 0) {
          for (const userOk of usersOk) {
            const userOkResult: ProjectInviteUserResult = {
              type: userOk.id_type === 'email' ? ProjectInviteUserType.email : ProjectInviteUserType.user,
              userId: userOk.user_id ?? null, // TODO: check if this is the correct field name
              email: userOk.email ?? null,
              role: userOk.project_role_id, // TODO: convert to UserProjectRole
              result: true
            }
            resultsOK.push(userOkResult)
          }
        }
        if (usersKo && usersKo.length > 0) {
          for (const userKo of usersKo) {
            const userKoResult: ProjectInviteUserResult = {
              type: userKo.id_type === 'email' ? ProjectInviteUserType.email : ProjectInviteUserType.user,
              userId: userKo.user_id ?? null, // TODO: check if this is the correct field name
              email: userKo.email ?? null,
              role: userKo.project_role_id, // TODO: convert to UserProjectRole
              result: false,
              error: new Error(userKo.error_message ?? 'A problem occurred inviting the user')
            }
            resultsError.push(userKoResult)
          }
        }
        return { ok: resultsOK, error: resultsError }
      }
      throw new Error('Failed to invite user to project')
    } catch (error) {
      console.error('ServerProjectAPI - inviteUsersToProject - error: ', error)
      throw error
    }
  }

  // single user invite alias (calls the multi user invite function & parses the result so its just for the single invite request)
  inviteUserToProject = async (companyId: number, projectId: number, invitedUser: ProjectInviteUser) => {
    try {
      const result = await this.inviteUsersToProject(companyId, projectId, [invitedUser])
      // parse the multi-invite response & just return the single response for the single invited user
      if (result) {
        if (result.ok && result.ok.length > 0) {
          return result.ok[0]
        } else if (result.error && result.error.length > 0) {
          // NB: could throw an error, but we may also want some of the invite details in the response, so just returning the whole invite result object for now
          return result.error[0]
        }
      }
      throw new Error('Failed to invite user to project')
    } catch (error) {
      console.error('ServerProjectAPI - inviteUserToProject - error: ', error)
      throw error
    }
  }

  removeUserFromProject = async (companyId: number, projectId: number, userId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/projects/users/' + userId, undefined, { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerProjectAPI - removeUserFromProject - error: ', error)
      throw error
    }
  }

  updateUserProjectRole = async (companyId: number, projectId: number, userId: number, projectRole: UserProjectRole): Promise<boolean> => {
    const pRole = projectRole !== UserProjectRole.unknown ? projectRole : UserProjectRole.member
    const data: {[key: string]: any} = {
      user_id: userId,
      new_project_role_id: pRole
    }
    try {
      const response = await this._apiClient.apiPut('/projects/users/roles/edit?id_type=id', data, { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 200) {
        if (response.data && response.data.result) {
          // const resultData = response.data.result
          return true
        }
        // NB: if the user already has the project role being assigned, it'll respond with an error in the body (but a 200 response, as the end result is still a success)
        if (response.data && response.data.error) {
          if (response.data.error_code === ServerErrorCodes.userRoleAlreadyAssigned) {
            throw new ServerProjectRoleAlreadyAssignedError()
          } else {
            throw new ServerError(response.data.error, response.status, response.data.error)
          }
        }
      }
      return false
    } catch (error) {
      console.error('ServerProjectAPI - updateUserProjectRole - error: ', error)
      throw error
    }
  }

  updateUserProjectAccessEnabled = async (companyId: number, projectId: number, userId: number, accessEnabled: boolean): Promise<boolean> => {
    const data: {[key: string]: any} = {
      user_id: userId,
      enabled: accessEnabled
    }
    try {
      const response = await this._apiClient.apiPut('/projects/users/enabling', data, { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerProjectAPI - updateUserProjectAccessEnabled - error: ', error)
      throw error
    }
  }

  // -------

  getUserCompanyProjectChannels = async (companyId: number, projectId: number): Promise<Array<Channel> | null> => {
    try {
      const response = await this._apiClient.apiGet('/viewer/channel', { 'company-id': companyId, 'project-id': projectId })
      const channels: Array<Channel> = []
      if (response.data && response.data.result && response.data.result) {
        const channelsData = response.data.result
        for (const channelData of channelsData) {
          const channel = Channel.fromJSON(channelData.id, channelData)
          if (channel) {
            channels.push(channel)
          }
        }
      }
      // sort/order the channels array by channel name
      // channels.sort((a: Channel, b: Channel) => a.name.localeCompare(b.name))
      // sort/order the channels array by program name using a more intelligent natural sort
      channels.sort((a, b) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
      return channels
    } catch (error) {
      console.error('ServerProjectAPI - getUserCompanyProjectChannels - error: ', error)
      throw error
    }
  }

  getUserCompanyProjectChannel = async (companyId: number, projectId: number, channelId: number): Promise<Channel | null> => {
    try {
      const response = await this._apiClient.apiGet('/viewer/channel/' + channelId, { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        const channelData = response.data.result
        return Channel.fromJSON(channelData.id, channelData, true)
      }
      return null
    } catch (error) {
      console.error('ServerProjectAPI - getUserCompanyProjectChannels - error: ', error)
      throw error
    }
  }

  getAllCompanyProjectChannels = async (companyId: number, projectId: number): Promise<Array<Channel> | null> => {
    try {
      const response = await this._apiClient.apiGet('/channel', { 'company-id': companyId, 'project-id': projectId })
      const channels: Array<Channel> = []
      if (response.data && response.data.result && response.data.result) {
        const channelsData = response.data.result
        for (const channelData of channelsData) {
          const channel = Channel.fromJSON(channelData.id, channelData)
          if (channel) {
            channels.push(channel)
          }
        }
      }
      // sort/order the channels array by channel name
      // channels.sort((a: Channel, b: Channel) => a.name.localeCompare(b.name))
      // sort/order the channels array by program name using a more intelligent natural sort
      channels.sort((a, b) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
      return channels
    } catch (error) {
      console.error('ServerProjectAPI - getAllCompanyProjectChannels - error: ', error)
      throw error
    }
  }

  addCompanyProjectChannel = async (companyId: number, projectId: number, channelName: string, colour?: string, enableAutoSolo?: boolean, playbackLayoutId?: number): Promise<Channel | null> => {
    const data: {[key: string]: any} = {
      name: channelName,
      playback_layout_id: playbackLayoutId ?? 1 // TODO: create an enum to represent the available playback layouts & set the default here with it?
    }
    if (colour) data.colour = colour
    if (enableAutoSolo) data.flag_auto_solo = enableAutoSolo
    try {
      const response = await this._apiClient.apiPost('/channel', data, { 'company-id': companyId, 'project-id': projectId })
      // TODO: also check response status? returns 201 on succcess
      if (response.data && response.data.result && response.data.result) {
        const channelData = response.data.result
        return Channel.fromJSON(channelData.id, channelData)
      }
      return null
    } catch (error) {
      console.error('ServerProjectAPI - addCompanyProjectChannel - error: ', error)
      throw error
    }
  }

  updateCompanyProjectChannel = async (companyId: number, projectId: number, channel: Channel): Promise<boolean> => {
    const data: {[key: string]: any} = {
      name: channel.name,
      playback_layout_id: channel.playbackLayoutId ?? 1 // TODO: create an enum to represent the available playback layouts & set the default here with it?
    }
    if (channel.colour !== undefined) data.colour = channel.colour
    if (channel.enableAutoSolo !== undefined) data.flag_auto_solo = channel.enableAutoSolo
    try {
      const response = await this._apiClient.apiPut('/channel/' + channel.id, data, { 'company-id': companyId, 'project-id': projectId })
      // TODO: what will the response be... this may not be correct!! <<<
      if (response.data && response.data.result && response.data.result) {
        // const channelData = response.data.result
        return true
      }
      return false
    } catch (error) {
      console.error('ServerProjectAPI - updateCompanyProjectChannel - error: ', error)
      throw error
    }
  }

  deleteCompanyProjectChannel = async (companyId: number, projectId: number, channelId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/channel/' + channelId, undefined, { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerProjectAPI - deleteCompanyProjectChannel - error: ', error)
      throw error
    }
  }

  // all programs within a project the user has access too (not channel/group specific)
  getUserCompanyProjectPrograms = async (companyId: number, projectId: number): Promise<Array<Program> | null> => {
    try {
      const response = await this._apiClient.apiGet('/viewer/programs', { 'company-id': companyId, 'project-id': projectId })
      const programs: Array<Program> = []
      if (response.data && response.data.result && response.data.result) {
        const programsData = response.data.result
        for (const programData of programsData) {
          const program = Program.fromJSON(programData.id, programData)
          if (program) {
            programs.push(program)
          }
        }
      }
      // sort/order the programs array by program name
      // programs.sort((a: Program, b: Program) => a.name.localeCompare(b.name))
      // sort/order the programs array by program name using a more intelligent natural sort
      programs.sort((a, b) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
      return programs
    } catch (error) {
      console.error('ServerProjectAPI - getUserCompanyProjectPrograms - error: ', error)
      throw error
    }
  }

  // all programs within a project (not channel/group specific)
  getAllCompanyProjectPrograms = async (companyId: number, projectId: number): Promise<Array<Program> | null> => {
    try {
      const response = await this._apiClient.apiGet('/programs', { 'company-id': companyId, 'project-id': projectId })
      const programs: Array<Program> = []
      if (response.data && response.data.result && response.data.result) {
        const programsData = response.data.result
        for (const programData of programsData) {
          const program = Program.fromJSON(programData.id, programData)
          if (program) {
            programs.push(program)
          }
        }
      }
      // sort/order the programs array by program name
      // programs.sort((a: Program, b: Program) => a.name.localeCompare(b.name))
      // sort/order the programs array by program name using a more intelligent natural sort
      programs.sort((a, b) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
      console.log('ServerProjectAPI - getAllCompanyProjectPrograms - programs:', programs)
      return programs
    } catch (error) {
      console.error('ServerProjectAPI - getAllCompanyProjectPrograms - error: ', error)
      throw error
    }
  }

  addCompanyProjectProgram = async (companyId: number, projectId: number, programData: IProgramAddData): Promise<Program> => {
    const data: {[key: string]: any} = {}
    // base fields:
    data.program_name = programData.name
    if (programData.shortName !== undefined) data.short_name = programData.shortName
    if (programData.colour !== undefined) data.colour = programData.colour
    if (programData.isAudioOnly !== undefined) data.flag_audio_only = programData.isAudioOnly
    if (programData.threeSixty !== undefined) data.flag_360_video = programData.threeSixty
    // srt general:
    if (programData.srtLatency !== undefined) data.srt_latency = programData.srtLatency // TODO: DEPRECIATE (once all API servers are running v0.3.28+)
    if (programData.srtMaxBandwidth !== undefined) data.srt_max_bandwidth = programData.srtMaxBandwidth // TODO: DEPRECIATE (once all API servers are running v0.3.28+)
    // srt input:
    if (programData.srtInputKeyLength !== undefined) data.srt_key_length_in = programData.srtInputKeyLength
    if (programData.srtInputPassphraseEnabled !== undefined) data.enable_passphrase_in = programData.srtInputPassphraseEnabled
    if (programData.srtInputLatency !== undefined) data.srt_latency_in = programData.srtInputLatency // ADDED: API v0.3.28+
    if (programData.srtInputMaxBandwidth !== undefined) data.srt_max_bandwidth_in = programData.srtInputMaxBandwidth // ADDED: API v0.3.28+
    // srt output:
    if (programData.srtOutputKeyLength !== undefined) data.srt_key_length_out = programData.srtOutputKeyLength
    if (programData.srtOutputPassphraseEnabled !== undefined) data.enable_passphrase_out = programData.srtOutputPassphraseEnabled
    if (programData.srtOutputLatency !== undefined) data.srt_latency_out = programData.srtOutputLatency // ADDED: API v0.3.28+
    if (programData.srtOutputMaxBandwidth !== undefined) data.srt_max_bandwidth_out = programData.srtOutputMaxBandwidth // ADDED: API v0.3.28+
    // srt ports:
    if (programData.srtCustomPortsEnabled !== undefined) data.flag_custom_ports = programData.srtCustomPortsEnabled
    if (programData.srtInputPort !== undefined) data.custom_port_in = programData.srtInputPort
    if (programData.srtOutputPort !== undefined) data.custom_port_out = programData.srtOutputPort
    // hotlink:
    if (programData.hotlinkEnabled !== undefined) data.auth_enabled = programData.hotlinkEnabled
    if (programData.hotlinkEnabled !== undefined && programData.hotlinkProtocols !== undefined) data.auth_protocols = programData.hotlinkProtocols
    // dvr:
    if (programData.dvrEnabled !== undefined) {
      data.dvr_enabled = programData.dvrEnabled
      // FIXME dvr - add prompt to override storage check
      if (data.dvr_enabled) data.skip_dvr_storage_check = true
    }
    console.log('ServerProjectAPI - addCompanyProjectProgram - data: ', data)
    try {
      const response = await this._apiClient.apiPost('/programs', data, { 'company-id': companyId, 'project-id': projectId })
      let program: Program | null = null
      if (response.status === 201 && response.data && response.data.result) {
        const programData = response.data.result
        program = Program.fromJSON(programData.id, programData)
      }
      if (!program) throw new Error('Invalid response')
      return program
    } catch (error) {
      console.error('ServerProjectAPI - addCompanyProjectProgram - error: ', error)
      throw error
    }
  }

  updateCompanyProjectProgram = async (companyId: number, projectId: number, programId: number, programData: IProgramUpdateData): Promise<Program> => {
    const data: {[key: string]: any} = {}
    // base fields:
    if (programData.name !== undefined) data.program_name = programData.name
    if (programData.shortName !== undefined) data.short_name = programData.shortName
    if (programData.colour !== undefined) data.colour = programData.colour
    if (programData.isAudioOnly !== undefined) data.flag_audio_only = programData.isAudioOnly
    if (programData.threeSixty !== undefined) data.flag_360_video = programData.threeSixty
    // srt general:
    if (programData.srtLatency !== undefined) data.srt_latency = programData.srtLatency // TODO: DEPRECIATE (once all API servers are running v0.3.28+)
    if (programData.srtMaxBandwidth !== undefined) data.srt_max_bandwidth = programData.srtMaxBandwidth // TODO: DEPRECIATE (once all API servers are running v0.3.28+)
    // srt input:
    if (programData.srtInputKeyLength !== undefined) data.srt_key_length_in = programData.srtInputKeyLength
    if (programData.srtInputPassphraseEnabled !== undefined) data.enable_passphrase_in = programData.srtInputPassphraseEnabled
    if (programData.srtInputLatency !== undefined) data.srt_latency_in = programData.srtInputLatency // ADDED: API v0.3.28+
    if (programData.srtInputMaxBandwidth !== undefined) data.srt_max_bandwidth_in = programData.srtInputMaxBandwidth // ADDED: API v0.3.28+
    // srt output:
    if (programData.srtOutputKeyLength !== undefined) data.srt_key_length_out = programData.srtOutputKeyLength
    if (programData.srtOutputPassphraseEnabled !== undefined) data.enable_passphrase_out = programData.srtOutputPassphraseEnabled
    if (programData.srtOutputLatency !== undefined) data.srt_latency_out = programData.srtOutputLatency // ADDED: API v0.3.28+
    if (programData.srtOutputMaxBandwidth !== undefined) data.srt_max_bandwidth_out = programData.srtOutputMaxBandwidth // ADDED: API v0.3.28+
    // srt ports:
    // NB: if srt input or output ports are specified the ports enabled flag must also be set to true (currently relying on the calling code to enforce it, so we can be more certain we do want to enable it vs trying to change a port without enabling it, which isn't currently supported api side)
    // NB: if the custom ports enabled flag is set to false, the custom port values will remain in the program data/model BUT won't be used for it anymore, instead auto port assignment will be used & the custom port values maybe re-used by other programs (& only re-checked if you goto re-enable custom ports & the api finds they are now in use)
    if (programData.srtCustomPortsEnabled !== undefined) data.flag_custom_ports = programData.srtCustomPortsEnabled
    if (programData.srtInputPort !== undefined) data.custom_port_in = programData.srtInputPort
    if (programData.srtOutputPort !== undefined) data.custom_port_out = programData.srtOutputPort
    // hotlink:
    if (programData.hotlinkEnabled !== undefined) data.auth_enabled = programData.hotlinkEnabled
    if (programData.hotlinkEnabled !== undefined && programData.hotlinkProtocols !== undefined) data.auth_protocols = programData.hotlinkProtocols
    // dvr:
    if (programData.dvrEnabled !== undefined) {
      data.dvr_enabled = programData.dvrEnabled
      // FIXME dvr - add prompt to override storage check
      if (data.dvr_enabled) data.skip_dvr_storage_check = true
    }
    console.log('ServerProjectAPI - updateCompanyProjectProgram - data: ', data)
    try {
      const response = await this._apiClient.apiPut('/programs/' + programId, data, { 'company-id': companyId, 'project-id': projectId })
      let program: Program | null = null
      if (response.status === 200 && response.data && response.data.result && response.data.result) {
        const programData = response.data.result
        program = Program.fromJSON(programData.id, programData)
      }
      if (!program) throw new Error('Invalid response')
      return program
    } catch (error) {
      console.error('ServerProjectAPI - updateCompanyProjectProgram - error: ', error)
      throw error
    }
  }

  deleteCompanyProjectProgram = async (companyId: number, projectId: number, programId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiDelete('/programs/' + programId, undefined, { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerProjectAPI - deleteCompanyProjectProgram - error: ', error)
      throw error
    }
  }

  recreateCompanyProjectProgram = async (companyId: number, projectId: number, programId: number): Promise<boolean> => {
    try {
      const response = await this._apiClient.apiPost('/programs/' + programId + '/recreate', {}, { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 200) {
        return true
      }
      return false
    } catch (error) {
      console.error('ServerProjectAPI - recreateCompanyProjectProgram - error: ', error)
      throw error
    }
  }

  getAllCompanyProjectChannelPrograms = async (companyId: number, projectId: number, channelId: number): Promise<Array<Program> | null> => {
    try {
      const response = await this._apiClient.apiGet('/channel/' + channelId + '/programs', { 'company-id': companyId, 'project-id': projectId })
      const programs: Array<Program> = []
      if (response.data && response.data.result && response.data.result) {
        const programsData = response.data.result
        for (const programData of programsData) {
          const program = Program.fromJSON(programData.id, programData)
          if (program) {
            programs.push(program)
          }
        }
      }

      // sort/order the programs array by program name
      // programs.sort((a: Program, b: Program) => a.name.localeCompare(b.name))
      // sort/order the programs array by program name using a more intelligent natural sort
      programs.sort((a, b) => a.name.localeCompare(b.name, navigator.languages[0] || navigator.language, { numeric: true, ignorePunctuation: true }))
      return programs
    } catch (error) {
      console.error('ServerProjectAPI - getAllCompanyProjectChannelPrograms - error: ', error)
      throw error
    }
  }

  getUserCompanyProjectChannelPrograms = async (companyId: number, projectId: number, channelId: number): Promise<Array<Program> | null> => {
    try {
      // NB: the user channel endpoint now returns an array of projects within it, so we re-use that here
      const channel = await this.getUserCompanyProjectChannel(companyId, projectId, channelId)
      console.log('ServerProjectAPI - getUserCompanyProjectChannelPrograms - channel?.programs: ', channel?.programs)
      return channel?.programs ?? null
    } catch (error) {
      console.error('ServerProjectAPI - getUserCompanyProjectChannelPrograms - error: ', error)
      throw error
    }
  }

  // TODO: DEPRECIATE: use updateProjectChannelPrograms instead!
  // adds projects to a channel
  // returns true if all added ok, or a map/object of program id's with their error responses if any did (but others may have added ok)
  // NB: although the endpoint supports mapping progams across multiple channels, we currently only support working with a single channel at a time to keep the logic simpler
  addCompanyProjectChannelPrograms = async (companyId: number, projectId: number, channelId: number, programIds: Array<number>) : Promise<boolean | Map<number, any>> => { // {[key: string]: any}> => { //Map<number, any>
    if (programIds.length === 0) {
      return false // TODO: throw an error?
    }
    const programsData: Array<{[key: string]: any}> = []
    for (const programId of programIds) {
      programsData.push({
        channel_id: channelId,
        program_id: programId,
        operation: 0, // 0 = CREATE, 1 = UPDATE, 2 = DELETE
        first_position: false,
        enabled: true,
        flag_solo: false,
        volume_level: 100 // TODO `0-100` > `0-1`
      })
    }
    const data: {[key: string]: any} = {
      channels_programs: programsData
    }
    try {
      const response = await this._apiClient.apiPost('/channel/programs', data, { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        // NB: the results of this endpoint only include entries for any errors, if its not in the response it was actioned ok
        if (response.data.result.programsKo && response.data.result.programsKo.length > 0) {
          const programsKo = response.data.result.programsKo
          // var programErrors: {[key: string]: any} = {}
          const programErrors = new Map<number, any>()
          for (const programKo of programsKo) {
            // if (programKo.program_id)
            // programErrors[ programKo.program_id ] = programKo
            programErrors.set(parseInt(programKo.program_id), programKo)
          }
          return programErrors
        }
      }
      return true
    } catch (error) {
      console.error('ServerProjectAPI - addCompanyProjectChannelPrograms - error: ', error)
      throw error
    }
  }

  updateProjectChannelPrograms = async (companyId: number, projectId: number, channelId: number, operations: Array<ChannelProgramOperation>): Promise<boolean | Map<number, any>> => {
    const data: {[key: string]: any} = {
      channels_programs: operations
    }
    try {
      const response = await this._apiClient.apiPost('/channel/programs', data, { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        // NB: the results of this endpoint only include entries for any errors, if its not in the response it was actioned ok
        if (response.data.result.programsKo && response.data.result.programsKo.length > 0) {
          const programsKo = response.data.result.programsKo
          const operationErrors = new Map<number, any>()
          for (const programKo of programsKo) {
            operationErrors.set(parseInt(programKo.program_id), programKo)
          }
          return operationErrors
        }
      }
      return true
    } catch (error) {
      console.error('ServerProjectAPI - updateProjectChannelPrograms - error: ', error)
      throw error
    }
  }

  // -------

  getCompanyProjectAvailablePorts = async (companyId: number, projectId: number): Promise<ServerProjectAvailablePortRanges | null> => {
    try {
      const response = await this._apiClient.apiGet('/projects/ports', { 'company-id': companyId, 'project-id': projectId })
      if (response.status === 200 && response.data.result) {
        const availablePorts: ServerProjectAvailablePortRanges = {}
        availablePorts.srt = {}
        availablePorts.srt.input = response.data.result.port_ranges_in
        availablePorts.srt.output = response.data.result.port_ranges_out
        return availablePorts
      }
      throw new Error('Invalid response')
    } catch (error) {
      console.error('ServerProjectAPI - getCompanyProjectAvailablePorts - error: ', error)
      throw error
    }
  }

  // -------

  // returns a standard Project data model with the full data set (including things like company transcoder settings the base project listings etc. might not return)
  getCompanyProjectInfo = async (companyId: number, projectId: number): Promise<Project> => {
    try {
      const response = await this._apiClient.apiGet('/projects/info', { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        const projectData = response.data.result
        const project = Project.fromJSON(projectData.id, projectData)
        console.log('ServerProjectAPI - getCompanyProjectInfo - project: ', project)
        if (project) return project
      }
      throw Error('Failed to load Project Info') // TODO: specific error?
    } catch (error) {
      console.error('ServerProjectAPI - getCompanyProjectInfo - error: ', error)
      throw error
    }
  }

  // returns a custom ProjectSummary object which includes the full Project data plus additional project related fields like users, channels, groups & programs
  getCompanyProjectSummary = async (companyId: number, projectId: number): Promise<ProjectSummary> => {
    try {
      const response = await this._apiClient.apiGet('/projects/summary', { 'company-id': companyId, 'project-id': projectId })
      if (response.data && response.data.result && response.data.result) {
        const data = response.data.result
        const projectSummary = ProjectSummary.fromJSON(data)
        if (projectSummary) return projectSummary
      }
      throw Error('Failed to load Project Summary') // TODO: specific error?
    } catch (error) {
      console.error('ServerProjectAPI - getCompanyProjectSummary - error: ', error)
      throw error
    }
  }
}

export default ServerProjectAPI
