import ServerAPIClient from './ServerAPIClient'
import { ServerError } from './ServerAPIErrors'

class ServerUserAPI {
  private _apiClient: ServerAPIClient

  constructor (apiClient: ServerAPIClient) {
    this._apiClient = apiClient
  }

  // -------

  updateUserName = async (firstName: string, lastName: string) => {
    try {
      // NB: the api returns the full user object, but for now we just check the response status & return true/false (as the auth api handles the rest of the user object updates currently)
      const data: any = { name: firstName, last_name: lastName }
      const response = await this._apiClient.apiPut('/auth/update', data)
      console.log('ServerUserAPI - updateUserName - response: ', response)
      // let user: User | undefined
      if (response.status === 200) {
        // if (response.data) {
        //   if (response.data.result) {
        //     // TESTING: re-use the login response processing as the register response is now the same
        //     user = this._processLoginSuccessResponse(response)
        //   }
        // }
        return true
      } else {
        console.log('ServerUserAPI - updateUserName - WARNING: UNEXPECTED STATUS CODE: ', response.status)
        throw new ServerError('Invalid response')
      }
      // this.emit(ServerEventTypeAuth, user) // TODO: no need to re-call this? the _processLoginSuccessResponse() call above does already??
      // return user
    } catch (error) {
      console.error('ServerUserAPI - updateUserName - error: ', error)
      // this.emit(ServerEventTypeAuth, null) // TESTING
      throw error
    }
  }

  // -------

  disableUser2FA = async (userId: number) => {
    try {
      const data: any = {}
      const response = await this._apiClient.apiPost('/auth/' + userId + '/2fa/disable', data)
      console.log('ServerUserAPI - disableUser2FA - response:', response)
      if (response.status === 200) {
        return true
      }
      throw new ServerError('Invalid response')
    } catch (error) {
      console.error('ServerUserAPI - disableUser2FA - error:', error)
      throw error
    }
  }

  // -------

  // NB: this completely removes/wipes the user from the system (site-admin/god use only if specifying other users, also usable for self-deletion, although we're not supporting that here yet)
  // TODO: if re-using this for self-deletion, don't pass in a user id & the api will handle the rest (will need to alter how the arg is handled, or maybe better to make a dedicated function for self deletion?)
  deleteUser = async (userId: number) => {
    try {
      const data: any = {}
      const response = await this._apiClient.apiDelete('/auth/delete?user_id=' + userId + '', data)
      console.log('ServerUserAPI - deleteUser - response:', response)
      if (response.status === 200) {
        return true
      }
      throw new ServerError('Invalid response')
    } catch (error) {
      console.error('ServerUserAPI - deleteUser - error:', error)
      throw error
    }
  }

  // -------

  unlockUser = async (userId: number) => {
    try {
      const data: any = { user_id: userId }
      const response = await this._apiClient.apiPost('/auth/unlock?id_type=id', data)
      console.log('ServerUserAPI - unlockUser - response:', response)
      if (response.status === 200) {
        return true
      }
      throw new ServerError('Invalid response')
    } catch (error) {
      console.error('ServerUserAPI - unlockUser - error:', error)
      throw error
    }
  }
}

export default ServerUserAPI
