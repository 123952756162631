import React, { useEffect, useRef, useState } from 'react'

import { useAuth } from 'src/core/providers'

import ArkButton from 'src/core/components/ArkButton'
import ArkDivider from 'src/core/components/ArkDivider'

import { Segment, Header, Message, Label } from 'semantic-ui-react'

interface IProps {
  showVerifyRequiredMsg: boolean // TODO: use or remove (NB: calling code still sets this prop, but we're not currently using it)
}

const VerifyEmailForm = (_props: IProps) => {
  const mounted = useRef(false)

  const authContext = useAuth()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<boolean>(false)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const resendVerifyEmail = async () => {
    setIsSubmitting(true)
    try {
      const result = await authContext.actions.resendVerifyEmail()
      if (mounted.current) {
        setIsSubmitting(false)
        setEmailSent(result)
        setEmailError(result !== true) // TODO: get resendVerifyEmail to throw errors with a relevant message to show (for now we just show a generic error msg here)
      }
    } catch (error) {
      setIsSubmitting(false)
      setEmailSent(false)
      setEmailError(true) // TODO: set & show the specific error message
    }
  }

  // -------

  return (
    <Segment inverted>

      <Header as="h2" textAlign="center">
        Check Your Email
      </Header>

      {emailSent && (
        <Message positive>
          <Message.Header>Email Sent</Message.Header>
          <p>A verify link has been sent to your email address.</p>
          <p>Please follow the link in the email to complete your verification.</p>
        </Message>
      )}
      {emailError && (
        <Message negative>
          <Message.Header>Email Error</Message.Header>
          <p>There was a problem re-sending a verify link to your email address.</p>
          <p>Please try again or contact us if the problem persists.</p>
        </Message>
      )}

      {emailSent === false && (
        <Label size="large">
          {/* {this.props.showVerifyRequiredMsg && (
            <p>You must verify your account to access this area.</p>
          )} */}
          <p>We sent a verification email when you registered...</p>
          <p>Please follow the link in the verification email sent to you during sign-up.</p>
          <ArkDivider />
          <p>If you can&apos;t find the email, click below to re-send it.</p>
          <ArkButton color="blue" fluid basic size="large" onClick={resendVerifyEmail} loading={isSubmitting}>
            Re-Send Verify Email
          </ArkButton>
        </Label>
      )}

    </Segment>
  )
}

export default VerifyEmailForm
