import React, { useContext, useEffect, useState } from 'react'

import { UserContext } from 'src/core/providers'
import { Company, CompanyUser, UserAccountStatus, UserCompanyStatus } from 'src/core/models'

import CompanyUserDeleteView, { CompanyUserDeleteCallback } from './CompanyUserDeleteView'
import CompanyUserReInviteView from './CompanyUserReInviteView'
import CompanyUserRolePanel from './CompanyUserRolePanel'
import CompanyUserUnlockButton from './CompanyUserUnlockButton'

import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'
import ArkTruncatedText from 'src/core/components/ArkTruncatedText'

import SiteAdminUserReset2FAButton from 'src/admin/pages/users/SiteAdminUserReset2FAButton'
import SiteAdminUserWipeButton from 'src/admin/pages/users/SiteAdminUserWipeButton'

import { OBJECT_COMPANY_NAME } from 'src/constants/strings'
import { USER_EMAIL_TRUNCATE_LENGTH } from 'src/constants/config'

import styles from './CompanyUserSidebar.module.css'

export type CompanyUserUpdateCallback = (userId: number) => void

interface IProps {
  company: Company
  user?: CompanyUser
  onChange?: Function
  onDeleteUser?: CompanyUserDeleteCallback
  onUpdateUser?: CompanyUserUpdateCallback
}

const CompanyUserSidebar = (props: IProps) => {
  const { company, user, onChange, onDeleteUser: _onDeleteUser, onUpdateUser: _onUpdateUser } = props

  const userContext = useContext(UserContext)

  const [userDeleted, setUserDeleted] = useState(false)

  const resetView = () => {
    setUserDeleted(false) // reset the state
  }

  // -------

  useEffect(() => {
    resetView()
  }, [user?.id])

  // -------

  const onDeleteUser = (user: CompanyUser) => {
    if (_onDeleteUser) _onDeleteUser(user)
    setUserDeleted(true)
  }

  const onUpdateUser = (userId: number) => {
    if (_onUpdateUser) _onUpdateUser(userId)
  }

  // -------

  if (!user) return null

  const userCompanyStatusInvited = user.companyStatus === UserCompanyStatus.invited || user.companyStatus === UserCompanyStatus.pending
  console.log('CompanyUserSidebar - render - user:', user)

  const loggedInUserIsSiteAdmin = userContext.actions.isSiteAdmin()
  const loggedInUserIsCompanyAdminOrHigher = userContext.actions.isCompanyAdminOrHigher()

  return (
    <>

      <ArkPanel bordered className={styles.sidebarHeader}>
        <ArkPanel.Header
          className={styles.sidebarTitle}
          title={user.name()}
          icon={
            <ArkAvatar
              type={user.userAvatarType()}
              name={user.name()}
              size="40"
            />}
        />
      </ArkPanel>

      {!userDeleted && (<>

        <ArkSpacer />

        <ArkPanel bordered>
          <ArkPanel.Properties>
            {/* <ArkPanel.PropertyRow title='id:' value={user.id} /> */}
            {!user.isGuest && (<ArkPanel.PropertyRow title='email:' value={<ArkTruncatedText maxLength={USER_EMAIL_TRUNCATE_LENGTH} subject='Email'>{user.email}</ArkTruncatedText>} />)}
            {user.isGuest && (<ArkPanel.PropertyRow title='User Type:' value={'GUEST LINK'} />)}
            <ArkPanel.PropertyRow title='status:' value={user.companyStatusName() /* + ' (' + CompanyUser.userStatusName(user.status) + ')' */} />
            <ArkPanel.PropertyRow title={OBJECT_COMPANY_NAME + ' role:'} value={user.companyRoleName()} />
            <ArkPanel.PropertyRow title='2FA:' value={user.tfaEnabled ? 'Enabled' : 'Disabled'} />
          </ArkPanel.Properties>
        </ArkPanel>

        <ArkSpacer />

        {!user.isGuest && (
          <CompanyUserRolePanel
            company={company}
            user={user}
            onChange={onChange}
          />
        )}

        {userCompanyStatusInvited && (
          <>
            <ArkSpacer />
            <CompanyUserReInviteView
              company={company}
              user={user}
              onResult={() => console.log('CompanyUserSidebar - render - CompanyUserReInviteView')}
            />
          </>
        )}
      </>)}

      <ArkSpacer grow />

      {(loggedInUserIsCompanyAdminOrHigher || loggedInUserIsSiteAdmin) && (
        <>
          {user.status === UserAccountStatus.locked && (
            <>
              <CompanyUserUnlockButton
                user={user}
                onUnlock={() => { onUpdateUser(user.id) }}
              />
              {loggedInUserIsSiteAdmin && <ArkSpacer />}
            </>
          )}
        </>
      )}
      {loggedInUserIsSiteAdmin && (
        <>
          <ArkPanel bordered className={styles.adminActionsPanel}>
            <ArkPanel.PropertySection title='SITE ADMIN ACTIONS' collapsible={true} collapsed={true} titleClassName={styles.adminActionsTitle}>
              {/* <ArkPanel.Header title='SITE ADMIN ACTIONS' className={styles.adminActionsTitle} /> */}
              <div className={styles.adminActionsContent}>
                {user.tfaEnabled && (
                  <SiteAdminUserReset2FAButton
                    user={user}
                    onReset={() => { onUpdateUser(user.id) }}
                  />
                )}
                {(
                  <SiteAdminUserWipeButton
                    user={user}
                    onWiped={() => { onDeleteUser(user) }}
                  />
                )}
              </div>
            </ArkPanel.PropertySection>
          </ArkPanel>
          {/* <ArkSpacer grow /> */}
        </>
      )}

      <CompanyUserDeleteView
        company={company}
        user={user}
        onDelete={onDeleteUser}
      />

    </>
  )
}

export default CompanyUserSidebar
