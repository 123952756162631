import React, { useEffect, useState } from 'react'

import ArkCheckbox, { ArkCheckboxProps } from '../ArkCheckbox'
import ArkLoader from '../ArkLoader'
import ArkSpacer from '../ArkSpacer'

import styles from './ArkLoadingCheckbox.module.css'

const ArkLoadingCheckbox = (props: ArkCheckboxProps) => {
  const [checked, setChecked] = useState<boolean | undefined>(props.checked)

  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked])

  const loading: boolean = checked !== props.checked

  return (
    <div className={styles.container}>
      <ArkCheckbox
        {...props }
        checked={checked}
        onChange={(event, data) => {
          setChecked(!checked)
          props.onChange && props.onChange(event, data)
        }}
      />
      {loading && (
        <>
          <ArkSpacer />
          <ArkLoader small />
        </>
      )}
    </div>
  )
}

export default ArkLoadingCheckbox
