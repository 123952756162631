import React from 'react'
import _ from 'lodash'

import styles from './ArkSlider.module.css'

interface ArkSliderProps {
  barColor?: string
  className?: string
  disabled?: boolean
  markers?: string[]
  max?: number
  min?: number
  step?: number | 'any'
  trackColor?: string
  value: number
  width?: number
  onChange: (value: number) => void
}

const ArkSlider = (props: ArkSliderProps) => {
  const max: number = props.max || 1
  const min: number = props.min || 0
  const percent: number = ((props.value - min) * 100) / (max - min)

  return (
    <div
      className={`${
        styles.main
      } ${
        props.disabled ? styles.disabled : ''
      } ${
        props.className || ''
      }`}
      style={{ width: props.width }}
    >
      <div className={styles.track} style={{ backgroundColor: props.trackColor }}>
        <div className={styles.bar} style={{ backgroundColor: props.barColor, width: `${percent}%` }} />
      </div>
      {props.markers && (
        <div className={styles.markers}>
          {_.map(props.markers, (marker, index) => (
            <div className={styles.marker} key={index}>
              <div className={styles.markerLabel}>{marker}</div>
            </div>
          ))}
        </div>
      )}
      <input
        className={styles.input}
        disabled={props.disabled}
        max={props.max}
        min={props.min}
        step={props.step}
        type='range'
        value={props.value}
        onChange={event => props.onChange(parseFloat(event.target.value))}
        onClick={event => event.stopPropagation()}
      />
    </div>
  )
}

export default ArkSlider
