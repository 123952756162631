import React, { useContext, useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import { SiteAdminContext } from 'src/core/providers'

import { Company } from 'src/core/models'
import { ICompanyAddData, ICompanyUpdateData } from 'src/core/models/company'

import ArkButton from 'src/core/components/ArkButton'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkMessage from 'src/core/components/ArkMessage'

import { DEMO_MODE_SUPPORT_ENABLED, DVR_ENABLED } from 'src/constants/config'
import { FEATURE_DVR_NAME, OBJECT_COMPANY_NAME, OBJECT_PROJECT_NAME } from 'src/constants/strings'

import styles from './CompanyForm.module.css'

const formSchema = yup.object().shape({
  name: yup.string().min(4).max(25).label('Company Name')
  // TODO: add other fields
})

export enum CompanyFormMode {
  Add = 'add',
  Edit = 'edit',
}

interface IProps {
  mode: CompanyFormMode
  company?: Company
  onCancel?: Function
  onSave?: Function
  onClose?: Function
  insideModal?: boolean // ArkForm prop - enable when showing this form within a modal (so fieldset label bg's match)
  // NB: not currently supporting deleting via this form
}

const CompanyForm = (props: IProps) => {
  const mounted = useRef(false)

  const { company, mode, onCancel, onClose, onSave, insideModal } = props

  const siteAdminContext = useContext(SiteAdminContext)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSaved, setHasSaved] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>(undefined)

  // individual settings specific to this form
  const [companyValues, setCompanyValues] = useState({
    name: company?.name ?? '',
    isActive: company?.isActive ?? (mode === CompanyFormMode.Add ? true : undefined),
    isDemo: DEMO_MODE_SUPPORT_ENABLED ? (company?.isDemo ?? undefined) : undefined,
    maxUsers: company?.maxUsers ?? undefined,
    projectDefaultMaxUsers: company?.projectDefaultMaxUsers ?? undefined,
    dvrEnabled: company?.dvrEnabled ?? undefined
  })
  const [companyValuesSaved, setCompanyValuesSaved] = useState({
    name: company?.name ?? '',
    isActive: company?.isActive ?? (mode === CompanyFormMode.Add ? true : undefined),
    isDemo: DEMO_MODE_SUPPORT_ENABLED ? (company?.isDemo ?? undefined) : undefined,
    maxUsers: company?.maxUsers ?? undefined,
    projectDefaultMaxUsers: company?.projectDefaultMaxUsers ?? undefined,
    dvrEnabled: company?.dvrEnabled ?? undefined
  })
  // track which fields have changes (if any)
  const [changes, setChanges] = useState<Array<string>>([])

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const companySettingsChanges = () => {
    const _changes: Array<string> = []
    if (companyValues) {
      for (const fieldName of Object.keys(companyValues)) {
        const oldValue = companyValuesSaved !== undefined ? (companyValuesSaved as any)[fieldName] : undefined
        const newValue = (companyValues as any)[fieldName]
        console.log('CompanyForm - companySettingsChanges - fieldName:', fieldName, ' oldValue:', oldValue, ' newValue:', newValue)
        if (oldValue !== newValue) {
          _changes.push(fieldName)
        }
      }
    }
    console.log('CompanyForm - companySettingsChanges - _changes:', _changes)
    return _changes
  }

  const hasChanges = (valueKey: string): boolean => {
    return (changes && changes.includes(valueKey))
  }

  const resetSaveResults = () => {
    setHasSaved(false)
    setError(undefined)
  }

  // -------

  // check for field/value changes once their setState call has run - ref: https://upmostly.com/tutorials/how-to-use-the-setstate-callback-in-react
  useEffect(() => {
    const _changes = companySettingsChanges()
    setChanges(_changes)
  }, [companyValues])

  // -------

  const addCompany = async (
    name: string,
    isActive?: boolean,
    isDemo?: boolean,
    maxUsers?: number,
    projectDefaultMaxUsers?: number,
    dvrEnabled?: boolean
  ) => {
    if (isSubmitting) return
    resetSaveResults()
    setIsSubmitting(true)
    try {
      const companyData: ICompanyAddData = {
        name
      }
      companyData.isActive = isActive
      if (DEMO_MODE_SUPPORT_ENABLED) companyData.isDemo = isDemo
      companyData.maxUsers = maxUsers
      companyData.projectDefaultMaxUsers = projectDefaultMaxUsers
      companyData.dvrEnabled = dvrEnabled
      const savedCompany = await siteAdminContext.actions.addCompany(companyData)
      // console.log('CompanyForm - addCompany - savedCompany: ', savedCompany)
      if (savedCompany) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
          setCompanyValuesSaved(companyValues)
        }
        if (onSave) onSave()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          throw new Error('A problem occurred adding the ' + OBJECT_COMPANY_NAME + ', please try again.')
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  const updateCompany = async (
    name: string,
    isActive?: boolean,
    isDemo?: boolean,
    maxUsers?: number,
    projectDefaultMaxUsers?: number,
    dvrEnabled?: boolean
  ) => {
    if (isSubmitting || !company) return
    resetSaveResults()
    setIsSubmitting(true)
    try {
      const companyData: ICompanyUpdateData = {}
      companyData.name = name
      if (hasChanges('isActive')) companyData.isActive = isActive
      if (DEMO_MODE_SUPPORT_ENABLED && hasChanges('isDemo')) companyData.isDemo = isDemo
      if (hasChanges('maxUsers')) companyData.maxUsers = maxUsers
      if (hasChanges('projectDefaultMaxUsers')) companyData.projectDefaultMaxUsers = projectDefaultMaxUsers
      if (hasChanges('dvrEnabled')) companyData.dvrEnabled = dvrEnabled
      console.log('CompanyForm - updateCompany - companyData: ', companyData)
      const savedCompany = await siteAdminContext.actions.updateCompany(company.id, companyData)
      console.log('CompanyForm - updateCompany - savedCompany: ', savedCompany)
      if (savedCompany) {
        if (mounted.current) {
          setIsSubmitting(false)
          setHasSaved(true)
          setCompanyValuesSaved(companyValues)
        }
        if (onSave) onSave()
      } else {
        if (mounted.current) {
          setIsSubmitting(false)
          throw new Error('A problem occurred updating the ' + OBJECT_COMPANY_NAME + ', please try again.')
        }
      }
    } catch (error) {
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { name } = fieldValues
    if (mode === CompanyFormMode.Add) {
      addCompany(name, fieldValues.isActive, fieldValues.isDemo, fieldValues.maxUsers, fieldValues.projectDefaultMaxUsers, fieldValues.dvrEnabled)
    } else if (mode === CompanyFormMode.Edit) {
      updateCompany(name, fieldValues.isActive, fieldValues.isDemo, fieldValues.maxUsers, fieldValues.projectDefaultMaxUsers, fieldValues.dvrEnabled)
    }
  }

  const onValueChanged = (fieldKey: string, fieldValue: any, oldFieldValue: any) => {
    console.log('CompanyForm - onValueChanged - fieldKey: ', fieldKey, ' fieldValue: ', fieldValue, ' oldFieldValue: ', oldFieldValue)
    setCompanyValues({
      ...companyValues,
      [fieldKey]: fieldValue
    })
    // NB: if the form can remain on screen after saving, call `resetSaveResults()` here
  }

  const _onCancel = () => {
    if (onCancel) onCancel()
  }

  // triggers after dismissing the success message (so the company was created/updated ok)
  const _onClose = async () => {
    if (!isSubmitting) setCompanyValues(companyValuesSaved) // reset the form back to the saved values
    if (onClose) onClose()
  }

  // -------

  const formFields: Array<ArkFormField> = []

  formFields.push(
    {
      type: ArkFormFieldType.Group,
      key: 'detailsAndFlagsGroup',
      fields: [
        {
          type: ArkFormFieldType.Fieldset,
          key: 'detailsFieldset',
          label: OBJECT_COMPANY_NAME + ' details',
          className: styles.detailsFieldset,
          fields: [
            {
              type: ArkFormFieldType.Group,
              key: 'detailsGroup',
              slimline: true,
              fields: [
                {
                  type: ArkFormFieldType.Input,
                  key: 'name',
                  label: OBJECT_COMPANY_NAME + ' name',
                  required: true,
                  defaultValue: company?.name ?? undefined,
                  fieldProps: { width: 10 },
                  className: hasChanges('name') ? styles.hasChanged : undefined
                }
              ]
            }
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '67%', minWidth: '200px' } },
          collapsible: false,
          collapsed: false
        },
        {
          type: ArkFormFieldType.Fieldset,
          key: 'flagsFieldset',
          label: OBJECT_COMPANY_NAME + ' Settings',
          className: styles.flagsFieldset,
          fields: [
            {
              type: ArkFormFieldType.Radio,
              key: 'isActive',
              label: (<label className={styles.flagLabel}><div className={styles.flagTitle}><span>Make Active - ⚠️ BETA</span></div></label>),
              required: false,
              toggle: true,
              defaultValue: company?.isActive ?? (mode === CompanyFormMode.Add ? true : undefined), // NB: default to true for new companies, otherwise leaving as undefined for unknown so the value won't be used unless toggled on/off manually
              wrapperProps: { className: styles.flagWrapper },
              className: hasChanges('isActive') ? styles.hasChanged : undefined
            },
            ...(DEMO_MODE_SUPPORT_ENABLED
              ? [
                {
                  type: ArkFormFieldType.Radio,
                  key: 'isDemo',
                  label: (<label className={styles.flagLabel}><div className={styles.flagTitle}><span>Demo Account</span></div></label>),
                  required: false,
                  toggle: true,
                  defaultValue: company?.isDemo,
                  wrapperProps: { className: styles.flagWrapper },
                  className: hasChanges('isDemo') ? styles.hasChanged : undefined
                }
              ]
              : []),
            {
              type: ArkFormFieldType.NumberInput,
              key: 'maxUsers',
              label: OBJECT_COMPANY_NAME + ' Max Users', // (<label className={styles.flagLabel}><div className={styles.flagTitle}><span>Max Users</span></div></label>),
              required: false,
              defaultValue: company?.maxUsers ?? undefined,
              wrapperProps: { className: styles.flagWrapper },
              className: hasChanges('maxUsers') ? styles.hasChanged : undefined,
              fieldProps: { width: '112px' }
            },
            {
              type: ArkFormFieldType.NumberInput,
              key: 'projectDefaultMaxUsers',
              label: OBJECT_PROJECT_NAME + ' Default Max Users', // (<label className={styles.flagLabel}><div className={styles.flagTitle}><span>Max Users</span></div></label>),
              required: false,
              defaultValue: company?.projectDefaultMaxUsers ?? undefined,
              wrapperProps: { className: styles.flagWrapper },
              className: hasChanges('projectDefaultMaxUsers') ? styles.hasChanged : undefined,
              fieldProps: { width: '112px' }
            }
          ],
          fieldProps: { style: { flexGrow: 1, flexBasis: '33%', minWidth: '200px' } },
          collapsible: false,
          collapsed: false
        }
      ],
      fieldProps: { widths: 'equal', style: { justifyContent: 'space-between', gap: '10px' } }
    }
  )

  if (DVR_ENABLED) {
    formFields.push({
      type: ArkFormFieldType.Fieldset,
      key: 'featuresFieldset',
      label: 'Features',
      fields: [
        {
          type: ArkFormFieldType.Radio,
          key: 'dvrEnabled',
          label: FEATURE_DVR_NAME,
          toggle: true,
          defaultValue: company?.dvrEnabled ?? false
        }
      ]
    })
  }

  formFields.push({
    type: ArkFormFieldType.Group,
    key: 'buttons',
    fields: [
      {
        type: ArkFormFieldType.CancelButton,
        key: 'cancel',
        label: 'CANCEL',
        fieldProps: { onClick: _onCancel, floated: 'left' }
      },
      {
        type: ArkFormFieldType.OKButton,
        key: 'submit',
        label: (mode === CompanyFormMode.Edit ? 'SAVE' : 'CREATE'),
        fieldProps: { loading: isSubmitting, floated: 'right' },
        disabled: changes.length === 0
      }
    ],
    fieldProps: { widths: 'equal' }
  })

  return (
    <>
      <ArkHeader as="h2" inverted>
        {mode === CompanyFormMode.Edit ? 'Edit' : 'Add'} {OBJECT_COMPANY_NAME}
      </ArkHeader>

      {hasSaved && (<>
        <ArkMessage positive>
          <ArkMessage.Header>{OBJECT_COMPANY_NAME} {mode === CompanyFormMode.Edit ? 'Updated' : 'Created'}</ArkMessage.Header>
          <ArkMessage.Item>The {OBJECT_COMPANY_NAME} has been {mode === CompanyFormMode.Edit ? 'updated' : 'created'} successfully</ArkMessage.Item>
        </ArkMessage>
        <ArkButton type="button" color="blue" fluid basic size="large" disabled={false} onClick={_onClose} style={{ marginTop: 15 }}>
          OK
        </ArkButton>
      </>)}

      {!hasSaved && (
        <ArkForm
          className={styles.companyForm}
          formKey="company"
          inverted
          formError={error}
          formFields={formFields}
          formSchema={formSchema}
          onFormSubmit={onFormSubmit}
          onValueChanged={onValueChanged}
          showLabels={true}
          insideModal={insideModal}
        />)}
    </>
  )
}

export default CompanyForm
