import React, { useContext, useEffect, useState } from 'react'

import { IDLE_ENABLED } from 'src/constants/config'
import {
  SECTION_ADMIN_NAME,
  SECTION_COMPANY_NAME,
  SECTION_PROJECT_NAME,
  SECTION_VIEWER_NAME
} from '../../../constants/strings'
import { NavContext, NavSection, SectionSwitcherContext } from '../../../core/providers'
import { useIdle } from 'src/core/providers/IdleProvider'
import { LayoutContext } from '../../../core/providers/LayoutProvider'
import { ResponsiveContext } from '../../../core/providers/ResponsiveProvider'
import ArkPopup from '../ArkPopup'
import ArkSectionButton, { ArkSectionButtonGroup } from '../ArkSectionButton'

import styles from './ArkSectionSwitcher.module.css'

export interface ArkSectionSwitcherProps {}

const ArkSectionSwitcher = (_props: ArkSectionSwitcherProps) => {
  const { actions: sectionActions, store: sectionsStore } = useContext(SectionSwitcherContext)
  const { showSiteAdminLink, showCompanyAdminLink, showProjectManagerLink, showProjectViewerLink } = sectionsStore

  const { store: layoutStore } = useContext(LayoutContext)
  const { leftSidebarState } = layoutStore

  const navContext = useContext(NavContext)
  const { store: navStore } = navContext
  const { currentSection } = navStore

  const { store: responsiveStore } = useContext(ResponsiveContext)
  const { isDesktop } = responsiveStore

  const idle = useIdle()

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  useEffect(() => {
    // auto-close popup
    if (leftSidebarState !== 'compact') setIsPopupOpen(false)
  }, [leftSidebarState])

  const isCompact = isDesktop && leftSidebarState !== 'full'

  // if no sections links are enabled, or only the viewer one is, return an empty/null component
  const hasLinks = showSiteAdminLink || showCompanyAdminLink || showProjectManagerLink || showProjectViewerLink
  const onlyViewer = showProjectViewerLink && (!showSiteAdminLink && !showCompanyAdminLink && !showProjectManagerLink)
  if (!hasLinks || onlyViewer) {
    return null
  }

  const onSectionClick = (section: NavSection, compact: boolean) => {
    if (compact) {
      setIsPopupOpen(!isPopupOpen)
      return
    }
    sectionActions.gotoSection(section)
  }

  const renderSectionButtons = (popup?: boolean) => {
    const shouldCompact = popup ? false : isCompact
    return (
      <ArkSectionButtonGroup compact={shouldCompact} popup={popup}>
        {showSiteAdminLink && (
          <ArkSectionButton
            active={currentSection === NavSection.admin}
            compact={shouldCompact}
            hidden={shouldCompact && currentSection !== NavSection.admin}
            iconName='admin'
            locked={IDLE_ENABLED && idle.getLocked()}
            onClick={() => onSectionClick(NavSection.admin, shouldCompact)}
            title={SECTION_ADMIN_NAME}
          />
        )}
        {showCompanyAdminLink && (
          <ArkSectionButton
            active={currentSection === NavSection.company}
            compact={shouldCompact}
            hidden={shouldCompact && currentSection !== NavSection.company}
            iconName='company'
            locked={IDLE_ENABLED && idle.getLocked()}
            onClick={() => onSectionClick(NavSection.company, shouldCompact)}
            title={SECTION_COMPANY_NAME}
          />
        )}
        {showProjectManagerLink && (
          <ArkSectionButton
            active={currentSection === NavSection.project}
            compact={shouldCompact}
            hidden={shouldCompact && currentSection !== NavSection.project}
            iconName='project'
            locked={IDLE_ENABLED && idle.getLocked()}
            onClick={() => onSectionClick(NavSection.project, shouldCompact)}
            title={SECTION_PROJECT_NAME}
          />
        )}
        {showProjectViewerLink && (
          <ArkSectionButton
            active={currentSection === NavSection.viewer}
            compact={shouldCompact}
            hidden={shouldCompact && currentSection !== NavSection.viewer}
            iconName='viewer'
            onClick={() => onSectionClick(NavSection.viewer, shouldCompact)}
            title={SECTION_VIEWER_NAME}
          />
        )}
      </ArkSectionButtonGroup>
    )
  }

  return (
    <ArkPopup
      className={styles.popup}
      onClose={ () => setIsPopupOpen(false)}
      open={isPopupOpen}
      position='right center'
      trigger={renderSectionButtons()}
    >
      {renderSectionButtons(true)}
    </ArkPopup>
  )
}
export default ArkSectionSwitcher
