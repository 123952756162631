import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import { Popup } from 'semantic-ui-react'

import ArkIcon from 'src/core/components/ArkIcon'
import { millisecondsToMinutes } from 'src/core/utilities/time'

import { useIdle } from './IdleProvider'

import styles from './IdleIndicator.module.css'

const DEBUG_ALWAYS_SHOW: boolean = false
const DEBUG_PROGRESS: number = 0

interface IdleIndicatorProps {
  autohide?: boolean
  locked?: boolean
  size?: number
  tooltips?: boolean
}

const IdleIndicator = (props: IdleIndicatorProps) => {
  const idle = useIdle()

  const locked: boolean = props.locked || idle.getLocked()
  const show: boolean = DEBUG_ALWAYS_SHOW || !props.autohide || idle.getPrompted()
  const size: number = props.size || 40

  const main = (
    <div
      className={`${styles.container} ${show && styles.show}`}
      style={{ height: size, width: size }}
    >
      <ArkIcon
        color={locked ? 'var(--th-danger)' : undefined}
        name={locked ? 'lock-solid' : 'unlock'}
        size={size * 0.5}
      />
      <div className={`${styles.progressContainer} ${(!DEBUG_PROGRESS && idle.getLocked()) && styles.hide}`}>
        <CircularProgressbar
          strokeWidth={5}
          styles={{
            path: {
              stroke: 'var(--th-danger)',
              strokeLinecap: 'round',
              transition: '1s linear'
            },
            trail: {
              stroke: 'transparent'
            }
          }}
          value={DEBUG_PROGRESS || idle.getPromptPercent()}
        />
      </div>
    </div>
  )

  if (!props.tooltips) return main

  return (
    <Popup
      className={styles.popup}
      inverted
      position='bottom left'
      trigger={main}
    >
      {idle.getLocked()
        ? (
          <span className={styles.lockedText}>
            <div>Management sections automatically locked due to inactivity.</div>
            <div>You will be asked to re-enter your password.</div>
          </span>
        )
        : (
          <>
            <div>Management sections will automatically lock after {millisecondsToMinutes(idle.getTimeout())} minutes of inactivity.</div>
          </>
        )}

    </Popup>
  )
}

export default IdleIndicator
