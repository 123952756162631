import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import ArkCenterLayout from 'src/core/components/ArkCenterLayout'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkPage from 'src/core/components/ArkPage/ArkPage'
import ArkSegment from 'src/core/components/ArkSegment'

import LoginPasswordResetStage1Form from './LoginPasswordResetStage1Form'
import LoginPasswordResetStage2Form from './LoginPasswordResetStage2Form'

interface IProps {}

interface IPasswordResetLocationStateType {
  email?: string
}

const LoginPasswordResetPage = (_props: IProps) => {
  const { resetToken } = useParams<{ resetToken: string }>()
  console.log('LoginPasswordResetPage - resetToken:', resetToken)

  const location = useLocation<IPasswordResetLocationStateType>()
  console.log('LoginPasswordResetPage - location:', location)

  const _renderResetForm = () => {
    return (
      <ArkPage>
        <ArkCenterLayout>
          <ArkSegment inverted>
            <ArkHeader as="h2" textAlign="center">Reset Password</ArkHeader>
            <LoginPasswordResetStage1Form email={location.state?.email} />
          </ArkSegment>
        </ArkCenterLayout>
      </ArkPage>
    )
  }

  const _renderResetToken = () => {
    return (
      <ArkPage>
        <ArkCenterLayout>
          <ArkSegment inverted>
            <ArkHeader as="h2" textAlign="center">Reset Password Confirmation</ArkHeader>
            <LoginPasswordResetStage2Form resetToken={resetToken} />
          </ArkSegment>
        </ArkCenterLayout>
      </ArkPage>
    )
  }

  if (resetToken) {
    return _renderResetToken()
  }
  return _renderResetForm()
}

export default LoginPasswordResetPage
