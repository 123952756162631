export enum ProgramError {
  NoAbrStream = 'noAbrStream',
  NoPassthroughStream = 'noPassthroughStream',
  NoStream = 'noStream',
}

export enum ProgramWatchdogStatus {
  Unknown = 'unknown',
  Connecting = 'connecting',
  Playing = 'playing',
  Reconnecting = 'reconnecting',
  Restarting = 'restarting'
}
