import React from 'react'

import UserChangePasswordForm from './UserChangePasswordForm'

export interface UserChangePasswordViewProps {
  onCancel?: Function
  onDone?: Function
}

const UserChangePasswordView = (props: UserChangePasswordViewProps) => {
  return (<UserChangePasswordForm onCancel={props.onCancel} onDone={props.onDone} />)
}

export default UserChangePasswordView
