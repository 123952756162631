import React, { useContext } from 'react'

import { CompanyAdminContext } from 'src/core/providers'
import { User } from 'src/core/models'

import ArkManagerDeleteButton from 'src/core/components/ArkManagerDeleteButton/ArkManagerDeleteButton'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { OBJECT_USER_NAME } from 'src/constants/strings'

export type CompanyUserUnlockCallback = (user: User) => void

interface IProps {
  user: User
  onUnlock?: CompanyUserUnlockCallback
  className?: string
}

const CompanyUserUnlockButton = (props: IProps) => {
  const { className, onUnlock: _onUnlock, user } = props

  const companyAdminContext = useContext(CompanyAdminContext) // NB: as org/company admins can unlock users from their own org, we use the company admin provider here (even though site admins can also use this to unlock users across any orgs via the same call)

  const onUnlock = async (): Promise<boolean> => {
    // NB: DON'T catch the error/exception here, let it throw (or if you do, rethrow it so the calling code can catch & display it)
    const unlockResult = await companyAdminContext.actions.unlockCompanyUser(user.id)
    console.log('CompanyUserUnlockButton - onUnlock - unlockCompanyUser - unlockResult:', unlockResult)
    return unlockResult
  }

  if (!user) return null

  return (
    <div className={(className ? ' ' + className : '')}>
      <ArkSpacer />
      <ArkManagerDeleteButton
        itemId={user.id}
        itemName={user.name()}
        itemTypeName={OBJECT_USER_NAME + ' Account'}
        itemNamePrefix='for'
        buttonTitle={'UNLOCK ' + OBJECT_USER_NAME + ''}
        deleteWord={'Unlock'}
        deletedWord={'Unlocked'}
        // confirmMsgPost={<>?<br />Continue</>}
        onDelete={onUnlock}
        onDeleteComplete={() => { if (_onUnlock) _onUnlock(user) }}
        buttonProps={{ color: 'yellow' }}
        // confirmOkButtonProps={{ color: 'yellow', basic: false }}
        // confirmTextInput={user.email}
      />
    </div>
  )
}

export default CompanyUserUnlockButton
