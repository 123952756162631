import React, { useEffect } from 'react'

import { useAuthSSO } from 'src/core/providers'
import { AuthLoginServiceSSOType, IAuthLoginService } from 'src/core/models'

import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkMessage from 'src/core/components/ArkMessage'

import Auth0SSOLoginView from '../SSO/Auth0/Auth0SSOLoginView'
import OktaSSOLoginView from '../SSO/Okta/OktaOIDCSSOLoginView'

import { COMPANY_LOGIN_SERVICE_SSO_DEBUG } from 'src/constants/config'

import LoginNotUserView from '../Login/LoginNotUserView'

interface RegisterSSOViewProps {
  email?: string // NB: if loaded via an sso callback route we won't have the email passed in, we must load it from the cache
  loginService?: IAuthLoginService // NB: if loaded via an sso callback route we won't know which register service its for & will have to (re)call the check-email api endpoint to find out before continuing
  isSSOCallback?: boolean // true if this is loaded after/from a redirect from the SSO provider
  onCancel?: () => void
  onRegisterCallback?: (accessToken: string, email?: string) => void
}

const RegisterSSOView = (props: RegisterSSOViewProps) => {
  const { email: pEmail, loginService: pLoginService, isSSOCallback, onCancel } = props

  const authSSOContext = useAuthSSO()

  const {
    isLoadingConfig,
    isInitialised,
    isInitialising,
    isRunning,
    isRedirecting,
    isAuthenticated,
    userEmail,
    cachedEmail,
    ssoServiceType,
    ssoServiceConfig,
    ssoAccessToken: accessToken,
    ssoError: error
  } = authSSOContext.store

  useEffect(() => {
    async function runAsync () {
      console.log('RegisterSSOView - useEffect - !isInitialised && !isInitialising - runAsync - pEmail(props):', pEmail, ' pLoginService(props):', pLoginService) //, ' sRegisterService(state):', sRegisterService)
      await authSSOContext.actions.loadSSOConfigAndEmail(pLoginService, pEmail)
    }
    if (!isLoadingConfig && !ssoServiceConfig && !error) runAsync() // only attempt to run on component mount/init
  }, [isLoadingConfig, ssoServiceConfig, error])

  const _renderDebug = () => {
    return (
      <>
        <h3>RegisterSSOView DBG:</h3>
        <div>email (props): {pEmail ?? '-'}</div>
        <div>email (cache): {cachedEmail ?? '-'}</div>
        <div>email (used): {userEmail ?? '-'}</div>
        {/* <div>email (state): {sEmail ?? '-'}</div> */}
        <br />
        {/* <div>registerServiceType (props): {pRegisterService ? pRegisterService.type : '-'}</div> */}
        {/* <div>registerServiceType (state): {sRegisterService ? sRegisterService.type : '-'}</div> */}
        {/* <div>registerService - isLoading(check-email): {isLoading ? 'YES' : 'NO'}</div> */}
        <div>ssoServiceType (state): {ssoServiceType ?? '-'}</div>
        <div>ssoServiceConfig (state): {ssoServiceConfig ? JSON.stringify(ssoServiceConfig) : '-'}</div>
        <br />
        <div>sso - isSSOCallback: {isSSOCallback ? 'YES' : 'NO'}</div>
        {/* <div>sso - hasAuthParams: {hasAuthParams ? 'YES' : 'NO'}</div> */}
        <div>sso - isLoadingConfig: {isLoadingConfig ? 'YES' : 'NO'}</div>
        <div>sso - isInitialised: {isInitialised ? 'YES' : 'NO'}</div>
        <div>sso - isInitialising: {isInitialising ? 'YES' : 'NO'}</div>
        <div>sso - isRunning: {isRunning ? 'YES' : 'NO'}</div>
        <div>sso - isRedirecting: {isRedirecting ? 'YES' : 'NO'}</div>
        <div>sso - isAuthenticated: {isInitialised ? (isAuthenticated ? 'YES' : 'NO') : '-'}</div>
        <div>sso - hasAccessToken: {isInitialised ? (accessToken ? 'YES' : 'NO') : '-'}</div>
        <br />
        <hr />
      </>
    )
  }

  const _onRegisterCallback = (accessToken: string, email?: string) => {
    console.log('RegisterSSOView - _onRegisterCallback - accessToken:', accessToken, ' email:', email)
    if (props.onRegisterCallback) props.onRegisterCallback(accessToken, email)
  }

  const showOktaLogin = !isLoadingConfig && ssoServiceConfig && ssoServiceType === AuthLoginServiceSSOType.SSOOktaOIDC
  const showAuth0Login = !isLoadingConfig && ssoServiceConfig && ssoServiceType === AuthLoginServiceSSOType.SSOAuth0
  const invalidSSODetails = !isLoadingConfig && (!ssoServiceType || !ssoServiceConfig)

  return (
    <>
      <div>
        {/* <h1>SSO Register</h1> */}

        {/**
          * NB: ONLY show an (SSO) error if we're not showing the okta/auth0 SSO view, as that shows the same SSO error already
          * NB: OR if a local error, currently only `invalidSSODetails` is detected
          * NB: refreshing the sso callback page currently causes an 'Email error' to be shown here when the okta view isn't showing, so we do still need this error display as well...
          */}
        {((error && !showOktaLogin && !showAuth0Login) || invalidSSODetails) && (
          <>
            <ArkMessage negative>
              <ArkMessage.Header>Register Error</ArkMessage.Header>
              {error && (<p>{error?.message}</p>)}
              {invalidSSODetails && (<p>Invalid SSO Details</p>)}
            </ArkMessage>
            <LoginNotUserView
              email={userEmail ?? ''}
              title={'Go Back'}
              onClick={() => { if (onCancel) onCancel() }}
            />
          </>
        )}
      </div>
      {COMPANY_LOGIN_SERVICE_SSO_DEBUG && _renderDebug()}
      {isLoadingConfig && (<ArkLoaderView message={'Loading'} />)}
      {showOktaLogin && (
        <OktaSSOLoginView
          ssoConfig={ssoServiceConfig}
          email={userEmail}
          register={true}
          isSSOCallback={isSSOCallback} /* this.state.isSSORedirectPage << TODO: << */
          onCancel={onCancel}
          onRegisterCallback={_onRegisterCallback}
        />
      )}
      {showAuth0Login && (
        <Auth0SSOLoginView
          email={userEmail}
          // TODO: add `register={true}` support like `OktaSSOLoginView`
          isSSOCallback={isSSOCallback} /* this.state.isSSORedirectPage << TODO: << */
          onCancel={onCancel}
        />
      )}
    </>
  )
}

export default RegisterSSOView
