/* eslint-disable import/no-duplicates */
/* eslint-disable new-cap */
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import jsSHA from 'jssha'
import 'jswbw2'
import { JswbwConfig, sdk } from 'jswbw2'

import { JSWBW_ENABLED } from 'src/constants/config'

const JSWBW_API_KEY: string = ''
// const JSWBW_PNG_SECRET_KEY: string = ''
const JSWBW_SECRET_KEY: string = ''
const JSWBW_TOKEN: string = ''
const JSWBW_URL: string = ''

declare global {
  interface Window {
    jswbw2: { new (config: JswbwConfig): sdk; }
  }
}

export interface IJswbwContext {
  getSurfaceData : () => string
}

interface JswbwProviderProps {
  children: ReactNode
}

export const JswbwContext = createContext<IJswbwContext>({} as IJswbwContext)

export const useJswbw = () => useContext(JswbwContext)

const JswbwProvider = (props: JswbwProviderProps) => {
  const [surfaceData, setSurfaceData] = useState<string>('')

  if (!JSWBW_ENABLED) return <>{props.children}</>

  /**
   * callbacks
   */

  const onBlob = (_blobData: ArrayBuffer, _surfaceId: string): void => {
    // console.log('JswbwProvider - onBlob - blobData:', blobData, 'surfaceId:', surfaceId)
  }

  const onError = (id: number, message: string): void => {
    console.log('JswbwProvider - onError - id:', id, 'message:', message)
  }

  const onImage = (imageData: string): void => {
    // console.log('JswbwProvider - onImage - imageData:', imageData)
    setSurfaceData(imageData)
  }

  const onMessage = (message: string): void => {
    console.log('JswbwProvider - onMessage - message:', message)
  }

  const onTlValidation = (data: Uint8Array): Uint8Array => {
    // console.log('JswbwProvider - onTlValidation - data:', data)
    const sha: jsSHA = new jsSHA('SHA-1', 'ARRAYBUFFER')
    sha.setHMACKey(JSWBW_SECRET_KEY, 'BYTES')
    sha.update(data)
    return sha.getHMAC('UINT8ARRAY')
  }

  /**
   * init
   */

  const init = (): void => {
    // console.log('JswbwProvider - init')
    const config: JswbwConfig = {
      apikey: JSWBW_API_KEY,
      callback: {
        blob: onBlob,
        error: onError,
        image: onImage,
        message: onMessage,
        tlValidation: onTlValidation
      },
      token: JSWBW_TOKEN,
      url: JSWBW_URL
    }
    const sdk: sdk = new window.jswbw2(config)
    sdk.start()
  }

  useEffect(init, [])

  const getSurfaceData = (): string => surfaceData

  // console.log('JswbwProvider - render')

  return (
    <JswbwContext.Provider value={{ getSurfaceData }}>
      {props.children}
    </JswbwContext.Provider>
  )
}

export default JswbwProvider
