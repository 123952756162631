import React, { ReactNode } from 'react'

import styles from './ArkBadge.module.css'

export interface ArkBadgeProps {
  children: ReactNode
  color?: string
  onMouseEnter?: () => void // for ArkTooltip
  onMouseLeave?: () => void // for ArkTooltip
}

const ArkBadge = (props: ArkBadgeProps) => (
  <div
    className={styles.badge}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    style={{ backgroundColor: props.color }}
  >
    <div className={styles.text}>{props.children}</div>
  </div>
)

export default ArkBadge
