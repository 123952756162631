import React, { useEffect, useRef, useState } from 'react'
import * as yup from 'yup'

import { useAuth, useServer } from 'src/core/providers'
import { IAuthLoginService } from 'src/core/models'

import ArkForm, { ArkFormField, ArkFormFieldOption, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'

const formSchema = yup.object().shape({
  email: yup.string().email().required()
})

export type LoginEmailLookupCallback = (email: string, loginService: IAuthLoginService) => void

interface IProps {
  email?: string
  autoRun?: boolean
  onEmailLookup?: LoginEmailLookupCallback
}

const LoginEmailLookupForm = (props: IProps) => {
  const mounted = useRef(false)

  const { email, autoRun, onEmailLookup } = props

  const authContext = useAuth()
  const serverContext = useServer()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [lookupError, setLookupError] = useState<Error | undefined>()
  const [formKey, setFormKey] = useState<string>(Date.now().toString(36) + Math.random().toString(36).substring(2)) // basic random key - ref: https://stackoverflow.com/a/44078785

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const runEmailLookup = async (email: string) => {
    if (email && isSubmitting === false) {
      setIsSubmitting(true)
      // TODO: July 2023 - see the comments in the ServerAuthAPI for the `checkEmailExists/emailLoginLookup` method re okta/auth0 sso api handling changes in the response from this api call <<<
      try {
        const loginService = await authContext.actions.emailLoginLookup(email)
        console.log('LoginEmailLookupForm - runEmailLookup - loginService:', loginService)
        if (mounted.current) setIsSubmitting(false)
        if (onEmailLookup) onEmailLookup(email, loginService)
      } catch (error) {
        if (mounted.current) {
          setLookupError(error)
          setIsSubmitting(false)
        }
      }
    }
  }

  useEffect(() => {
    console.log('LoginEmailLookupForm - componentDidMount - this.props - email:', email, ' autoRun:', autoRun)
    if (email && autoRun === true) {
      console.log('LoginEmailLookupForm - componentDidMount - autoRun enabled - submitting form...')
      runEmailLookup(email)
    }
  }, [])

  // TESTING: equivalent to the componentDidUpdate method `if (this.props.email !== prevProps.email) { ... }`
  useEffect(() => {
    setFormKey(Date.now().toString(36) + Math.random().toString(36).substring(2))
    // auto submit if autoRun is enabled
    console.log('LoginEmailLookupForm - useEffect[email] - email:', email, ' autoRun:', autoRun)
    if (email && autoRun === true) {
      console.log('LoginEmailLookupForm - useEffect[email] - autoRun enabled - submitting form...')
      runEmailLookup(email)
    }
  }, [email])

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { email } = fieldValues
    await runEmailLookup(email)
  }

  const onFormValueChanged = async (fieldKey: string, _fieldValue: any, _oldFieldValue: any) => {
    // TESTING: if an authError is set, clear it once the user starts to type/edit the email
    if (fieldKey === 'email' && authContext.store.authError !== undefined) {
      authContext.actions.clearAuthError()
    }
  }

  // -------

  const authError = authContext.store.authError

  const formFields: Array<ArkFormField> = []

  if (serverContext.store.servers) {
    const serverOptions: Array<ArkFormFieldOption> = []
    for (const serverKey in serverContext.store.servers) {
      const server = serverContext.store.servers[serverKey]
      serverOptions.push({ key: serverKey, text: server.name, value: serverKey })
    }
    formFields.push({
      type: ArkFormFieldType.Select,
      key: 'server',
      label: 'Server',
      required: true,
      defaultValue: serverContext.store.apiServerType,
      options: serverOptions,
      fieldProps: {
        // WARNING: this stops the select field updating within the ArkForm currently, so we have to ignore the submitted value & rely on the one from here only
        onChange: (event: React.SyntheticEvent<HTMLElement, Event>, data: any) => {
          const server = data.value
          if (server !== serverContext.store.apiServerType) {
            console.log('LoginEmailLookupForm - render - Select - server - onChange - CHANGE TO SERVER: ', server)
            const selectServerResult = serverContext.actions.selectServer(server)
            console.log('LoginEmailLookupForm - selectServerResult: ', selectServerResult)
            // FIXME: update the UI/selection if the server selection fails?
          }
        }
      }
    })
  }

  // console.log('LoginEmailLookupForm - render - email:', email, ' formKey:', formKey)
  formFields.push({
    type: ArkFormFieldType.Input,
    key: 'email',
    label: 'Email Address',
    required: true,
    defaultValue: email
  })
  formFields.push({ type: ArkFormFieldType.Button, key: 'submit', label: 'Continue', fieldProps: { loading: isSubmitting, fluid: true } })

  return (
    <ArkForm
      key={formKey} // NB: unique key added to force a re-render when the email prop changes
      formKey="emailLookup"
      className="email-lookup-form"
      inverted
      formError={lookupError ?? authError}
      formFields={formFields}
      formSchema={formSchema}
      onFormSubmit={onFormSubmit}
      onValueChanged={onFormValueChanged}
    ></ArkForm>
  )
}

export default LoginEmailLookupForm
