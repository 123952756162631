import React, { useEffect, useRef } from 'react'
import * as yup from 'yup'

import { AuthStatus, useAuth } from 'src/core/providers'

import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'

import styles from './LoginPage.module.css'

const formSchema = yup.object().shape({
  password: yup.string().min(5).max(60).required()
})

export type LoginEmailPasswordCallback = (result: boolean) => void

interface IProps {
  email: string
}

const LoginEmailPasswordForm = (props: IProps) => {
  const mounted = useRef(false)

  const { email } = props

  const authContext = useAuth()

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { password } = fieldValues
    const loading = authContext.store.authStatus === AuthStatus.loading
    if (password && loading === false) {
      // on success the AuthProvider will trigger a redirect away, if an error happens its propagated down from the auth provider in the render function
      // NB: if 2FA is required the auth provider will update its `authStatus` value & the parent/calling `LoginPage` component catches & triggers the appropriate UI
      await authContext.actions.loginWithEmailAndPassword(email, password)
    }
  }

  const loading = authContext.store.authStatus === AuthStatus.loading
  const err = authContext.store.authError

  // NB: now adding a disabled email field so browser password save/auto-fill works with this split 2-stage email > password login process
  // NB: ORIGINAL: tried to set it as hidden, but that stops the autofill from fully working, so showing & disabling it seems to be the best compramise for now (could maybe use css to hide it while keeping autofill working if we want?)
  // NB: UPDATE 1: setting `type: hidden` & then using `display: none` on the parent field element (to also hide the field label) seems to hide it while keeping autofill working (in latest Chrome at least)
  // NB: UPDATE 2: reversing the `type: hidden` use, as chrome on a new machine wasn't picking up the email field, hiding it via css seems to still work though to achieve the same result
  const formFields: Array<ArkFormField> = []
  formFields.push({ type: ArkFormFieldType.Input, key: 'email', label: 'Email Address', defaultValue: email, required: true, className: styles.emailPassHideEmail, fieldProps: { disabled: true /* , type: 'hidden' */ } })
  formFields.push({ type: ArkFormFieldType.Input, key: 'password', label: 'Password', required: true, fieldProps: { type: 'password' } })
  formFields.push({ type: ArkFormFieldType.Button, key: 'submit', label: 'Login', fieldProps: { loading: loading, fluid: true } })

  return (
    <ArkForm
      formKey="emailPassword"
      className="email-password-form"
      inverted
      formError={err}
      formFields={formFields}
      formSchema={formSchema}
      onFormSubmit={onFormSubmit}
    ></ArkForm>
  )
}

export default LoginEmailPasswordForm
