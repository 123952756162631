import React, { useContext } from 'react'

import { Auth0Client, Auth0ClientOptions, GetTokenSilentlyOptions, GetTokenSilentlyVerboseResponse, LogoutOptions, RedirectLoginOptions, RedirectLoginResult, User as Auth0User } from '@auth0/auth0-spa-js'
import OktaAuth, { OktaAuthOptions, SigninWithRedirectOptions, SignoutOptions, UserClaims as OktaUser } from '@okta/okta-auth-js'

import { withAuthContext, IAuthMultiContext, AuthStatus } from '.'
import { AuthLoginServiceSSOType, AuthLoginServiceType, IAuthLoginService, IAuthLoginServiceSSOConfig, AuthSSOUser, AuthSSOUserOkta, AuthSSOUserAuth0 } from '../models'

import ServerAPIClient from '../services/ServerAPIClient'
import ServerAuthAPI from '../services/ServerAuthAPI'

import {
  COMPANY_LOGIN_SERVICE_AUTH0_CONFIG,
  COMPANY_LOGIN_SERVICE_OKTA_OIDC_CONFIG,
  // COMPANY_LOGIN_SERVICE_OKTA_SAML_CONFIG,
  COMPANY_LOGIN_SERVICE_SSO_AUTO_LOGIN,
  COMPANY_LOGIN_SERVICE_SSO_DEBUG,
  COMPANY_LOGIN_SERVICE_SSO_WIPE_TOKEN_POST_LOGIN
} from 'src/constants/config'

// // TODO: replace with `ServerAuthLoginServiceType`? (or match the service ids to the api & rename this to be 'login service' specific?)
// // UPDATE: testing out using it as more of an alias to the SSO specific `ServerAuthLoginServiceType` entries (as it also has non-SSO entries)
// // TODO: should this just be moved within the `ServerAuthAPI` class next to the `ServerAuthLoginServiceType` declaration & have  `ServerAuthLoginServiceType` use/extend the SSO specific values instead of vice versa?
// export enum AuthSSOServiceType {
//   Okta = ServerAuthLoginServiceType.OktaSSO as number,
//   Auth0 = ServerAuthLoginServiceType.Auth0SSO as number
// }

export interface IAuthSSOStore {
  // general
  isLoadingConfig: boolean
  isInitialised: boolean
  isInitialising: boolean
  isRunning: boolean
  isRedirecting: boolean
  isAuthenticated: boolean
  userEmail?: string
  cachedEmail?: string
  // current/loaded login service/provider
  ssoServiceType?: AuthLoginServiceSSOType
  ssoServiceConfig?: IAuthLoginServiceSSOConfig
  ssoAccessToken?: string
  ssoError?: Error
  // auth0
  auth0?: Auth0Client
  auth0User?: Auth0User
  // okta
  okta?: OktaAuth
  oktaUser?: OktaUser
}

export interface IAuthSSOActions {
  // -------
  loadSSOConfigAndEmail: (loginService?: IAuthLoginService, email?: string) => Promise<void>
  // -------
  setupSSOService: (ssoServiceType: AuthLoginServiceSSOType, ssoConfig: IAuthLoginServiceSSOConfig, email?: string, register?: boolean) => Promise<void>
  // -------
  setupSSOServiceAndLogin: (ssoServiceType: AuthLoginServiceSSOType, ssoConfig: IAuthLoginServiceSSOConfig, email?: string, isCallback?: boolean, submitAccessToken?: boolean, register?: boolean) => Promise<void>
  // -------
  // general actions
  isAuthenticated: () => Promise<boolean>
  logoutSSOProvider: (options?: SignoutOptions | LogoutOptions) => Promise<void>
  // -------
  // helpers
  getSSOProviderName: (loginService?: IAuthLoginService) => string | undefined
  // -------
  // Okta specific actions
  oktsIsLoginRedirect: () => boolean
  oktaOIDCSignInWithRedirect: (email?: string, opts?: SigninWithRedirectOptions) => Promise<void>
  oktaOIDCHandleRedirect: (originalUri?: string) => Promise<void>
  oktaCheckSession: () => Promise<boolean>
  oktaOIDCGetAccessToken: (updateState?: boolean) => Promise<string | undefined>
  // -------
  oktaSAMLSignInWithRedirect: (email: string, loginServiceId: number, companyServiceId: number) => Promise<void>
  // -------
  // Auth0 specific actions
  // ref: https://auth0.github.io/auth0-spa-js/classes/Auth0Client.html
  // TODO: prefix these function names with `auth0` or make them generic to work with any provider (& just change the args to use `any`? or declare with alternative types per provider?)
  auth0LoginWithRedirect<TAppState>(options?: RedirectLoginOptions<TAppState>): Promise<void>
  auth0HandleRedirectCallback<TAppState>(url?: string): Promise<RedirectLoginResult<TAppState> | undefined>
  auth0CheckSession(options?: GetTokenSilentlyOptions): Promise<void>
  auth0GetTokenSilently(options: GetTokenSilentlyOptions & { detailedResponse: true }): Promise<GetTokenSilentlyVerboseResponse | undefined>
  auth0HasAuthParams(searchParams?: string): boolean
  // -------
  saveAuthSSOCache: (serviceType: AuthLoginServiceType, email: string) => void
  getAuthSSOCache: () => { serviceType: AuthLoginServiceType, email: string } | undefined
  // -------
}

export interface IAuthSSOContext {
  actions: IAuthSSOActions
  store: IAuthSSOStore
}

export interface IAuthSSOMultiContext {
  authSSOContext: IAuthSSOContext
}

export const AuthSSOContext = React.createContext<IAuthSSOContext>({} as IAuthSSOContext)

export const useAuthSSO = () => useContext(AuthSSOContext)

export interface AuthSSOProviderProps extends IAuthMultiContext {
  apiClient: ServerAPIClient
  authApi: ServerAuthAPI
  children?: React.ReactNode
}
export interface AuthSSOProviderState extends IAuthSSOStore {
}

class AuthSSOProvider extends React.Component<AuthSSOProviderProps, AuthSSOProviderState> {
  _isMounted: boolean = false

  constructor (props: AuthSSOProviderProps) {
    super(props)
    this.state = {
      isLoadingConfig: false,
      isInitialised: false,
      isInitialising: false,
      isRunning: false,
      isRedirecting: false,
      isAuthenticated: false
    }
  }

  componentDidMount () {
    console.log('AuthSSOProvider - componentDidMount')
    this._isMounted = true
    // DEV ONLY: check & warn if auto login is disabled but debug mode isn't enabled (you won't be able to do/see anything if so!)
    if (!COMPANY_LOGIN_SERVICE_SSO_AUTO_LOGIN && !COMPANY_LOGIN_SERVICE_SSO_DEBUG) {
      this.setState({ ssoError: new Error('SSO Login is Disabled. Check SSO web-app config settings!') })
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  // -------

  componentDidUpdate (prevProps: AuthSSOProviderProps, _prevState: AuthSSOProviderState) {
    // check if the user logs in & clear the cached email if so
    const authStatus = this.props.authContext.store.authStatus
    const prevAuthStatus = prevProps.authContext.store.authStatus
    if (authStatus === AuthStatus.loggedIn && prevAuthStatus !== AuthStatus.loggedIn) {
      console.log('AuthSSOProvider - componentDidUpdate - USER LOGGED IN')
      this.clearAuthSSOCache()
    }
  }

  // -------

  // helper function mainly for use when returning from an SSO callback redirect, we won't have the sso config data needed
  // ..so we make a call to our api to get it based on the email & service type id cached before the sso process was started
  // NB: does NOT need the sso service to be initialised first (as it's only used to get the sso config data), & actually needs to be run before it can be initialised on the callback page/route
  // NB: should be ok to call both before & after an sso callback redirect, passing in the known values if we have them, & it loads them from the cache if not, triggering relevant errors if anything is missing or mismatched
  loadSSOConfigAndEmail = async (loginService?: IAuthLoginService, email?: string): Promise<void> => {
    console.log('AuthSSOProvider - loadSSOConfigAndEmail - loginService:', loginService, ' email:', email, ' this.state.isLoadingConfig(BEFORE):', this.state.isLoadingConfig)
    try {
      if (this.state.isLoadingConfig) return

      await new Promise((resolve) => this.setState({ isLoadingConfig: true, ssoServiceConfig: undefined, ssoError: undefined }, () => resolve(this.state)))

      let _email: string | undefined = email
      let _loginService: IAuthLoginService | undefined = loginService
      let _loginServiceType: AuthLoginServiceType | undefined = _loginService?.type
      // if (_loginServiceType === AuthLoginServiceType.NewUser && _loginService?.ssoConfig?.type) _loginServiceType = _loginService.ssoConfig.type // #990 auth ux changes - removed `AuthLoginServiceType.NewUser` so this should no longer be needed (temp left in for future reference until we're sure its ok to fully remove)

      // load the email & login service type from the sso cache if not passed in via props (eg. if loaded via an sso callback route)
      if (_email === undefined || _loginServiceType === undefined) {
        const _cachedSSOData = this.getAuthSSOCache()
        console.log('AuthSSOProvider - loadSSOConfigAndEmail - _cachedSSOData:', _cachedSSOData)

        if (_email === undefined) {
          _email = _cachedSSOData?.email
        } else if (_email !== _cachedSSOData?.email) {
          // if the login email was passed in via props but doesn't match the cached one, flag as an error
          // NB: the cached one can be undefined on a page refresh of the sso callback page & trigger this
          // TODO: should the cached email (or data) being undefined be caught & handled differently? or use a more specific error message otherwise?
          console.warn('AuthSSOProvider - loadSSOConfigAndEmail - WARNING: email from props does not match the cached email:', _email, ' !== ', _cachedSSOData?.email)
          throw new Error('Email error')
        }

        // if no service type was passed in via props load it from the cache
        if (_loginServiceType === undefined) {
          _loginServiceType = _cachedSSOData?.serviceType as number
        } else if (_loginServiceType !== _cachedSSOData?.serviceType as number) {
          // if a service type was passed in via props but doesn't match the cached one, flag as an error
          // NB: this should only really be able to happen if an admin changed sso configs while the user was attempting to login with the originally enabled sso service
          console.warn('AuthSSOProvider - loadSSOConfigAndEmail - WARNING: loginServiceType from props does not match the cached loginServiceType:', _loginServiceType, ' !== ', _cachedSSOData?.serviceType)
          throw new Error('Service type error')
        }
      }

      // if we don't have an email or service type flag after attempting to load from the cache, flag as an error
      if (_email === undefined || _loginServiceType === undefined) {
        console.warn('AuthSSOProvider - loadSSOConfigAndEmail - WARNING: missing email and/or service loginServiceType')
        throw new Error('Missing details')
      }

      // if we have an email & service type, but no login service config, query the check-email api endpoint to get it
      if (_email !== undefined && _loginServiceType !== undefined && _loginService === undefined) {
        console.log('AuthSSOProvider - loadSSOConfigAndEmail - _email:', _email, ' _loginServiceType:', _loginServiceType)

        // // update the email state var if it was loaded from the cache above
        // if (this.state.userEmail !== _email) {
        //   await new Promise((resolve) => this.setState({ userEmail: _email }, () => resolve(this.state)))
        // }

        // query the check-email api endpoint to get the login service details
        // ..checking the service type matches the cached one before we redirected away to be sure its not from a different sso provider
        // ..(incase something happened to be edited by an admin mid-way through this users sso login process)
        const lookupLoginService = await this.props.authContext.actions.emailLoginLookup(_email)
        const lookupLoginServiceType = lookupLoginService.type // lookupLoginService.type === AuthLoginServiceType.NewUser ? (lookupLoginService.ssoConfig?.type ?? lookupLoginService.type) : lookupLoginService.type // #990 auth ux changes - removed `AuthLoginServiceType.NewUser` so this should no longer be needed (temp left in for future reference until we're sure its ok to fully remove)
        console.log('AuthSSOProvider - loadSSOConfigAndEmail - lookupLoginService(api):', lookupLoginService, ' lookupLoginServiceType:', lookupLoginServiceType)
        if (lookupLoginService && lookupLoginServiceType === _loginServiceType) {
          _loginService = lookupLoginService
        } else {
          console.warn('AuthSSOProvider - loadSSOConfigAndEmail - WARNING: lookupLoginService from api does not match the cached loginService - lookupLoginService:', lookupLoginService.type, ' !== _loginServiceType: ', _loginServiceType)
          throw new Error('Failed to load login service details. Type mismatch')
        }
      }

      // we should have all the needed fields to continue with the sso login process now...
      if (_email !== undefined && _loginServiceType !== undefined && _loginService !== undefined) {
        console.log('AuthSSOProvider - loadSSOConfigAndEmail - OK TO CONTINUE - _email:', _email, ' _loginServiceType:', _loginServiceType, ' _loginService:', _loginService)
        await new Promise((resolve) => this.setState({
          userEmail: _email,
          ssoServiceType: _loginServiceType as number,
          ssoServiceConfig: _loginService!.ssoConfig,
          isLoadingConfig: false
        }, () => resolve(this.state)))
      } else {
        // if we reach here & still don't have all the needed fields, throw an error
        throw new Error('Failed to load login service details.')
      }
    } catch (error) {
      console.error('AuthSSOProvider - loadSSOConfigAndEmail - error:', error)
      await new Promise((resolve) => this.setState({ isLoadingConfig: false, ssoError: error }, () => resolve(this.state)))
    }
  }

  // -------

  // initialises the specified sso service provider lib instance (so we can make client side calls to it)
  setupSSOService = async (ssoServiceType: AuthLoginServiceSSOType, ssoConfig: IAuthLoginServiceSSOConfig, email?: string, register?: boolean) => {
    console.log('AuthSSOProvider - setupSSOService - ssoServiceType:', ssoServiceType, ' ssoConfig:', ssoConfig, ' email:', email, ' register:', register)
    // halt if provider is already set (only allow one to be initalised for a given session, at least with current basic handling)
    if (this.state.isInitialised || this.state.isInitialising) {
      console.log('AuthSSOProvider - setupSSOService - ALREADY Initialised/Initialising - isInitialised:', this.state.isInitialised, ' isInitialising:', this.state.isInitialising)
      // TODO: check & log if the providerType is different to the one already initalised?
      return
    }
    console.log('AuthSSOProvider - setupSSOService (start) - ssoServiceType:', ssoServiceType)
    try {
      await new Promise((resolve) => this.setState({ isInitialised: false, isInitialising: true }, () => resolve(this.state))) // this.setState({ isInitialised: false, isInitialising: true })
      await new Promise(resolve => setTimeout(resolve, 1000))
      switch (ssoServiceType) {
        case AuthLoginServiceSSOType.SSOOktaOIDC:
          await this._setupSSOServiceOktaOIDC(ssoConfig, email, register)
          break
        case AuthLoginServiceSSOType.SSOOktaSAML:
          await this._setupSSOServiceOktaSAML(ssoConfig, email, register)
          break
        case AuthLoginServiceSSOType.SSOAuth0:
          await this._setupSSOServiceAuth0(ssoConfig, email)
          break
        default:
          throw new Error('Invalid Auth Proivder')
      }
      // TODO: is this still needed, if so note why...
      await new Promise(resolve => setTimeout(resolve, 1000))

      // TESTING: also check if we're already authenticated with the sso provider here
      // NB: from a partial sso login previously - if you logged into the sso form ok but it wasn't processed client side..
      //     ..& we instead re-started the login process again, this could/should then trigger?..
      //     ..in theory only happens in debug mode, but need to check if certain error conditions could also trigger it too?
      const isAuthenticated = await this.isAuthenticated()
      console.log('AuthSSOProvider - setupSSOService - isAuthenticated:', isAuthenticated, ' ssoServiceType:', ssoServiceType)
      if (isAuthenticated) {
        switch (ssoServiceType) {
          case AuthLoginServiceSSOType.SSOOktaOIDC: {
            console.log('AuthSSOProvider - setupSSOService - getUser...')
            const user = await this.state.okta?.getUser()
            console.log('AuthSSOProvider - setupSSOService - user:', user)
            await new Promise((resolve) => this.setState({ oktaUser: user, isAuthenticated: !!user }, () => resolve(this.state)))
            break
          }
          case AuthLoginServiceSSOType.SSOOktaSAML: {
            // TODO: can/should we do anything for SAML here?
            break
          }
          case AuthLoginServiceSSOType.SSOAuth0: {
            console.log('AuthSSOProvider - setupSSOService - getUser...')
            const user = await this.state.auth0?.getUser()
            console.log('AuthSSOProvider - setupSSOService - user:', user)
            await new Promise((resolve) => this.setState({ auth0User: user, isAuthenticated: !!user }, () => resolve(this.state)))
            break
          }
          default:
        }
      }
      // NB: moved the isInitialised/isInitialising update here AFTER the getUser call above has complete (otherwise the UI would hide the loading indicator a few seconds before we've complete & we were left with a blank screen for that time)
      // NB: wait for setState to complete before returning so we know all vars are ready for use (could otherwise flip these from state to ref vars?)
      await new Promise((resolve) => this.setState({ isInitialised: true, isInitialising: false }, () => resolve(this.state)))
      console.log('AuthSSOProvider - setupSSOService - this.state.isInitialised(after):', this.state.isInitialised)
    } catch (error) {
      console.error('AuthSSOProvider - setupSSOService - error:', error)
      // NB: wait for setState to complete before returning so we know all vars are ready for use (could otherwise flip these from state to ref vars?)
      await new Promise((resolve) => this.setState({ isInitialised: false, isInitialising: false, ssoError: error }, () => resolve(this.state)))
      throw error
    }
    console.log('AuthSSOProvider - setupSSOService (end)')
  }

  // -------

  // main entry point for the SSO login process
  // NB: the email arg is optional as callbacks from the sso provider won't have it, so we need to load it from the cache instead (& error out if the cache is empty, or doesn't match the logged in user)
  // UPDATE: the `submitAccessToken` arg is now somewhat ignored for okta logins (see the notes above the `loginWithSSOToken` call for the okta login flow further down for more details)
  // TODO: remove the `submitAccessToken` arg - is it actually needed anymore?
  setupSSOServiceAndLogin = async (ssoServiceType: AuthLoginServiceSSOType, ssoConfig: IAuthLoginServiceSSOConfig, _email?: string, isCallback: boolean = false, submitAccessToken: boolean = false, register?: boolean): Promise<void> => {
    console.log('AuthSSOProvider - setupSSOServiceAndLogin (start) - ssoServiceType:', ssoServiceType, ' ssoConfig:', ssoConfig, ' _email:', _email, ' isCallback:', isCallback, ' submitAccessToken:', submitAccessToken, ' register:', register)
    try {
      // make sure we have an email, if its a callback (or its just not set?) load it from the cache
      // if its not a callback, cache the email so its available when the callback returns us here
      // TODO: should we only need to load from the cache if its a callback, if so enforce that & error if its not set otherwise?
      let email = _email
      if (!isCallback && _email) { // && !submitAccessToken
        // save the email to the cache so we can re-call it from the callback redirect from the sso login page
        this.saveAuthSSOCache(ssoServiceType, _email)
      } else if (!email) {
        // attempt to load the email from the cache
        email = this.getAuthSSOCache()?.email
        await new Promise((resolve) => this.setState({ cachedEmail: email }, () => resolve(this.state)))
      }
      console.log('AuthSSOProvider - setupSSOServiceAndLogin - email:', email)
      if (!email) throw new Error('Email is Required')

      // note the email & setup the sso provider so we can query & use it
      await new Promise((resolve) => this.setState({ userEmail: email }, () => resolve(this.state)))
      console.log('AuthSSOProvider - setupSSOServiceAndLogin - setupSSOService (start)...')
      await this.setupSSOService(ssoServiceType, ssoConfig, email, register)
      console.log('AuthSSOProvider - setupSSOServiceAndLogin - setupSSOService (end)...')
      await new Promise((resolve) => this.setState({ isRunning: true }, () => resolve(this.state)))

      // check if we're authenticated with the sso provider
      let isAuthenticated = await this.isAuthenticated()
      console.log('AuthSSOProvider - setupSSOServiceAndLogin - isAuthenticated:', isAuthenticated, ' isCallback:', isCallback)

      let authSSOUser: AuthSSOUser | undefined
      if (isAuthenticated) {
        switch (ssoServiceType) {
          case AuthLoginServiceSSOType.SSOOktaOIDC: authSSOUser = await this.oktaGetUser(); break
          case AuthLoginServiceSSOType.SSOOktaSAML:
            // TODO: can/should we do anything for SAML here? we can't get the user details like we can with OIDC
            break
          case AuthLoginServiceSSOType.SSOAuth0: authSSOUser = await this.auth0GetUser(); break
          default: throw new Error('Invalid Auth Proivder')
        }
      }
      console.log('AuthSSOProvider - setupSSOServiceAndLogin - authSSOUser:', authSSOUser)

      let authSSOOktaSAMLToken: string | undefined

      // TESTING: check if we're already authenticated with the sso provider (from a previous run), but its for a different email
      // TODO: sometimes `isCallback === true` when we're not on an actual callback (but we are at `/login/sso` with no args, after a previous cached okta user is loaded??)
      if (isAuthenticated && !isCallback) {
        const authSSOEmail = authSSOUser?.getEmail()
        if (!authSSOEmail) {
          console.log('AuthSSOProvider - setupSSOServiceAndLogin - isAuthenticated + !isCallback - !authSSOEmail')
          // TODO: ???
        } else if (authSSOEmail !== email) {
          console.log('AuthSSOProvider - setupSSOServiceAndLogin - isAuthenticated + !isCallback - authSSOEmail !== email - authSSOEmail:', authSSOEmail, ' email:', email)
          // TESTING: if we're authenticated with the sso provider but its for a different email, logout so we can re-login with the correct one
          // TODO: this doesn't seem to work when called here, but does if triggered manually via a button, maybe needs the page to be reloaded after its triggered? or time for state vars to update? (or similar)
          await this.logoutSSOProvider()
          isAuthenticated = false
          await new Promise((resolve) => setTimeout(resolve, 1000)) // TESTING: wait a bit to see if the logout has time to complete before continuing?
          // TODO: with the pause/timeout added, we seem to redirect back to the login page after, clearing any progress made so far (& moving us away from the registration if we were on that path)
          // TODO: but it does clear/reset the okta cache, so we can login/register with a different email if we try again (where-as without the pause/timeout the cache wasn't cleared & so we'd always end up back here with the same incorrect email)
          // TODO: find a way to run the above (perhaps before this function is ever called) without triggering the redirect or in a way that reloads the same page after so we can continue?
          // TODO: think the logout has a redirect set on the okta admin side?
        }
      }

      let isRedirectingToSSOLogin = false
      if (!isAuthenticated && !isCallback) {
        // DEBUG ONLY: if we 'logout' of the SSO provider it should clear the providers token cache & force the user to see/perform the full login steps (including email entry etc.)
        // UPDATE: NOT currently working here (for auth0 at least), only the `...POST_LOGIN` one works, likely as `checkSession` or similar may need to be run (for auth0) before it has the needed data to then logout? disabling this one for now & relying on the post login one
        // if (COMPANY_LOGIN_SERVICE_SSO_WIPE_TOKEN_PRE_LOGIN) {
        //   await this.logoutSSOProvider()
        // }
        console.log('AuthSSOProvider - setupSSOServiceAndLogin - !isAuthenticated && !isCallback - trigger SSO redirect...')
        // trigger the SSO login process
        // NB: these will redirect away from our page/site & then redirect back to the callback route once complete
        switch (ssoServiceType) {
          case AuthLoginServiceSSOType.SSOOktaOIDC:
            isRedirectingToSSOLogin = true
            await this.oktaOIDCSignInWithRedirect(email)
            break
          case AuthLoginServiceSSOType.SSOOktaSAML:
            isRedirectingToSSOLogin = true
            await this.oktaSAMLSignInWithRedirect(email, ssoServiceType, ssoConfig.companyServiceId)
            break
          case AuthLoginServiceSSOType.SSOAuth0:
            isRedirectingToSSOLogin = true
            await this.auth0LoginWithRedirect()
            break
          default:
            throw new Error('Invalid Auth Proivder')
        }
      } else if (!isAuthenticated && isCallback) {
        console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback...')
        switch (ssoServiceType) {
          case AuthLoginServiceSSOType.SSOOktaOIDC: {
            // await new Promise((resolve) => this.setState({ isRunning: true }, () => resolve(this.state)))
            const hasAuthParams = this.oktaOIDCHasAuthParams()
            console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Okta OIDC) - hasAuthParams:', hasAuthParams)
            if (hasAuthParams) {
              const redirectResult = await this.oktaOIDCHandleRedirect()
              console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Okta OIDC) - hasAuthParams - redirectResult:', redirectResult)
              isAuthenticated = await this.isAuthenticated()
              console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Okta OIDC) - hasAuthParams - isAuthenticated:', isAuthenticated, ' email(cached):', email, ' authSSOUser:', authSSOUser)
              // NB: continued further down (if we're now authenticated with the sso service)...
            } else {
              console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Okta OIDC) - !hasAuthParams - checkSession...')
              // TESTING: existing okta auth session? (shouldn't happen with our current usage of their lib with our api auth?)
              isAuthenticated = await this.oktaCheckSession()
              // TODO: check/update the isAuthenticate status here so code further down can run if needed?
              console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Okta OIDC) - !hasAuthParams - isAuthenticated:', isAuthenticated)
              // TODO: ...
            }
            // await new Promise((resolve) => this.setState({ isRunning: false }, () => resolve(this.state)))
            break
          }
          case AuthLoginServiceSSOType.SSOOktaSAML: {
            console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Okta SAML) - TODO <<<<')
            const hasAuthParams = this.oktaSAMLHasAuthParams()
            console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Okta SAML) - hasAuthParams:', hasAuthParams)
            if (hasAuthParams) {
              const redirectResult = await this.oktaSAMLHandleRedirect()
              console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Okta SAML) - hasAuthParams - redirectResult:', redirectResult)
              const oktaToken = redirectResult.token
              console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Okta SAML) - hasAuthParams - oktaToken:', oktaToken)
              isAuthenticated = true // TESTING: assume we're authenticated if we got a token back (we should be if the sso login process completed successfully)
              authSSOOktaSAMLToken = oktaToken
              console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Okta SAML) - !hasAuthParams - isAuthenticated:', isAuthenticated, ' authSSOOktaSAMLToken:', authSSOOktaSAMLToken)
            } else {
              // NB: with Okta SAML auth we don't currently have a way to check if a session is already active (okta side) like we do with OIDC
              console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Okta SAML) - !hasAuthParams - isAuthenticated:', isAuthenticated)
            }
            break
          }
          case AuthLoginServiceSSOType.SSOAuth0: {
            // await new Promise((resolve) => this.setState({ isRunning: true }, () => resolve(this.state)))
            const hasAuthParams = this.auth0HasAuthParams()
            console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Auth0) - hasAuthParams:', hasAuthParams)
            if (hasAuthParams) {
              const redirectResult = await this.auth0HandleRedirectCallback()
              console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Auth0) - redirectResult:', redirectResult)
              isAuthenticated = await this.isAuthenticated()
              console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Auth0) - isAuthenticated:', isAuthenticated)
              // NB: continued further down (if we're now authenticated with the sso service)...
            } else {
              console.log('AuthSSOProvider - setupSSOServiceAndLogin - isCallback (Auth0) - checkSession...')
              // TESTING: existing auth0 auth session? (shouldn't happen with our current usage of their lib with our api auth?)
              await this.auth0CheckSession()
              // TODO: check/update the isAuthenticate status here so code further down can run if needed?
              // TODO: ...
            }
            // await new Promise((resolve) => this.setState({ isRunning: false }, () => resolve(this.state)))
            break
          }
          default:
            throw new Error('Invalid Auth Proivder')
        }
      } else {
        console.log('AuthSSOProvider - setupSSOServiceAndLogin - isAuthenticated...')
        // NB: do nothing, its handled below...
      }

      // TESTING: regardless if we're on the callback or not, if we're authenticated by the time we reach here - attempt to process the sso login
      // UPDATE: only do so if we're not registering (we need to get more details when registering, & then call the register api endpoint instead)
      if (isAuthenticated && register !== true) {
        switch (ssoServiceType) {
          case AuthLoginServiceSSOType.SSOOktaOIDC: {
            // TESTING: check we have both a valid okta user & their email matches the cached email they entered when starting the login process, halt if not (or none was cached for some reason)
            // TODO: make these error messages more user friendly
            const oktaUser = await this.state.okta?.getUser()
            console.log('AuthSSOProvider - setupSSOServiceAndLogin - isAuthenticated + !register (Okta OIDC) - oktaUser:', oktaUser)
            const cachedEmail = this.getAuthSSOCache()?.email
            if (!oktaUser) {
              throw new Error('NO SSO USER')
            } else if (!oktaUser.email) {
              throw new Error('NO SSO USER EMAIL')
            } else if (!cachedEmail) {
              throw new Error('NO CACHED EMAIL')
            } else if (cachedEmail?.toLowerCase() !== oktaUser.email?.toLowerCase()) {
              // throw new Error('EMAIL MISMATCH')
              throw new Error('Failed to login. Changing the email address on the OKTA page is forbidden. Please ask your administrator if a change of email address is required.')
            }
            const token = await this.oktaOIDCGetAccessToken()
            console.log('AuthSSOProvider - setupSSOServiceAndLogin - isAuthenticated + !register (Okta) - token:', token, ' submitAccessToken:', submitAccessToken)
            if (token) {
              await new Promise((resolve) => this.setState({ ssoAccessToken: token }, () => resolve(this.state)))
              // UPDATE: skipping the `submitAccessToken` check here - if we have a valid token & not registering a new user...
              // ...we should submit regardless, otherwise if you have a cached okta session & re-login with it...
              // ...it skips the okta login page & so we don't get the redirect, which is what was being used to...
              // ...set the `submitAccessToken` value in the first place, & so when that happens you'd end up stuck on the login page with no way to proceed)
              // if (submitAccessToken) {
              // login to our api server with the auth0 access token
              // NB: careful of any state updates that might run after this, as the login will navigate away from the current route & at least with the current usage of this provider, will unmount/dealloc this class & so trigger errors related to state updates after that
              await this.props.authContext.actions.loginWithSSOToken(oktaUser.email, AuthLoginServiceSSOType.SSOOktaOIDC, ssoConfig.companyServiceId, token)
              // DEBUG ONLY: if we 'logout' of the SSO provider it should clear the providers token cache & force the user to see/perform the full login steps (including email entry etc.) the next time they try to login via this SSO provider
              if (COMPANY_LOGIN_SERVICE_SSO_WIPE_TOKEN_POST_LOGIN) {
                await this.logoutSSOProvider()
              }
              // }
            }
            break
          }
          case AuthLoginServiceSSOType.SSOOktaSAML: {
            console.log('AuthSSOProvider - setupSSOServiceAndLogin - isAuthenticated + !register (Okta SAML) - TODO: <<<<')
            const token = authSSOOktaSAMLToken
            if (token) {
              console.log('AuthSSOProvider - setupSSOServiceAndLogin - isAuthenticated + !register (Okta SAML) - token:', token, ' email:', email, ' companyServiceId:', ssoConfig.companyServiceId)
              await new Promise((resolve) => this.setState({ ssoAccessToken: token }, () => resolve(this.state)))
              // login to our api server with the auth0 access token
              // NB: careful of any state updates that might run after this, as the login will navigate away from the current route & at least with the current usage of this provider, will unmount/dealloc this class & so trigger errors related to state updates after that
              await this.props.authContext.actions.loginWithSSOToken(email, AuthLoginServiceSSOType.SSOOktaSAML, ssoConfig.companyServiceId, token)
              // DEBUG ONLY: if we 'logout' of the SSO provider it should clear the providers token cache & force the user to see/perform the full login steps (including email entry etc.) the next time they try to login via this SSO provider
              if (COMPANY_LOGIN_SERVICE_SSO_WIPE_TOKEN_POST_LOGIN) {
                // TODO: anything to do with SAML here?
              }
            }
            break
          }
          case AuthLoginServiceSSOType.SSOAuth0: {
            // TESTING: check we have both a valid auth0 user & their email matches the cached email they entered when starting the login process, halt if not (or none was cached for some reason)
            // TODO: make these error messages more user friendly
            const auth0User = await this.state.auth0?.getUser()
            const cachedEmail = this.getAuthSSOCache()?.email
            if (!auth0User) {
              throw new Error('NO AUTH0 USER')
            } else if (!auth0User.email) {
              throw new Error('NO AUTH0 USER EMAIL')
            } else if (!cachedEmail) {
              throw new Error('NO CACHED EMAIL')
            } else if (cachedEmail !== auth0User.email) {
              throw new Error('EMAIL MISMATCH')
            }
            const token = await this.auth0GetTokenSilently({ detailedResponse: true })
            console.log('AuthSSOProvider - setupSSOServiceAndLogin - isAuthenticated + !register (Auth0) - token:', token, ' submitAccessToken:', submitAccessToken)
            if (token) {
              await new Promise((resolve) => this.setState({ ssoAccessToken: token.access_token }, () => resolve(this.state)))
              // TODO: skip the `submitAccessToken` for auth0 logins? (see the okta notes above for why, & check if it also applies to auth0 handling??)
              if (submitAccessToken) {
                // login to our api server with the auth0 access token
                // NB: careful of any state updates that might run after this, as the login will navigate away from the current route & at least with the current usage of this provider, will unmount/dealloc this class & so trigger errors related to state updates after that
                await this.props.authContext.actions.loginWithSSOToken(auth0User.email!, AuthLoginServiceSSOType.SSOAuth0, ssoConfig.companyServiceId, token.access_token)
                // DEBUG ONLY: if we 'logout' of the SSO provider it should clear the providers token cache & force the user to see/perform the full login steps (including email entry etc.) the next time they try to login via this SSO provider
                if (COMPANY_LOGIN_SERVICE_SSO_WIPE_TOKEN_POST_LOGIN) {
                  await this.logoutSSOProvider()
                }
              }
            }
            break
          }
          default:
            throw new Error('Invalid Auth Proivder')
        }
      } else if (isAuthenticated && register === true) {
        console.log('AuthSSOProvider - setupSSOServiceAndLogin - isAuthenticated + register...')
        // TODO: handle registering via SSO (need to get more details from the user before proceeding? phone number etc. cache the SSO token & email & flip back to allow the register page to get the needed input & trigger the register call once done/ready?)
        switch (ssoServiceType) {
          case AuthLoginServiceSSOType.SSOOktaOIDC: {
            // TESTING: check we have both a valid okta user & their email matches the cached email they entered when starting the register process, halt if not (or none was cached for some reason)
            // TODO: make these error messages more user friendly
            const oktaUser = await this.state.okta?.getUser()
            console.log('AuthSSOProvider - setupSSOServiceAndLogin - isAuthenticated + register - oktaUser:', oktaUser)
            const cachedEmail = this.getAuthSSOCache()?.email
            if (!oktaUser) {
              throw new Error('NO SSO USER')
            } else if (!oktaUser.email) {
              throw new Error('NO SSO USER EMAIL')
            } else if (!cachedEmail) {
              throw new Error('NO CACHED EMAIL')
            } else if (cachedEmail?.toLowerCase() !== oktaUser.email?.toLowerCase()) {
              // throw new Error('EMAIL MISMATCH')
              throw new Error('Failed to register. Changing the email address on the OKTA page is forbidden. Please ask your administrator if a change of email address is required.')
            }
            const token = await this.oktaOIDCGetAccessToken()
            console.log('AuthSSOProvider - setupSSOServiceAndLogin - isAuthenticated + register (Okta) - token:', token)
            if (token) {
              // NB: the `oktaUser` state var is already set before getting here (or should be)
              await new Promise((resolve) => this.setState({ ssoAccessToken: token }, () => resolve(this.state)))
              // if (submitAccessToken) {
              //   // login to our api server with the auth0 access token
              //   // NB: careful of any state updates that might run after this, as the login will navigate away from the current route & at least with the current usage of this provider, will unmount/dealloc this class & so trigger errors related to state updates after that
              //   await this.props.authContext.actions.loginWithSSOToken(oktaUser.email, AuthLoginServiceSSOType.SSOOkta, token)
              //   // DEBUG ONLY: if we 'logout' of the SSO provider it should clear the providers token cache & force the user to see/perform the full login steps (including email entry etc.) the next time they try to login via this SSO provider
              //   if (COMPANY_LOGIN_SERVICE_SSO_WIPE_TOKEN_POST_LOGIN) {
              //     await this.logout()
              //   }
              // }
              // NB: NOT doing anything here for now, just setting the state vars & letting the register page handle the rest
            }
            break
          }
          case AuthLoginServiceSSOType.SSOOktaSAML: {
            console.log('AuthSSOProvider - setupSSOServiceAndLogin - isAuthenticated + register (Okta SAML) - TODO: <<<<')
            // TODO: <<<<
            break
          }
          // TODO: add support for auth0 based registrations here
          // case AuthLoginServiceSSOType.SSOAuth0: {
          // }
          default:
            throw new Error('Invalid Auth Proivder')
        }
      } else {
        console.log('AuthSSOProvider - setupSSOServiceAndLogin - !isAuthenticated (do nothing here?) - isAuthenticated:', isAuthenticated, ' register:', register)
      }

      // UPDATE: DON'T clear the `isRunning` flag if we're redirecting to the SSO login page (as we need to wait for the redirect to complete/trigger before we can clear it, & once it triggers the page callback it'll load a new page, which will set the relevant state values fresh)
      if (this._isMounted && !isRedirectingToSSOLogin) await new Promise((resolve) => this.setState({ isRunning: false }, () => resolve(this.state)))
    } catch (error) {
      console.error('AuthSSOProvider - setupSSOServiceAndLogin - error:', error)
      // NB: currently NOT rethrowing the error & instead logging it to a state var for the UI component(s) to check for & display as appropriate
      await new Promise((resolve) => this.setState({ isRunning: false, ssoError: error }, () => resolve(this.state))) // this.setState({ isRunning: false, providerError: error })
      // TESTING HERE: if an error throws, clear the cached email, the user should restart the process from the beginning (TODO: for all errors?)
      this.clearAuthSSOCache()
    }
    console.log('AuthSSOProvider - setupSSOServiceAndLogin (end)')
  }

  // -------

  // NB: calling code catches/handles errors
  _setupSSOServiceOktaOIDC = async (ssoConfig: IAuthLoginServiceSSOConfig, email?: string, register?: boolean) => {
    console.log('AuthSSOProvider - _setupSSOServiceOktaOIDC - ssoConfig:', ssoConfig, ' email:', email, ' register:', register)
    if (this.state.okta) return

    // basic check to make sure all required fields are present
    // TODO: check/verify the ssoConfig (or can we catch & throw an error if it's invalid when we init the OktaAuth instance?)
    if (!ssoConfig.clientId || !ssoConfig.issuer) {
      throw new Error('Invalid Okta Config')
    }

    // config & setup the Okta client
    // ref: https://github.com/okta/okta-auth-js
    const _oktaConfig = COMPANY_LOGIN_SERVICE_OKTA_OIDC_CONFIG
    const _oktaConfigOpts: OktaAuthOptions = {
      issuer: ssoConfig.issuer,
      clientId: ssoConfig.clientId,
      redirectUri: window.location.origin + (register ? _oktaConfig.registerCallbackUrlPath : _oktaConfig.loginCallbackUrlPath)
    }
    console.log('AuthSSOProvider - _setupSSOServiceOktaOIDC (end) - _oktaConfigOpts:', _oktaConfigOpts)
    // NB: see the `signInWithRedirect` usage for pre-filling the email/username field via the `loginHint` option
    const _okta = new OktaAuth(_oktaConfigOpts)
    // NB: wait for setState to complete before returning so we know all vars are ready for use (could otherwise flip these from state to ref vars?)
    await new Promise((resolve) => this.setState({ okta: _okta, ssoServiceType: AuthLoginServiceSSOType.SSOOktaOIDC, ssoServiceConfig: ssoConfig }, () => resolve(this.state)))
    console.log('AuthSSOProvider - _setupSSOServiceOktaOIDC (end) - this.state.ssoServiceType:', this.state.ssoServiceType)
  }

  _setupSSOServiceOktaSAML = async (ssoConfig: IAuthLoginServiceSSOConfig, email?: string, register?: boolean) => {
    console.log('AuthSSOProvider - _setupSSOServiceOktaSAML - ssoConfig:', ssoConfig, ' email:', email, ' register:', register)
    if (this.state.okta) return // TODO: should we reuse the `okta` var, or create a specific one for SAML usage (if it can even use the okta lib??)
    //
    console.log('AuthSSOProvider - _setupSSOServiceOktaSAML...')
    // TODO: <<<<
  }

  // -------

  // NB: calling code catches/handles errors
  _setupSSOServiceAuth0 = async (ssoConfig: IAuthLoginServiceSSOConfig, email?: string) => {
    console.log('AuthSSOProvider - _setupSSOServiceAuth0 - ssoConfig:', ssoConfig, ' email:', email)
    if (this.state.auth0) return

    // TODO: update to use the ssoConfig details <<<<
    // TODO: check/verify the ssoConfig (or can we catch & throw an error if it's invalid when we init the OktaAuth instance?)

    // config & setup the Auth0 client
    // ref: https://www.npmjs.com/package/@auth0/auth0-spa-js (readme/notes)
    const _auth0Config = COMPANY_LOGIN_SERVICE_AUTH0_CONFIG
    const _auth0ConfigOpts: Auth0ClientOptions = {
      domain: _auth0Config.domain,
      clientId: _auth0Config.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin + _auth0Config.callbackUrlPath,
        audience: _auth0Config.audience
      }
    }
    // pre-fill the email field if one is provided
    if (email) _auth0ConfigOpts.authorizationParams = { ..._auth0ConfigOpts.authorizationParams, login_hint: email }
    // init the client
    const _auth0 = new Auth0Client(_auth0ConfigOpts)

    // NB: wait for setState to complete before returning so we know all vars are ready for use (could otherwise flip these from state to ref vars?)
    await new Promise((resolve) => this.setState({ auth0: _auth0, ssoServiceType: AuthLoginServiceSSOType.SSOAuth0, ssoServiceConfig: ssoConfig }, () => resolve(this.state)))
    console.log('AuthSSOProvider - _setupSSOServiceAuth0 (end) - this.state.ssoServiceType:', this.state.ssoServiceType)

    // try {
    //   await _auth0.getTokenSilently()
    // } catch (error) {
    //   console.error('AuthSSOProvider - loadAuth0 - error:', error)
    //   if (error.error !== 'login_required') {
    //     throw error // TODO: ???? do we only want to do this if we think we should have an active auth0 auth session & don't currently?
    //   }
    // }
  }

  // -------

  isInitalised = async (): Promise<boolean> => {
    switch (this.state.ssoServiceType) {
      case AuthLoginServiceSSOType.SSOOktaOIDC: return this.state.okta !== undefined
      // TODO: SSOOktaSAML
      case AuthLoginServiceSSOType.SSOAuth0: return this.state.auth0 !== undefined
      default: return false
    }
  }

  isAuthenticated = async (): Promise<boolean> => {
    switch (this.state.ssoServiceType) {
      case AuthLoginServiceSSOType.SSOOktaOIDC: return this.state.okta?.isAuthenticated() ?? false
      case AuthLoginServiceSSOType.SSOOktaSAML: return false // TODO: (or should we always consider the user logged out okta side with SAML?) <<<<
      case AuthLoginServiceSSOType.SSOAuth0: return await this.state.auth0?.isAuthenticated() ?? false
      default: return false
    }
  }

  logoutSSOProvider = async (options?: SignoutOptions | LogoutOptions): Promise<void> => {
    console.log('AuthSSOProvider - logoutSSOProvider')
    switch (this.state.ssoServiceType) {
      case AuthLoginServiceSSOType.SSOOktaOIDC: await this.oktaSignOut(options as SignoutOptions); break
      // TODO: SSOOktaSAML
      case AuthLoginServiceSSOType.SSOAuth0: return await this.auth0Logout(options as LogoutOptions)
      default:
    }
  }

  // -------

  getSSOProviderName = (loginService?: IAuthLoginService): string | undefined => {
    switch (loginService?.type) {
      case AuthLoginServiceType.SSOOktaOIDC: return 'Okta'
      case AuthLoginServiceType.SSOOktaSAML: return 'Okta'
      case AuthLoginServiceType.SSOAuth0: return 'Auth0'
    }
    return undefined
  }

  // -------

  oktsIsLoginRedirect = (): boolean => {
    return this.state.okta?.isLoginRedirect() ?? false
  }

  oktaOIDCSignInWithRedirect = async (email?: string, opts?: SigninWithRedirectOptions): Promise<void> => {
    let _opts: SigninWithRedirectOptions | undefined = opts
    // pre-fill the email field if one is provided
    // refs:
    //  - https://github.com/okta/okta-auth-js/issues/214#issuecomment-523227469
    //  - https://github.com/okta/okta-auth-js#authorize-options
    if ((email || this.state.userEmail) && (!opts || !opts.loginHint)) {
      if (!_opts) _opts = {}
      _opts.loginHint = email ?? this.state.userEmail
    }
    console.log('AuthSSOProvider - oktaOIDCSignInWithRedirect - _opts:', _opts, ' email:', email, ' this.state.userEmail:', this.state.userEmail)
    return await this.state.okta?.signInWithRedirect(_opts)
  }

  oktaOIDCHandleRedirect = async (originalUri?: string): Promise<void> => {
    try {
      console.log('AuthSSOProvider - oktaOIDCHandleRedirect - this.state.okta:', this.state.okta, ' window.location:', window.location)
      await this.state.okta?.handleRedirect(originalUri)
      const user = await this.state.okta?.getUser()
      console.log('AuthSSOProvider - oktaOIDCHandleRedirect - user:', user)
      await new Promise((resolve) => this.setState({ oktaUser: user, isAuthenticated: !!user }, () => resolve(this.state)))
      // TODO: return bool?
    } catch (error) {
      console.error('AuthSSOProvider - oktaOIDCHandleRedirect - error:', error)
      throw error
    }
  }

  // TESTING: okta specific checkSession equivilent (to auth0's)
  // NB: updated to return `_isAuthenticated` so calling code doesn't have to wait for state updates to propagate
  oktaCheckSession = async (): Promise<boolean> => {
    const _isAuthenticated = await this.isAuthenticated()
    console.log('AuthSSOProvider - oktaCheckSession - _isAuthenticated:', _isAuthenticated)
    if (_isAuthenticated) {
      const user = await this.state.okta?.getUser()
      console.log('AuthSSOProvider - oktaCheckSession - user:', user)
      await new Promise((resolve) => this.setState({ oktaUser: user, isAuthenticated: !!user }, () => resolve(this.state)))
    }
    return _isAuthenticated
  }

  oktaGetUser = async (): Promise<AuthSSOUser | undefined> => {
    const oktaUser = await this.state.okta?.getUser()
    const authSSOUser = oktaUser ? new AuthSSOUserOkta(oktaUser) : undefined
    return authSSOUser
  }

  oktaOIDCGetAccessToken = async (updateState: boolean = false): Promise<string | undefined> => {
    const accessToken = this.state.okta?.getAccessToken()
    console.log('AuthSSOProvider - oktaOIDCGetAccessToken - accessToken:', accessToken)
    if (updateState) {
      await new Promise((resolve) => this.setState({ ssoAccessToken: accessToken }, () => resolve(this.state)))
    }
    return accessToken
  }

  // WARNING: when using chrome incognito mode - okta logout (& session querying) is broken!!!!
  // WARNING: unless a browser security setting is changed to something thats quite insecure (& wouldn't recommend if you use chrome for regular browsing!!)
  // WARNING: ref: https://support.okta.com/help/s/article/Okta-session-me-API-returns-404?language=en_US
  // WARNING: look out for a `<okta-domain>/api/v1/session/me` error log showing a `404 Not Found` error when attempting to logout (potentially automatically as part of an okta login), or checking the okta user session?
  oktaSignOut = async (_opts?: SignoutOptions): Promise<boolean> => {
    // set default okta logout options & merge in any passed in ones
    // const _opts: SignoutOptions = {
    //   revokeAccessToken: true,
    //   revokeRefreshToken: true,
    //   clearTokensBeforeRedirect: true,
    //   // postLogoutRedirectUri: undefined,
    //   ...opts
    // }
    // return await this.state.okta?.signOut(_opts) ?? false

    // TESTING:
    // ref: https://devforum.okta.com/t/logout-using-embedded-sign-in-widget-fails/20639
    // const _accessToken = await this.state.okta?.tokenManager.get('accessToken')
    // if (_accessToken) this.state.okta?.token.revoke(_accessToken)

    // TESTING: trying to fully clear okta sessions as re-logins seem to resume the old user session (even if we've logged out & cleared the cache etc.)
    // TODO: still not working? e.g: try logging in as one okta user, then logout & try & login as a different okta user
    // TODO: ..we seem to get auto logged into the previous okta user without seeing the okta login page again?
    // UPDATE: see the `session/me` 404 error warning notes above, this might be the cause of the general okta logout issues? retest all/any of these with that change applied or in non-incognito sessions to see if the below is no longer needed?
    console.log('AuthSSOProvider - oktaSignOut - this.state.okta?.session:', this.state.okta?.session)
    const sessionExists = await this.state.okta?.session.exists()
    console.log('AuthSSOProvider - oktaSignOut - sessionExists:', sessionExists)
    await this.state.okta?.revokeAccessToken()
    await this.state.okta?.revokeRefreshToken()
    if (sessionExists) await this.state.okta?.session.close()
    this.state.okta?.tokenManager.clear()
    this.state.okta?.clearStorage()
    // this.state.okta?.closeSession() // NB: not tried this yet, noticed it in some okta issue comments, might be worth a try in place of some/most/all of the above??
    return true
  }

  // NB: adapted from the `auth0HasAuthParams` version (currently seems to use the same query params/args)
  // TODO: double check if the okta callback url also can include an `error` query param/arg
  oktaOIDCHasAuthParams = (searchParams = window.location.search): boolean => {
    const CODE_RE = /[?&]code=[^&]+/
    const STATE_RE = /[?&]state=[^&]+/
    const ERROR_RE = /[?&]error=[^&]+/
    return (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) && STATE_RE.test(searchParams)
  }

  // -------

  oktaSAMLSignInWithRedirect = async (email: string, loginServiceId: number, companyServiceId: number): Promise<void> => {
    console.log('AuthSSOProvider - oktaSAMLSignInWithRedirect - email:', email, ' loginServiceId:', loginServiceId, ' companyServiceId:', companyServiceId)
    await this.props.authApi.loginForSSOLoginService(email, loginServiceId, companyServiceId)
    // TODO: return anything? <<<<
  }

  // NB: adapted from the `auth0HasAuthParams` version
  oktaSAMLHasAuthParams = (searchParams = window.location.search): boolean => {
    const TOKEN_RE = /[?&]okta_token=[^&]+/
    return TOKEN_RE.test(searchParams)
  }

  oktaSAMLHandleRedirect = async (): Promise<{ token: string }> => { // originalUri?: string
    try {
      console.log('AuthSSOProvider - oktaSAMLHandleRedirect - this.state.okta:', this.state.okta, ' window.location:', window.location)
      // parse the `okta_token` param from the url
      const urlParams = new URLSearchParams(window.location.search)
      const token = urlParams.get('okta_token')
      console.log('AuthSSOProvider - oktaSAMLHandleRedirect - token:', token)
      if (!token) {
        throw new Error('No Valid Token Found')
      }
      // TODO:
      // await new Promise((resolve) => this.setState({ oktaUser: user, isAuthenticated: !!user }, () => resolve(this.state)))
      // TODO: return bool?
      return { token } // NB: as we don't have a user object or session for SAML we return the parsed token directly
    } catch (error) {
      console.error('AuthSSOProvider - oktaSAMLHandleRedirect - error:', error)
      throw error
    }
  }

  // oktaSAMLGetAccessToken = async (updateState: boolean = false): Promise<string | undefined> => {
  // }

  // -------

  auth0LoginWithRedirect = async (options?: RedirectLoginOptions): Promise<void> => {
    console.log('AuthSSOProvider - auth0LoginWithRedirect')
    // TESTING: flag we're redirecting - NB: we DON'T reset it after as the callback will reload/return to this page & so everything is re-initialised
    await new Promise((resolve) => this.setState({ isRedirecting: true }, () => resolve(this.state)))
    return await this.state.auth0?.loginWithRedirect(options)
  }

  auth0HandleRedirectCallback = async (url?: string): Promise<RedirectLoginResult | undefined> => {
    console.log('AuthSSOProvider - auth0HandleRedirectCallback - url:', url)
    try {
      console.log('AuthSSOProvider - auth0HandleRedirectCallback - this.state.auth0:', this.state.auth0)
      // NB: may throw an 'invalid state' error if you try to reload/refresh the callback page after an internal `auth0-spa-js` cookie has been deleted
      // ref: https://community.auth0.com/t/invalid-state-on-reload-auth0-callback-url-using-auth0-spa-js-and-angular-8/36469/10
      // TODO: see suggestions at the end of the ref post for possible solutions if this ends up happening in normal usage
      // TODO: ..although should only happen for us in debug mode when we're not instantly dealing with the callback response when it returns
      const redirectResult = await this.state.auth0?.handleRedirectCallback(url)
      console.log('AuthSSOProvider - auth0HandleRedirectCallback - redirectResult:', redirectResult)
      const user = await this.state.auth0?.getUser()
      console.log('AuthSSOProvider - auth0HandleRedirectCallback - user:', user)
      await new Promise((resolve) => this.setState({ auth0User: user, isAuthenticated: !!user }, () => resolve(this.state)))
      return redirectResult
    } catch (error) {
      console.error('AuthSSOProvider - auth0HandleRedirectCallback - error:', error)
      throw error
    }
  }

  auth0CheckSession = async (options?: GetTokenSilentlyOptions): Promise<void> => {
    console.log('AuthSSOProvider - auth0CheckSession - options:', options)
    await this.state.auth0?.checkSession(options)
    const user = await this.state.auth0?.getUser()
    console.log('AuthSSOProvider - auth0CheckSession - user:', user)
    await new Promise((resolve) => this.setState({ auth0User: user, isAuthenticated: !!user }, () => resolve(this.state)))
  }

  auth0GetUser = async (): Promise<AuthSSOUser | undefined> => {
    const auth0User = await this.state.auth0?.getUser()
    const authSSOUser = auth0User ? new AuthSSOUserAuth0(auth0User) : undefined
    return authSSOUser
  }

  auth0GetTokenSilently = async (options: GetTokenSilentlyOptions & { detailedResponse: true }): Promise<GetTokenSilentlyVerboseResponse | undefined> => {
    return await this.state.auth0?.getTokenSilently(options)
  }

  auth0Logout = async (options?: LogoutOptions): Promise<void> => {
    return await this.state.auth0?.logout(options)
  }

  // ref: https://github.com/auth0/auth0-react/blob/40dbca51e13af6dbc5f080140914b209fe963660/src/utils.tsx
  auth0HasAuthParams = (searchParams = window.location.search): boolean => {
    const CODE_RE = /[?&]code=[^&]+/
    const STATE_RE = /[?&]state=[^&]+/
    const ERROR_RE = /[?&]error=[^&]+/
    return (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) && STATE_RE.test(searchParams)
  }

  // -------

  saveAuthSSOCache = (serviceType: AuthLoginServiceType, email: string): void => {
    console.log('AuthSSOProvider - saveAuthSSOCache')
    this.props.authApi.saveAuthSSOCache(serviceType, email)
  }

  getAuthSSOCache = (): { serviceType: AuthLoginServiceType, email: string } | undefined => {
    return this.props.authApi.getAuthSSOCache() // as { serviceType: AuthLoginServiceType, email: string } | undefined // NB: cast the `serviceType` field from `ServerAuthLoginServiceType` to `AuthSSOServiceType`
  }

  clearAuthSSOCache = (): void => {
    console.log('AuthSSOProvider - clearAuthSSOCache')
    return this.props.authApi.clearAuthSSOCache()
  }

  // -------

  actions: IAuthSSOActions = {
    // -------
    loadSSOConfigAndEmail: this.loadSSOConfigAndEmail,
    // -------
    setupSSOService: this.setupSSOService,
    setupSSOServiceAndLogin: this.setupSSOServiceAndLogin,
    // -------
    isAuthenticated: this.isAuthenticated,
    logoutSSOProvider: this.logoutSSOProvider,
    // -------
    getSSOProviderName: this.getSSOProviderName,
    // -------
    oktsIsLoginRedirect: this.oktsIsLoginRedirect,
    oktaOIDCSignInWithRedirect: this.oktaOIDCSignInWithRedirect,
    oktaOIDCHandleRedirect: this.oktaOIDCHandleRedirect,
    oktaCheckSession: this.oktaCheckSession,
    oktaOIDCGetAccessToken: this.oktaOIDCGetAccessToken,
    // -------
    oktaSAMLSignInWithRedirect: this.oktaSAMLSignInWithRedirect,
    // -------
    auth0LoginWithRedirect: this.auth0LoginWithRedirect,
    auth0HandleRedirectCallback: this.auth0HandleRedirectCallback,
    auth0CheckSession: this.auth0CheckSession,
    auth0GetTokenSilently: this.auth0GetTokenSilently,
    auth0HasAuthParams: this.auth0HasAuthParams,
    // -------
    saveAuthSSOCache: this.saveAuthSSOCache,
    getAuthSSOCache: this.getAuthSSOCache
    // -------
  }

  render () {
    return (
      <AuthSSOContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for IAuthStore */ }}
      >
        {this.props.children}
      </AuthSSOContext.Provider>
    )
  }
}

const withAuthSSOContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withAuthSSOContextHOC = (props: any) => (
    <AuthSSOContext.Consumer>
      {(authSSOContext) => {
        if (authSSOContext === null) {
          throw new Error('AuthSSOConsumer must be used within an AuthSSOProvider')
        }
        // console.log('withAuthSSOContext - render - AuthSSOContext.Consumer - authSSOContext.store: ', authSSOContext.store)
        return (<Component {...props} {...{ authSSOContext: authSSOContext }} />)
      }}
    </AuthSSOContext.Consumer>
  )
  return withAuthSSOContextHOC
}

// export { AuthSSOProvider }
const AuthSSOProviderWithContext = withAuthContext(AuthSSOProvider)
export { AuthSSOProviderWithContext as AuthSSOProvider }

export { withAuthSSOContext }
