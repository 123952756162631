// https://en.wikipedia.org/wiki/List_of_UTC_offsets
export const UtcOffsetNames: { value: string, key: number }[] = [
  { key: -720, value: 'UTC-12:00' },
  { key: -660, value: 'UTC-11:00' },
  { key: -600, value: 'UTC-10:00' },
  { key: -570, value: 'UTC-09:30' },
  { key: -540, value: 'UTC-09:00' },
  { key: -480, value: 'UTC-08:00' },
  { key: -420, value: 'UTC-07:00' },
  { key: -360, value: 'UTC-06:00' },
  { key: -300, value: 'UTC-05:00' },
  { key: -240, value: 'UTC-04:00' },
  { key: -210, value: 'UTC-03:30' },
  { key: -180, value: 'UTC-03:00' },
  { key: -120, value: 'UTC-02:00' },
  { key: -60, value: 'UTC-01:00' },
  { key: 0, value: 'UTC+00:00' },
  { key: 60, value: 'UTC+01:00' },
  { key: 120, value: 'UTC+02:00' },
  { key: 180, value: 'UTC+03:00' },
  { key: 210, value: 'UTC+03:30' },
  { key: 240, value: 'UTC+04:00' },
  { key: 270, value: 'UTC+04:30' },
  { key: 300, value: 'UTC+05:00' },
  { key: 330, value: 'UTC+05:30' },
  { key: 345, value: 'UTC+05:45' },
  { key: 360, value: 'UTC+06:00' },
  { key: 390, value: 'UTC+06:30' },
  { key: 420, value: 'UTC+07:00' },
  { key: 480, value: 'UTC+08:00' },
  { key: 525, value: 'UTC+08:45' },
  { key: 540, value: 'UTC+09:00' },
  { key: 570, value: 'UTC+09:30' },
  { key: 600, value: 'UTC+10:00' },
  { key: 630, value: 'UTC+10:30' },
  { key: 660, value: 'UTC+11:00' },
  { key: 720, value: 'UTC+12:00' },
  { key: 765, value: 'UTC+12:45' },
  { key: 780, value: 'UTC+13:00' },
  { key: 840, value: 'UTC+14:00' }
]
