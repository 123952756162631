import React, { ReactNode } from 'react'

import styles from './ArkLabelBadge.module.css'

export interface ArkLabelBadgeProps {
  bottomLabel?: string
  children: ReactNode
  color?: string
  topLabel?: string
  onMouseEnter?: () => void // for ArkTooltip
  onMouseLeave?: () => void // for ArkTooltip
}

const ArkLabelBadge = (props: ArkLabelBadgeProps) => (
  <div
    className={styles.badge}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
  >
    <div className={styles.leftBorder} />
    <div className={styles.middle}>
      <div className={styles.top}>
        <div className={styles.topBorder} />
        {props.topLabel && <div className={styles.label}>{props.topLabel}</div>}
        <div className={styles.topBorder} />
      </div>
      <div className={styles.body} style={{ color: props.color }}>
        {props.children}
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomBorder} />
        {props.bottomLabel && <div className={styles.label}>{props.bottomLabel}</div>}
        <div className={styles.bottomBorder} />
      </div>
    </div>
    <div className={styles.rightBorder} />
  </div>
)

export default ArkLabelBadge
