import React from 'react'

import { FEATURE_DVR_NAME, OBJECT_COMPANY_NAME, PAGE_SETTINGS_NAME } from 'src/constants/strings'
import ArkPanel from 'src/core/components/ArkPanel'
import { Company } from 'src/core/models'

import styles from './CompanySettingsView.module.css'

const DEBUG_OVERRIDE_DVR_ENABLED: boolean | undefined = undefined

export interface CompanyDvrSettingsViewProps {
  company: Company
}

export const CompanyDvrSettingsView = (props: CompanyDvrSettingsViewProps) => {
  const dvrEnabled: boolean = DEBUG_OVERRIDE_DVR_ENABLED ?? props.company.dvrEnabled

  return (
    <ArkPanel bordered className={styles.panel}>
      <ArkPanel.Header
        bottomBorder
        className={styles.panelHeader}
        title={`${OBJECT_COMPANY_NAME} ${FEATURE_DVR_NAME} ${PAGE_SETTINGS_NAME}`}
      />
      <ArkPanel.Footer className={styles.panelFooter}>
        <div className={styles.panelFooterText}>
          {dvrEnabled
            ? (
              <div>
                {`${FEATURE_DVR_NAME} is `}
                <span className={styles.panelFooterTextSuccess}>enabled</span>
                {` for this ${OBJECT_COMPANY_NAME}.`}
              </div>
            )
            : (
              <>
                <div>
                  {`${FEATURE_DVR_NAME} is `}
                  <span className={styles.panelFooterTextDanger}>not enabled</span>
                  {` for this ${OBJECT_COMPANY_NAME}.`}
                </div>
                <div>Please contact sales to upgrade.</div>
              </>
            )}
        </div>
      </ArkPanel.Footer>
    </ArkPanel>
  )
}
