/**
 * AdminCompanyListItem
 */

import React from 'react'

import { AuthLoginServiceType, Company, CompanyLoginServiceMinimal } from 'src/core/models'
import { useAuth } from 'src/core/providers'

import ArkIcon from 'src/core/components/ArkIcon'
import ArkListItem, { ArkListItemDetail } from 'src/core/components/ArkListItem'

import styles from './AdminCompany.module.css'

interface AdminCompanyListItemProps {
  active: boolean
  company: Company
  filter?: string
  onClick: () => void
  onEditClick?: () => void
}

const AdminCompanyListItem = (props: AdminCompanyListItemProps) => {
  const {
    active,
    company,
    filter,
    onClick,
    onEditClick
  } = props

  const authContext = useAuth()

  const loginServices = [new CompanyLoginServiceMinimal(AuthLoginServiceType.EmailPassword, true), ...(company.loginServices || [])]
  const loginServicesDisplay: React.ReactNode | undefined = loginServices.filter((ls) => ls.enabled).map((loginService, index) => {
    console.log('AdminCompanyListItem - loginServicesDisplay - company:', company.id, ' index:', index, ' loginService:', loginService)
    return (
      <span key={'login_service_' + loginService.instanceId + '_' + index} className={styles.loginService}>
        {authContext.actions.getAuthLoginServiceTypeName(loginService.serviceType)}
        {/* {loginService.serviceType}&nbsp; */}
      </span>
    )
  })
  return (
    <ArkListItem
      active={active}
      filter={filter}
      leftComponent={<ArkIcon name='company' size={28} />}
      onClick={onClick}
      onEditClick={onEditClick}
      rightComponent={
        <ArkListItemDetail title='Login Services' className={styles.loginServicesDetail}>
          <div className={styles.loginServices}>
            {loginServicesDisplay}
          </div>
        </ArkListItemDetail>
      }
      title={company.name}
    />
  )
}

export default AdminCompanyListItem
