export const millisecondsToMinutes = (ms: number): number => {
  return Math.round(ms / 1000 / 60)
}

export const millisecondsToSeconds = (ms: number): number => {
  return Math.round(ms / 1000)
}

export const minutesToMilliseconds = (mins: number): number => {
  return mins * 60 * 1000
}

export const minutesToSeconds = (mins: number): number => {
  return mins * 60
}

export const secondsToMilliseconds = (secs: number): number => {
  return secs * 1000
}

export const secondsToMinutes = (secs: number): number => {
  return secs / 60
}
