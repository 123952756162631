import { ROLE_ADMIN_NAME, ROLE_MANAGER_NAME, ROLE_MEMBER_NAME, ROLE_MIRROR_GROUP_NAME, ROLE_OWNER_NAME } from 'src/constants/strings'
import { ArkAvatarType } from '../components/ArkAvatar'
import { BaseModel } from './base_model'

// NB: this is different from the newer UserProvider UserStatus enum (was named the same, but since renamed to make it distinct)
export enum UserAccountStatus {
  unknown = 0,
  active = 1, // NB: the user status will be 'active' if they have an account they can login with
  pending = 2, // NB: the user status will be 'pending' if they have a partial account (currently only if you change email & need to re-verify it, as we no longer require email verification on signup as you had to click the emailed invite which equates to the same thing)
  invited = 3,
  locked = 4 // NB: the user status will be 'locked' if they have too many failed login attempts
}

export enum UserSiteRole {
  unknown = 0,
  admin = 1,
  member = 2
}

export enum UserCompanyRole {
  unknown = 0,
  admin = 1,
  member = 2,
}
export enum UserCompanyStatus {
  unknown = 0,
  active = 1,
  pending = 2,
  invited = 3,
}

export enum UserProjectRole {
  unknown = 0,
  admin = 1,
  manager = 2,
  member = 3,
}

// NB: these args are named from the api & used with api calls, so keeping them in this format instead of camelCase (could otherwise map them if we want to keep camelCase in our code)
// eslint-disable-next-line camelcase
export type GroupUserOperation = { operation: number, user_id: number } // NB: the api now requires the group_id in the endpoint url instead of in the data `group_id: number`

// company > project lookup data - schema: {<company_id> => [<project_id>, <project_id>]}
export type CompanyProjectAccessLookup = Map<number, Array<number>>

export class User extends BaseModel {
    id: number
    email: string
    firstName?: string
    lastName?: string
    status?: UserAccountStatus // NB: this is the users site wide status, not company specific
    tfaEnabled: boolean
    phoneNumber?: string
    photoURL?: string

    emailVerified: boolean
    phoneVerified: boolean

    siteRole: UserSiteRole

    isGuest: boolean
    guestInfo?: GuestUserInfo

    projectViewLookup?: CompanyProjectAccessLookup
    projectAdminLookup?: CompanyProjectAccessLookup // NB: this covers project manager & admin levels (should we rename this to make it really obvious? also note the UserProvider adminProjects are ONLY company admin level, maybe both should be renamed to avoid any confusion in the future?)

    // WARNING: if updating the User constructor, also update its usage in sub-User model classes like CompanyUser, ProjectUser etc!
    constructor (
      id: number,
      email: string,
      firstName?: string,
      lastName?: string,
      status?: UserAccountStatus,
      tfaEnabled?: boolean,
      phoneNumber?: string,
      photoURL?: string,
      phoneVerified: boolean = false,
      siteRole: UserSiteRole = UserSiteRole.member,
      isGuest: boolean = false,
      guestInfo?: GuestUserInfo,
      projectViewLookup?: CompanyProjectAccessLookup,
      projectAdminLookup?: CompanyProjectAccessLookup
    ) {
      super()

      this.id = id
      this.email = email
      this.firstName = firstName
      this.lastName = lastName
      this.status = status
      this.phoneNumber = phoneNumber
      this.photoURL = photoURL

      this.tfaEnabled = tfaEnabled ?? false

      this.emailVerified = !!(status && status === UserAccountStatus.active)
      this.phoneVerified = phoneVerified

      this.siteRole = siteRole

      this.isGuest = isGuest
      this.guestInfo = guestInfo

      this.projectViewLookup = projectViewLookup
      this.projectAdminLookup = projectAdminLookup
    }

    getJSON () : string {
      return JSON.stringify(this)
    }

    static fromJSON (userId: number, json: any) : User | null {
      if (!json) {
        return null
      }
      const isGuest = json.flag_guest ?? false
      const guestInfo: GuestUserInfo | undefined = isGuest && json.guest_info
        ? { companyId: json.guest_info.company_id, projectId: json.guest_info.project_id, projectName: json.guest_info.project_name, guestKey: json.guest_info.guest_key, url: json.guest_info.url }
        : undefined
      // parse the company>project id mappings property - schema: { <companyId_1> : [<projectId_1>, <projectId_2>, ...], ... }
      // viewer & admin lookups are supplied separately
      // NB: due to json limitations, the company id key comes in as a string, so we convert it to a number as we parse them
      let projectViewLookup: CompanyProjectAccessLookup | undefined
      let projectAdminLookup: CompanyProjectAccessLookup | undefined
      if (json.viewerAssignments) {
        const companyProjectIdsOrig = json.viewerAssignments
        projectViewLookup = new Map<number, Array<number>>()
        for (const companyIdStr of Object.keys(companyProjectIdsOrig)) {
          projectViewLookup.set(parseInt(companyIdStr), companyProjectIdsOrig[companyIdStr])
        }
      }
      if (json.managerAssignments) {
        const companyProjectIdsOrig = json.managerAssignments
        projectAdminLookup = new Map<number, Array<number>>()
        for (const companyIdStr of Object.keys(companyProjectIdsOrig)) {
          projectAdminLookup.set(parseInt(companyIdStr), companyProjectIdsOrig[companyIdStr])
        }
      }
      const phoneNumber = json.phone_number !== '' ? json.phone_number : undefined // catch if the phone number is an empty string & treat it as null/undefined
      return new User(
        userId,
        json.email,
        json.name,
        json.last_name,
        (json.status.id in UserAccountStatus ? json.status.id : UserAccountStatus.unknown),
        json.tfa_enabled,
        phoneNumber,
        json.photoURL,
        json.phone_number_verified,
        (json.flag_site_admin === true ? UserSiteRole.admin : UserSiteRole.member),
        isGuest,
        guestInfo,
        projectViewLookup,
        projectAdminLookup
      )
    }

    static propertyToJSONKeyMap () {
      return {
        id: 'id',
        email: 'email',
        firstName: 'name',
        lastName: 'last_name',
        // NB: status: how to handle status as the api returns it as an object which we get the id from a sub field, map it to the subfield like 'status.id'?
        tfaEnabled: 'tfa_enabled',
        phoneNumber: 'phone_number',
        photoURL: 'photoURL',
        phoneVerified: 'phone_number_verified',
        // NB: siteRole: how to handle siteRole as the api returns 'flag_site_admin' with true/false & we map it to a UserSiteRole enum when parsing it
        isGuest: 'flag_guest'
        // NB: guestInfo: is a sub-object, map to the parent api object name? (skipping it for now)
        // TODO: other properties?
      }
    }

    static userStatusName (status?: UserAccountStatus) {
      if (status) {
        switch (status) {
          case UserAccountStatus.active: return 'ACTIVE'
          case UserAccountStatus.pending: return 'PENDING'
          case UserAccountStatus.invited: return 'INVITED'
          case UserAccountStatus.locked: return 'LOCKED'
        }
      }
      return 'UNKNOWN'
    }

    isSiteAdmin = () => {
      return this.siteRole === UserSiteRole.admin
    }

    userAvatarType = (): ArkAvatarType => {
      if (this.isGuest) return ArkAvatarType.userGuest
      if (this.isSiteAdmin()) return ArkAvatarType.userSiteAdmin
      return ArkAvatarType.user
    }

    name = () => {
      let name = ''
      if (this.firstName) {
        name += this.firstName
      }
      if (this.lastName && !this.isGuest) {
        if (name.length > 0) name += ' '
        name += this.lastName
      }
      if (name.length === 0) {
        name = this.email ?? '#' + this.id
      }
      return name
    }

    nameIsEmail = (): boolean => {
      return (this.name() === this.email)
    }
}

export class CompanyUser extends User {
  companyRole?: UserCompanyRole
  companyStatus?: UserCompanyStatus
  constructor (
    // User fields
    id: number,
    email: string,
    firstName?: string,
    lastName?: string,
    status?: UserAccountStatus,
    tfaEnabled?: boolean,
    phoneNumber?: string,
    photoURL?: string,
    phoneVerified: boolean = false,
    siteRole?: UserSiteRole,
    isGuest: boolean = false,
    guestInfo?: GuestUserInfo,
    // CompanyUser specific fields
    companyRole?: UserCompanyRole,
    companyStatus?: UserCompanyStatus
  ) {
    // NB: the api doesn't supply the view & admin lookup vars for the non auth base user focused endpoints, so we skip them here
    super(id, email, firstName, lastName, status, tfaEnabled, phoneNumber, photoURL, phoneVerified, siteRole, isGuest, guestInfo)
    this.companyRole = companyRole
    this.companyStatus = companyStatus
  }

  static fromJSON (userId: number, json: any) : CompanyUser | null {
    if (!json) {
      return null
    }
    const isGuest = json.flag_guest ?? false
    const guestInfo: GuestUserInfo | undefined = isGuest && json.guest_info
      ? { companyId: json.guest_info.company_id, projectId: json.guest_info.project_id, projectName: json.guest_info.project_name, guestKey: json.guest_info.guest_key, url: json.guest_info.url }
      : undefined
    return new CompanyUser(
      userId,
      json.email,
      json.name,
      json.last_name,
      (json.status.id in UserAccountStatus ? json.status.id : UserAccountStatus.unknown),
      json.tfa_enabled,
      json.phone_number,
      json.photoURL,
      json.phone_number_verified,
      (json.flag_site_admin === true ? UserSiteRole.admin : undefined),
      isGuest,
      guestInfo,
      (json.company_role?.id in UserCompanyRole ? json.company_role?.id : UserCompanyRole.unknown),
      (json.company_status?.id in UserCompanyStatus ? json.company_status?.id : UserCompanyStatus.unknown)
    )
  }

  companyRoleName = () => {
    return CompanyUser.companyRoleName(this.companyRole)
  }

  companyStatusName = () => {
    if (this.status === UserAccountStatus.locked) return CompanyUser.userStatusName(this.status)
    return CompanyUser.companyStatusName(this.companyStatus)
  }

  isCompanyAdmin = () => {
    return this.companyRole === UserCompanyRole.admin
  }

  userAvatarType = (): ArkAvatarType => {
    if (this.isGuest) return ArkAvatarType.userGuest
    if (this.isSiteAdmin()) return ArkAvatarType.userSiteAdmin
    if (this.isCompanyAdmin()) return ArkAvatarType.userCompanyAdmin
    return ArkAvatarType.user
  }

  static companyRoleName (companyRole?: UserCompanyRole) {
    if (companyRole) {
      switch (companyRole) {
        case UserCompanyRole.admin: return ROLE_ADMIN_NAME
        case UserCompanyRole.member: return ROLE_MEMBER_NAME
      }
    }
    return 'UNKNOWN'
  }

  static companyStatusName (companyStatus?: UserCompanyStatus) {
    if (companyStatus) {
      switch (companyStatus) {
        case UserCompanyStatus.active: return 'REGISTERED' // NB: renamed from 'ACTIVE' to 'REGISTERED' so it doesn't sound like they're 'online' currently
        case UserCompanyStatus.pending: return 'INVITED' // NB: showing 'INVITED' for this status as well for the time being
        case UserCompanyStatus.invited: return 'INVITED'
      }
    }
    return 'UNKNOWN'
  }
}

export class ProjectUser extends CompanyUser {
  projectId?: number
  projectOwner: boolean = false
  projectRole?: UserProjectRole
  projectAccessEnabled: boolean
  projectDirectUser: boolean // NB: indicates if the user is directly assigned to the project (not via a mirrored org/company group)
  constructor (
    // User fields
    id: number,
    email: string,
    firstName?: string,
    lastName?: string,
    status?: UserAccountStatus,
    tfaEnabled?: boolean,
    phoneNumber?: string,
    photoURL?: string,
    phoneVerified: boolean = false,
    siteRole?: UserSiteRole,
    isGuest: boolean = false,
    guestInfo?: GuestUserInfo,
    // CompanyUser specific fields
    companyRole?: UserCompanyRole,
    companyStatus?: UserCompanyStatus,
    // ProjectUser specific fields
    projectId?: number,
    projectOwner: boolean = false,
    projectRole?: UserProjectRole,
    projectAccessEnabled: boolean = false,
    projectDirectUser: boolean = true // NB: defaulting to true for older api version support '< v0.2.28' (TODO: possibly flip to false once all APIs are updated to supply this field?)
  ) {
    // NB: the api doesn't supply the view & admin lookup vars for the non auth base user focused endpoints, so we skip them here
    super(id, email, firstName, lastName, status, tfaEnabled, phoneNumber, photoURL, phoneVerified, siteRole, isGuest, guestInfo, companyRole, companyStatus)
    this.projectId = projectId
    this.projectOwner = projectOwner
    this.projectRole = projectRole
    this.projectAccessEnabled = projectAccessEnabled
    this.projectDirectUser = projectDirectUser
  }

  // NB: the /projects/users api endpoint currently returns the projectRole (& some other fields) outside/alongside the 'user' object
  // NB: ..instead of within the user object, so we allow it to be passed in as an additional arg here
  // NB: it now also returns an 'enabled' bool at the same outside level, which can be either passed in as an arg, or injected into the json as 'project_access_enabled'
  static fromJSON (userId: number, json: any, projectId?: number, projectOwner?: boolean, projectRole?: UserProjectRole, projectAccessEnabled?: boolean, projectDirectUser?: boolean) : ProjectUser | null {
    if (!json) {
      return null
    }
    const pId = projectId !== undefined ? projectId : json.project_id
    const pOwner = projectOwner !== undefined ? projectOwner : !!(json.project_owner)
    const pRole = projectRole !== undefined ? projectRole : json.project_role
    const isGuest = json.flag_guest ?? false
    const guestInfo: GuestUserInfo | undefined = isGuest && json.guest_info
      ? { companyId: json.guest_info.company_id, projectId: json.guest_info.project_id, projectName: json.guest_info.project_name, guestKey: json.guest_info.guest_key, url: json.guest_info.url }
      : undefined
    return new ProjectUser(
      userId,
      json.email,
      json.name,
      json.last_name,
      (json.status.id in UserAccountStatus ? json.status.id : UserAccountStatus.unknown),
      json.tfa_enabled,
      json.phone_number,
      json.photoURL,
      json.phone_number_verified,
      (json.flag_site_admin === true ? UserSiteRole.admin : undefined),
      isGuest,
      guestInfo,
      (json.company_role?.id in UserCompanyRole ? json.company_role?.id : UserCompanyRole.unknown),
      (json.company_status?.id in UserCompanyStatus ? json.company_status?.id : UserCompanyStatus.unknown),
      pId,
      pOwner,
      (pRole?.id in UserProjectRole ? pRole?.id : UserProjectRole.unknown),
      (projectAccessEnabled !== undefined ? projectAccessEnabled : json.project_access_enabled),
      (projectDirectUser !== undefined ? projectDirectUser : json.flag_direct_user)
    )
  }

  projectRoleName = () => {
    if (this.isProjectOwner()) return ROLE_OWNER_NAME // NB: not a normal role, the project owner flag takes priority over the role when displaying
    if (!this.isProjectDirectUser()) return ROLE_MIRROR_GROUP_NAME // NB: not a normal role, project user is not directly added to the project, but has access via a mirrored group
    return ProjectUser.projectRoleName(this.projectRole)
  }

  isProjectManager = () => this.projectRole === UserProjectRole.manager

  isProjectAdmin = () => this.projectRole === UserProjectRole.admin

  isProjectAdminOrManager = () => {
    return this.isProjectAdmin() || this.isProjectManager()
  }

  isProjectOwner = () => this.projectOwner

  isProjectDirectUser = () => this.projectDirectUser

  userAvatarType = (): ArkAvatarType => {
    if (this.isGuest) return ArkAvatarType.userGuest
    if (this.isSiteAdmin()) return ArkAvatarType.userSiteAdmin
    if (this.isCompanyAdmin()) return ArkAvatarType.userCompanyAdmin
    if (this.isProjectAdmin()) return ArkAvatarType.userProjectAdmin
    if (this.isProjectManager()) return ArkAvatarType.userProjectManager
    return ArkAvatarType.user
  }

  static projectRoleName (projectRole?: UserProjectRole) {
    if (projectRole) {
      switch (projectRole) {
        case UserProjectRole.admin: return ROLE_ADMIN_NAME
        case UserProjectRole.manager: return ROLE_MANAGER_NAME
        case UserProjectRole.member: return ROLE_MEMBER_NAME
      }
    }
    return 'UNKNOWN'
  }
}

export interface GuestUserInfo {
  companyId: number
  projectId: number
  projectName?: string // TODO: `project_name` field added in API v0.3.37, make this a required field once all servers are running v0.3.37+ (& update the json parsing code to match)
  guestKey: string
  url: string
}
