import React, { CSSProperties, MouseEventHandler, ReactNode, useState } from 'react'

import ArkIcon, { IconName } from '../ArkIcon'

import styles from './ArkIconButton.module.css'

const DEBUG_LAYOUT: boolean = false

interface ArkIconButtonProps {
  active?: boolean
  activeColor?: string
  children?: ReactNode
  className?: string
  color?: string
  debugLayout?: boolean
  disabled?: boolean
  hidden?: boolean
  hoverColor?: string
  iconStyle?: CSSProperties
  name?: IconName
  onClick?: () => void
  onMouseEnter?: () => void // for ArkTooltip
  onMouseLeave?: () => void // for ArkTooltip
  open?: boolean
  size?: number
  width?: number
}

const ArkIconButton = (props: ArkIconButtonProps) => {
  const {
    active,
    activeColor,
    children,
    className,
    color,
    debugLayout,
    disabled,
    hidden,
    hoverColor,
    name,
    onClick,
    open,
    size,
    iconStyle,
    width,
    ...otherProps
  } = props

  const [isHover, setIsHover] = useState(false)

  const _onClick: MouseEventHandler<HTMLDivElement> = (ev) => {
    ev.stopPropagation()
    !disabled && onClick && onClick()
  }

  let theColor = color
  if (active) theColor = activeColor || 'var(--blue)'
  if (isHover && !disabled && hoverColor) theColor = hoverColor

  return (
    <div>
      <div
        className={`${
          styles.button
        } ${
          !hoverColor && styles.hoverOpacity
        } ${
          className || ''
        } ${
          disabled ? styles.disabled : ''
        } ${
          hidden ? styles.hidden : ''
        } ${
          open ? styles.open : ''
        }`}
        onClick={_onClick}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={{
          width,
          ...DEBUG_LAYOUT || debugLayout
            ? { backgroundColor: 'rgba(255, 0, 0, 0.25)', border: '1px solid red' }
            : undefined
        }}
        {...otherProps}
      >
        {name && (
          <ArkIcon
            color={theColor}
            debugLayout={DEBUG_LAYOUT || debugLayout}
            name={name}
            size={size}
            style={iconStyle}
          />
        )}
        {children}
      </div>
    </div>
  )
}

export default ArkIconButton
