import React, { useEffect, useRef, useState } from 'react'
import { matchPath, Redirect, useLocation } from 'react-router-dom'

import {
  AuthSSOContext, AuthSSOProvider,
  useAuth,
  useCompanyInvite,
  useGlobalConfig,
  useNav,
  useServer
} from 'src/core/providers'
import { AuthLoginServiceType, IAuthLoginService } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkCenterLayout from 'src/core/components/ArkCenterLayout'
import ArkLoaderView from 'src/core/components/ArkLoaderView'
import ArkPage from 'src/core/components/ArkPage/ArkPage'

import * as ROUTES from 'src/constants/routes'

import RegisterForm from './RegisterForm'
import RegisterSSOForm from './RegisterSSOForm'
import LoginEmailLookupForm from '../Login/LoginEmailLookupForm'
import LoginNotUserView from '../Login/LoginNotUserView'
import RegisterSSOView from '../Register/RegisterSSOView'

import { Divider, Header, Label, Segment } from 'semantic-ui-react'

import styles from './RegisterPage.module.css'

enum RegisterPageStage {
  email, details, ssoLogin, ssoDetails, tfa // phone
}

interface IProps {}

const RegisterPage = (_props: IProps) => {
  const mounted = useRef(false)

  const authContext = useAuth()
  const companyInviteContext = useCompanyInvite()
  const globalConfigContext = useGlobalConfig()
  const navContext = useNav()
  const serverContext = useServer()

  const location = useLocation()

  const [registerStage, setRegisterStage] = useState<RegisterPageStage>(RegisterPageStage.email)
  const [loginService, setLoginService] = useState<IAuthLoginService | undefined>(undefined) // NB: only used for SSO logins here (so we know which sso service to use & its config details)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [authName, setAuthName] = useState<{ firstName?: string, lastName?: string } | undefined>(undefined)
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true) // NB: true until we've checked if we're handling an invite or SSO callback (so we don't flash the email lookup form or registration disabled handling, which can now trigger a redirect away to the login form)
  const [isSSOCallback, setIsSSOCallback] = useState<boolean>(false)
  const [isInvite, setIsInvite] = useState<boolean>(false)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  useEffect(() => {
    let authEmail: string | undefined
    let authName: { firstName?: string, lastName?: string } | undefined
    if (authContext.store.cacheEmail && authContext.store.cacheType === 'register') {
      authEmail = authContext.store.cacheEmail
      if (authContext.store.cacheName) {
        authName = authContext.store.cacheName
      }
      // this.props.authContext.actions.cacheClear() // NB: no longer clearing the cache here so page refreshes mid login/register resume with the cached email
      console.log('RegisterPage - useEffect[] - cacheEmail set + cacheType === \'register\' - authEmail:', authEmail, ' authName:', authName)
    } else if (authContext.store.cacheEmail && authContext.store.cacheType !== 'register') {
      console.log('RegisterPage - useEffect[] - cacheEmail set + cacheType !== \'register\' - cacheType:' + authContext.store.cacheType + ' cacheEmail:', authContext.store.cacheEmail)
    } else {
      console.log('RegisterPage - useEffect[] - cacheEmail NOT set - cacheType:' + authContext.store.cacheType + ' cacheEmail:', authContext.store.cacheEmail)
    }

    // TESTING: if registration is disabled, check if we are here via an invite, so we can skip the registration disabled checks
    // NB: if the page refreshes (for whatever reason) - `inviteRegistrationRequired` won't be true anymore when redirected from the invite process
    // NB: so we also check if an invite is cached via `getCachedInvite` & if its for the email we have cached for registration & allow registration if so (ignore/skip if there isn't an email or matching invite cached)??
    const registrationEnabled = globalConfigContext.store.config.registrationEnabled
    let _isInvite = false
    if (authEmail !== undefined && !registrationEnabled) {
      // check if we were (directly) redirected here from the invite process (NB: this will be false if the page is reloaded since then)
      const inviteRegistrationRequired = companyInviteContext.store.registrationRequired
      // console.log('RegisterPage - componentDidMount - invite-check - inviteRegistrationRequired:', inviteRegistrationRequired)
      // fallback - if the page was reloaded since the invite redirect `registrationRequired` will be false, so check if we have an invite cached & if its for the email we have cached for this registration
      if (!inviteRegistrationRequired) {
        _isInvite = companyInviteContext.actions.hasCachedInviteForEmail(authEmail)
      } else {
        _isInvite = true
      }
    }
    console.log('RegisterPage - useEffect[] - invite-check - authEmail:', authEmail, ' registrationEnabled:', registrationEnabled, ' _isInvite:', _isInvite)

    if (authEmail) {
      // UPDATE: instead of skipping the email lookup stage when an email was cached (e.g redirected from the login page)
      // UPDATE: ..we auto re-run the lookup so we can check the login service type/provider & handle it accordingly (instead of caching that as well from the login page, to avoid stale cache issues or potential abuse of the cache)
      setEmail(authEmail)
      setAuthName(authName)
      setRegisterStage(RegisterPageStage.email)
      setIsInvite(_isInvite)
    }

    // check if this is an sso callback route/path (redirected back from the external okta/auth0 login service/provider)
    // ..if so treat this as an sso login stage & hand over further sso processing to the dedicated sso view & its provider (it figures out which login service etc.)
    // NB: we don't have access to the AuthSSOProvider at this level/commponent, so we manually check the route/path here
    // NB: now only setting to true if theres args in the sso callback url, don't consider it a callback if they aren't (as they've already been parsed?)
    const isSSOCallbackPath = matchPath(location.pathname, { path: ROUTES.REGISTER_SSO, exact: false, strict: false })
    const hasSSOCallbackArgs = location.search !== ''
    console.log('RegisterPage - useEffect[] - isSSOCallbackPath:', isSSOCallbackPath, ' hasSSOCallbackArgs:', hasSSOCallbackArgs, ' location:', location)
    if (isSSOCallbackPath && hasSSOCallbackArgs && registerStage !== RegisterPageStage.ssoLogin) {
      // TODO: reset/update if the page url changes while this page/component remains loaded?
      setRegisterStage(RegisterPageStage.ssoLogin)
      setIsSSOCallback(true)
    }

    // done loading the page
    setIsLoadingPage(false)
  }, [])

  const _onRegisterCallback = (accessToken: string, _email?: string) => {
    console.log('RegisterPage - _onRegisterCallback - accessToken:', accessToken, ' _email:', _email, ' email(state):', email, ' isSSOCallback(state):', isSSOCallback)
    console.log('RegisterPage - _onRegisterCallback - authContext - cacheType:' + authContext.store.cacheType + ' cacheEmail:', authContext.store.cacheEmail, ' cacheType:', authContext.store.cacheType)

    if (_email !== undefined && email !== _email) {
      console.log('RegisterPage - _onRegisterCallback - UPDATING email(state) with _email:', _email)
      setEmail(_email)
    } else if (authContext.store.cacheType === 'sso' && authContext.store.cacheEmail !== undefined && email !== authContext.store.cacheEmail) {
      console.log('RegisterPage - _onRegisterCallback - UPDATING email(state) with cacheEmail:', authContext.store.cacheEmail, ' was:', email)
      setEmail(authContext.store.cacheEmail)
    }
    setRegisterStage(RegisterPageStage.ssoDetails)

    // update the url/path to remove the sso specific portion of it (now that portion of the auth is done)
    // UPDATE: this redirect breaks the `isSSOCallback` check/bool, so skipping/leaving it as-is for the time being (ideally it would be fixed with the redirect in place, can we just re-set the state var after the url/location change?)
    // this.props.navContext.actions.goto(ROUTES.REGISTER)
  }

  const _onRegisterCancel = () => {
    console.log('RegisterPage - _onRegisterCancel')
    authContext.actions.cacheClear()
    authContext.actions.clearAuthError()
    setRegisterStage(RegisterPageStage.email)
    setLoginService(undefined)
    setEmail(undefined)
  }

  const _renderRegisterFormDisabled = () => {
    console.log('RegisterPage - _renderRegisterFormDisabled')
    // UPDATE: we no longer show a 'registration disabled' warning, instead we redirect to the login page (as the login page now has some text blurb about being invite only etc.)
    return (
      // OLD:
      // <div className={styles.login}>
      //   <Label size="large" className={styles.registerWarningLabel}>
      //     <p>⚠️ Registration is disabled!</p>
      //   </Label>
      // </div>
      // NEW:
      <Redirect to={{ pathname: ROUTES.LOGIN }} />
      // DEBUG/TEMP:
      // <>DBG: REGISTRATION DISABLED</>
    )
  }

  const _renderEmailLookupForm = () => {
    console.log('RegisterPage - _renderEmailLookupForm - email:', email)
    return (
      <LoginEmailLookupForm
        email={email}
        autoRun={email !== undefined}
        onEmailLookup={(email: string, loginService: IAuthLoginService) => {
          console.log('RegisterPage - onEmailLookup - email:', email, ' loginService:', loginService)
          // UPDATE: #990 auth ux changes - check if we have cached invite details now the check-email response no longer indicates if the user is new or not
          const loginServiceType = loginService.type
          console.log('RegisterPage - onEmailLookup - loginServiceType:', loginServiceType)
          const cachedInvite = companyInviteContext.actions.getCachedInvite()
          const isInvite = companyInviteContext.actions.hasCachedInviteForEmail(email)
          console.log('RegisterPage - onEmailLookup - cachedInvite:', cachedInvite, ' isInvite:', isInvite, ' loginServiceType:', loginServiceType)
          if (isInvite && (loginServiceType === AuthLoginServiceType.EmailPassword)) {
            setRegisterStage(RegisterPageStage.details)
            setEmail(email)
          } else if (isInvite && loginServiceType === AuthLoginServiceType.SSOOktaOIDC) {
            setRegisterStage(RegisterPageStage.ssoLogin)
            setLoginService(loginService)
            setEmail(email)
          } else if (isInvite && loginServiceType === AuthLoginServiceType.SSOAuth0) {
            setRegisterStage(RegisterPageStage.ssoLogin)
            setLoginService(loginService)
            setEmail(email)
          } else if (isInvite) {
            console.log('RegisterPage - onEmailLookup - WARNING: isInvite - loginServiceType:', loginServiceType, ' - UNKNOWN/UNHANDLED LOGIN SERVICE TYPE <<<<')
            // TODO: throw/show an error?? (NB: low priority as this shouldn't happen under normal use, only during dev if we're adding a new login service & handling hasn't been added here yet)
          } else {
            console.log('RegisterPage - onEmailLookup - !isInvite - GOTO LOGIN PAGE...')
            // redirect to the login page & cache the email so the loaded page can check for it & skip the email entry step
            authContext.actions.cacheLoginEmail(email)
            navContext.actions.goto(ROUTES.LOGIN)
          }
        }}
      />
    )
  }

  const _renderEmailLookupPage = () => {
    const registrationEnabled = globalConfigContext.store.config.registrationEnabled
    return (
      <>
        <Header as="h2" textAlign="center">Register</Header>

        {(registrationEnabled || isInvite) ? _renderEmailLookupForm() : _renderRegisterFormDisabled()}

        <Divider horizontal inverted>Or</Divider>

        <div className={styles.login}>
          <Label size="large">
            <p>Already have an account?</p>
          </Label>
          <ArkButton fluid size="large" onClick={() => {
            navContext.actions.goto(ROUTES.LOGIN)
          }}>
            Login
          </ArkButton>
        </div>
      </>
    )
  }

  const _renderDetailsPage = () => {
    return (
      <>
        <Header as="h2" textAlign="center">Register</Header>
        <RegisterForm
          email={email}
          firstName={authName?.firstName}
          lastName={authName?.lastName}
        />
        <LoginNotUserView
          email={email ?? ''}
          onClick={() => {
            authContext.actions.cacheClear()
            // NB: now clearing the `email` state here otherwise it'll auto re-run the email lookup & block you from changing the email (mainly done here to match the login page behaviour)
            setRegisterStage(RegisterPageStage.email)
            setEmail(undefined)
          }}
        />
      </>
    )
  }

  const _renderEnable2FAPage = () => {
    return null
  }

  const _renderSSOLoginPage = (loginService?: IAuthLoginService) => {
    console.log('RegisterPage - _renderSSOLoginPage - email:', email, ' loginService:', loginService, ' isSSOCallback:', isSSOCallback)
    const apiClient = serverContext.store.apiClient
    const authApi = serverContext.store.authApi
    // NB: we might not have the email available here if we're coming back from the okta/auth0 callback..
    // ..so render/call it regardless & let it check/handle internally (loads the email from the cache if its set etc.)
    if (!apiClient || !authApi) return null
    return (
      <AuthSSOProvider apiClient={apiClient} authApi={authApi}>
        <RegisterSSOView
          email={email}
          loginService={loginService}
          isSSOCallback={isSSOCallback}
          onRegisterCallback={_onRegisterCallback}
          onCancel={_onRegisterCancel}
        />
      </AuthSSOProvider>
    )
  }

  const _renderSSODetailsPage = (loginService?: IAuthLoginService) => {
    console.log('RegisterPage - _renderSSODetailsPage - email:', email, ' loginService:', loginService)
    const apiClient = serverContext.store.apiClient
    const authApi = serverContext.store.authApi
    // NB: we might not have the email available here if we're coming back from the okta/auth0 callback..
    // ..so render/call it regardless & let it check/handle internally (loads the email from the cache if its set etc.)
    if (!apiClient || !authApi) return null
    return (
      <AuthSSOProvider apiClient={apiClient} authApi={authApi}>
        <AuthSSOContext.Consumer>
          {(authSSOContext) => {
            console.log('RegisterPage - _renderSSODetailsPage - authSSOContext: ', authSSOContext)
            // grab the users first & last name from the SSO user data/object
            // TODO: DON'T make this okta specific <<<<<<
            const { oktaUser } = authSSOContext.store
            const firstName = oktaUser?.given_name ?? authName?.firstName
            const lastName = oktaUser?.family_name ?? authName?.lastName
            const lockName = !!firstName && !!lastName // don't allow the name fields to be edited if we have them from the SSO provider
            return (
              <>
                <Header as="h2" textAlign="center">Register</Header>
                <RegisterSSOForm
                  email={email}
                  firstName={firstName}
                  lastName={lastName}
                  lockName={lockName}
                />
                <LoginNotUserView
                  email={email ?? ''}
                  onClick={() => {
                    authContext.actions.cacheClear()
                    // NB: now clearing the `email` state here otherwise it'll auto re-run the email lookup & block you from changing the email (mainly done here to match the login page behaviour)
                    setRegisterStage(RegisterPageStage.email)
                    setEmail(undefined)
                  }}
                />
              </>)
          }}
        </AuthSSOContext.Consumer>
      </AuthSSOProvider>
    )
  }

  let page: JSX.Element | null = null
  if (isLoadingPage) {
    page = (<ArkLoaderView message='Loading...' />)
  } else {
    switch (registerStage) {
      case RegisterPageStage.email: page = _renderEmailLookupPage(); break
      case RegisterPageStage.details: page = _renderDetailsPage(); break
      case RegisterPageStage.ssoLogin: page = _renderSSOLoginPage(loginService); break
      case RegisterPageStage.ssoDetails: page = _renderSSODetailsPage(loginService); break
      case RegisterPageStage.tfa: page = _renderEnable2FAPage(); break
    }
  }

  return (
    <ArkPage>
      <ArkCenterLayout>
        <Segment inverted>{page}</Segment>
      </ArkCenterLayout>
    </ArkPage>
  )
}
export default RegisterPage
