/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { ReactNode, RefObject, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import useResizeObserver from 'use-resize-observer'

import {
  AUDIO_LEVELS_ENABLED,
  DVR_ENABLED,
  PROGRAM_CONTROLS_ALWAYS_SHOW_TIME,
  PROGRAM_CONTROLS_AUTOHIDE_ENABLED,
  PROGRAM_CONTROLS_AUTOHIDE_TIMELINE,
  PROGRAM_CONTROLS_COMPACT_SIZE,
  // PROGRAM_CONTROLS_SEEK_TIME_SM,
  PROGRAM_CONTROLS_SEEK_TIME_MD,
  PROGRAM_CONTROLS_SEEK_TIME_LG,
  PROGRAM_CONTROLS_SHOW_DEBUG_INFO,
  PROGRAM_THREE_SIXTY_ENABLED,
  STATUS_ENABLED
} from 'src/constants/config'
import { FEATURE_DVR_NAME, OBJECT_CHANNEL_NAME, OBJECT_PROGRAM_NAME } from 'src/constants/strings'
import ArkAudioLevelsButton from 'src/core/components/ArkAudioLevelsButton'
import ArkBadge from 'src/core/components/ArkBadge'
import ArkDvrBadge from 'src/core/components/ArkDvrBadge'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkLabelBadge from 'src/core/components/ArkLabelBadge'
import ArkProgramIcon from 'src/core/components/ArkProgramIcon'
import ArkSpacer from 'src/core/components/ArkSpacer'
import ArkTooltip from 'src/core/components/ArkTooltip'
import ArkVolumeButton from 'src/core/components/ArkVolumeButton'
import ArkVolumeInput from 'src/core/components/ArkVolumeInput'
import { Program } from 'src/core/models'
import { useProjectStatus } from 'src/core/providers'
import { useAudioLevels } from 'src/viewer/providers/AudioLevelsProvider'
import { useViewer } from 'src/viewer/providers/ViewerProvider'
import { PlayerResolution } from 'src/core/types/player'

import ProgramAudioPopup from './ProgramAudioPopup'
import ProgramControlsAutohide from './ProgramControlsAutohide'
import ProgramOptionsPopup from './ProgramOptionsPopup'
import ProgramTimelineScalePopup from './ProgramTimelineScalePopup'
import ProgramTimelineView from './ProgramTimelineView'
import { formatTime } from './utilities'

import styles from './ProgramControlsView.module.css'

export type ProgramControlsDebugInfoItem = {
  name: string
  value: string
}

interface ProgramControlsProps {
  containerRef: RefObject<HTMLDivElement>
  customAbr: boolean
  customAdvancedParameters: any
  customBuffer: number
  customOptions: boolean
  customPassthrough: boolean
  customResolution: PlayerResolution
  customThreeSixty: boolean
  customThreeSixtyPreferPassthrough: boolean
  debugInfo: ProgramControlsDebugInfoItem[]
  fps: number | undefined
  fullscreen: boolean
  hidden: boolean
  paused: boolean
  playerRendition: string | undefined
  playerTime: number
  program: Program
  restarting: boolean
  seekTime: number
  stopped: boolean
  time: number
  vodDuration: number
  volume: number
  onCustomAbrChange: (value: boolean) => void
  onCustomAdvancedParametersChange: (value: any) => void
  onCustomBufferChange: (value: number) => void
  onCustomOptionsChange: (value: boolean) => void
  onCustomPassthroughChange: (value: boolean) => void
  onCustomResolutionChange: (value: PlayerResolution) => void
  onCustomThreeSixtyChange: (value: boolean) => void
  onCustomThreeSixtyPreferPassthroughChange: (value: boolean) => void
  onFullscreenClick: () => void
  onPauseClick: () => void
  onPlayClick: () => void
  onRestartClick: () => void
  onSeekTimeChange: (value: number) => void
  onStoppedChange: (value: boolean) => void
}

const ProgramControls = (props: ProgramControlsProps) => {
  const audioLevels = useAudioLevels()
  const projectStatus = useProjectStatus()
  const viewer = useViewer()

  const controlsRef = useRef<HTMLDivElement>(null)
  const showControlsRef = useRef<boolean>(false)

  const [audioPopupOpen, setAudioPopupOpen] = useState<boolean>(false)
  const [compact, setCompact] = useState<boolean>(false)
  const [optionsPopupOpen, setOptionsPopupOpen] = useState<boolean>(false)
  const [showControls, setShowControls] = useState<boolean>(!PROGRAM_CONTROLS_AUTOHIDE_ENABLED)
  const [showInfo, setShowInfo] = useState<boolean>(false)
  const [showTimeline, setShowTimeline] = useState<boolean>(false)
  const [thumbnailPopupOpen, setThumbnailPopupOpen] = useState<boolean>(false)
  const [timelineScale, setTimelineScale] = useState<number>(1)
  const [timelineScalePopupOpen, setTimelineScalePopupOpen] = useState<boolean>(false)

  useEffect(() => {
    showControlsRef.current = showControls
  }, [showControls])

  // compact
  useResizeObserver<HTMLDivElement>({
    onResize: (size) => {
      if (size.width) setCompact(size.width < PROGRAM_CONTROLS_COMPACT_SIZE)
    },
    ref: controlsRef
  })

  // autohide timeline
  useEffect(() => {
    if (
      PROGRAM_CONTROLS_AUTOHIDE_TIMELINE &&
      getLive() &&
      !showControls &&
      showTimeline
    ) {
      setTimeout(() => {
        if (!showControlsRef.current) setShowTimeline(false)
      }, 1000)
    }
  }, [props.seekTime, showControls, showTimeline])

  /**
   * actions
   */

  const onDvrClick = (): void => {
    // console.log('ProgramControls - onDvrClick')
    if (getLive()) {
      props.onSeekTimeChange(
        _.clamp(props.seekTime + PROGRAM_CONTROLS_SEEK_TIME_LG, 0, getMaxDuration())
      )
    }
    setShowTimeline(true)
  }

  const onLiveClick = (): void => {
    // console.log('ProgramControls - onLiveClick')
    props.onSeekTimeChange(0)
    setShowTimeline(false)
  }

  const onSeekClick = (time: number): void => {
    // console.log('ProgramControls - onSeekClick - time:', time)
    props.onSeekTimeChange(
      _.clamp(props.seekTime - time, 0, getMaxDuration())
    )
    setShowTimeline(true)
  }

  const onTimelineClick = (): void => {
    // console.log('ProgramControls - onTimelineClick')
    setTimelineScale(1)
    setShowTimeline(!showTimeline)
  }

  /**
   * utilities
   */

  const getAutoHideDisabled = (): boolean => {
    return (
      props.hidden ||
      audioPopupOpen ||
      optionsPopupOpen ||
      thumbnailPopupOpen ||
      timelineScalePopupOpen
    )
  }

  const getCompactAudio = (): boolean => {
    return compact || showTimeline
  }

  const getDvr = (): boolean => {
    return props.seekTime !== 0
  }

  const getDvrEnabled = (): boolean => {
    return DVR_ENABLED && props.program.dvrEnabled
  }

  const getLive = (): boolean => {
    return props.seekTime === 0
  }

  const getMaxDuration = (): number => {
    if (!viewer.project) return 0
    return viewer.project.dvrMaxDuration * 60 // minutes > seconds
  }

  const getShowDvrButton = (): boolean => {
    return getDvrEnabled() && !showTimeline
  }

  const getShowLiveButton = (): boolean => {
    return getDvrEnabled()
  }

  const getShowPauseButton = (): boolean => {
    return !props.paused
  }

  const getShowPlayButton = (): boolean => {
    return props.paused
  }

  const getShowSeekTime = (): boolean => {
    return props.seekTime !== 0
  }

  const getShowTime = (): boolean => {
    return (
      PROGRAM_CONTROLS_ALWAYS_SHOW_TIME ||
      props.paused ||
      getDvr() ||
      showTimeline
    )
  }

  const getSolo = (): boolean => {
    return (
      viewer.getChannelAutoSoloProgram() === props.program.id ||
      viewer.getProgramSolo(props.program.id)
    )
  }

  /**
   * render
   */

  // console.log(`ProgramControlsView[${props.program.id}] - render`)

  if (props.hidden) return null

  /**
   * top
   */

  const top: ReactNode = (
    <div className={styles.topBar}>
      <ArkSpacer />
      <ArkProgramIcon
        color={props.program.colour}
        initials={props.program.shortNameCapitalised()}
        onlineStatus={STATUS_ENABLED ? projectStatus.getProgramOnlineStatus(props.program.id) : undefined}
        size={30}
      />
      <ArkSpacer />
      <div className={styles.programName}>{props.program.name}</div>
      <ArkSpacer grow size={0} />
      {getSolo() && (
        <>
          <ArkSpacer />
          <ArkTooltip position='top center' text='Solo on'>
            <ArkBadge color='var(--green)'>SOLO</ArkBadge>
          </ArkTooltip>
        </>
      )}
      {props.customThreeSixty && (
        <>
          <ArkSpacer />
          <ArkTooltip position='top center' text='360° video'>
            <ArkBadge color='var(--blue)'>360°</ArkBadge>
          </ArkTooltip>
        </>
      )}
      {getDvrEnabled() && (
        <>
          <ArkSpacer />
          <ArkTooltip position='top center' text={`${FEATURE_DVR_NAME} recording`}>
            <ArkDvrBadge />
          </ArkTooltip>
        </>
      )}
      {props.playerRendition && (
        <>
          <ArkSpacer large />
          <ArkTooltip position='top center' text='Resolution'>
            <ArkLabelBadge topLabel='RES'>{props.playerRendition}</ArkLabelBadge>
          </ArkTooltip>
        </>
      )}
      <ArkSpacer size={7.5} />
      <ArkTooltip
        position='top center'
        text={showInfo ? `Hide ${OBJECT_PROGRAM_NAME} info` : `Show ${OBJECT_PROGRAM_NAME} info`}
      >
        <ArkIconButton
          name='info-circle'
          open={showInfo}
          size={24}
          width={35}
          onClick={() => setShowInfo(!showInfo)}
        />
      </ArkTooltip>
      <ArkTooltip position='top center' text={props.fullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}>
        <ArkIconButton
          name={props.fullscreen ? 'fullscreen-off' : 'fullscreen-on'}
          size={24}
          width={35}
          onClick={props.onFullscreenClick}
        />
      </ArkTooltip>
      <ArkSpacer size={7.5} />
    </div>
  )

  /**
   * middle
   */

  const infoPanel: ReactNode = showInfo && (
    <div className={styles.infoPanel}>
      <div className={styles.infoTitle}>{`${OBJECT_PROGRAM_NAME.toUpperCase()} INFO`}</div>
      <ArkSpacer />
      <div className={styles.infoRow}>
        <div className={styles.infoName}>ID:</div>
        <div className={styles.infoValue}>{props.program.id}</div>
      </div>
      <div className={styles.infoRow}>
        <div className={styles.infoName}>FPS:</div>
        <div className={styles.infoValue}>{props.fps}</div>
      </div>
      <div className={styles.infoRow}>
        <div className={styles.infoName}>Duration:</div>
        <div className={styles.infoValue}>{formatTime(props.playerTime)}</div>
      </div>
      {PROGRAM_CONTROLS_SHOW_DEBUG_INFO && (
        <>
          <ArkSpacer />
          <div className={styles.infoTitle}>DEBUG</div>
          <ArkSpacer />
          {_.map(props.debugInfo, (item, index) => (
            <div className={styles.infoRow} key={index}>
              <div className={styles.infoName}>{`${item.name}:`}</div>
              <div className={styles.infoValue}>{item.value}</div>
            </div>
          ))}
        </>
      )}
    </div>
  )

  const middle: ReactNode = (
    <div className={styles.middle}>
      {infoPanel}
    </div>
  )

  /**
   * bottom
   */

  const timeline: ReactNode = showTimeline && (
    <div className={styles.timelineContainer}>
      <ProgramTimelineView
        maxDuration={getMaxDuration()}
        scale={timelineScale}
        seekTime={props.seekTime}
        thumbnailPopupOpen={thumbnailPopupOpen}
        thumbnailUrl={props.program.dvrThumbnailUrl!}
        vodDuration={props.vodDuration}
        onSeekTimeChange={props.onSeekTimeChange}
        onThumbnailPopupOpenChange={setThumbnailPopupOpen}
      />
    </div>
  )

  const bottom: ReactNode = (
    <div className={styles.bottomBar}>
      {timeline}
      <div className={styles.bottomMain}>
        <div className={styles.bottomLeft}>
          <ArkSpacer size={7.5} />
          {getDvrEnabled() && (
            <>
              <ArkTooltip position='bottom center' text={showTimeline ? 'Hide timeline' : 'Show timeline'}>
                <ArkIconButton
                  name='timeline'
                  open={showTimeline}
                  size={22}
                  width={35}
                  onClick={onTimelineClick}
                />
              </ArkTooltip>
              {showTimeline && (
                <ProgramTimelineScalePopup
                  open={timelineScalePopupOpen}
                  trigger={
                    <ArkTooltip
                      disabled={timelineScalePopupOpen}
                      position='bottom center'
                      text='Show timeline zoom'
                    >
                      <ArkIconButton
                        name='search-plus'
                        open={timelineScalePopupOpen}
                        size={22}
                        width={35}
                        onClick={() => setTimelineScalePopupOpen(!timelineScalePopupOpen)}
                      />
                    </ArkTooltip>
                  }
                  value={timelineScale}
                  onChange={setTimelineScale}
                  onClose={() => setTimelineScalePopupOpen(false)}
                  onOpen={() => setTimelineScalePopupOpen(true)}
                />
              )}
            </>
          )}
          {getShowTime() && (
            <>
              <ArkSpacer size={2.5} />
              <ArkTooltip
                position='top center'
                text={getShowSeekTime() ? 'Play time (white)\nRewind time (grey)' : 'Play time'}
              >
                <div className={styles.timeContainer}>
                  <div className={styles.time}>{formatTime(props.time)}</div>
                  {getShowSeekTime() && <div className={styles.seekTime}>{`-${formatTime(props.seekTime)}`}</div>}
                </div>
              </ArkTooltip>
            </>
          )}
          <ArkSpacer grow size={0} />
          {showTimeline && (
            <>
              <ArkTooltip position='bottom center' text={`Step backward ${PROGRAM_CONTROLS_SEEK_TIME_LG} seconds`}>
                <ArkIconButton
                  disabled={props.seekTime === getMaxDuration()}
                  name='seek-backward-large'
                  size={36}
                  onClick={() => onSeekClick(-PROGRAM_CONTROLS_SEEK_TIME_LG)}
                >
                  <div className={styles.buttonLabel}>{`-${PROGRAM_CONTROLS_SEEK_TIME_LG}s`}</div>
                </ArkIconButton>
              </ArkTooltip>
              <ArkTooltip position='bottom center' text={`Step backward ${PROGRAM_CONTROLS_SEEK_TIME_MD} seconds`}>
                <ArkIconButton
                  disabled={props.seekTime === getMaxDuration()}
                  name='seek-backward-large'
                  size={36}
                  onClick={() => onSeekClick(-PROGRAM_CONTROLS_SEEK_TIME_MD)}
                >
                  <div className={styles.buttonLabel}>{`-${PROGRAM_CONTROLS_SEEK_TIME_MD}s`}</div>
                </ArkIconButton>
              </ArkTooltip>
              {/* <ArkTooltip position='bottom center' text={`Step backward ${PROGRAM_CONTROLS_SEEK_TIME_SM} second`}>
                <ArkIconButton
                  disabled={props.seekTime === getMaxDuration()}
                  name='seek-backward-large'
                  size={36}
                  onClick={() => onSeekClick(-PROGRAM_CONTROLS_SEEK_TIME_SM)}
                >
                  <div className={styles.buttonLabel}>{`-${PROGRAM_CONTROLS_SEEK_TIME_SM}s`}</div>
                </ArkIconButton>
              </ArkTooltip> */}
            </>
          )}
          {getShowDvrButton() && (
            <ArkTooltip position='bottom center' text={`Enter ${FEATURE_DVR_NAME}`}>
              <ArkIconButton
                name='seek-backward-large'
                size={36}
                onClick={onDvrClick}
              >
                <div className={styles.buttonLabel}>{FEATURE_DVR_NAME}</div>
              </ArkIconButton>
            </ArkTooltip>
          )}
        </div>
        <div className={styles.bottomMiddle}>
          {getShowPauseButton() && (
            <ArkTooltip position='bottom center' text='Pause'>
              <ArkIconButton
                name='pause-circle-large'
                size={36}
                onClick={props.onPauseClick}
              />
            </ArkTooltip>
          )}
          {getShowPlayButton() && (
            <ArkTooltip position='bottom center' text='Play'>
              <ArkIconButton
                name='play-circle-large'
                size={36}
                onClick={props.onPlayClick}
              />
            </ArkTooltip>
          )}
        </div>
        <div className={styles.bottomRight}>
          {showTimeline && (
            <>
              {/* <ArkTooltip position='bottom center' text={`Skip forward ${PROGRAM_CONTROLS_SEEK_TIME_SM} second`}>
                <ArkIconButton
                  disabled={!props.seekTime}
                  name='seek-forward-large'
                  size={36}
                  onClick={() => onSeekClick(PROGRAM_CONTROLS_SEEK_TIME_SM)}
                >
                  <div className={styles.buttonLabel}>{`+${PROGRAM_CONTROLS_SEEK_TIME_SM}s`}</div>
                </ArkIconButton>
              </ArkTooltip> */}
              <ArkTooltip position='bottom center' text={`Skip forward ${PROGRAM_CONTROLS_SEEK_TIME_MD} seconds`}>
                <ArkIconButton
                  disabled={!props.seekTime}
                  name='seek-forward-large'
                  size={36}
                  onClick={() => onSeekClick(PROGRAM_CONTROLS_SEEK_TIME_MD)}
                >
                  <div className={styles.buttonLabel}>{`+${PROGRAM_CONTROLS_SEEK_TIME_MD}s`}</div>
                </ArkIconButton>
              </ArkTooltip>
              <ArkTooltip position='bottom center' text={`Skip forward ${PROGRAM_CONTROLS_SEEK_TIME_LG} seconds`}>
                <ArkIconButton
                  disabled={!props.seekTime}
                  name='seek-forward-large'
                  size={36}
                  onClick={() => onSeekClick(PROGRAM_CONTROLS_SEEK_TIME_LG)}
                >
                  <div className={styles.buttonLabel}>{`+${PROGRAM_CONTROLS_SEEK_TIME_LG}s`}</div>
                </ArkIconButton>
              </ArkTooltip>
            </>
          )}
          {getShowLiveButton() && (
            <ArkTooltip position='bottom center' text='Return to LIVE'>
              <ArkIconButton
                disabled={!props.seekTime}
                name='seek-forward-large'
                size={36}
                onClick={onLiveClick}
              >
                <div className={styles.buttonLabel}>LIVE</div>
              </ArkIconButton>
            </ArkTooltip>
          )}
          <ArkSpacer grow size={0} />
          <ProgramOptionsPopup
            customAbr={props.customAbr}
            customAdvancedParameters={props.customAdvancedParameters}
            customBuffer={props.customBuffer}
            customOptions={props.customOptions}
            customPassthrough={props.customPassthrough}
            customResolution={props.customResolution}
            customThreeSixty={props.customThreeSixty}
            customThreeSixtyPreferPassthrough={props.customThreeSixtyPreferPassthrough}
            open={optionsPopupOpen}
            programThreeSixty={PROGRAM_THREE_SIXTY_ENABLED && props.program.threeSixty}
            restarting={props.restarting}
            stopped={props.stopped}
            trigger={(
              <ArkTooltip
                disabled={optionsPopupOpen}
                position='bottom center'
                text='Show video options'
              >
                <ArkIconButton
                  active={props.customOptions}
                  name='video'
                  open={optionsPopupOpen}
                  size={24}
                  width={35}
                  onClick={() => setOptionsPopupOpen(!optionsPopupOpen)}
                />
              </ArkTooltip>
            )}
            onClose={() => setOptionsPopupOpen(false)}
            onCustomAbrChange={props.onCustomAbrChange}
            onCustomAdvancedParametersChange={props.onCustomAdvancedParametersChange}
            onCustomBufferChange={props.onCustomBufferChange}
            onCustomOptionsChange={props.onCustomOptionsChange}
            onCustomPassthroughChange={props.onCustomPassthroughChange}
            onCustomResolutionChange={props.onCustomResolutionChange}
            onCustomThreeSixtyOnChange={props.onCustomThreeSixtyChange}
            onCustomThreeSixtyPreferPassthroughChange={props.onCustomThreeSixtyPreferPassthroughChange}
            onOpen={() => setOptionsPopupOpen(true)}
            onRestartClick={props.onRestartClick}
            onStoppedChange={props.onStoppedChange}
          />
          {getCompactAudio()
            ? (
              <ProgramAudioPopup
                mute={viewer.getProgramMute(props.program.id)}
                open={audioPopupOpen}
                trigger={
                  <ArkTooltip
                    disabled={audioPopupOpen}
                    position='bottom center'
                    text='Show audio options'
                  >
                    {viewer.getProgramMute(props.program.id)
                      ? (
                        <ArkIconButton
                          active={viewer.getProgramMute(props.program.id)}
                          activeColor='var(--red)'
                          open={audioPopupOpen}
                          name='volume-mute'
                          size={24}
                          width={35}
                          onClick={() => setAudioPopupOpen(!audioPopupOpen)}
                        />
                      )
                      : (
                        <ArkVolumeButton
                          open={audioPopupOpen}
                          size={24}
                          value={props.volume}
                          width={35}
                          onClick={() => setAudioPopupOpen(!audioPopupOpen)}
                        />
                      )
                    }
                  </ArkTooltip>
                }
                volume={props.volume}
                onClose={() => setAudioPopupOpen(false)}
                onMuteClick={() => viewer.setProgramMute(props.program.id, !viewer.getProgramMute(props.program.id))}
                onOpen={() => setAudioPopupOpen(true)}
                onVolumeChange={(volume) => viewer.setProgramVolume(props.program.id, volume)}
              />
            )
            : (
              <>
                <ArkTooltip position='bottom center' text={viewer.getProgramMute(props.program.id) ? 'Unmute' : 'Mute'}>
                  <ArkIconButton
                    active={viewer.getProgramMute(props.program.id)}
                    activeColor='var(--red)'
                    name='volume-mute'
                    size={24}
                    width={35}
                    onClick={() => viewer.setProgramMute(props.program.id, !viewer.getProgramMute(props.program.id))}
                  />
                </ArkTooltip>
                <ArkSpacer size={7.5} />
                <ArkTooltip position='bottom center' text={`Volume: ${Math.round(props.volume * 100)}%`}>
                  <ArkVolumeInput
                    size={100}
                    value={props.volume}
                    onChange={(value) => viewer.setProgramVolume(props.program.id, value)}
                  />
                </ArkTooltip>
                <ArkSpacer size={7.5} />
              </>
            )
          }
          {AUDIO_LEVELS_ENABLED && (
            <>
              <ArkTooltip position='bottom center' text={`Open ${OBJECT_CHANNEL_NAME} mixer`}>
                <ArkAudioLevelsButton
                  postDisabled={audioLevels.getProgramAudioLevelPostFaderDisabled(props.program.id)}
                  postValue={audioLevels.getProgramAudioLevelPostFader(props.program.id)}
                  preDisabled={audioLevels.getProgramNoAudio(props.program.id)}
                  preValue={audioLevels.getProgramAudioLevelPreFader(props.program.id)}
                  width={35}
                  onClick={() => viewer.setShowMixer(true)}
                />
              </ArkTooltip>
            </>
          )}
          <ArkSpacer size={7.5} />
        </div>
      </div>
    </div>
  )

  /**
   * main
   */

  return (
    <ProgramControlsAutohide
      containerRef={props.containerRef}
      disabled={getAutoHideDisabled()}
      onShowChange={setShowControls}
    >
      <div className={`${styles.controls} ${showControls ? styles.show : ''}`} ref={controlsRef}>
        {top}
        {middle}
        {bottom}
      </div>
    </ProgramControlsAutohide>
  )
}

export default ProgramControls
