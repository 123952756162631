import React from 'react'
import _ from 'lodash'
import { Modal } from 'semantic-ui-react'

import { useAudioLevels } from 'src/viewer/providers/AudioLevelsProvider'
import { useViewer } from 'src/viewer/providers/ViewerProvider'

import ChannelMixerPanel from './ChannelMixerPanel'

import styles from './ChannelMixerModal.module.css'

// FIXME rename "master" to "channel"

const ChannelMixerModal = () => {
  const audioLevels = useAudioLevels()
  const viewer = useViewer()

  const programPanelComponents = _.map(viewer.programs, program => (
    <ChannelMixerPanel
      audioLevelPostFader={audioLevels.getProgramAudioLevelPostFader(program.id)}
      audioLevelPostFaderDisabled={audioLevels.getProgramAudioLevelPostFaderDisabled(program.id)}
      audioLevelPreFader={audioLevels.getProgramAudioLevelPreFader(program.id)}
      autoSolo={viewer.getChannelAutoSoloProgram() === program.id}
      key={program.id}
      mute={viewer.getProgramMute(program.id)}
      muteDisabled={viewer.getChannelAutoSolo()}
      noAudio={audioLevels.getProgramNoAudio(program.id)}
      onMuteClick={() => viewer.setProgramMute(program.id, !viewer.getProgramMute(program.id))}
      onSoloClick={() => viewer.setProgramSolo(program.id, !viewer.getProgramSolo(program.id))}
      onVolumeChange={(volume) => viewer.setProgramVolume(program.id, volume)}
      program={program}
      solo={viewer.getProgramSolo(program.id)}
      soloDisabled={viewer.getChannelAutoSolo()}
      volume={viewer.getProgramVolume(program.id)}
    />
  ))

  const masterPanelComponent = (
    <ChannelMixerPanel
      audioLevelPostFader={audioLevels.getChannelAudioLevelPostFader()}
      audioLevelPostFaderDisabled={!viewer.audioStarted || viewer.getChannelMute()}
      audioLevelPreFader={audioLevels.getChannelAudioLevelPreFader()}
      audioStarted={viewer.audioStarted}
      autoSolo={viewer.getChannelAutoSolo()}
      mute={viewer.getChannelMute()}
      noAudio={!viewer.audioStarted}
      onMuteClick={() => viewer.setChannelMute(!viewer.getChannelMute())}
      onAutoSoloClick={() => viewer.setChannelAutoSolo(!viewer.getChannelAutoSolo())}
      onStartAudioClick={() => viewer.startAudio()}
      onVolumeChange={viewer.setChannelVolume}
      volume={viewer.getChannelVolume()}
    />
  )

  return (
    <Modal
      className={styles.modal}
      closeIcon
      closeOnDimmerClick
      dimmer='blurring'
      mountNode={document.fullscreenElement}
      onClose={() => viewer.setShowMixer(false)}
      open={viewer.showMixer}
    >
      <Modal.Content>
        {!viewer.audioStarted && (
          <div className={styles.startAudioLabel}>
            To allow your browser to auto-play video, AUDIO has been STOPPED. Click &ldquo;Start Audio&ldquo; to change this.
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.leftMaskOuter}>
            <div className={styles.leftMaskInner} />
          </div>
          <div className={styles.panels}>
            {programPanelComponents}
          </div>
          <div className={styles.rightMaskOuter}>
            <div className={styles.rightMaskInner} />
          </div>
          {masterPanelComponent}
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default ChannelMixerModal
