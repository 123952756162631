/**
 * ArkInfoView
 */

import React, { ReactNode } from 'react'

import ArkIcon, { IconName } from '../ArkIcon'
import ArkSpacer from '../ArkSpacer'

import styles from './ArkInfoView.module.css'

interface ArkInfoViewProps {
  iconComponent?: ReactNode
  iconName?: IconName
  title: string
  message?: string | ReactNode
  buttons?: ReactNode
  className?: string
  compact?: boolean
  titleClassName?: string
  messageClassName?: string
}

const ArkInfoView = (props: ArkInfoViewProps) => {
  const {
    buttons,
    className,
    compact,
    iconComponent,
    iconName,
    message,
    messageClassName,
    title,
    titleClassName
  } = props

  return (
    <div className={`${styles.view} ${className || ''}`}>
      {iconName && (
        <>
          <ArkIcon color='var(--tx-light-mid)' name={iconName} size={!compact ? 84 : undefined} />
          {!compact && <ArkSpacer size={40} />}
        </>
      )}
      {iconComponent && (
        <>
          {iconComponent}
          {!compact && <ArkSpacer size={40} />}
        </>
      )}
      {!compact && (
        <>
          <div className={styles.title + (titleClassName ? ' ' + titleClassName : '')}>{title}</div>
          {message && (
            <>
              <ArkSpacer size={30} />
              <div className={`${styles.message} ${messageClassName || ''}`}>{message}</div>
            </>
          )}
          {buttons && (
            <>
              <ArkSpacer size={30} />
              {buttons}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ArkInfoView
