/**
 * ArkPopup
 */

import React, { useEffect, useRef, useState } from 'react'
import { Popup, PopupProps } from 'semantic-ui-react'

import { POPUP_MOUSE_LEAVE_DELAY } from 'src/constants/config'

import styles from './ArkPopup.module.css'

export interface ArkPopupProps extends PopupProps {
  color?: string
  dimmer?: boolean
  title?: string
  titleClassName?: string
}

const ArkPopup = (props: ArkPopupProps) => {
  const {
    children,
    className,
    color = 'var(--bg-dark-card)',
    dimmer,
    open,
    title,
    titleClassName,
    ...otherProps
  } = props

  const [isMounted, setIsMounted] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    if (open) {
      setIsOpen(true)
      setTimeout(() => setShow(true))
    } else {
      setShow(false)
      setTimeout(() => setIsOpen(false), 300)
    }
  }, [open])

  /**
   * HACK fix fullscreen
   */

  const nodeRef = useRef<Node>()

  const onMount = () => {
    setIsMounted(true)
    const node = document.body.lastChild
    if (!node) return
    document.fullscreenElement?.appendChild(node)
    nodeRef.current = node
  }

  const onUnmount = () => {
    setIsMounted(false)
    const node = nodeRef.current
    if (!node) return
    document.body.appendChild(node)
  }

  /**
   * render
   */

  return (
    <>
      {dimmer && isMounted && <div className={styles.dimmer} />}
      <Popup
        hideOnScroll
        inverted
        mouseLeaveDelay={POPUP_MOUSE_LEAVE_DELAY}
        className={`${styles.popup} ${className || ''}`}
        closeOnPortalMouseLeave
        closeOnTriggerMouseLeave
        on='click'
        onClick={(event: MouseEvent) => event.stopPropagation()}
        onMount={onMount}
        onUnmount={onUnmount}
        open={isOpen}
        style={{ backgroundColor: color, opacity: show ? 1 : 0 }}
        {...otherProps}
      >
        {title && <div className={styles.title + (titleClassName ? ' ' + titleClassName : '')}>{title}</div>}
        {children}
      </Popup>
    </>
  )
}

export default ArkPopup
