import React from 'react'

import { Switch, Route } from 'react-router-dom'

import * as ROUTES from 'src/constants/routes'

import UserAccountProvider from './UserAccountProvider'
import { UserAccountSection, UserAccountSectionPage } from './UserAccountData'
import UserAccountPage from './UserAccountPage/UserAccountPage'

const UserAccountRouter = () => {
  return (
    <UserAccountProvider>
      <Switch>
        {/* <Route path={ROUTES.ACCOUNT_SETTINGS_2FA} component={User2FAPage} /> */}
        <Route exact path={ROUTES.ACCOUNT}>
          <UserAccountPage />
        </Route>
        <Route exact path={ROUTES.ACCOUNT_PROFILE}>
          <UserAccountPage section={UserAccountSection.profile} />
        </Route>
        <Route path={ROUTES.ACCOUNT_PROFILE_EDIT_NAME}>
          <UserAccountPage section={UserAccountSection.profile} sectionPage={UserAccountSectionPage.profileEditName} />
        </Route>
        <Route path={ROUTES.ACCOUNT_PROFILE_EDIT_EMAIL}>
          <UserAccountPage section={UserAccountSection.profile} sectionPage={UserAccountSectionPage.profileEditEmail} />
        </Route>
        <Route path={ROUTES.ACCOUNT_PROFILE_VERIFY_PHONE}>
          <UserAccountPage section={UserAccountSection.profile} sectionPage={UserAccountSectionPage.profileVerifyPhone} />
        </Route>
        <Route exact path={ROUTES.ACCOUNT_SETTINGS}>
          <UserAccountPage section={UserAccountSection.settings} />
        </Route>
        <Route path={ROUTES.ACCOUNT_SETTINGS_CHANGE_PASSWORD}>
          <UserAccountPage section={UserAccountSection.settings} sectionPage={UserAccountSectionPage.settingsEditPassword} />
        </Route>
        <Route path={ROUTES.ACCOUNT_SETTINGS_2FA}>
          <UserAccountPage section={UserAccountSection.settings} sectionPage={UserAccountSectionPage.settings2FA} />
        </Route>
        <Route path={ROUTES.ACCOUNT_SETTINGS_SESSIONS}>
          <UserAccountPage section={UserAccountSection.settings} sectionPage={UserAccountSectionPage.settingsSessions} />
        </Route>
      </Switch>
    </UserAccountProvider>
  )
}

export default UserAccountRouter
