import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { DropdownItemProps } from 'semantic-ui-react'

import { DVR_MAX_DURATION_OPTIONS } from 'src/constants/config'
import {
  FEATURE_DVR_NAME,
  OBJECT_COMPANY_NAME,
  OBJECT_PROGRAM_NAME,
  OBJECT_PROJECT_NAME,
  PAGE_SETTINGS_NAME,
  ROLE_ADMIN_NAME_PLURAL
} from 'src/constants/strings'
import ArkButton from 'src/core/components/ArkButton'
import ArkDropdown from 'src/core/components/ArkDropdown'
import ArkHint from 'src/core/components/ArkHint'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkNumberInput from 'src/core/components/ArkNumberInput'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkRadio from 'src/core/components/ArkRadio'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { Company, Project } from 'src/core/models'
import { useProjectAdmin } from 'src/core/providers/ProjectAdminProvider'
import { useUser } from 'src/core/providers/UserProvider'

import styles from './ProjectSettingsView.module.css'

const DEBUG_OVERRIDE_COMPANY_DVR_ENABLED: boolean | undefined = undefined
const DEBUG_OVERRIDE_USER_ADMIN: boolean | undefined = undefined

export interface ProjectDvrSettingsViewProps {
  company: Company
  project: Project
}

// string > number | null
const getMaxSize = (value: string): number | null => {
  return value === '0' ? null : parseInt(value)
}

// number | null > string
const getMaxSizeValue = (maxSize: number | null): string => {
  return maxSize === null ? '0' : maxSize.toString()
}

export const ProjectDvrSettingsView = (props: ProjectDvrSettingsViewProps) => {
  const projectAdmin = useProjectAdmin()
  const user = useUser()

  const [error, setError] = useState<Error>()
  const [saved, setSaved] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [warnings, setWarnings] = useState<Array<Error>>()

  const [enabledValue, setEnabledValue] = useState<boolean>(props.project.dvrEnabled)
  const [maxDurationValue, setMaxDurationValue] = useState<number>(props.project.dvrMaxDuration)
  const [maxSizeValue, setMaxSizeValue] = useState<string>(getMaxSizeValue(props.project.dvrMaxSize))

  useEffect(() => {
    setError(undefined)
    setSaved(false)
    setWarnings(undefined)
  }, [enabledValue, maxDurationValue, maxSizeValue])

  const onClearClick = async () => {
    // console.log('ProjectDvrSettingsView - onClearClick')
    setMaxDurationValue(props.project.dvrMaxDuration)
    setMaxSizeValue(getMaxSizeValue(props.project.dvrMaxSize))
    setEnabledValue(props.project.dvrEnabled)
  }

  const onSaveClick = async () => {
    try {
      // console.log('ProjectDvrSettingsView - onSaveClick')
      setSaving(true)
      await projectAdmin.actions.updateProjectInfo(props.company.id, props.project.id, {
        dvrEnabled: enabledValue,
        dvrMaxDuration: maxDurationValue,
        dvrMaxSize: getMaxSize(maxSizeValue)
      })
      await user.actions.reloadUserData()
      setSaving(false)
      setSaved(true)
    } catch (error) {
      console.error('ProjectDvrSettingsView - onSaveClick - error:', error)
      setSaving(false)
      setError(error)
    }
  }

  const companyDvrEnabled: boolean = DEBUG_OVERRIDE_COMPANY_DVR_ENABLED ?? props.company.dvrEnabled
  const userAdmin: boolean = DEBUG_OVERRIDE_USER_ADMIN ?? user.actions.isCompanyAdminOrHigher()

  const maxDurationOptions: DropdownItemProps[] = _.map(DVR_MAX_DURATION_OPTIONS, item => ({
    active: item.value === maxDurationValue,
    onClick: () => setMaxDurationValue(item.value),
    text: item.name,
    value: item.value
  }))

  const enabledChanges: boolean = enabledValue !== props.project.dvrEnabled
  const maxDurationChanges: boolean = maxDurationValue !== props.project.dvrMaxDuration
  const maxSizeChanges: boolean = getMaxSize(maxSizeValue) !== props.project.dvrMaxSize
  const changes: boolean = enabledChanges || maxDurationChanges || maxSizeChanges

  return (
    <ArkPanel bordered className={styles.panel}>
      <ArkPanel.Header
        bottomBorder
        className={styles.panelHeader}
        title={`${OBJECT_PROJECT_NAME} ${FEATURE_DVR_NAME} ${PAGE_SETTINGS_NAME}`}
      />
      <ArkPanel.Properties>
        {error && (
          <ArkPanel.PropertyRow>
            <ArkPanel.PropertyRowMessage negative>
              <ArkMessage.Header>Error</ArkMessage.Header>
              <p>{error.message}</p>
            </ArkPanel.PropertyRowMessage>
          </ArkPanel.PropertyRow>
        )}
        {saved && (
          <ArkPanel.PropertyRow>
            <ArkPanel.PropertyRowMessage positive>
              <ArkMessage.Header>Saved</ArkMessage.Header>
              <p>Your changes have been saved</p>
            </ArkPanel.PropertyRowMessage>
          </ArkPanel.PropertyRow>
        )}
        {warnings && _.some(warnings) && (
          <ArkPanel.PropertyRow>
            <ArkPanel.PropertyRowMessage warning>
              <ArkMessage.Header>Warning</ArkMessage.Header>
              {warnings.length === 1
                ? (
                  <p>{warnings[0].message}</p>
                )
                : (
                  <ArkMessage.List>
                    {warnings.map((saveWarning: Error, index: number) => (
                      <ArkMessage.Item key={index}>{saveWarning.message}</ArkMessage.Item>
                    ))}
                  </ArkMessage.List>
                )}
            </ArkPanel.PropertyRowMessage>
          </ArkPanel.PropertyRow>
        )}
        <ArkPanel.PropertyRow
          hasChanges={enabledChanges}
          hint={
            <ArkHint
              iconSize={22}
              message={
                <>
                  <ArkSpacer small />
                  <div>{`Enable ${FEATURE_DVR_NAME} for this ${OBJECT_PROJECT_NAME}.`}</div>
                  <ArkSpacer small />
                  <div>{`Each ${OBJECT_PROGRAM_NAME} uses around:`}</div>
                  <div>• 1,500 MB per hour at 720p</div>
                  <div>• 2,000 MB per hour at 1080p</div>
                  <ArkSpacer small />
                  <div><em>{`You also need to turn on ${FEATURE_DVR_NAME} for each ${OBJECT_PROGRAM_NAME}.`}</em></div>
                </>
              }
              popupPosition='bottom left'
              popupSize='small'
              title={`Enable ${FEATURE_DVR_NAME}`}
              type='info-circle'
            />
          }
          title={`Enable ${FEATURE_DVR_NAME}:`}
          titleSize='large'
          value={
            <ArkRadio
              checked={enabledValue}
              disabled={!companyDvrEnabled || !userAdmin}
              onChange={() => setEnabledValue(!enabledValue)}
              toggle
            />
          }
        />
        <ArkPanel.PropertyRow
          hasChanges={maxDurationChanges}
          hint={
            <ArkHint
              iconSize={22}
              message={
                <>
                  <ArkSpacer small />
                  <div>{`The maximum ${FEATURE_DVR_NAME} duration.`}</div>
                  <ArkSpacer small />
                  <div><em>Footage older than the max duration will automatically be deleted.</em></div>
                </>
              }
              popupPosition='bottom left'
              popupSize='small'
              title='Max Duration'
              type='info-circle'
            />
          }
          title='Max Duration:'
          titleSize='large'
          value={
            <ArkDropdown
              disabled={!companyDvrEnabled || !userAdmin || !enabledValue}
              fluid
              options={maxDurationOptions}
              selection
              value={maxDurationValue}
            />
          }
        />
        <ArkPanel.PropertyRow
          hasChanges={maxSizeChanges}
          hint={
            <ArkHint
              iconSize={22}
              message={
                <>
                  <ArkSpacer small />
                  <div>{`The maximum file size per ${OBJECT_PROGRAM_NAME} in megabytes (MB).`}</div>
                  <ArkSpacer small />
                  <div>0 — no maximum file size (recommended)</div>
                </>
              }
              popupPosition='bottom left'
              popupSize='small'
              title='Max File Size'
              type='info-circle'
            />
          }
          title='Max File Size:'
          titleSize='large'
          value={
            <ArkNumberInput
              className={styles.numberInput}
              disabled={!companyDvrEnabled || !userAdmin || !enabledValue}
              minValue={0}
              onChange={setMaxSizeValue}
              size='mini'
              value={maxSizeValue}
            />
          }
        />
      </ArkPanel.Properties>
      <ArkPanel.Footer className={styles.panelFooter} topBorder>
        {companyDvrEnabled
          ? (
            <>
              {userAdmin
                ? (
                  <div className={styles.panelFooterButtons}>
                    <ArkButton
                      color='red'
                      disabled={!changes || saving}
                      onClick={onClearClick}
                    >
                    CLEAR CHANGES
                    </ArkButton>
                    <ArkButton
                      color='green'
                      disabled={!changes}
                      loading={saving}
                      onClick={onSaveClick}
                    >
                    SAVE CHANGES
                    </ArkButton>
                  </div>
                )
                : (
                  <div className={styles.panelFooterText}>
                    {`Only ${ROLE_ADMIN_NAME_PLURAL} can change ${OBJECT_PROJECT_NAME} ${FEATURE_DVR_NAME} ${PAGE_SETTINGS_NAME}.`}
                  </div>
                )}
            </>
          )
          : (
            <div className={styles.panelFooterText}>
              <div>
                {`${FEATURE_DVR_NAME} is `}
                <span className={styles.panelFooterTextDanger}>not enabled</span>
                {` for this ${OBJECT_COMPANY_NAME}.`}
              </div>
              <div>Please contact sales to upgrade.</div>
            </div>
          )}
      </ArkPanel.Footer>
    </ArkPanel>
  )
}
