import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { OBJECT_COMPANY_NAME, PAGE_SETTINGS_NAME } from 'src/constants/strings'
import ArkButton from 'src/core/components/ArkButton'
import ArkHint from 'src/core/components/ArkHint'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkNumberInput from 'src/core/components/ArkNumberInput'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkRadio from 'src/core/components/ArkRadio'
import { UserCompany } from 'src/core/models'
import { CompanyInfoUpdateResult, useCompanyAdmin } from 'src/core/providers/CompanyAdminProvider'
import { useUser } from 'src/core/providers/UserProvider'
import { millisecondsToMinutes, minutesToSeconds, secondsToMinutes } from 'src/core/utilities/time'

import styles from './CompanySettingsView.module.css'
import { IDLE_TIMEOUT_MAX_MS, IDLE_TIMEOUT_MIN_MS } from 'src/constants/config'

export interface CompanyIdleSettingsViewProps {
  company: UserCompany
}

export const CompanyIdleSettingsView = (props: CompanyIdleSettingsViewProps) => {
  const companyAdmin = useCompanyAdmin()
  const user = useUser()

  const [error, setError] = useState<Error>()
  const [saved, setSaved] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [warnings, setWarnings] = useState<Array<Error>>()

  const [idleTimeoutManagement, setIdleTimeoutManagement] = useState<boolean>(props.company.idleTimeoutManagement)
  const [idleTimeoutManagementTime, setIdleTimeoutManagementTime] = useState<number>(props.company.idleTimeoutManagementTime)

  useEffect(() => {
    setError(undefined)
    setSaved(false)
    setWarnings(undefined)
  }, [idleTimeoutManagement, idleTimeoutManagementTime])

  const onClearClick = async () => {
    // console.log('CompanyIdleSettingsView - onClearClick')
    setIdleTimeoutManagement(props.company.idleTimeoutManagement)
    setIdleTimeoutManagementTime(props.company.idleTimeoutManagementTime)
  }

  const onSaveClick = async () => {
    try {
      // console.log('CompanyIdleSettingsView - onSaveClick')
      setSaving(true)
      const result: CompanyInfoUpdateResult = await companyAdmin.actions.updateCompanyInfo(props.company.id, {
        idleTimeoutManagement,
        idleTimeoutManagementTime
      })
      if (result.warnings) {
        setSaving(false)
        setWarnings(result.warnings)
        return
      }
      setSaving(false)
      setSaved(true)
    } catch (error) {
      console.error('CompanyIdleSettingsView - onSaveClick - error:', error)
      setSaving(false)
      setError(error)
    }
  }

  const isCompanyAdminOrHigher: boolean = user.actions.isCompanyAdminOrHigher()

  const idleTimeoutManagementChanges: boolean = idleTimeoutManagement !== props.company.idleTimeoutManagement
  const idleTimeoutManagementTimeChanges: boolean = idleTimeoutManagementTime !== props.company.idleTimeoutManagementTime
  const changes: boolean = idleTimeoutManagementChanges || idleTimeoutManagementTimeChanges

  return (
    <div className={styles.userSettings}>
      <div className={styles.content}>
        <ArkPanel bordered>
          <ArkPanel.Header
            bottomBorder
            className={styles.header}
            title={`${OBJECT_COMPANY_NAME} Auto-lock ${PAGE_SETTINGS_NAME}`}
          />
          <ArkPanel.Properties>
            {error && (
              <ArkPanel.PropertyRow>
                <ArkPanel.PropertyRowMessage negative>
                  <ArkMessage.Header>Error</ArkMessage.Header>
                  <p>{error.message}</p>
                </ArkPanel.PropertyRowMessage>
              </ArkPanel.PropertyRow>
            )}
            {saved && (
              <ArkPanel.PropertyRow>
                <ArkPanel.PropertyRowMessage positive>
                  <ArkMessage.Header>Saved</ArkMessage.Header>
                  <p>Your changes have been saved</p>
                </ArkPanel.PropertyRowMessage>
              </ArkPanel.PropertyRow>
            )}
            {warnings && _.some(warnings) && (
              <ArkPanel.PropertyRow>
                <ArkPanel.PropertyRowMessage warning>
                  <ArkMessage.Header>Warning</ArkMessage.Header>
                  {warnings.length === 1
                    ? (
                      <p>{warnings[0].message}</p>
                    )
                    : (
                      <ArkMessage.List>
                        {warnings.map((saveWarning: Error, index: number) => (
                          <ArkMessage.Item key={index}>{saveWarning.message}</ArkMessage.Item>
                        ))}
                      </ArkMessage.List>
                    )}
                </ArkPanel.PropertyRowMessage>
              </ArkPanel.PropertyRow>
            )}
            <ArkPanel.PropertyRow
              hasChanges={idleTimeoutManagementChanges}
              hint={
                <ArkHint
                  iconSize={22}
                  message='Automatically lock management sections after a period of inactivity. Once locked, users must re-enter their password to access management sections.'
                  popupPosition='bottom left'
                  popupSize='small'
                  title='Auto-lock management'
                  type='info-circle'
                />
              }
              title='Auto-lock management:'
              titleSize='xlarge'
              value={
                <ArkRadio
                  checked={idleTimeoutManagement}
                  disabled={!isCompanyAdminOrHigher}
                  onChange={() => setIdleTimeoutManagement(!idleTimeoutManagement)}
                  toggle
                />
              }
            />
            <ArkPanel.PropertyRow
              hasChanges={idleTimeoutManagementTimeChanges}
              hint={
                <ArkHint
                  iconSize={22}
                  message='The amount of time (in minutes) before management sections are automatically locked.'
                  popupPosition='bottom left'
                  popupSize='small'
                  title='Auto-lock time (minutes)'
                  type='info-circle'
                />
              }
              title='Auto-lock time (minutes)'
              titleSize='xlarge'
              value={
                <ArkNumberInput
                  className={styles.numberInput}
                  disabled={!isCompanyAdminOrHigher || !idleTimeoutManagement}
                  maxValue={millisecondsToMinutes(IDLE_TIMEOUT_MAX_MS)}
                  minValue={millisecondsToMinutes(IDLE_TIMEOUT_MIN_MS)}
                  onChange={(value: string) => setIdleTimeoutManagementTime(minutesToSeconds(parseInt(value)))}
                  size='mini'
                  value={secondsToMinutes(idleTimeoutManagementTime).toString()}
                />
              }
            />
          </ArkPanel.Properties>
          {isCompanyAdminOrHigher && (
            <ArkPanel.Footer className={styles.userFooter} topBorder>
              <div className={styles.buttons}>
                <ArkButton
                  color='red'
                  disabled={!changes || saving}
                  onClick={onClearClick}
                >
                  CLEAR CHANGES
                </ArkButton>
                <ArkButton
                  color='green'
                  disabled={!changes}
                  loading={saving}
                  onClick={onSaveClick}
                >
                  SAVE CHANGES
                </ArkButton>
              </div>
            </ArkPanel.Footer>
          )}
        </ArkPanel>
      </div>
    </div>
  )
}
