/**
 * ArkAudioMeterToolbar
 */

import React from 'react'

import { AUDIO_METERS_MULTIPLIER } from '../../../constants/config'

import styles from './ArkAudioMeterToolbar.module.css'

// FIXME combine with ArkAudioMeter

interface ArkToolbarAudioMeterProps {
  className?: string
  color?: string
  disabled?: boolean
  value?: number | undefined
}

const ArkAudioMeterToolbar = (props: ArkToolbarAudioMeterProps) => {
  const { className, color = 'var(--tx-light)', disabled, value = 0 } = props

  const _color = disabled ? 'var(--red)' : color
  const _value = Math.min(value * AUDIO_METERS_MULTIPLIER, 1)
  const height = disabled ? 0 : Math.round(_value * 100)

  return (
    <div className={`${styles.meter} ${className || ''}`}>
      <div className={styles.track} style={{ backgroundColor: _color }} />
      <div className={styles.bar} style={{ backgroundColor: _color, height: `${height}%` }} />
    </div>
  )
}

export default ArkAudioMeterToolbar
