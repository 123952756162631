import React, { ReactNode, RefObject, useEffect, useRef } from 'react'
import _ from 'lodash'

import {
  PROGRAM_CONTROLS_AUTOHIDE_ENABLED,
  PROGRAM_CONTROLS_AUTOHIDE_IDLE_DELAY
} from 'src/constants/config'

interface ProgramControlsAutohideProps {
  children: ReactNode
  containerRef: RefObject<HTMLDivElement>
  disabled: boolean
  onShowChange: (value: boolean) => void
}

const ProgramControlsAutohide = (props: ProgramControlsAutohideProps): ReactNode => {
  const mouseEnteredRef = useRef<boolean>(false)
  const mouseMoveTimeRef = useRef<number>(0)
  const show = useRef<boolean>(false)

  useEffect(() => {
    if (!PROGRAM_CONTROLS_AUTOHIDE_ENABLED || props.disabled) return

    const onMouseEnter = (): void => {
      // console.log('ProgramControlsAutohide - onMouseEnter')
      mouseEnteredRef.current = true
      if (!show.current) {
        show.current = true
        props.onShowChange(true)
      }
    }

    const onMouseLeave = (): void => {
      // console.log('ProgramControlsAutohide - onMouseLeave')
      mouseEnteredRef.current = false
      if (show.current) {
        show.current = false
        props.onShowChange(false)
      }
    }

    const onMouseMove = _.throttle(() => {
      // console.log('ProgramControlsAutohide - onMouseMove')
      if (!mouseEnteredRef.current) return
      mouseMoveTimeRef.current = Date.now()
      if (!show.current) {
        show.current = true
        props.onShowChange(true)
      }
    }, 300)

    props.containerRef.current?.addEventListener('mouseenter', onMouseEnter)
    props.containerRef.current?.addEventListener('mouseleave', onMouseLeave)
    props.containerRef.current?.addEventListener('mousemove', onMouseMove)

    const timer: ReturnType<typeof setTimeout> = setInterval(() => {
      const elapsed = Date.now() - mouseMoveTimeRef.current
      if (show.current && elapsed > PROGRAM_CONTROLS_AUTOHIDE_IDLE_DELAY) {
        show.current = false
        props.onShowChange(false)
      }
    }, 300)

    return () => {
      props.containerRef.current?.removeEventListener('mouseenter', onMouseEnter)
      props.containerRef.current?.removeEventListener('mouseleave', onMouseLeave)
      props.containerRef.current?.removeEventListener('mousemove', onMouseMove)
      clearInterval(timer)
    }
  }, [props.containerRef.current, props.disabled])

  return <>{props.children}</>
}

export default ProgramControlsAutohide
