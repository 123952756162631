import axios, { AxiosResponse, AxiosError, ResponseType } from 'axios' // { AxiosRequestConfig, AxiosPromise }

import ServerAuthAPI from './ServerAuthAPI'
import { ServerErrorCodes, ServerError, ServerAuthError, ServerAuthTokenExpiredError, ServerCompany2FARequiredError, ServerProject2FARequiredError, ServerAuthTokenNoDeviceError, ServerAuthInvalidCredentialsError, ServerAuth2FAOTPRequiredError, ServerAuthAccountLockedError } from './ServerAPIErrors' /* ServerNotFoundError, ServerAlreadyExistsError, ServerValidationError, ServerNoChangesError, */ /* ServerCompany2FARequiredError, ServerCompany2FAInvalidError, ServerProject2FARequiredError, ServerProject2FAInvalidError */

// refs:
//  https://www.intricatecloud.io/2020/03/how-to-handle-api-errors-in-your-web-app-using-axios/

class ServerAPIClient {
  public apiBaseUrl: string
  public authToken?: string
  public authDeviceUUID?: string
  public authApi?: ServerAuthAPI

  public companyAuthToken?: string
  public projectAuthToken?: string

  constructor (apiBaseUrl: string, authToken?: string, authDeviceUUID?: string) {
    this.apiBaseUrl = apiBaseUrl
    this.authToken = authToken
    this.authDeviceUUID = authDeviceUUID
    this.companyAuthToken = undefined
    this.projectAuthToken = undefined
  }

  apiGet = async (apiPath: string, headers?: any, refreshExpiredAuth: boolean = true, responseType?: ResponseType): Promise<AxiosResponse<any>> => {
    console.log('ServerAPIClient - apiGet - apiPath: ', apiPath, ' headers: ', headers, ' responseType: ', responseType, ' authToken: ', this.authToken)
    try {
      const config = this._apiConfig(headers, undefined, responseType)
      const response = await axios.get(this.apiBaseUrl + apiPath, config)
      console.log('ServerAPIClient - apiGet - apiPath: ', apiPath, ' response: ', response)
      return response
    } catch (error) {
      console.error('ServerAPIClient - apiGet - apiPath: ', apiPath, ' error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      const newError = await this.apiParseErrorResponse(error)
      if (refreshExpiredAuth) {
        const tokenRefreshed = await this._apiHandleAuthTokenExpiredError(newError)
        if (tokenRefreshed) {
          return await this.apiGet(apiPath, headers, false) // NB: recursive call with refreshExpiredAuth disabled so it doesn't potentially re-trigger & cause an infinite loop
        }
      }
      throw newError
    }
  }

  apiPost = async (apiPath: string, data: Object, headers?: any, refreshExpiredAuth: boolean = true): Promise<AxiosResponse<any>> => {
    console.log('ServerAPIClient - apiPost - apiPath: ', apiPath, ' data: ', data, ' headers: ', headers, ' authToken: ', this.authToken)
    try {
      const config = this._apiConfig(headers)
      console.log('ServerAPIClient - apiPost - config: ', config)
      const response = await axios.post(this.apiBaseUrl + apiPath, data, config)
      console.log('ServerAPIClient - apiPost - apiPath: ', apiPath, ' response: ', response)
      return response
    } catch (error) {
      console.error('ServerAPIClient - apiPost - apiPath: ', apiPath, ' error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      const newError = await this.apiParseErrorResponse(error)
      if (refreshExpiredAuth) {
        const tokenRefreshed = await this._apiHandleAuthTokenExpiredError(newError)
        if (tokenRefreshed) {
          return await this.apiPost(apiPath, data, headers, false) // NB: recursive call with refreshExpiredAuth disabled so it doesn't potentially re-trigger & cause an infinite loop
        }
      }
      throw newError
    }
  }

  apiPut = async (apiPath: string, data: Object, headers?: any, refreshExpiredAuth: boolean = true): Promise<AxiosResponse<any>> => {
    console.log('ServerAPIClient - apiPut - apiPath: ', apiPath, ' data: ', data, ' authToken: ', this.authToken)
    try {
      const config = this._apiConfig(headers)
      const response = await axios.put(this.apiBaseUrl + apiPath, data, config)
      console.log('ServerAPIClient - apiPut - apiPath: ', apiPath, ' response: ', response)
      return response
    } catch (error) {
      console.error('ServerAPIClient - apiPut - apiPath: ', apiPath, ' error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      const newError = await this.apiParseErrorResponse(error)
      if (refreshExpiredAuth) {
        const tokenRefreshed = await this._apiHandleAuthTokenExpiredError(newError)
        if (tokenRefreshed) {
          return await this.apiPut(apiPath, data, headers, false) // NB: recursive call with refreshExpiredAuth disabled so it doesn't potentially re-trigger & cause an infinite loop
        }
      }
      throw newError
    }
  }

  apiPatch = async (apiPath: string, data: Object, headers?: any, refreshExpiredAuth: boolean = true): Promise<AxiosResponse<any>> => {
    console.log('ServerAPIClient - apiPatch - apiPath: ', apiPath, ' data: ', data, ' authToken: ', this.authToken)
    try {
      const config = this._apiConfig(headers)
      const response = await axios.patch(this.apiBaseUrl + apiPath, data, config)
      console.log('ServerAPIClient - apiPatch - apiPath: ', apiPath, ' response: ', response)
      return response
    } catch (error) {
      console.error('ServerAPIClient - apiPatch - apiPath: ', apiPath, ' error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      const newError = await this.apiParseErrorResponse(error)
      if (refreshExpiredAuth) {
        const tokenRefreshed = await this._apiHandleAuthTokenExpiredError(newError)
        if (tokenRefreshed) {
          return await this.apiPatch(apiPath, data, headers, false) // NB: recursive call with refreshExpiredAuth disabled so it doesn't potentially re-trigger & cause an infinite loop
        }
      }
      throw newError
    }
  }

  // TODO: the data arg doesn't seem to be working with delete calls & the current api server setup? needs looking into (maybe server side & not client?)
  // TODO: tried the following below, but it doesn't seem to be working - ref: https://stackoverflow.com/a/56210828
  // TODO: UPDATE: see the new comments on data line used further down, may fix it (untested)
  // UPDATE: this was with the old firebase api - does it apply to the newer custom api server? (might be useful in the future?)
  apiDelete = async (apiPath: string, data?: Object, headers?: any, refreshExpiredAuth: boolean = true): Promise<AxiosResponse<any>> => {
    console.log('ServerAPIClient - apiDelete - apiPath: ', apiPath, ' authToken: ', this.authToken)
    try {
      const config = this._apiConfig(headers, data)
      const response = await axios.delete(this.apiBaseUrl + apiPath, config)
      console.log('ServerAPIClient - apiDelete - apiPath: ', apiPath, ' response: ', response)
      return response
    } catch (error) {
      console.error('ServerAPIClient - apiDelete - apiPath: ', apiPath, ' error: ', error, ' error.response: ', error.response, ' error.response.data: ', (error.response && error.response.data ? error.response.data : null))
      const newError = await this.apiParseErrorResponse(error)
      if (refreshExpiredAuth) {
        const tokenRefreshed = await this._apiHandleAuthTokenExpiredError(newError)
        if (tokenRefreshed) {
          return await this.apiDelete(apiPath, data, headers, false) // NB: recursive call with refreshExpiredAuth disabled so it doesn't potentially re-trigger & cause an infinite loop
        }
      }
      throw newError
    }
  }

  apiParseErrorResponse = async (error: Error) : Promise<Error> => {
    // console.log('ServerAPIClient - apiParseErrorResponse - error: ', error)
    if (this._isAxiosError(error)) {
      // client received an error response (5xx, 4xx)
      if (error.response) {
        if (error.response.status === 401) {
          const errorCode = error.response?.data?.error_code
          // TODO: should we could authTokenNotValid as 'token expired' as well? (originally this is what the api was returning, but authTokenExpired seems to have replaced it, need to double check..)
          if (errorCode === ServerErrorCodes.authTokenExpired || errorCode === ServerErrorCodes.authTokenNotValid) {
            console.log('ServerAPIClient - apiParseErrorResponse - ServerAuthTokenExpiredError - msg: ', error.response.data.error)
            return new ServerAuthTokenExpiredError(error.response.data.error)
          // TODO: DEPRECIATE - org/project forced 2fa switch auth
          // NB: OLD forced 2fa error handling - when switching org/project we used to show a 2fa enter screen, but we no longer use this method (we now only show a 2fa enter screen on login instead & the api fires different error codes for the forced 2fa)
          // } else if (errorCode === ServerErrorCodes.oldCompany2FARequired) {
          //   this.authApi?.setCompany2FARequired(true) // TESTING HERE <<<<
          //   return new ServerCompany2FARequiredError(error.response.data.error)
          // } else if (errorCode === ServerErrorCodes.oldCompany2FAInvalid) {
          //   this.authApi?.setCompany2FARequired(true) // TESTING HERE <<<<
          //   return new ServerCompany2FAInvalidError(error.response.data.error)
          // } else if (errorCode === ServerErrorCodes.oldProject2FARequired) {
          //   this.authApi?.setProject2FARequired(true) // TESTING HERE <<<<
          //   return new ServerProject2FARequiredError(error.response.data.error)
          // } else if (errorCode === ServerErrorCodes.oldProject2FAInvalid) {
          //   this.authApi?.setProject2FARequired(true) // TESTING HERE <<<<
          //   return new ServerProject2FAInvalidError(error.response.data.error)
          } else if (errorCode === ServerErrorCodes.auth2FAOTPRequired) {
            throw new ServerAuth2FAOTPRequiredError() // NB: not passing the `error.message` in so it uses our own wording instead
          // TESTING: NEW forced 2fa error codes - when switching org/project we now detect if 2fa is required before showing the page content & calling page specific api endpoints, so this is more a final catch all for any missed forced 2fa errors
          } else if (errorCode === ServerErrorCodes.newCompany2FARequired) {
            this.authApi?.setCompany2FARequired(true) // NB: not currently used? (the api no longer supports 2fa on org/project switch)
            return new ServerCompany2FARequiredError(error.response.data.error)
          } else if (errorCode === ServerErrorCodes.newProject2FARequired) {
            this.authApi?.setProject2FARequired(true) // NB: not currently used? (the api no longer supports 2fa on org/project switch)
            return new ServerProject2FARequiredError(error.response.data.error)
          } else if (errorCode === ServerErrorCodes.authTokenNoDevice) {
            return new ServerAuthTokenNoDeviceError(error.response.data.error)
          } else if (errorCode === ServerErrorCodes.authInvalidCredentials) {
            return new ServerAuthInvalidCredentialsError(error.response.data.error, error.response.data.result?.login_attempts_left)
          } else if (errorCode === ServerErrorCodes.authAccountLocked) {
            return new ServerAuthAccountLockedError() // NB: use the more user friendly & detailed local error message instead of the api error message from `error.response.data.error`
          }
          console.log('ServerAPIClient - apiParseErrorResponse - ServerAuthError - msg: ', error.response.data.error)
          return new ServerAuthError(error.response.data.error, errorCode)
        } else if (error.response.data) {
          // console.log('ServerAPIClient - apiParseErrorResponse - AxiosError - error.response.data: ', error.response.data)
          // TODO:
          // TODO: return specific error types depending on the error.response.status (using the most generic custom error ServerError type for now)
          // TODO: AND add support for the new custom/internal error_code in the error body response
          // TODO:
          // TESTING: updated to check for blob based requests & convert the blob error response to the normal json (NB: currently only used for image get endpoint usage)
          // ref: https://stackoverflow.com/a/68636030
          let errorData = error.response.data
          // console.log('ServerAPIClient - apiParseErrorResponse - errorData: ', errorData)
          // const errorText = await errorData.text()
          // console.log('ServerAPIClient - apiParseErrorResponse - errorText: ', errorText)
          if (error.request.responseType === 'blob' &&
            errorData instanceof Blob &&
            errorData.type &&
            errorData.type.toLowerCase().indexOf('json') !== -1
          ) {
            console.log('ServerAPIClient - apiParseErrorResponse - responseType == blob...')
            try {
              errorData = JSON.parse(await errorData.text())
            } catch (error) {
              // TODO: how to handle a failed json parse in a blob error response? just do nothing & rely on the fallback api error handling (response status code still available, but not the api custom errors)
            }
          }
          if (errorData.error) {
            console.error('ServerAPIClient - apiParseErrorResponse - AxiosError - error: ', error) // log out the original error incase extra data gets lost in the current basic custom error handling below
            console.error('ServerAPIClient - apiParseErrorResponse - AxiosError - errorData: ', errorData)
            return new ServerError(errorData.error, error.response.status, errorData.error_code, errorData)
          }
        }
      } else if (error.request) { // client never received a response, or request never left
        // TODO: ?
      } else { // fallback error handling
        // TODO: ?
      }
    }
    return error
  }

  // auth token renewal handling
  // NB: this should be called in the error handling for each api call type (get, post, put etc.)
  // - if an expired auth token error is found
  // - it attempts to renew the auth token with the refresh token (if there is one)
  // - if the renewal fails it triggers a logout (the refresh token has also expired or is invalid/not-set)
  //   & the calling code should pass up its original error to its calling code
  // - if the renewal succeeds the calling code should not return an error & instead attempt to re-run its original api call
  //   (make sure it doesn't get stuck in a potential infinite loop of api calls if the 2nd call somehow also returns an expired token error)
  // - UPDATE: addded support for `ServerAuthTokenNoDeviceError` which triggers when the device auth session is no longer valid (or doesn't exist)
  //   this now triggers a logout as well (as the user should be logged out if their device auth session is no longer valid)
  _apiHandleAuthTokenExpiredError = async (error: Error) : Promise<boolean> => {
    // console.log('ServerAPIClient - apiHandleAuthTokenExpiredError - error: ', error)
    if (error instanceof ServerAuthTokenExpiredError) {
      console.log('ServerAPIClient - apiHandleAuthTokenExpiredError === ServerAuthTokenExpiredError...')
      const tokenRefreshed = await this.authApi?.refreshAuthToken() ?? false
      console.log('ServerAPIClient - apiHandleAuthTokenExpiredError - tokenRefreshed: ', tokenRefreshed)
      if (!tokenRefreshed) {
        this.authApi?.tokenExpired()
      }
      return tokenRefreshed
    } else if (error instanceof ServerAuthTokenNoDeviceError) {
      console.log('ServerAPIClient - apiHandleAuthTokenExpiredError === ServerAuthTokenNoDeviceError...')
      this.authApi?.tokenExpired() // NB: treat this as an expired token, as the auth token/session for this device is no longer valid & so the user should be logged out
      return false
    }
    return false
  }

  _isAxiosError (error: any): error is AxiosError<any> {
    // ref: https://www.reddit.com/r/typescript/comments/f91zlt/how_do_i_check_that_a_caught_error_matches_a/fipdbxd?utm_source=share&utm_medium=web2x&context=3
    return (error as AxiosError).isAxiosError !== undefined
  }

  private _apiConfig = (headers?: any, data?: Object, responseType?: ResponseType) => {
    const config = {
      headers: this._apiHeaders(headers),
      // TESTING: moved here, was mistakenly added within the 'headers' section before - TODO: not tested this change but it may fix the issue mentioned above
      ...(data ? { data: data } : {}), // add data to a delete call - ref: https://stackoverflow.com/a/56210828
      ...(responseType ? { responseType: responseType } : {})
    }
    console.log('ServerAPIClient - _apiConfig - config: ', config)
    return config
  }

  private _apiHeaders = (headers?: any) => {
    console.log('ServerAPIClient - _apiHeaders - authToken:', this.authToken)
    return {
      ...(this.authToken ? { Authorization: `Bearer ${this.authToken}` } : {}),
      ...(this.authDeviceUUID ? { 'device-uuid': this.authDeviceUUID } : {}),
      ...(this.companyAuthToken ? { 'company-token': this.companyAuthToken } : {}),
      ...(this.projectAuthToken ? { 'project-token': this.projectAuthToken } : {}),
      ...headers
    }
  }

  // TEMP:
  getApiHeaders = (headers?: any) => {
    return this._apiHeaders(headers)
  }
}

export default ServerAPIClient
