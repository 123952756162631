import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { DropdownItemProps } from 'semantic-ui-react'

import { OBJECT_PROJECT_NAME, PAGE_SETTINGS_NAME } from 'src/constants/strings'
import ArkButton from 'src/core/components/ArkButton'
import ArkDropdown from 'src/core/components/ArkDropdown'
import ArkHint from 'src/core/components/ArkHint'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { Project } from 'src/core/models'
import { useProjectAdmin } from 'src/core/providers/ProjectAdminProvider'
import { useUser } from 'src/core/providers/UserProvider'
import { UtcOffsetNames } from 'src/core/types/project'
import { getUtcOffsetName } from 'src/core/utilities/project'

import styles from './ProjectSettingsView.module.css'

export interface ProjectTimeZoneViewProps {
  companyId: number
  project: Project
}

export const ProjectTimeZoneView = (props: ProjectTimeZoneViewProps) => {
  const projectAdmin = useProjectAdmin()
  const user = useUser()

  const [error, setError] = useState<Error>()
  const [saved, setSaved] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [warnings, setWarnings] = useState<Array<Error>>()

  const [utcOffsetMinutesValue, setUtcOffsetMinutesValue] = useState<number>(props.project.utcOffsetMinutes)

  useEffect(() => {
    setError(undefined)
    setSaved(false)
    setWarnings(undefined)
  }, [utcOffsetMinutesValue])

  const onClearClick = async () => {
    // console.log('ProjectTimeZoneView - onClearClick')
    setUtcOffsetMinutesValue(props.project.utcOffsetMinutes)
  }

  const onSaveClick = async () => {
    try {
      // console.log('ProjectTimeZoneView - onSaveClick')
      setSaving(true)
      await projectAdmin.actions.updateProjectInfo(props.companyId, props.project.id, {
        utcOffsetMinutes: utcOffsetMinutesValue
      })
      await user.actions.reloadUserData()
      setSaving(false)
      setSaved(true)
    } catch (error) {
      console.error('ProjectTimeZoneView - onSaveClick - error:', error)
      setSaving(false)
      setError(error)
    }
  }

  const utcOffsetOptions: DropdownItemProps[] = _.map(UtcOffsetNames, item => ({
    active: item.key === utcOffsetMinutesValue,
    onClick: () => setUtcOffsetMinutesValue(item.key),
    text: item.value,
    value: item.key
  }))

  const localOffset: number = new Date().getTimezoneOffset()

  const utcOffsetMinutesChanges: boolean = utcOffsetMinutesValue !== props.project.utcOffsetMinutes
  const changes: boolean = utcOffsetMinutesChanges

  return (
    <ArkPanel bordered className={styles.panel}>
      <ArkPanel.Header
        bottomBorder
        className={styles.panelHeader}
        title={`${OBJECT_PROJECT_NAME} Time Zone ${PAGE_SETTINGS_NAME}`}
      />
      <ArkPanel.Properties>
        {error && (
          <ArkPanel.PropertyRow>
            <ArkPanel.PropertyRowMessage negative>
              <ArkMessage.Header>Error</ArkMessage.Header>
              <p>{error.message}</p>
            </ArkPanel.PropertyRowMessage>
          </ArkPanel.PropertyRow>
        )}
        {saved && (
          <ArkPanel.PropertyRow>
            <ArkPanel.PropertyRowMessage positive>
              <ArkMessage.Header>Saved</ArkMessage.Header>
              <p>Your changes have been saved</p>
            </ArkPanel.PropertyRowMessage>
          </ArkPanel.PropertyRow>
        )}
        {warnings && _.some(warnings) && (
          <ArkPanel.PropertyRow>
            <ArkPanel.PropertyRowMessage warning>
              <ArkMessage.Header>Warning</ArkMessage.Header>
              {warnings.length === 1
                ? (
                  <p>{warnings[0].message}</p>
                )
                : (
                  <ArkMessage.List>
                    {warnings.map((saveWarning: Error, index: number) => (
                      <ArkMessage.Item key={index}>{saveWarning.message}</ArkMessage.Item>
                    ))}
                  </ArkMessage.List>
                )}
            </ArkPanel.PropertyRowMessage>
          </ArkPanel.PropertyRow>
        )}
        <ArkPanel.PropertyRow
          hasChanges={utcOffsetMinutesChanges}
          hint={
            <ArkHint
              iconSize={22}
              message={
                <>
                  <ArkSpacer small />
                  <div>The <a href='https://en.wikipedia.org/wiki/List_of_UTC_offsets' rel="noreferrer" target='_blank'>UTC offset</a> of the project.</div>
                  <ArkSpacer small />
                  <div><em>Note: Daylight savings is not automatically applied.</em></div>
                </>
              }
              popupPosition='bottom left'
              popupSize='small'
              title='Project Offset'
              type='info-circle'
            />
          }
          title='Project Offset:'
          titleSize='medium'
          value={
            <ArkDropdown
              fluid
              options={utcOffsetOptions}
              selection
              value={utcOffsetMinutesValue}
            />
          }
        />
        <ArkPanel.PropertyRow
          hint={
            <ArkHint
              iconSize={22}
              message={
                <>
                  <ArkSpacer small />
                  <div>The <a href='https://en.wikipedia.org/wiki/List_of_UTC_offsets' rel="noreferrer" target='_blank'>UTC offset</a> of your local device.</div>
                  <ArkSpacer small />
                  <div>Click &quot;Sync&quot; to change the Project Offset.</div>
                </>
              }
              popupPosition='bottom left'
              popupSize='small'
              title='Local Offset'
              type='info-circle'
            />
          }
          title='Local Offset:'
          titleSize='medium'
          value={
            <div className={styles.timeZoneLocalContainer}>
              <div>{getUtcOffsetName(localOffset)}</div>
              {localOffset !== utcOffsetMinutesValue && (
                <div>
                  <ArkButton
                    fluid
                    onClick={() => setUtcOffsetMinutesValue(localOffset)}
                    size='mini'
                  >
                    Sync
                  </ArkButton>
                </div>
              )}
            </div>
          }
        />
      </ArkPanel.Properties>
      <ArkPanel.Footer className={styles.panelFooter} topBorder>
        <div className={styles.panelFooterButtons}>
          <ArkButton
            color='red'
            disabled={!changes || saving}
            onClick={onClearClick}
          >
            CLEAR CHANGES
          </ArkButton>
          <ArkButton
            color='green'
            disabled={!changes}
            loading={saving}
            onClick={onSaveClick}
          >
            SAVE CHANGES
          </ArkButton>
        </div>
      </ArkPanel.Footer>
    </ArkPanel>
  )
}
