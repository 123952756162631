import React, { useContext } from 'react'

import { withUserContext, IUserMultiContext } from '.'
import { Company, VideoEngine } from '../models'
import { ICompanyAddData, ICompanyUpdateData } from '../models/company'
import { IVideoEngineAddData, IVideoEngineUpdateData } from '../models/video_engine'

import ServerAPIClient from '../services/ServerAPIClient'
import ServerCompanyAPI from '../services/ServerCompanyAPI'
import ServerProjectAPI from '../services/ServerProjectAPI'
import ServerUserAPI from '../services/ServerUserAPI'
import ServerVideoEngineAPI from '../services/ServerVideoEngineAPI'

export interface ISiteAdminStore {
}

export interface ISiteAdminActions {
  // company admin
  // NB: make sure to catch errors when calling any of these!
  getAllCompanies: () => Promise<Array<Company> | null>
  addCompany: (companyData: ICompanyAddData) => Promise<Company>
  updateCompany: (companyId: number, companyData: ICompanyUpdateData) => Promise<Company>
  deleteCompany: (companyId: number) => Promise<boolean>
  // user admin (site wide)
  disableUser2FA: (userId: number) => Promise<boolean>
  deleteUser: (userId: number) => Promise<boolean>
  // video engine admin
  getAllVideoEngines: () => Promise<Array<VideoEngine> | null>
  addVideoEngine: (videoEngineData: IVideoEngineAddData) => Promise<VideoEngine>
  updateVideoEngine: (videoEngineId: number, videoEngineData: IVideoEngineUpdateData) => Promise<VideoEngine>
  deleteVideoEngine: (videoEngineId: number) => Promise<boolean>
}

export interface ISiteAdminContext {
  actions: ISiteAdminActions
  store: ISiteAdminStore
}

export interface ISiteAdminMultiContext {
  siteAdminContext: ISiteAdminContext
}

export const SiteAdminContext = React.createContext<ISiteAdminContext>({} as ISiteAdminContext)

export const useSiteAdmin = () => useContext(SiteAdminContext)

export interface SiteAdminProviderProps extends IUserMultiContext {
  apiClient: ServerAPIClient
  companyApi?: ServerCompanyAPI
  projectApi?: ServerProjectAPI
  children?: React.ReactNode
  userAPI?: ServerUserAPI
  videoEngineAPI?: ServerVideoEngineAPI
}
export interface SiteAdminProviderState extends ISiteAdminStore {
  companyApi: ServerCompanyAPI
  projectApi: ServerProjectAPI
  userAPI: ServerUserAPI
  videoEngineAPI: ServerVideoEngineAPI
}

class SiteAdminProvider extends React.Component<SiteAdminProviderProps, SiteAdminProviderState> {
  constructor (props: SiteAdminProviderProps) {
    super(props)
    this.state = {
      companyApi: props.companyApi ?? new ServerCompanyAPI(this.props.apiClient),
      projectApi: props.projectApi ?? new ServerProjectAPI(this.props.apiClient),
      userAPI: props.userAPI ?? new ServerUserAPI(this.props.apiClient),
      videoEngineAPI: props.videoEngineAPI ?? new ServerVideoEngineAPI(this.props.apiClient)
    }
  }

  componentDidMount () {
  }

  // -------
  // NB: make sure to catch errors when calling any of these!

  getAllCompanies = async (): Promise<Array<Company> | null> =>
    this.state.companyApi.getAllCompanies()

  addCompany = async (companyData: ICompanyAddData): Promise<Company> => {
    const result = await this.state.companyApi.addCompany(companyData)
    // trigger a user data update so changes are available (e.g. to update the companies dropdown in the header etc.)
    // TODO: only trigger a partial reload?
    await this.props.userContext.actions.reloadUserData()
    return result
  }

  updateCompany = async (companyId: number, companyData: ICompanyUpdateData): Promise<Company> => {
    // console.log('SiteAdminProvider - updateCompany - companyId: ', companyId, ' companyData: ', companyData)
    const result = await this.state.companyApi.updateCompany(companyId, companyData)
    // console.log('SiteAdminProvider - updateCompany - result: ', result)
    // trigger a user data update so changes are available (e.g. to update the companies dropdown in the header etc.)
    // NB: if the api call throws an error, this won't be called, so the user data won't be reloaded (as expected)
    // TODO: only trigger a partial reload?
    await this.props.userContext.actions.reloadUserData()
    return result
  }

  deleteCompany = async (companyId: number): Promise<boolean> => {
    const result = await this.state.companyApi.deleteCompany(companyId)
    // TODO: update the logged in users company data so this change is shown in the listing
    return result
  }

  // -------
  // user admin (site wide)

  disableUser2FA = async (userId: number): Promise<boolean> => {
    console.log('SiteAdminProvider - disableUser2FA - userId: ', userId)
    const result = await this.state.userAPI.disableUser2FA(userId)
    return result
  }

  // WARNING: this completely removes/wipes the user from the system (site-admin/god use only)
  deleteUser = async (userId: number): Promise<boolean> => {
    const result = await this.state.userAPI.deleteUser(userId)
    return result
  }

  // -------
  // video engine admin

  getAllVideoEngines = async (): Promise<Array<VideoEngine> | null> =>
    this.state.videoEngineAPI.getAllVideoEngines()

  addVideoEngine = async (videoEngineData: IVideoEngineAddData): Promise<VideoEngine> =>
    this.state.videoEngineAPI.addVideoEngine(videoEngineData)

  updateVideoEngine = async (videoEngineId: number, videoEngineData: IVideoEngineUpdateData): Promise<VideoEngine> =>
    this.state.videoEngineAPI.updateVideoEngine(videoEngineId, videoEngineData)

  deleteVideoEngine = async (videoEngineId: number): Promise<boolean> =>
    this.state.videoEngineAPI.deleteVideoEngine(videoEngineId)

  // -------

  actions: ISiteAdminActions = {
    // company admin
    getAllCompanies: this.getAllCompanies,
    addCompany: this.addCompany,
    updateCompany: this.updateCompany,
    deleteCompany: this.deleteCompany,
    // user admin (site wide)
    disableUser2FA: this.disableUser2FA,
    deleteUser: this.deleteUser,
    // video engine admin
    getAllVideoEngines: this.getAllVideoEngines,
    addVideoEngine: this.addVideoEngine,
    updateVideoEngine: this.updateVideoEngine,
    deleteVideoEngine: this.deleteVideoEngine
  }

  // NB: in a class component the state ref won't be available on init & throws an error declaring it like this
  // NB: ..(if declared the same as the function component context does), reading the state values via optionals stops the errors
  // NB: ..but doesn't seem to relay the real state later, so passing in the whole state (which extends the store interface) as the store value
  // store: ISiteAdminStore = {
  //  ...
  // }

  render () {
    return (
      <SiteAdminContext.Provider
        value={{ actions: this.actions, store: this.state /* this.store - NB: see comments for ISiteAdminStore */ }}
      >
        {this.props.children}
      </SiteAdminContext.Provider>
    )
  }
}

const withSiteAdminContext = <P extends object>(Component: React.ComponentType<P>) => {
  const withSiteAdminContextHOC = (props: any) => (
    <SiteAdminContext.Consumer>
      {(siteAdminContext) => {
        if (siteAdminContext === null) {
          throw new Error('SiteAdminConsumer must be used within a SiteAdminProvider')
        }
        // console.log('withSiteAdminContext - render - SiteAdminContext.Consumer - siteAdminContext.store: ', siteAdminContext.store)
        return (<Component {...props} {...{ siteAdminContext: siteAdminContext }} />)
      }}
    </SiteAdminContext.Consumer>
  )
  return withSiteAdminContextHOC
}

const SiteAdminProviderWithContext = withUserContext(SiteAdminProvider)

export { SiteAdminProviderWithContext as SiteAdminProvider }
export { withSiteAdminContext }
