import React from 'react'

import { ProjectStatusProvider } from 'src/core/providers'

import AppNotice from '../../components/AppNotice/AppNotice'
import ArkViewerPage from '../../components/ArkViewerPage/ArkViewerPage'
import ChannelMixerModal from '../../components/ChannelView/ChannelMixerModal'
import ChannelView from '../../components/ChannelView/ChannelView'
import AudioLevelsProvider from '../../providers/AudioLevelsProvider'
import JswbwProvider from '../../providers/JswbwProvider'
import ViewerNoticeProvider from '../../providers/ViewerNoticeProvider'
import ViewerProvider from '../../providers/ViewerProvider'

class ViewerHomePage extends React.Component {
  render () {
    return (
      <ProjectStatusProvider>
        <ViewerNoticeProvider>
          <ViewerProvider>
            <AudioLevelsProvider>
              <JswbwProvider>
                <ArkViewerPage noScroll>
                  <AppNotice>
                    <ChannelView />
                    <ChannelMixerModal />
                  </AppNotice>
                </ArkViewerPage>
              </JswbwProvider>
            </AudioLevelsProvider>
          </ViewerProvider>
        </ViewerNoticeProvider>
      </ProjectStatusProvider>
    )
  }
}
export default ViewerHomePage
