import React from 'react'
import { Link } from 'react-router-dom'

import * as ROUTES from 'src/constants/routes'

import ArkCenterLayout from 'src/core/components/ArkCenterLayout'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkPage from 'src/core/components/ArkPage/ArkPage'
import ArkSegment from 'src/core/components/ArkSegment'

import styles from './LoginHelp.module.css'
import { useGlobalConfig } from 'src/core/providers'

const LoginHelpPage = () => {
  const globalConfigContext = useGlobalConfig()
  const globalConfig = globalConfigContext.store.config

  const _renderLoginHelpText = () => {
    return (
      <>
        <p>Having trouble logging in?</p>
      </>
    )
  }

  return (
    <ArkPage>
      <ArkCenterLayout>
        <ArkSegment inverted>
          <ArkHeader as="h2" textAlign="center">Login Help</ArkHeader>
          {_renderLoginHelpText()}
          <div className={styles.helpSection}>
            <ArkHeader as="h3" inverted className={styles.subHeader}>Forgotten Password</ArkHeader>
            <div className={styles.forgotPassword}>
              <p>If you have forgotten your password follow the link below to reset it:</p>
              <div className={styles.linkContainer}>
                <Link to={ROUTES.LOGIN_PASSWORD_FORGOT} className={styles.link}>Reset your password</Link>
              </div>
            </div>
          </div>
          <div className={styles.helpSection}>
            <ArkHeader as="h3" inverted className={styles.subHeader}>Two Factor Authentication (2FA)</ArkHeader>
            <div className={styles.tfa}>
              <p>If you have Two Factor Authentication (2FA) enabled and are having trouble logging in with it, please contact your {globalConfig.appName} admin to help reset it.</p>
            </div>
          </div>
          <div className={styles.helpSection}>
            <ArkHeader as="h3" inverted className={styles.subHeader}>General Support</ArkHeader>
            <div className={styles.support}>
              <p>For other support queries please check our support page:</p>
              <div className={styles.linkContainer}>
                <Link to={ROUTES.SUPPORT} target='_blank' className={styles.link}>Goto Support</Link>
              </div>
            </div>
          </div>
          <div className={styles.loginLink}>
            <Link to={ROUTES.LOGIN}>Back to Login</Link>
          </div>
        </ArkSegment>
      </ArkCenterLayout>
    </ArkPage>
  )
}

export default LoginHelpPage
