import React from 'react'
import { Modal, TransitionablePortal } from 'semantic-ui-react'

import ArkInfoView from 'src/core/components/ArkInfoView'
import { millisecondsToSeconds } from 'src/core/utilities/time'

import { useGlobalConfig } from '../GlobalConfigProvider'
import IdleIndicator from './IdleIndicator'

import styles from './IdlePromptView.module.css'

const DEBUG_ALWAYS_SHOW: boolean = false

interface IdlePromptViewProps {
  remaining: number
  show: boolean
}

const IdlePromptView = (props: IdlePromptViewProps) => {
  const globalConfig = useGlobalConfig()
  const config = globalConfig.store.config

  return (
    <TransitionablePortal
      open={DEBUG_ALWAYS_SHOW || props.show}
      transition={{
        animation: 'fade up',
        duration: 500
      }}
    >
      <Modal className={styles.modal} open>
        <ArkInfoView
          iconComponent={<IdleIndicator size={80} />}
          title='Are you still there?'
          message={
            <>
              <div>{config.appName} will automatically lock in {millisecondsToSeconds(props.remaining)} seconds.</div>
              <div>Move the mouse or press a key to cancel.</div>
            </>
          }
        />
      </Modal>
    </TransitionablePortal>
  )
}

export default IdlePromptView
