export type NavSection = 'admin' | 'company' | 'login' | 'project' | 'viewer'

export const getNavSection = (pathname: string): NavSection | undefined => {
  switch (pathname.split('/')[1]) {
    case 'admin':
      return 'admin'
    case 'company':
      return 'company'
    case 'login':
      return 'login'
    case 'project':
      return 'project'
    case 'view':
      return 'viewer'
    default:
      return undefined
  }
}
