/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import useResizeObserver from 'use-resize-observer'

import ArkSlider from 'src/core/components/ArkSlider'

import ProgramThumbnailPopup from './ProgramThumbnailPopup'
import { getTimelineMarkers } from './utilities'

import styles from './ProgramTimelineView.module.css'

// FIXME automatically scroll to keep thumb in view when using seek buttons

const CARET_WIDTH: number = 1
const SLIDER_SPACING: number = 15
const THUMB_SPACING: number = 5

interface ProgramTimelineViewProps {
  maxDuration: number
  scale: number
  seekTime: number;
  thumbnailPopupOpen: boolean
  thumbnailUrl: string
  vodDuration: number
  onSeekTimeChange: (time: number) => void
  onThumbnailPopupOpenChange: (open: boolean) => void
}

const ProgramTimelineView = (props: ProgramTimelineViewProps): ReactNode => {
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)

  const [containerWidth, setContainerWidth] = useState<number>(0)
  const [hoverSeekTime, setHoverSeekTime] = useState<number>(0)
  const [mouseX, setMouseX] = useState<number>(0)

  useResizeObserver<HTMLDivElement>({
    ref: containerRef,
    onResize: (size) => {
      if (size.width) setContainerWidth(size.width)
    }
  })

  useEffect(() => {
    const ratio: number = 1 - (props.seekTime / props.maxDuration)
    const range: number = ((containerWidth - (SLIDER_SPACING * 2)) * props.scale) - (THUMB_SPACING * 2)
    const left: number = ((range * ratio) + SLIDER_SPACING + THUMB_SPACING) - (containerWidth / 2)
    setTimeout(() => scrollRef.current?.scrollTo({ left }))
  }, [props.scale])

  /**
   * actions
   */

  const updateCaret = () => {
    setHoverSeekTime(getHoverSeekTime())
  }

  /**
   * utilities
   */

  const getBarWidth = (): number => {
    const range: number = ((containerWidth - (SLIDER_SPACING * 2)) * props.scale) - (THUMB_SPACING * 2)
    const ratio: number = _.clamp(props.vodDuration / props.maxDuration, 0, 1)
    const width: number = (range * ratio) + THUMB_SPACING
    return width
  }

  const getCaretLeft = (): number => {
    if (!scrollRef.current) return 0
    const range: number = ((containerWidth - (SLIDER_SPACING * 2)) * props.scale) - (THUMB_SPACING * 2)
    const time: number = (props.maxDuration - hoverSeekTime) / props.maxDuration
    let left: number = (range * time) + SLIDER_SPACING + THUMB_SPACING - scrollRef.current.scrollLeft
    left = _.clamp(left, SLIDER_SPACING + THUMB_SPACING, range + SLIDER_SPACING + THUMB_SPACING - CARET_WIDTH)
    return left
  }

  const getContentWidth = (): number | undefined => {
    if (!containerWidth) return undefined
    return ((containerWidth - (SLIDER_SPACING * 2)) * props.scale) + (SLIDER_SPACING * 2)
  }

  const getHoverSeekTime = (): number => {
    if (!contentRef.current) return 0
    const boundingClientRect: DOMRect = contentRef.current.getBoundingClientRect()
    const range: number = boundingClientRect.width - (SLIDER_SPACING * 2) - (THUMB_SPACING * 2)
    const value: number = _.clamp(mouseX - boundingClientRect.left - SLIDER_SPACING - THUMB_SPACING, 0, range)
    const ratio: number = value / range
    const time: number = props.maxDuration - Math.round(props.maxDuration * ratio)
    return time
  }

  const getNoFragment = (): boolean => {
    return (props.vodDuration - hoverSeekTime) < 0
  }

  /**
   * render
   */

  const caret: ReactNode = (
    <div
      className={styles.caret}
      style={{
        left: getCaretLeft(),
        opacity: props.thumbnailPopupOpen ? 1 : 0
      }}
    >
      <div className={styles.caretBody} />
    </div>
  )

  return (
    <div className={styles.container} ref={containerRef}>
      <div
        className={styles.scroll}
        ref={scrollRef}
        onScroll={updateCaret}
      >
        <div
          className={styles.content}
          ref={contentRef}
          style={{ width: getContentWidth() }}
          onMouseEnter={() => props.onThumbnailPopupOpenChange(true)}
          onMouseLeave={() => props.onThumbnailPopupOpenChange(false)}
          onMouseMove={(event) => {
            setMouseX(event.nativeEvent.clientX)
            updateCaret()
          }}
        >
          <div className={styles.track}>
            <div className={styles.bar} style={{ width: getBarWidth() }} />
          </div>
          <div className={styles.sliderContainer}>
            <ArkSlider
              barColor='transparent'
              markers={getTimelineMarkers(props.maxDuration, props.scale)}
              max={props.maxDuration}
              trackColor='transparent'
              value={props.maxDuration - props.seekTime}
              onChange={() => props.onSeekTimeChange(getHoverSeekTime())}
            />
          </div>
        </div>
      </div>
      <ProgramThumbnailPopup
        hoverSeekTime={hoverSeekTime}
        noFragment={getNoFragment()}
        open={props.thumbnailPopupOpen}
        thumbnailUrl={props.thumbnailUrl}
        trigger={caret}
      />
    </div>
  )
}

export default ProgramTimelineView
