import React from 'react'

import ArkCenterLayout from 'src/core/components/ArkCenterLayout'
import ArkPage from 'src/core/components/ArkPage/ArkPage'

import VerifyEmailForm from './VerifyEmailForm'

const VerifyEmailWarningPage = () => {
  return (
    <ArkPage>
      <ArkCenterLayout>
        <VerifyEmailForm showVerifyRequiredMsg={true} />
      </ArkCenterLayout>
    </ArkPage>
  )
}

export default VerifyEmailWarningPage
