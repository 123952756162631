import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import { CARDS_MOCK_API_ENABLED } from 'src/constants/config'
import { OBJECT_CARD_NAME, OBJECT_IMAGE_NAME, OBJECT_IMAGE_NAME_PLURAL } from 'src/constants/strings'
import ArkButton, { ArkButtonGroup } from 'src/core/components/ArkButton'
import ArkCard from 'src/core/components/ArkCard'
import ArkFieldset from 'src/core/components/ArkFieldset'
import ArkFormLabel from 'src/core/components/ArkForm/ArkFormLabel'
import ArkFormText from 'src/core/components/ArkForm/ArkFormText'
import ArkHeader from 'src/core/components/ArkHeader'
import ArkInput from 'src/core/components/ArkInput'
import ArkModal from 'src/core/components/ArkModal'
import ArkSlider from 'src/core/components/ArkSlider'
import ArkSpacer from 'src/core/components/ArkSpacer'
import ArkTextArea from 'src/core/components/ArkTextArea'
import { Card, DEFAULT_COMPANY_CARD, DEFAULT_IMAGE, Image } from 'src/core/models'
import { CompanyImageContextValue, useCompanyImage } from 'src/core/providers/CompanyImageProvider'

import styles from './CardEditorModal.module.css'

// FIXME use <ArkForm>

const MAX_TITLE_LENGTH: number = 50
const MAX_MESSAGE_LENGTH: number = 250
const MAX_FOOTER_LENGTH: number = 50

interface CardEditorModalProps {
  card: Card
  onClose: () => void
  onSubmit: (props: Partial<Card>) => void
  open: boolean
}

const CardEditorModal = (props: CardEditorModalProps) => {
  const companyImage: CompanyImageContextValue = useCompanyImage()

  const backgroundColorColorInput = useRef<HTMLInputElement>(null)
  const backgroundImageFileInput = useRef<HTMLInputElement>(null)

  const [backgroundColor, setBackgroundColor] = useState<string>('')
  const [backgroundColorOpen, setBackgroundColorOpen] = useState<boolean>(false)
  const [backgroundImage, setBackgroundImage] = useState<Image>()
  const [backgroundImageUploading, setBackgroundImaeUploading] = useState<boolean>(false)
  const [backgroundOpacity, setBackgroundOpacity] = useState<number>(100)
  const [backgroundSize, setBackgroundSize] = useState<'contain' | 'cover'>('contain')
  const [colorScheme, setColorScheme] = useState<'dark' | 'light'>('dark')
  const [footer, setFooter] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [section, setSection] = useState<'background' | 'text'>('background')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')

  const previewCard: Card = {
    ...props.card,
    background: backgroundImage ? { ...DEFAULT_IMAGE, url: backgroundImage.url } : null,
    background_opacity: backgroundOpacity,
    background_size: backgroundSize,
    colour: backgroundColor || null,
    colour_scheme: colorScheme,
    footer,
    message,
    title
  }

  useEffect(() => {
    if (!props.open) return
    setBackgroundColor(props.card.colour || DEFAULT_COMPANY_CARD.colour!)
    setBackgroundImage(props.card.background || undefined)
    setBackgroundOpacity(props.card.background_opacity)
    setBackgroundSize(props.card.background_size)
    setColorScheme(props.card.colour_scheme)
    setFooter(props.card.footer || DEFAULT_COMPANY_CARD.footer!)
    setMessage(props.card.message || DEFAULT_COMPANY_CARD.message!)
    setTitle(props.card.title || DEFAULT_COMPANY_CARD.title!)
    setSubmitting(false)
  }, [props.open])

  const onBackgroundColorClick = (): void => {
    // console.log('CardEditorModal - onBackgroundColorClick')
    if (backgroundColorOpen) {
      setBackgroundColorOpen(false)
      return
    }
    setBackgroundColorOpen(true)
    backgroundColorColorInput.current!.click()
  }

  const onBackgroundDeleteClick = (): void => {
    // console.log('CardEditorModal - onBackgroundDeleteClick')
    if (!window.confirm(`Are you sure you want to delete "${backgroundImage!.file_name_original}"?`)) return // FIXME add custom dialog box
    setBackgroundImage(undefined)
  }

  const onBackgroundImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
    // console.log('CardEditorModal - onBackgroundImageChange - event:', event)
    const file: File | undefined = _.first(event.target.files)
    if (!file) return
    setBackgroundImaeUploading(true)
    const image: Image | undefined = await companyImage.uploadImageInteractive(file)
    if (image) setBackgroundImage(image)
    setBackgroundImaeUploading(false)
  }

  const onSubmit = (): void => {
    // console.log('CardEditorModal - onSubmit')
    setSubmitting(true)
    const newProps: Partial<Card> = {
      background_id: backgroundImage?.id || null,
      ...(CARDS_MOCK_API_ENABLED && { background: backgroundImage }),
      background_opacity: backgroundOpacity,
      background_size: backgroundSize,
      colour: backgroundColor,
      colour_scheme: colorScheme,
      footer,
      message,
      title
    }
    // console.log('CardEditorModal - onSubmit - props:', newProps)
    props.onSubmit(newProps)
  }

  return (
    <ArkModal className={styles.modal} onClose={props.onClose} open={props.open}>
      <ArkHeader as='h2' inverted>{`Edit ${OBJECT_CARD_NAME} content`}</ArkHeader>
      <div className={styles.split}>
        <ArkCard card={previewCard} className={styles.card} />
        <ArkSpacer large />
        <div className={styles.right}>
          <ArkButtonGroup size="small">
            <ArkButton active={section === 'background'} onClick={() => setSection('background')}>Background</ArkButton>
            <ArkButton active={section === 'text'} onClick={() => setSection('text')}>Text</ArkButton>
          </ArkButtonGroup>
          <ArkSpacer large />
          {section === 'background' && (
            <ArkFieldset className={styles.fieldset}>
              <div className={styles.backgroundColorContainer}>
                <ArkInput
                  className={styles.backgroundColorInput}
                  label='Color'
                  onBlur={() => setBackgroundColorOpen(false)}
                  onClick={onBackgroundColorClick}
                  onClear={backgroundColor !== DEFAULT_COMPANY_CARD.colour
                    ? () => setBackgroundColor(DEFAULT_COMPANY_CARD.colour!)
                    : undefined
                  }
                  style={{ backgroundColor }}
                  value={backgroundColor.toUpperCase()}
                />
                <input
                  className={styles.backgroundColorColorInput}
                  onChange={(event) => setBackgroundColor(event.target.value)}
                  ref={backgroundColorColorInput}
                  type='color'
                  value={backgroundColor}
                />
              </div>
              <ArkSpacer large />
              <ArkInput
                className={styles.backgroundImageInput}
                label={OBJECT_IMAGE_NAME}
                onClick={() => backgroundImageFileInput.current!.click()}
                onClear={onBackgroundDeleteClick}
                placeholder={backgroundImageUploading
                  ? `Uploading ${OBJECT_IMAGE_NAME.toLowerCase()}…`
                  : `Upload ${OBJECT_IMAGE_NAME.toLowerCase()}…`
                }
                readOnly
                value={backgroundImage?.file_name_original || ''}
              />
              <input
                accept='image/jpeg, image/png'
                className={styles.backgroundImageFileInput}
                onChange={onBackgroundImageChange}
                ref={backgroundImageFileInput}
                type='file'
              />
              <ArkFormText>
                <div>{`Supports JPEG or PNG ${OBJECT_IMAGE_NAME_PLURAL.toLowerCase()} up to 20 MB.`}</div>
                <div>{`We recommend a 4K (3840 x 2160 px) ${OBJECT_IMAGE_NAME.toLowerCase()} under 5 MB.`}</div>
              </ArkFormText>
              <ArkSpacer large />
              <ArkFormLabel>Fit:</ArkFormLabel>
              <ArkButtonGroup fluid size="tiny">
                <ArkButton active={backgroundSize === 'contain'} onClick={() => setBackgroundSize('contain')}>
                    Contain
                </ArkButton>
                <ArkButton active={backgroundSize === 'cover'} onClick={() => setBackgroundSize('cover')}>
                    Cover
                </ArkButton>
              </ArkButtonGroup>
              <ArkSpacer large />
              <ArkFormLabel right={`${backgroundOpacity}%`}>Opacity</ArkFormLabel>
              <ArkSlider
                onChange={setBackgroundOpacity}
                value={backgroundOpacity}
              />
            </ArkFieldset>
          )}
          {section === 'text' && (
            <ArkFieldset className={styles.fieldset}>
              <ArkFormLabel>Color:</ArkFormLabel>
              <ArkButtonGroup fluid size="tiny">
                <ArkButton active={colorScheme === 'dark'} onClick={() => setColorScheme('dark')}>White</ArkButton>
                <ArkButton active={colorScheme === 'light'} onClick={() => setColorScheme('light')}>Black</ArkButton>
              </ArkButtonGroup>
              <ArkSpacer large />
              <ArkInput
                label='Title'
                maxLength={MAX_TITLE_LENGTH}
                onChange={(event) => setTitle(event.target.value)}
                onClear={() => setTitle('')}
                value={title}
              />
              <ArkFormText alignRight>
                {`${title.length}/${MAX_TITLE_LENGTH} characters`}
              </ArkFormText>
              <ArkTextArea
                label='Message'
                maxLength={MAX_MESSAGE_LENGTH}
                onChange={(event) => setMessage(event.target.value)}
                onClear={() => setMessage('')}
                value={message}
              />
              <ArkFormText alignRight>
                {`${message.length}/${MAX_MESSAGE_LENGTH} characters`}
              </ArkFormText>
              <ArkInput
                label='Footer'
                maxLength={MAX_FOOTER_LENGTH}
                onChange={(event) => setFooter(event.target.value)}
                onClear={() => setFooter('')}
                value={footer}
              />
              <ArkFormText alignRight>
                {`${footer.length}/${MAX_FOOTER_LENGTH} characters`}
              </ArkFormText>
            </ArkFieldset>
          )}
        </div>
      </div>
      <ArkSpacer large />
      <div className={styles.footer}>
        <ArkButton
          basic
          className={styles.footerButton}
          color='orange'
          onClick={props.onClose}
          size='large'
        >
          Cancel
        </ArkButton>
        <ArkSpacer grow />
        <ArkButton
          basic
          className={styles.footerButton}
          color='blue'
          loading={submitting}
          onClick={onSubmit}
          size='large'
        >
          Save
        </ArkButton>
      </div>
    </ArkModal>
  )
}

export default CardEditorModal
