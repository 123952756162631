import React from 'react'

import styles from './ArkVolumeInput.module.css'

// FIXME use ArkSlider

const DEBUG_LAYOUT: boolean = false

interface ArkVolumeInputProps {
  className?: string
  disabled?: boolean
  large?: boolean
  onChange: (value: number) => void
  onMouseEnter?: () => void // for ArkTooltip
  onMouseLeave?: () => void // for ArkTooltip
  size?: number
  value: number
  vertical?: boolean
}

const ArkVolumeInput = (props: ArkVolumeInputProps) => {
  const { className, disabled, large, onChange, size = 100, value, vertical } = props

  return (
    <span
      className={`${disabled ? styles.disabled : ''} ${large ? styles.large : ''} ${vertical ? styles.vertical : ''}`}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      style={DEBUG_LAYOUT ? { backgroundColor: 'rgba(255, 0, 0, 0.25)' } : undefined}
    >
      <div
        className={styles.outer}
        style={vertical ? { height: size } : { width: size }}
      >
        <div
          className={`${styles.inner} ${className || ''}`}
          style={{ width: size }}
        >
          <div className={styles.track}>
            <div className={styles.bar} style={{ width: `${value * 100}%` }} />
          </div>
          <input
            className={styles.input}
            max={1}
            min={0}
            step={0.01}
            type='range'
            value={value}
            onChange={(event) => onChange(parseFloat(event.target.value))}
            onClick={(event) => event.stopPropagation()}
          />
        </div>
      </div>
    </span>
  )
}

export default ArkVolumeInput
