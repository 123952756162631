import React from 'react'

import styles from './LoginNotUserView.module.css'

export interface LoginNotUserViewProps {
  className?: string
  email: string
  onClick?: () => void
  title?: string // optional custom title if you don't want to show the default 'Not {email}?' one
}

const LoginNotUserView = (props: LoginNotUserViewProps) => {
  const { className, email, onClick, title } = props
  return (
    <div className={`${styles.notEmailLink}${className ? ' ' + className : ''}`}>
      <span className={styles.notEmailLink} onClick={() => { if (onClick) onClick() }}>{title || <>Not {email}?</>}</span>
    </div>
  )
}

export default LoginNotUserView
