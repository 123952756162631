/**
 * ArkLargeButton
 */

import React, { ReactNode, useContext } from 'react'

import { ResponsiveContext } from '../../providers/ResponsiveProvider'
import ArkButton from '../ArkButton'
import ArkIcon, { IconName } from '../ArkIcon'
import ArkSpacer from '../ArkSpacer'

import styles from './ArkLargeButton.module.css'

/**
 * button
 */

interface ArkLargeButtonProps {
  iconName: IconName
  locked?: boolean
  onClick: () => void
  show: boolean
  title: string
}

const ArkLargeButton = ({ iconName, locked, onClick, show, title }: ArkLargeButtonProps) => {
  const { store } = useContext(ResponsiveContext)
  const { isMobile } = store

  if (!show) return null

  return (
    <ArkButton className={`${styles.button} ${isMobile ? styles.buttonMobile : ''}`} onClick={onClick}>
      <div className={styles.iconContainer}>
        <ArkIcon name={iconName} size={64} />
        {locked && <ArkIcon className={styles.lock} color='var(--th-danger)' name='lock-solid' size={24} />}
      </div>
      <ArkSpacer size={isMobile ? 20 : 30} />
      <div className={styles.title}>{title}</div>
    </ArkButton>
  )
}
export default ArkLargeButton

/**
 * group
 */

export interface ArkLargeButtonGroupProps {
  children: ReactNode
}

export const ArkLargeButtonGroup = ({ children }: ArkLargeButtonGroupProps) =>
  <div className={styles.group}>{children}</div>
