import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

import { parseJwt } from 'src/core/utilities/token'

import { useAuth, useNav } from 'src/core/providers'
import { ServerAuth2FAOTPRequiredError, ServerTokenNotFoundError } from 'src/core/services/ServerAPIErrors'

import * as ROUTES from 'src/constants/routes'

import ArkButton from 'src/core/components/ArkButton'
import ArkDivider from 'src/core/components/ArkDivider'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkMessage from 'src/core/components/ArkMessage'
import ArkSpacer from 'src/core/components/ArkSpacer'

import User2FAInputView, { User2FAInputViewMode } from '../../account/User2FAPage/User2FAInputView'

import styles from './LoginHelp.module.css'

const formSchema = yup.object().shape({
  password: yup.string().min(6).max(40).required(),
  passwordConfirm: yup.string().oneOf([yup.ref('password'), undefined], 'Passwords must match')
})

interface IProps {
  resetToken: string
}

const LoginPasswordResetStage2Form = (props: IProps) => {
  const mounted = useRef(false)

  const { resetToken } = props

  const authContext = useAuth()
  const navContext = useNav()

  const [tokenEmail, setTokenEmail] = useState<string | undefined>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [passwordReset, setPasswordReset] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>(!resetToken ? new Error('Invalid password reset code, please check the email link & try again.') : undefined)

  // 2FA enabled users requires a 2nd step, where we cache the new password & prompt for their 2FA OTP code before re-submitting
  const [tfaRequired, setTFARequired] = useState<boolean>(false)
  const [newPassword, setNewPassword] = useState<string | undefined>()

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    try {
      const decodedToken = resetToken ? parseJwt(resetToken) : undefined // NB: this doesn't validate the token, just decodes it
      console.log('LoginPasswordResetStage2Form - decodedToken:', decodedToken)
      setTokenEmail(decodedToken?.email)
    } catch (error) {
      console.error('LoginPasswordResetStage2Form - error:', error)
      if (mounted.current) setError(new Error('Invalid password reset token, please check the email link & try again.'))
    }
  }, [])

  // -------

  const submitResetPassword = async (_newPassword: string, otpCode?: string) => {
    console.log('LoginPasswordResetStage2Form - submitResetPassword - _newPassword:', _newPassword, 'otpCode:', otpCode)
    if (isSubmitting) return
    setIsSubmitting(true)
    if (error) setError(undefined)
    try {
      const result = await authContext.actions.resetEmailPassword(resetToken, _newPassword, otpCode)
      if (result) {
        if (mounted.current) {
          setIsSubmitting(false)
          setPasswordReset(true)
          // TODO: ideally re-direct on success to remove the token from the url (stop page refresh showing the form with an expired/used token)
          // TODO: but still need to show a success message as well
          if (tfaRequired) setTFARequired(false)
          if (newPassword) setNewPassword(undefined)
        }
        return
      }
      throw new Error('Error resetting password')
    } catch (error) {
      console.error('LoginPasswordResetStage2Form - onFormSubmit - error:', error)
      if (mounted.current) setIsSubmitting(false)
      // catch if the password reset token is invalid/not-found & display a custom error message (likely was already used, or possibly expired? (although maybe that returns a different error?))
      if (error instanceof ServerTokenNotFoundError) {
        console.log('LoginPasswordResetStage2Form - onFormSubmit - error - ServerTokenNotFoundError - error:', error)
        if (mounted.current) setError(new ServerTokenNotFoundError('Password reset failed, please start again.'))
      // catch if 2FA is required & flip into 2FA OTP entry mode (instead of just showing the error)
      } else if (error instanceof ServerAuth2FAOTPRequiredError) {
        console.log('LoginPasswordResetStage2Form - onFormSubmit - error - ServerAuth2FAOTPRequiredError - error:', error)
        if (mounted.current) {
          setTFARequired(true)
          setNewPassword(_newPassword)
        }
      } else {
        if (mounted.current) setError(error)
      }
    }
  }

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    const { password } = fieldValues
    await submitResetPassword(password)
  }

  // -------

  const resetForm = () => {
    if (tfaRequired) setTFARequired(false)
    if (newPassword) setNewPassword(undefined)
    if (isSubmitting) setIsSubmitting(false)
    if (error) setError(undefined)
  }

  // -------

  const formFields: Array<ArkFormField> = []
  // show the user's email address (from the token) as a reference
  if (tokenEmail) {
    formFields.push({ type: ArkFormFieldType.Field, key: 'emailPreview', content: (<div className={styles.email}><span className={styles.title}>Email:</span><span className={styles.value}>{tokenEmail}</span></div>) })
    formFields.push({ type: ArkFormFieldType.Field, key: 'emailDivider', content: (<ArkDivider className={styles.divider} horizontal section></ArkDivider>) })
  }
  formFields.push({ type: ArkFormFieldType.Input, key: 'password', label: 'New Password', required: true, fieldProps: { type: 'password' } }) // icon: lock
  formFields.push({ type: ArkFormFieldType.Input, key: 'passwordConfirm', label: 'Confirm New Password', required: true, fieldProps: { type: 'password' } })
  formFields.push({ type: ArkFormFieldType.Button, key: 'submit', label: 'Reset Password', fieldProps: { loading: isSubmitting, fluid: true } })

  // -------

  // renders the 2FA OTP input view within a mock ArkForm fieldset to keep the styling consistent with the previous password entry form
  // NB: originally used an actual ArkForm & its fieldset, but as `User2FAInputView` is a form in itself, it was throwing html syntax errors with the use of nested forms so switched to use a mock form fieldset instead
  const render2FAOTPForm = () => {
    return (
      <div className={`${styles.passwordResetForm2FA}`}>
        <div className={`${styles.fieldset} ${styles.fieldsetBorder}`}>
          <ArkMessage warning visible>
            <ArkMessage.Header>2FA Confirmation</ArkMessage.Header>
            <p>Enter your 2FA OTP code to confirm your password change</p>
          </ArkMessage>
          <div className={styles.email}>
            <span className={styles.title}>Email:</span>
            <span className={styles.value}>{tokenEmail}</span>
          </div>
          <ArkDivider className={styles.divider} horizontal section></ArkDivider>
          <User2FAInputView
            inputMode={User2FAInputViewMode.split}
            autoSubmit={true}
            isBusy={isSubmitting}
            error={error}
            onSubmit={async (otpCode) => {
              // console.log('LoginPasswordResetStage2Form - render2FAOTPForm - otpCode:', otpCode)
              // halt with an error if we don't have the old & new passwords cached (shouldn't happen in normal usage)
              if (!newPassword) {
                // console.error('LoginPasswordResetStage2Form - render2FAOTPForm - missing newPassword')
                setError(new Error('An error occurred. Please try again.'))
                return
              }
              // re-submit the change password request with the 2FA OTP code
              await submitResetPassword(newPassword, otpCode)
            }}
          />
          <div className={styles.tfaCancel}>
            <a href="#" onClick={(event) => {
              event.preventDefault()
              resetForm()
            }}>Cancel</a>
          </div>
        </div>
      </div>
    )
  }

  const tokenNotFoundError = !!(error && error instanceof ServerTokenNotFoundError)
  const tokenError = !!(error && !tokenNotFoundError) // TESTING: catch any other errors so we can show retry/resend UI

  return (
    <>
      {passwordReset && (
        <>
          <ArkMessage positive>
            <ArkMessage.Header>Password Changed</ArkMessage.Header>
            <p>Your password has been successfully changed. <br />You can now login.</p>
          </ArkMessage>
          <ArkButton fluid size="large" onClick={() => {
            navContext.actions.goto(ROUTES.LOGIN)
          }}>
            Login
          </ArkButton>
        </>
      )}
      {(tokenNotFoundError || tokenError) && (
        <>
          <ArkMessage warning={tokenNotFoundError} error={!tokenNotFoundError} className={styles.resetTokenNotFound}>
            <ArkMessage.Header>Password Reset Error</ArkMessage.Header>
            {tokenNotFoundError && (<p>Password reset link has expired.</p>)}
            {tokenError && (<p>{error?.message ?? 'An error occurred. Please try again.'}</p>)}
          </ArkMessage>
          <ArkButton fluid size="large" onClick={() => {
            navContext.actions.goto({ pathname: ROUTES.LOGIN_PASSWORD_FORGOT, state: { email: tokenEmail } })
          }}>
            Restart password reset
          </ArkButton>
          <div className={styles.resetCancel}>
            <a href="#" onClick={(event) => {
              event.preventDefault()
              resetForm()
            }}>Cancel</a>
          </div>
        </>
      )}
      {tfaRequired && (
        <>
          <ArkSpacer size={10} />
          {render2FAOTPForm()}
        </>
      )}
      {(!passwordReset && !tfaRequired && !tokenNotFoundError && !tokenError) && (
        <>
          <ArkForm
            formKey="passwordReset"
            className={styles.passwordResetForm2}
            inverted
            formError={error}
            formFields={formFields}
            formSchema={formSchema}
            onFormSubmit={onFormSubmit}
          ></ArkForm>
          <ArkDivider horizontal inverted></ArkDivider>
          <div className={styles.loginLink}>
            <Link to={ROUTES.LOGIN}>Back to Login</Link>
          </div>
        </>
      )}
    </>
  )
}

export default LoginPasswordResetStage2Form
