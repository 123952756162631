import React, { ReactNode } from 'react'
import _ from 'lodash'
import { Popup, PopupProps } from 'semantic-ui-react'

import styles from './ArkTooltip.module.css'

const DEBUG_ALWAYS_SHOW: boolean = false

export interface ArkTooltipProps extends PopupProps {
  children: ReactNode
  disabled?: boolean
  onClick?: () => void
  position?: PopupProps['position']
  text: string
}

const ArkTooltip = (props: ArkTooltipProps) => (
  <Popup
    className={styles.popup}
    disabled={props.disabled}
    inverted
    mouseEnterDelay={1000}
    open={DEBUG_ALWAYS_SHOW || undefined}
    position={props.position}
    trigger={props.children}
  >
    {_.map(props.text.split('\n'), (line, index) => (
      <div key={index}>{line}</div>)
    )}
  </Popup>
)

export default ArkTooltip
