import React, { useState } from 'react'

import { DVR_ENABLED } from 'src/constants/config'
import * as ROUTES from 'src/constants/routes'
import { FEATURE_DVR_NAME, SECTION_COMPANY_NAME, SECTION_MANAGER_SUFFIX_NAME } from 'src/constants/strings'
import { useAuth, useNav, useUser } from 'src/core/providers'

import { AuthLoginServiceType, Company, CompanyLoginServiceMinimal } from 'src/core/models'

import ArkButton from 'src/core/components/ArkButton'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkSpacer from 'src/core/components/ArkSpacer'

import CompanyDeleteView, { CompanyDidDeleteCallback } from './CompanyDeleteView'

import styles from './AdminCompany.module.css'

export type CompanyEditCallback = (company: Company) => void

interface IProps {
  company?: Company
  onEdit?: CompanyEditCallback
  onDidDelete?: CompanyDidDeleteCallback
}

const AdminCompanySidebar = (props: IProps) => {
  const { company, onEdit: _onEdit, onDidDelete: _onDidDelete } = props

  const authContext = useAuth()
  const userContext = useUser()
  const navContext = useNav()

  const [companyDeleted, setCompanyDeleted] = useState<boolean>(false)

  // -------

  // const resetView = () => {
  //   setCompanyDeleted(false) // reset the state
  // }

  // -------

  const onEdit = () => {
    if (_onEdit && company) _onEdit(company)
  }

  const onDidDeleteCompany = (company: Company) => {
    if (_onDidDelete) _onDidDelete(company)
    setCompanyDeleted(true)
  }

  // -------

  const onOpenCompanyManager = () => {
    if (company) {
      // select the company & navigate to its company manager section
      userContext.actions.selectCompany(company.id) // TODO: disable the auto redirect this does & rely on the manual one below?
      navContext.actions.goto(ROUTES.COMPANY_MANAGER) // NB: specifically navigate to the company manger page for the newly selected company
    }
  }

  if (!company) return null

  const loginServices = [new CompanyLoginServiceMinimal(AuthLoginServiceType.EmailPassword, true), ...(company.loginServices || [])]
  const loginServicesDisplay: React.ReactNode | undefined = loginServices.filter((ls) => ls.enabled).map((loginService, index) => {
    console.log('AdminCompanyListItem - loginServicesDisplay - company:', company.id, ' index:', index, ' loginService:', loginService)
    return (
      <span key={'login_service_' + loginService.instanceId + '_' + index} className={styles.loginService}>
        {authContext.actions.getAuthLoginServiceTypeName(loginService.serviceType)}
      </span>
    )
  })

  return (
    <>

      <ArkPanel bordered className={styles.sidebarHeader}>
        <ArkPanel.Header className={styles.sidebarTitle} title={company.name} iconName='company' />
        <ArkIconButton
          className={styles.menuButton}
          name='settings'
          onClick={onEdit}
          size={24}
        />
      </ArkPanel>

      {!companyDeleted && (<>

        <ArkSpacer />
        {/* <ArkButton type="button" fluid size="large" onClick={this.onEdit}>EDIT {SECTION_COMPANY_NAME}</ArkButton> */}

        <ArkPanel bordered>
          <ArkPanel.Properties>
            <ArkPanel.PropertyRow
              title='id:'
              titleSize='large'
              value={company.id}
            />
            <ArkPanel.PropertyRow
              title='STATUS:'
              titleSize='large'
              value={company.isActive ? 'ACTIVE' : 'DISABLED'}
            />
            <ArkPanel.PropertyRow
              title='TYPE:'
              titleSize='large'
              value={company.isDemo ? 'DEMO' : 'FULL'}
            />
            <ArkPanel.PropertyRow
              title='2FA:'
              titleSize='large'
              value={company.force2fa ? 'REQUIRED' : 'OPTIONAL'}
            />
            <ArkPanel.PropertyRow
              title='MAX USERS:'
              titleSize='large'
              value={company.maxUsers}
            />
            <ArkPanel.PropertyRow
              title='PROJECT DEFAULT MAX USERS:'
              titleSize='large'
              value={company.projectDefaultMaxUsers}
            />
            <ArkPanel.PropertyRow
              title='LOGIN SERVICES:'
              titleSize='large'
              value={
                <div className={`${styles.loginServices} ${styles.loginServicesSidebar}`}>
                  {loginServicesDisplay}
                </div>
              }
            />
            {DVR_ENABLED && (
              <ArkPanel.PropertyRow
                title={`${FEATURE_DVR_NAME}:`}
                titleSize='large'
                value={company.dvrEnabled ? 'YES' : 'NO'}
              />
            )}
          </ArkPanel.Properties>
        </ArkPanel>

        <ArkSpacer />

        <ArkButton type="button" fluid size="large" onClick={onOpenCompanyManager}>OPEN IN {SECTION_COMPANY_NAME + ' ' + SECTION_MANAGER_SUFFIX_NAME}</ArkButton>
      </>)}

      <ArkSpacer grow />

      <CompanyDeleteView
        company={company}
        onDidDelete={onDidDeleteCompany}
      />

    </>
  )
}

export default AdminCompanySidebar
