import React from 'react'

import { DEFAULT_PICKER_COLOR, STATUS_ENABLED } from 'src/constants/config'
import ArkAvatar from 'src/core/components/ArkAvatar'
import ArkIconButton from 'src/core/components/ArkIconButton'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkPanel from 'src/core/components/ArkPanel'
import ArkProgramIcon from 'src/core/components/ArkProgramIcon'
import ArkSpacer from 'src/core/components/ArkSpacer'
import { Channel, OnlineStatus, Program } from 'src/core/models'
import { ProjectStatusProps, withProjectStatusContext } from 'src/core/providers'
import { getOnlineStatusText } from 'src/core/utilities/status'

import ChannelDeleteView, { ProjectChannelDeleteCallback } from './ChannelDeleteView'
import ProjectChannelProgramsPanel from './ProjectChannelProgramsPanel'

import styles from './ProjectChannelSidebar.module.css'

export type ProjectChannelEditCallback = (channel: Channel) => void

interface IProps extends ProjectStatusProps {
  companyId: number
  projectId: number
  channel?: Channel
  onChange?: Function
  onEdit?: ProjectChannelEditCallback
  onDidDelete?: ProjectChannelDeleteCallback
  loadingPrograms: boolean
  programs: Program[]
}
interface IState {
}

class ProjectChannelSidebar extends React.Component<IProps, IState> {
  render () {
    const { companyId, projectId, channel, onChange, onEdit: _onEdit, onDidDelete, loadingPrograms, programs } = this.props
    if (!channel) return (<></>)

    const onlineStatus: OnlineStatus | undefined = STATUS_ENABLED
      ? this.props.projectStatus.getChannelOnlineStatus(channel.id)
      : undefined

    // FIXME refactor since moving from list item
    const programsComponent = (
      <div className={styles.channelPrograms}>

        <ArkSpacer />

        {loadingPrograms && <ArkLoader small />}

        {/* TODO: decide how to structure these programs, e.g. in a (sub)table? in a ul list or some SUI equiv? or just divs styled manually? */}
        {!loadingPrograms && programs && programs.length > 0 && (
          <div className={styles.channelPrograms + ' ' + styles.list}>
            <h4>Channel Programs:</h4>
            <ul>
              {programs.map((program: Program) => {
                return (
                  <li key={'program_' + program.id}>
                    <ArkProgramIcon
                      color={program.colour}
                      initials={program.shortNameCapitalised()}
                      onlineStatus={onlineStatus}
                      size={30}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        )}
        {!loadingPrograms && (!programs || programs.length === 0) && (
          <div>
            ⚠️ No programs
          </div>
        )}
      </div>
    )

    const onEdit = () => {
      if (_onEdit && channel) _onEdit(channel)
    }

    return (
      <>
        <ArkPanel bordered className={styles.sidebarHeader}>
          <ArkPanel.Header
            className={styles.sidebarTitle}
            title={channel.name}
            icon={
              <ArkAvatar
                className={styles.channelAvatar}
                name={channel.name ?? ''}
                maxInitials={3}
                size="40"
                round={false}
                color={channel.colour ?? DEFAULT_PICKER_COLOR}
                onlineStatus={onlineStatus}
                variant='channel'
              />
            }
          />
          <ArkIconButton
            className={styles.menuButton}
            name='settings'
            onClick={onEdit}
            size={24}
          />
        </ArkPanel>

        {/* <ArkButton type="button" fluid size="large" onClick={onEdit}>EDIT {OBJECT_CHANNEL_NAME}</ArkButton> */}

        <ArkSpacer />

        <ArkPanel bordered>
          <ArkPanel.Properties>
            <ArkPanel.PropertyRow title='ID:' value={channel.id} />
            <ArkPanel.PropertyRow title='Name:' value={channel.name} />
            {onlineStatus && (
              <ArkPanel.PropertyRow title='Online Status:' value={getOnlineStatusText(onlineStatus)} />)
            }
          </ArkPanel.Properties>
        </ArkPanel>

        {programsComponent}

        <ArkSpacer />

        <ProjectChannelProgramsPanel
          companyId={companyId}
          projectId={projectId}
          channel={channel}
          onChange={onChange}
        />

        <ArkSpacer grow />

        <ChannelDeleteView
          companyId={companyId}
          projectId={projectId}
          channel={channel}
          onDidDelete={onDidDelete}
        />

      </>
    )
  }
}

export default withProjectStatusContext(ProjectChannelSidebar)
