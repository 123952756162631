import * as Sentry from '@sentry/react'

import {
  API_SERVER_TYPE,
  API_SERVER_URL,
  APP_ENV,
  APP_VERSION,
  DEBUG_MODE,
  SENTRY_DSN,
  SENTRY_ENABLED,
  SENTRY_REPLAY_ENABLED,
  SENTRY_TRACING_ENABLED
} from './constants/config'

// https://repro-stream.sentry.io/projects/repro-web/getting-started

export const initSentry = (): void => {
  if (!SENTRY_ENABLED) return
  if (DEBUG_MODE) console.log('sentry - initSentry')
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: APP_ENV,
    initialScope: {
      tags: {
        api_server_type: API_SERVER_TYPE,
        api_server_url: API_SERVER_URL
      }
    },
    integrations: [
      ...(SENTRY_TRACING_ENABLED ? [Sentry.browserTracingIntegration()] : []),
      ...(SENTRY_REPLAY_ENABLED ? [Sentry.replayIntegration()] : [])
    ],
    release: APP_VERSION,

    // Tracing
    ...(SENTRY_TRACING_ENABLED && {
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
    }),

    // Session Replay
    ...(SENTRY_TRACING_ENABLED && {
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  })
}
