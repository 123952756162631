import React from 'react'

import ArkIconButton from '../ArkIconButton'

import styles from './ArkVolumeButton.module.css'

export interface ArkVolumeButtonProps {
  onClick?: () => void
  onMouseEnter?: () => void // for ArkTooltip
  onMouseLeave?: () => void // for ArkTooltip
  open: boolean
  size?: number
  value: number
  width?: number
}

const ArkVolumeButton = (props: ArkVolumeButtonProps) => (
  <ArkIconButton
    className={styles.button}
    iconStyle={{ transform: 'translateX(-2px)' }}
    name='volume-off'
    onClick={props.onClick}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    open={props.open}
    size={props.size}
    width={props.width}
  >
    <div className={styles.meterTrack}>
      <div
        className={styles.meterBar}
        style={{ height: `${props.value * 100}%` }}
      />
    </div>
  </ArkIconButton>
)

export default ArkVolumeButton
