/**
 * https://softvelum.com/player/web/
 * https://softvelum.com/player/web/sdk_documentation/
 */

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import _ from 'lodash'

import { SLDP, SLDPPlayer, SLDPPlayerPlaybackType } from 'public/vendor/softvelum/SLDP'

import { VIDEO_PLAYER_CONSOLE_LOG_ENABLED, VIDEO_PLAYER_SLDP_SDK_URL } from 'src/constants/config'
import { PlayerResolution } from 'src/core/types/player'
import { delay } from 'src/core/utilities/delay'

import styles from './VideoPlayerSLDP.module.css'

// FIXME refactor status to use a ref

declare global {
  interface Window {
    SLDP: SLDP
  }
}

export type VideoPlayerSLDPStatus = {
  currentTime: number
  duration: number
  error: string | undefined
  fps: number | undefined
  playing: boolean
  rendition: string | undefined
  vodDuration: number
  vodPosition: number
}

export const DEFAULT_VIDEO_PLAYER_SLDP_STATUS: VideoPlayerSLDPStatus = {
  currentTime: 0,
  duration: 0,
  error: undefined,
  fps: undefined,
  playing: false,
  rendition: undefined,
  vodDuration: 0,
  vodPosition: 0
}

export interface VideoPlayerSLDPProps {
  abr?: boolean
  advancedParameters?: any
  buffer?: number
  controls?: boolean
  hidden?: boolean
  id: string
  muted?: boolean
  play?: boolean
  resolution?: PlayerResolution
  streamUrl: string
  vodUrl?: string
  volume?: number
  onAudioLevelChange?: (level: number) => void
  onImageDataChange?: (data: ImageData) => void
  onStatusChange?: (status: VideoPlayerSLDPStatus) => void
}

export interface VideoPlayerSLDPRef {
  seekLive: (buffer?: number) => void
  seekVod: (position: number) => void
}

const VideoPlayerSLDP = forwardRef((props: VideoPlayerSLDPProps, ref: ForwardedRef<VideoPlayerSLDPRef>) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const frameCountRef = useRef<number>()
  const loadedRef = useRef<boolean>(false)
  const mountedRef = useRef<boolean>(false)
  const playbackType = useRef<SLDPPlayerPlaybackType>('live')
  const playerRef = useRef<SLDPPlayer>()
  const propsRef = useRef<VideoPlayerSLDPProps>(props)

  // status
  const [currentTime, setCurrentTime] = useState<number>(0)
  const [duration, setDuration] = useState<number>(0)
  const [error, setError] = useState<string>()
  const [fps, setFps] = useState<number>()
  const [playing, setPlaying] = useState<boolean>(false)
  const [rendition, setRendition] = useState<string>()
  const [vodDuration, setVodDuration] = useState<number>(0)
  const [vodPosition, setVodPosition] = useState<number>(0)

  useEffect(() => {
    propsRef.current = props
  }, [props])

  useImperativeHandle(ref, () => {
    return ({ seekLive, seekVod })
  }, [])

  const containerId: string = `sldp-player-${props.id}`

  /**
   * load
   */

  useEffect(() => {
    VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - mount`)
    mountedRef.current = true
    load()
    return () => {
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - unmount`)
      mountedRef.current = false
      destroyPlayer()
    }
  }, [])

  const load = async () => {
    VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - load`)
    await loadSdk()
    if (!mountedRef.current) {
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - load - abort (unmounted)`)
      return
    }
    await loadPlayer()
    VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - load - loaded`)
  }

  const loadSdk = async () => {
    VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - loadSdk`)
    if (document.getElementById('sldp')) {
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - loadSdk - already loading`)
      while (window.SLDP === undefined) {
        VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - loadSdk - waiting…`)
        await delay()
      }
      return
    }
    const script: HTMLScriptElement = document.createElement('script')
    script.id = 'sldp'
    script.src = VIDEO_PLAYER_SLDP_SDK_URL
    document.body.appendChild(script)
    while (mountedRef.current && window.SLDP === undefined) {
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - loadSdk - loading…`)
      await delay()
    }
    if (!mountedRef.current) {
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - loadSdk - abort (unmounted)`)
      return
    }
    VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - loadSdk - loaded`)
  }

  const loadPlayer = async () => {
    try {
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - loadPlayer`)
      // https://softvelum.com/player/web/#params
      playerRef.current = window.SLDP.init({
        container: containerId,
        stream_url: props.streamUrl,
        vod: props.vodUrl
          ? {
            live_failover: false,
            startup_vod_failover: false,
            url: props.vodUrl
          }
          : undefined,
        autoplay: true,
        muted: true,
        width: 'parent',
        height: 'parent',
        // deprecated: initial_resolution
        buffering: props.buffer,
        offset: 4,
        latency_tolerance: 500,
        // latency_adjust_method
        // splash_screen
        adaptive_bitrate: props.abr
          ? {
            initial_rendition: props.resolution,
            max_rendition: props.resolution
            // size_constrained
          }
          : false,
        // pause_timeout
        key_frame_alignment: true,
        controls: props.controls,
        // audio_only
        // video_only
        // audio_title
        reconnects: Infinity,
        // muteable
        // fullscreen
        // ios_failback_app_url
        // ios_failback_scheme
        // ios_failback_secure_scheme
        // sync_buffer
        vu_meter: {
          api: 'AudioWorklet',
          // container
          mode: 'peak',
          type: 'input',
          rate: 10
          // db_range
        },
        // opus_decoder
        // video_element
        // aspect_ratio
        screenshots: propsRef.current.onImageDataChange !== undefined,
        // captions
        // adjust_zero_dist_dts
        // fmp4_full_gop
        // dts_eq_pts
        ...props.advancedParameters
      })
      playerRef.current.setCallbacks({
        onConnectionStarted: url => {
          VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - player - onConnectionStarted - url:`, url)
        },
        onConnectionEstablished: streams => {
          try {
            VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - player - onConnectionEstablished - streams:`, streams)
            setError(undefined)
            setPlaying(true)
            setRendition(playerRef.current?.getCurrentRendition())
          } catch (error) {
            console.error(`VideoPlayerSLDP[${props.id}] - player - onConnectionEstablished - error:`, error)
          }
        },
        onVolumeSet: volume => {
          VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - player - onVolumeSet - volume:`, volume)
        },
        onConnectionClosed: () => {
          VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - player - onConnectionClosed`)
          setPlaying(false)
        },
        onError: (error, opts) => {
          VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - player - onError - error:`, error, 'opts:', opts)
          setError(opts.type === playbackType.current ? error : undefined)
        },
        onChangeRendition: (rendition, name) => {
          VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - player - onChangeRendition - rendition:`, rendition, 'name:', name)
        },
        onChangeRenditionComplete: (rendition, name) => {
          try {
            VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - player - onChangeRenditionComplete - rendition:`, rendition, 'name:', name)
            setRendition(rendition)
            if (propsRef.current.play) playerRef.current?.play()
          } catch (error) {
            console.error(`VideoPlayerSLDP[${props.id}] - player - onChangeRenditionComplete - error:`, error)
          }
        },
        onLatencyAdjustSeek: (from, to) => {
          VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - player - onLatencyAdjustSeek - from:`, from, 'to:', to)
        },
        onLowBuffer: () => {
          VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - player - onLowBuffer`)
        },
        onVUMeterUpdate: (magnitudes, _decibels) => {
          // VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - player - onVUMeterUpdate - magnitudes:`, magnitudes, 'decibels:', _decibels)
          if (!propsRef.current.onAudioLevelChange) return
          let level: number = _.max(magnitudes) || 0
          level = _.clamp(level * 2, 0, 1)
          propsRef.current.onAudioLevelChange(level)
        },
        onCaptionsArrived: (captions) => {
          VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - player - onCaptionsArrived - captions:`, captions)
        },
        onScreenshotReady: (imageData, _presentationTimestamp) => {
          // VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - player - onScreenshotReady - imageData:`, imageData, 'presentationTimestamp:', presentationTimestamp)
          if (!propsRef.current.onImageDataChange) return
          propsRef.current.onImageDataChange(imageData)
        },
        onPlaybackProgress: (status) => {
          // VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - player - onPlaybackProgress - status:`, status)
          playbackType.current = status.type
          if (status.type === 'vod') {
            setVodDuration(status.duration)
            setVodPosition(status.position)
          }
        }
      })
      await delay(1000)
      loadedRef.current = true
      updatePlayerVolume()
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - loadPlayer - loaded`)
    } catch (error) {
      console.error(`VideoPlayerSLDP[${props.id}] - loadPlayer - error:`, error)
    }
  }

  /**
   * reload
   */

  const reload = async () => {
    VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - reload`)
    loadedRef.current = false
    await destroyPlayer()
    if (!mountedRef.current) {
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - reload - abort (unmounted)`)
      return
    }
    await loadPlayer()
    VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - reload - reloaded`)
  }

  /**
   * status
   */

  const updateStatus = (): void => {
    try {
      if (!mountedRef.current || !loadedRef.current || !containerRef.current) return
      // VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - updateStatus`)
      const video: HTMLVideoElement | null = containerRef.current.querySelector('video')
      if (!video) return

      // current time
      const newCurrentTime: number = video.currentTime
      // VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - updateStatus - currentTime:`, newCurrentTime)
      setCurrentTime(newCurrentTime)

      // duration
      const newDuration: number = video.duration
      // VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - updateStatus - duration:`, newDuration)
      setDuration(newDuration)

      // frame count
      const frameCount: number | undefined = (video as any).webkitDecodedFrameCount
      // VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - updateStatus - frameCount:`, frameCount)
      if (frameCount) {
        if (frameCountRef.current !== undefined) {
          const newFps: number = frameCount - frameCountRef.current
          // VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - updateStatus - fps:`, newFps)
          setFps(newFps)
        }
        frameCountRef.current = frameCount
      }
    } catch (error) {
      console.error(`VideoPlayerSLDP[${props.id}] - updateStatus - error:`, error)
    }
  }

  useEffect(() => {
    VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - status - start`)
    const interval: ReturnType<typeof setInterval> = setInterval(updateStatus, 1000)
    return () => {
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - status - stop`)
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (!mountedRef.current || !loadedRef.current || !props.onStatusChange) return
    const status: VideoPlayerSLDPStatus = {
      currentTime,
      duration,
      error,
      fps,
      playing,
      rendition,
      vodDuration,
      vodPosition
    }
    // VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - onStatusChange - status:`, status)
    props.onStatusChange(status)
  }, [
    currentTime,
    duration,
    error,
    fps,
    playing,
    rendition,
    vodDuration,
    vodPosition
  ])

  /**
   * volume
   */

  // update player volume considering `muted` & `volume` props
  const updatePlayerVolume = (): void => {
    try {
      // VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - updatePlayerVolume - muted:`, propsRef.current.muted, 'volume:', propsRef.current.volume)
      if (!mountedRef.current || !loadedRef.current) return
      const volume: number = propsRef.current.volume ? Math.round(propsRef.current.volume * 100) : 0
      playerRef.current?.setVolume(propsRef.current.muted ? 0 : volume)
    } catch (error) {
      console.error(`VideoPlayerSLDP[${props.id}] - updatePlayerVolume - error:`, error)
    }
  }

  // player volume fail-safe
  useEffect(() => {
    VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - volume - start`)
    const interval: ReturnType<typeof setInterval> = setInterval(updatePlayerVolume, 1000)
    return () => {
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - volume - stop`)
      clearInterval(interval)
    }
  }, [])

  /**
   * props
   */

  useEffect(() => {
    if (!mountedRef.current || !loadedRef.current) return
    VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - abr:`, props.abr)
    reload()
  }, [props.abr])

  useEffect(() => {
    if (!mountedRef.current || !loadedRef.current) return
    VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - muted:`, props.muted)
    updatePlayerVolume()
  }, [props.muted])

  useEffect(() => {
    try {
      if (!mountedRef.current || !loadedRef.current) return
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - play:`, props.play)
      props.play ? playerRef.current?.play() : playerRef.current?.pause()
    } catch (error) {
      console.error(`VideoPlayerSLDP[${props.id}] - play - error:`, error)
    }
  }, [props.play])

  useEffect(() => {
    try {
      if (!mountedRef.current || !loadedRef.current || !props.resolution) return
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - resolution:`, props.resolution)
      playerRef.current?.changeRendition(props.resolution)
    } catch (error) {
      console.error(`VideoPlayerSLDP[${props.id}] - resolution - error:`, error)
    }
  }, [props.resolution])

  useEffect(() => {
    try {
      if (!mountedRef.current || !loadedRef.current) return
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - streamUrl:`, props.streamUrl)
      playerRef.current?.setStreamURL(props.streamUrl)
    } catch (error) {
      console.error(`VideoPlayerSLDP[${props.id}] - streamUrl - error:`, error)
    }
  }, [props.streamUrl])

  useEffect(() => {
    if (!mountedRef.current || !loadedRef.current) return
    VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - volume:`, props.volume)
    updatePlayerVolume()
  }, [props.volume])

  useEffect(() => {
    if (!mountedRef.current || !loadedRef.current) return
    VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - onImageDataChange:`, props.onImageDataChange)
    reload()
  }, [props.onImageDataChange])

  /**
   * actions
   */

  const seekLive = (buffer?: number): void => {
    try {
      if (!mountedRef.current || !loadedRef.current) return
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - seekLive - buffer:`, buffer)
      setError(undefined)
      playerRef.current?.seekLive(buffer)
    } catch (error) {
      console.error(`VideoPlayerSLDP[${props.id}] - seekLive - error:`, error)
    }
  }

  const seekVod = (position: number): void => {
    try {
      if (!mountedRef.current || !loadedRef.current) return
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - seekVod - position:`, position)
      setError(undefined)
      playerRef.current?.seekVod(position)
    } catch (error) {
      console.error(`VideoPlayerSLDP[${props.id}] - seekVod - error:`, error)
    }
  }

  /**
   * utilities
   */

  const destroyPlayer = async (): Promise<void> => new Promise((resolve) => {
    try {
      VIDEO_PLAYER_CONSOLE_LOG_ENABLED && console.log(`VideoPlayerSLDP[${props.id}] - destroyPlayer`)
      if (!playerRef.current) return resolve()
      playerRef.current.destroy(resolve)
    } catch (error) {
      console.error(`VideoPlayerSLDP[${props.id}] - destroyPlayer - error:`, error)
      resolve()
    }
  })

  /**
   * render
   */

  return (
    <div
      className={`${styles.container} ${props.hidden ? styles.hidden : ''}`}
      data-test-id="ark-video-player-sldp" // e2e testing identifier
      id={containerId}
      ref={containerRef}
    />
  )
})

VideoPlayerSLDP.displayName = 'VideoPlayerSLDP'

export default VideoPlayerSLDP
