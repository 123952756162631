import React, { ReactNode } from 'react'
import _ from 'lodash'

import { OBJECT_PROGRAM_NAME } from 'src/constants/strings'
import ArkIcon, { IconName } from 'src/core/components/ArkIcon'
import ArkLoader from 'src/core/components/ArkLoader'
import ArkSpacer from 'src/core/components/ArkSpacer'

import { formatPlayerError, getProgramErrorText, getWatchdogStatusMessage } from './utilities'
import { ProgramError, ProgramWatchdogStatus } from './types'

import styles from './ProgramStatusView.module.css'

const DEBUG_ALWAYS_SHOW_NO_FRAGMENT: boolean = false
const DEBUG_ALWAYS_SHOW_OFFLINE: boolean = false
const DEBUG_ALWAYS_SHOW_PLAYER_ERROR: boolean = false
const DEBUG_ALWAYS_SHOW_PROGRAM_ERROR: boolean = false
const DEBUG_ALWAYS_SHOW_WATCHDOG_STATUS: boolean = false
const DEBUG_SEMI_TRANSPARENT: boolean = false

interface ProgramStatusComponentProps {
  iconComponent?: ReactNode
  iconName?: IconName
  message?: string
  thumbnail: boolean
}

const ProgramStatusComponent = (props: ProgramStatusComponentProps) => (
  <div className={`${styles.container} ${DEBUG_SEMI_TRANSPARENT ? styles.semiTransparent : ''}`}>
    <div className={styles.messageContainer}>
      {props.iconComponent}
      {props.iconName && (
        <ArkIcon
          color='var(--tx-muted)'
          name={props.iconName}
          size={props.thumbnail ? 24 : 48}
        />
      )}
      {props.message && !props.thumbnail && (
        <div className={styles.message}>{props.message}</div>
      )}
    </div>
  </div>
)

interface ProgramStatusViewProps {
  noFragment?: boolean
  offline?: boolean
  playerError?: string
  programError?: ProgramError
  thumbnail: boolean
  watchdogStatus?: ProgramWatchdogStatus
}

const ProgramStatusView = (props: ProgramStatusViewProps) => {
  /**
   * program error
   */
  if (DEBUG_ALWAYS_SHOW_PROGRAM_ERROR || props.programError) {
    return (
      <ProgramStatusComponent
        iconName='warning'
        message={getProgramErrorText(props.programError || ProgramError.NoStream)}
        thumbnail={props.thumbnail}
      />
    )
  }

  /**
   * offline
   */
  if (DEBUG_ALWAYS_SHOW_OFFLINE || props.offline) {
    return (
      <ProgramStatusComponent
        iconName='offline'
        message={`${OBJECT_PROGRAM_NAME} offline`}
        thumbnail={props.thumbnail}
      />
    )
  }

  /**
   * no fragment
   */
  if (DEBUG_ALWAYS_SHOW_NO_FRAGMENT || props.noFragment) {
    return (
      <ProgramStatusComponent
        iconName='offline'
        message='No recording'
        thumbnail={props.thumbnail}
      />
    )
  }

  /**
   * watchdog status
   */
  if (
    DEBUG_ALWAYS_SHOW_WATCHDOG_STATUS ||
    !_.includes([undefined, ProgramWatchdogStatus.Unknown, ProgramWatchdogStatus.Playing], props.watchdogStatus)
  ) {
    return (
      <ProgramStatusComponent
        iconComponent={(
          <>
            <ArkLoader small={props.thumbnail} />
            <ArkSpacer large/>
          </>
        )}
        message={getWatchdogStatusMessage(props.watchdogStatus || ProgramWatchdogStatus.Connecting)}
        thumbnail={props.thumbnail}
      />
    )
  }

  /**
   * player error
   */
  if (DEBUG_ALWAYS_SHOW_PLAYER_ERROR || props.playerError) {
    return (
      <ProgramStatusComponent
        iconName='exclamation-circle'
        message={formatPlayerError(props.playerError || 'Something went wrong')}
        thumbnail={props.thumbnail}
      />
    )
  }
}

export default ProgramStatusView
