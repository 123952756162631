/**
 * ProgramsGridView
 */

import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import useResizeObserver from 'use-resize-observer'

import ArkPagination from 'src/core/components/ArkPagination'
import {
  ChannelGridLayout,
  ChannelGridCoords,
  ChannelGridCoordsLayoutMap,
  ChannelGridCoordsLayout
} from 'src/core/types/channel'
import { getAutoCoords } from 'src/core/utilities/channel'
import { useViewer } from 'src/viewer/providers/ViewerProvider'

import ProgramView from '../ProgramView/ProgramView'

import styles from './ProgramsGridView.module.css'

const MARGIN = 10

const ProgramsGridView = () => {
  const viewer = useViewer()

  if (!_.some(viewer.programs)) return null

  const container = useRef<HTMLDivElement>(null)

  const [activePage, setActivePage] = useState<number>(1)
  const [containerSize, setContainerSize] = useState<{ width: number, height: number }>({ width: 0, height: 0 })

  useEffect(() => {
    setActivePage(1)
  }, [viewer.getChannelGridLayout()])

  useEffect(() => {
    if (!_.some(viewer.programs)) return
    viewer.setAutoGridCoords(getAutoCoords(_.size(viewer.programs)))
  }, [viewer.programs])

  useResizeObserver<HTMLDivElement>({
    onResize: (size) => {
      if (size.width && size.height) setContainerSize({ width: size.width, height: size.height })
    },
    ref: container
  })

  const gridLayout: ChannelGridLayout = viewer.getChannelGridLayout()

  // grid coords
  let coords: ChannelGridCoords = { x: 0, y: 0 }
  if (gridLayout === ChannelGridLayout.GridAuto) {
    coords = viewer.autoGridCoords
  } else {
    const layout: ChannelGridCoordsLayout | undefined = _.find(ChannelGridCoordsLayoutMap, { layout: gridLayout })
    if (layout) coords = layout.coords
  }

  const numberOfItemsPerPage: number = coords.x * coords.y
  const numberOfPages: number = Math.ceil(_.size(viewer.programs) / numberOfItemsPerPage)
  const numberOfItems: number = numberOfPages * numberOfItemsPerPage
  const numberOfPrograms: number = _.size(viewer.programs)

  // dimensions
  const maxItemWidth: number = (containerSize.width / coords.x) - MARGIN
  const maxItemHeight: number = (containerSize.height / coords.y) - MARGIN
  let itemWidth: number = 0
  let itemHeight: number = 0
  if (maxItemWidth * (9 / 16) > maxItemHeight) {
    // vertically constrained
    itemWidth = Math.floor(maxItemHeight * (16 / 9))
    itemHeight = Math.floor(maxItemHeight)
  } else {
    // horizontally constrained
    itemWidth = Math.floor(maxItemWidth)
    itemHeight = Math.floor(maxItemWidth * (9 / 16))
  }

  const containerInnerWidth: number = (itemWidth + MARGIN) * coords.x

  const activeRange: number[] = _.range(numberOfItemsPerPage * (activePage - 1), numberOfItemsPerPage * activePage)

  /**
   * programs
   */

  const programComponents: JSX.Element[] = _.map(viewer.programs, (program, index) => {
    const isHidden: boolean = !_.includes(activeRange, index)
    return (
      <div
        className={`${styles.item} ${isHidden ? styles.itemHidden : ''}`}
        key={index}
        style={{ width: itemWidth, height: itemHeight }}
      >
        <ProgramView
          key={program.id}
          program={program}
          thumbnail={isHidden}
        />
      </div>
    )
  })

  /**
   * placeholders
   */

  const placeholderComponents: JSX.Element[] = _.map(_.range(numberOfPrograms, numberOfItems), index => {
    if (!_.includes(activeRange, index)) return <></>
    return (
      <div
        className={styles.item}
        key={index}
        style={{ width: itemWidth, height: itemHeight }}
      >
        <div className={styles.placeholder}>
          <div className={styles.placeholderLabel}>NO PROGRAM</div>
        </div>
      </div>
    )
  })

  /**
   * pagination
   */

  const paginationComponent = numberOfPages > 1 && (
    <div className={styles.pagination}>
      <ArkPagination
        activePage={activePage}
        firstItem={null}
        lastItem={null}
        onPageChange={(_event, data) => setActivePage(typeof data.activePage === 'number' ? data.activePage : 1)}
        size='mini'
        totalPages={numberOfPages}
      />
    </div>
  )

  /**
   * render
   */

  return (
    <>
      <div className={styles.containerOuter}>
        <div className={styles.containerMiddle} ref={container}>
          <div
            className={styles.containerInner}
            style={{
              width: containerInnerWidth,
              ...(coords.y > 1 && { flexWrap: 'wrap' }) // fix resize glitches
            }}
          >
            {programComponents}
            {placeholderComponents}
          </div>
        </div>
      </div>
      {paginationComponent}
    </>
  )
}

export default ProgramsGridView
