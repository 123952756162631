import React, { useContext } from 'react'

import { IDLE_ENABLED } from 'src/constants/config'
import {
  OBJECT_PROJECT_NAME,
  SECTION_ADMIN_NAME,
  SECTION_COMPANY_NAME,
  SECTION_MANAGER_SUFFIX_NAME,
  SECTION_VIEWER_NAME
} from 'src/constants/strings'
import { SectionSwitcherContext, NavSection } from 'src/core/providers'
import { useIdle } from 'src/core/providers/IdleProvider'

import ArkLargeButton, { ArkLargeButtonGroup } from '../ArkLargeButton'

import styles from './ArkSectionSwitcherLargeView.module.css'

const ArkSectionSwitcherLargeView = () => {
  const idle = useIdle()

  const { actions: sectionActions, store: sectionsStore } = useContext(SectionSwitcherContext)
  const { showSiteAdminLink, showCompanyAdminLink, showProjectManagerLink, showProjectViewerLink } = sectionsStore

  return (
    <div className={styles.view}>
      <ArkLargeButtonGroup>
        <ArkLargeButton
          iconName='admin'
          locked={IDLE_ENABLED && idle.getLocked()}
          onClick={() => sectionActions.gotoSection(NavSection.admin)}
          show={showSiteAdminLink}
          title={SECTION_ADMIN_NAME + ' TOOLS'}
        />
        <ArkLargeButton
          iconName='company'
          locked={IDLE_ENABLED && idle.getLocked()}
          onClick={() => sectionActions.gotoSection(NavSection.company)}
          show={showCompanyAdminLink}
          title={SECTION_COMPANY_NAME + ' ' + SECTION_MANAGER_SUFFIX_NAME}
        />
        <ArkLargeButton
          iconName='project'
          locked={IDLE_ENABLED && idle.getLocked()}
          onClick={() => sectionActions.gotoSection(NavSection.project)}
          show={showProjectManagerLink}
          title={OBJECT_PROJECT_NAME + ' ' + SECTION_MANAGER_SUFFIX_NAME}
        />
        <ArkLargeButton
          iconName='viewer'
          onClick={() => sectionActions.gotoSection(NavSection.viewer)}
          show={showProjectViewerLink}
          title={'OPEN ' + SECTION_VIEWER_NAME}
        />
      </ArkLargeButtonGroup>
    </div>
  )
}

export default ArkSectionSwitcherLargeView
