import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

import { useAuth } from 'src/core/providers'
import { ServerError } from 'src/core/services/ServerAPIErrors'

import * as ROUTES from 'src/constants/routes'

import ArkDivider from 'src/core/components/ArkDivider'
import ArkForm, { ArkFormField, ArkFormFieldType, ArkFormFieldValues, ArkFormProps } from 'src/core/components/ArkForm/ArkForm'
import ArkMessage from 'src/core/components/ArkMessage'

import styles from './LoginHelp.module.css'

const formSchema = yup.object().shape({
  email: yup.string().email().required()
})

interface IProps {
  email?: string
}

const LoginPasswordResetStage1Form = (props: IProps) => {
  const mounted = useRef(false)

  const { email } = props

  const authContext = useAuth()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [error, setError] = useState<Error | undefined>()

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const onFormSubmit = async (fieldValues: ArkFormFieldValues, _event: React.FormEvent<HTMLFormElement>, _data: ArkFormProps) => {
    if (isSubmitting) return
    const { email } = fieldValues
    setIsSubmitting(true)
    try {
      const result = await authContext.actions.forgotEmailPassword(email)
      if (result) {
        if (mounted.current) {
          setIsSubmitting(false)
          setEmailSent(true)
        }
        return
      }
      throw new Error('Error sending password reset email')
    } catch (error: any) {
      console.error('LoginPasswordResetStage1Form - onFormSubmit - error:', error)
      if (error instanceof ServerError) {
        console.error('LoginPasswordResetStage1Form - onFormSubmit - ServerError - error:', error)
      }
      if (mounted.current) {
        setIsSubmitting(false)
        setError(error)
      }
    }
  }

  // -------

  const formFields: Array<ArkFormField> = []
  formFields.push({ type: ArkFormFieldType.Input, key: 'email', label: 'Email Address', defaultValue: email ?? '', required: true })
  formFields.push({ type: ArkFormFieldType.Button, key: 'submit', label: 'Reset Password', fieldProps: { loading: isSubmitting, fluid: true } })

  return (
    <>
      {emailSent && (
        <>
          <ArkMessage positive className={styles.resetEmailSent}>
            <ArkMessage.Header>Email Sent</ArkMessage.Header>
            <p>A password reset link has been sent to your email.<br /><strong>Please follow the instructions in the email to reset your password.</strong></p>
          </ArkMessage>
          <div className={styles.loginLink}>
            <Link to={ROUTES.LOGIN}>Back to Login</Link>
          </div>
        </>
      )}
      {!emailSent && (
        <>
          <ArkForm
            formKey="passwordReset"
            className="password-reset-form"
            inverted
            formError={error}
            formFields={formFields}
            formSchema={formSchema}
            onFormSubmit={onFormSubmit}
          ></ArkForm>
          <ArkDivider horizontal inverted></ArkDivider>
          <div className={styles.loginLink}>
            <Link to={ROUTES.LOGIN}>Back to Login</Link>
          </div>
        </>
      )}
    </>
  )
}

export default LoginPasswordResetStage1Form
