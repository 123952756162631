// refs:
//  https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
//  https://stackoverflow.com/questions/41102060/typescript-extending-error-class
//  https://joefallon.net/2018/09/typescript-try-catch-finally-and-custom-errors/

import { OBJECT_COMPANY_NAME } from 'src/constants/strings'
import { pluralize } from 'src/core/utilities/strings'

// custom server api error codes, returned as an "error_code" field within the error response json body
// ref: https://docs.google.com/spreadsheets/d/1P4R1IYLG5U0Na_GupuF-zGcyLoThPJYq97TlgvGThLc/edit#gid=0
export enum ServerErrorCodes {

  authTokenExpired = 0.41, // TESTING: is this for the auth token? or refresh? - TODO: is this in the custom responses spreadsheet??
  auth2FAOTPRequired = 0.44, // NB: currently returned for 2FA enabled users when attempting to change their passsword or email without providing a 2FA OTP code (other similar actions may also require 2FA OTP code in the future & so return this when its missing)
  authTokenNotValid = 1.3,
  authTokenNoDevice = 1.4, // API error: `USER_NOT_FOUND_FOR_CURRENT_UUID` (user not found for the current device) - can occur whenever a user’s session is invalidated, e.g: 'invalidating all sessions for one user' / 'password changed' / 'email changed' / 'invalidating a specific device session'
  authInvalidCredentials = 1.48, // NB: API v0.4.2+ added this generic error for unknown users and wrong password / otp / okta_token (NB: can also be returned when changing password & the old one is incorrect)
  authAccountLocked = 1.51, // NB: API v0.4.2+ added this error for when a user account is locked due to too many failed login attempts

  inviteAlreadyAccepted = 0.23,
  inviteExpired = 0.24,
  inviteTokenBelongsToOtherUser = 0.25,

  userRoleAlreadyAssigned = 0.27,

  tokenNotFound = 0.38, // NB: `/auth/reset_pass/` endpoint returns this when the token is not found (e.g. previously used & no longer valid), but it may also be reused for other scenarios in the future (named in a generic way because of this, NB: also note the api has some 'use' specific similar error codes for this too e.g. `auth_..`, `registration_..`)

  auth2FAPhoneNumberNotVerified = 1.24,

  passwordPolicy = 1.28, // ERROR_PASSWORD_POLICY (NB: http status code 400)

  newCompany2FARequired = 2.16, // TODO: RENAME once the old versions have been removed (or refactored if needed at all)
  oldCompany2FARequired = 2.1, // TODO: DEPRECIATE - org/project forced 2fa switch auth - old server error related to forced 2fa when switching org which we no longer use (it throws an alternative error instead)
  oldCompany2FAInvalid = 2.2, // TODO: DEPRECIATE - org/project forced 2fa switch auth

  newProject2FARequired = 3.19, // TODO: RENAME once the old versions have been removed (or refactored if needed at all)
  oldProject2FARequired = 3.3, // TODO: DEPRECIATE - org/project forced 2fa switch auth - old server error related to forced 2fa when switching project which we no longer use (it throws an alternative error instead)
  oldProject2FAInvalid = 3.4, // TODO: DEPRECIATE - org/project forced 2fa switch auth

  videoEngineInvalidPort = 9.4, // 'Port not found' response if looking up a port for a video engine & its not in a pre-declared valid range for that engine

  watermarkDoesNotExist = 13.2,
  watermarkErrorReading = 13.3,
}

export class ServerError extends Error {
  public statusCode?: number // http status code
  public errorCode?: number // internal Ark error code
  public data?: any // the axios data field, incase additional data was passed along as part of the error
  constructor (message?: string, statusCode?: number, errorCode?: number, data?: any) {
    super(message) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
    this.statusCode = statusCode
    this.errorCode = errorCode
    this.data = data
  }
}

export class ServerAuthError extends ServerError {
  constructor (message?: string, errorCode?: number) {
    super((message === undefined ? 'Auth Error' : message), 401, errorCode) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}
export class ServerAuthTokenExpiredError extends ServerAuthError {
  constructor (message?: string) {
    super((message === undefined ? 'Auth Token Expired' : message), ServerErrorCodes.authTokenNotValid) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}
export class ServerAuthTokenNoDeviceError extends ServerAuthError {
  constructor (message?: string) {
    super((message === undefined ? 'Auth Device Not Found' : message), ServerErrorCodes.authTokenNoDevice) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}
export class ServerAuthInvalidCredentialsError extends ServerAuthError {
  public attemptsLeft?: number
  constructor (message?: string, attemptsLeft?: number) {
    let _message = (message === undefined ? 'Invalid email or password' : message)
    if (attemptsLeft !== undefined) {
      _message += ` - ${attemptsLeft} login ${pluralize('attempt', attemptsLeft)} left`
    }
    super(_message, ServerErrorCodes.authInvalidCredentials) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
    this.attemptsLeft = attemptsLeft
  }
}
export class ServerAuthAccountLockedError extends ServerAuthError {
  constructor (message?: string) {
    super((message === undefined ? `Account locked. Too many failed login attempts. Please contact your ${OBJECT_COMPANY_NAME} admin or our support.` : message), ServerErrorCodes.authAccountLocked) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

// NB: see the `ServerErrorCodes.tokenNotFound` error code notes for more info on this error
export class ServerTokenNotFoundError extends ServerAuthError {
  constructor (message?: string) {
    super((message === undefined ? 'Token Not Found' : message), ServerErrorCodes.tokenNotFound) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

export class ServerAuthPasswordPolicyError extends ServerError {
  public policyRules?: Array<string>
  public policyViolations?: Array<string>
  constructor (message?: string, policyRules?: Array<string>, policyViolations?: Array<string>) {
    super((message === undefined ? 'Auth Password Policy Error' : message), ServerErrorCodes.passwordPolicy) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
    this.policyRules = policyRules
    this.policyViolations = policyViolations
  }
}

export class ServerAuth2FAOTPRequiredError extends ServerAuthError {
  constructor (message?: string) {
    super((message === undefined ? '2FA OTP Code Required' : message), ServerErrorCodes.auth2FAOTPRequired) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}
export class ServerAuth2FAPhoneNumberNotVerifiedError extends ServerAuthError {
  constructor (message?: string) {
    super((message === undefined ? 'Phone number verification is mandatory in order to enable 2FA' : message), ServerErrorCodes.auth2FAPhoneNumberNotVerified) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

export class ServerNotFoundError extends ServerError {
  constructor (message?: string, errorCode?: number) {
    super((message === undefined ? 'Not Found' : message), 404, errorCode) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

// TODO:
// TODO: add support for the new custom/internal error codes to these...
// TODO:

export class ServerAlreadyExistsError extends ServerError {
  constructor (message?: string) {
    super((message === undefined ? 'Already Exists' : message), 101) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}
export class ServerValidationError extends ServerError {
  public field?: string
  public issue?: string // TODO: rename to 'type'? as in 'error type'? seems more suited & similar to how joi refers to the cause
  constructor (message?: string, field?: string, issue?: string, code?: number) {
    super((message === undefined ? 'Validation Error' : message), (code !== undefined ? code : 202)) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
    this.field = field
    this.issue = issue
  }
}
export class ServerNoChangesError extends ServerError {
  constructor (message?: string) {
    super((message === undefined ? 'No Changes' : message), 102) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

// company invite

export class ServerCompanyInviteAlreadyAcceptedError extends ServerError {
  constructor (message?: string) {
    super((message === undefined ? 'Company invitation already accepted' : message), 309, ServerErrorCodes.inviteAlreadyAccepted) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

export class ServerCompanyInviteExpiredError extends ServerError {
  constructor (message?: string) {
    super((message === undefined ? 'Company invitation has expired' : message), 401, ServerErrorCodes.inviteExpired) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

export class ServerCompanyInviteOtherUserError extends ServerError {
  constructor (message?: string) {
    super((message === undefined ? 'Company invitation is for a different user' : message), 401, ServerErrorCodes.inviteTokenBelongsToOtherUser) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

// company 2FA

export class ServerCompany2FARequiredError extends ServerError {
  constructor (message?: string) {
    // TODO: use config string for Organisation
    super((message === undefined ? 'Organisation requires enforced 2FA' : message), 401, ServerErrorCodes.newCompany2FARequired) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

// TODO: DEPRECIATE - org/project forced 2fa switch auth - old server error related to forced 2fa when switching org which we no longer use (it throws an alternative error instead)
// export class ServerCompany2FARequiredOLDError extends ServerError {
//   constructor (message?: string) {
//     super((message === undefined ? 'Organisation requires enforced 2FA' : message), 401, ServerErrorCodes.oldCompany2FARequired) // 'Error' breaks prototype chain here
//     Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
//   }
// }

// TODO: DEPRECIATE - org/project forced 2fa switch auth
// export class ServerCompany2FAInvalidOLDError extends ServerError {
//   constructor (message?: string) {
//     super((message === undefined ? 'Organisation token doens\'t match with the last generated' : message), 401, ServerErrorCodes.oldCompany2FAInvalid) // 'Error' breaks prototype chain here
//     Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
//   }
// }

// project 2FA

export class ServerProject2FARequiredError extends ServerError {
  constructor (message?: string) {
    // TODO: use config string for Project
    super((message === undefined ? 'Project requires enforced 2FA' : message), 401, ServerErrorCodes.newProject2FARequired) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
  }
}

// TODO: DEPRECIATE - org/project forced 2fa switch auth - old server error related to forced 2fa when switching project which we no longer use (it throws an alternative error instead)
// export class ServerProject2FARequiredOLDError extends ServerError {
//   constructor (message?: string) {
//     super((message === undefined ? 'Project requires enforced 2FA' : message), 401, ServerErrorCodes.oldProject2FARequired) // 'Error' breaks prototype chain here
//     Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
//   }
// }

// TODO: DEPRECIATE - org/project forced 2fa switch auth
// export class ServerProject2FAInvalidOLDError extends ServerError {
//   constructor (message?: string) {
//     super((message === undefined ? 'Project token doens\'t match with the last generated' : message), 401, ServerErrorCodes.oldProject2FAInvalid) // 'Error' breaks prototype chain here
//     Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
//   }
// }
