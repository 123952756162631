import React, { useEffect, useRef, useState } from 'react'

import { useSiteAdmin } from 'src/core/providers'
import * as ROUTES from 'src/constants/routes'

import { Company } from 'src/core/models'

import ArkAdminPage from 'src/admin/components/ArkAdminPage/ArkAdminPage'
import AdminCompanySidebar from './AdminCompanySidebar'
import CompanyForm, { CompanyFormMode } from './CompanyForm'

import AdminCompanyListItem from './AdminCompanyListItem'

import ArkManagerContentView from 'src/core/components/ArkManagerContentView/ArkManagerContentView'
import ArkManagerListView, { ArkManagerFilteredItem } from 'src/core/components/ArkManagerListView/ArkManagerListView'
import ArkManagerFilterForm from 'src/core/components/ArkManagerListView/ArkManagerFilterForm'
import ArkModal from 'src/core/components/ArkModal'
import { OBJECT_COMPANY_NAME, OBJECT_COMPANY_NAME_PLURAL, SECTION_ADMIN_NAME } from 'src/constants/strings'

interface IProps {}

const AdminCompaniesPage = (_props: IProps) => {
  const mounted = useRef(false)

  const siteAdminContext = useSiteAdmin()

  const [loading, setLoading] = useState<boolean>(false)
  const [companies, setCompanies] = useState<Array<Company>>([])
  const [filteredCompanies, setFilteredCompanies] = useState<Array<ArkManagerFilteredItem<Company>> | undefined>(undefined)
  const [filter, setFilter] = useState<string | undefined>(undefined)
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined)
  const [editCompany, setEditCompany] = useState<Company | undefined>(undefined)
  const [showAddEditModal, setShowAddEditModal] = useState<boolean>(false)

  // -------

  useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])

  // -------

  const loadCompanies = async () => {
    if (loading === true) return false
    if (!mounted.current) return false
    try {
      setLoading(true)
      const companies = await siteAdminContext.actions.getAllCompanies()
      if (mounted.current) {
        setLoading(false)
        setCompanies(companies || [])
        // TESTING: update the selectedCompany whenever the company is reloaded (incase its been edited)
        if (selectedCompany) {
          const _company = companies?.find(c => c.id === selectedCompany?.id)
          setSelectedCompany(_company)
        }
      }
    } catch (error) {
      console.error('AdminCompaniesPage - loadUsers - error: ', error)
      if (mounted.current) {
        setLoading(false)
        setCompanies([])
        // TODO: add an error prop & display an error message if this happens
      }
    }
  }

  useEffect(() => {
    loadCompanies()
  }, [])

  // -------

  const selectCompany = (company?: Company) => {
    setSelectedCompany(company)
  }

  // -------

  const filterCompanies = (_filter: string) => {
    if (loading) return
    const filter = _filter.length > 0 ? _filter : undefined
    const filteredCompanies = filter
      ? companies.reduce<Array<ArkManagerFilteredItem<Company>>>((r, company) => {
        let nameMatch = false
        if (company.name.toLowerCase().includes(filter.toLowerCase())) {
          nameMatch = true
        }
        if (nameMatch) {
          const matchingFields: Array<string> = []
          if (nameMatch) matchingFields.push('name')
          const filteredUser: ArkManagerFilteredItem<Company> = {
            item: company,
            matchingFields
          }
          r.push(filteredUser)
        }
        return r
      }, [] as Array<ArkManagerFilteredItem<Company>>)
      : undefined
    if (selectedCompany && (!(filteredCompanies?.find((filteredCompany) => filteredCompany.item.id === selectedCompany.id)))) {
      selectCompany(undefined) // if an item was selected but isn't in the filtered list deselect them
    }
    setFilter(filter)
    setFilteredCompanies(filteredCompanies)
  }

  // const clearFilteredGroups = () => {
  //   setFilter(undefined)
  //   setFilteredCompanies(undefined)
  // }

  // -------

  const renderCompanyTableRowContent = (company: Company, isSelected: boolean, filter?: string) => {
    return (
      <AdminCompanyListItem
        active={isSelected}
        company={company}
        filter={filter}
        key={company.id}
        onClick={() => selectCompany(company)}
        // onEditClick={() => this.showEditCompanyModal(company)}
      />
    )
  }

  // -------

  const renderCompanyFilterForm = () => {
    return (
      <ArkManagerFilterForm
        autoComplete={false}
        filterTitle='Filter by name'
        filterValue={filter ?? ''}
        onFilterChange={(filter: string) => {
          filterCompanies(filter)
        }}
      />
    )
  }

  // -------

  const showAddModal = () => {
    setShowAddEditModal(true)
  }

  const showEditModal = (company: Company) => {
    setEditCompany(company)
    setShowAddEditModal(true)
  }

  // call directly to initiate a modal callback (used when the user clicks custom buttons to close the modal instead of the modals own 'x' button etc.)
  const hideAddEditModal = () => {
    // NB: DON'T clear/reset `editCompany` here, see `didHideCompanyModal` which is called once the modal has actually closed & we can safely reset it then
    // NB: this stops a saved edit form flipping the 'updated' success text to 'created' briefly while the modal closes if we reset it straight away here
    // NB: & also stops the form title flipping from edit to add as it closes via the cancel button
    setShowAddEditModal(false)
  }

  // triggered via modal callbacks once the modal has already closed
  const didHideAddEditModal = () => {
    setEditCompany(undefined)
    setShowAddEditModal(false)
  }

  // -------

  const onEdit = (_company: Company) => {
    showEditModal(_company)
  }

  const onDidDelete = (_company: Company) => {
    // trigger a data re-load so the deleted company no longer shows
    loadCompanies()
    selectCompany(undefined)
  }

  // -------

  const renderCompanyForm = (company?: Company) => {
    return (
      <CompanyForm
        mode={company ? CompanyFormMode.Edit : CompanyFormMode.Add}
        company={company}
        onCancel={() => { hideAddEditModal() }}
        onSave={() => {
          // trigger a companies data re-load to show the newly added company
          loadCompanies()
        }}
        // onDelete={() => {
        //   // trigger a data re-load so the deleted company no longer shows
        //   this.loadCompanies()
        // }}
        onClose={() => { hideAddEditModal() }}
        insideModal={true}
      />
    )
  }

  // -------

  const renderCompanyFormModal = () => {
    return (
      <ArkModal open={showAddEditModal} onClose={() => didHideAddEditModal()}>
        {renderCompanyForm(editCompany)}
      </ArkModal>
    )
  }

  // -------

  // TODO: PORT if/once onChange support is added to the sidebar (if we add support of editing companies directly via the sidebar instead of just the edit modal)
  // onChange={async () => {
  //   // trigger a company users data re-load so the updated user values show
  //   await this.loadCompanyUsers()
  //   // trigger the sidepanel to update with the updated user object
  //   if (this.state.selectedUser && this.state.users) {
  //     const newUser = this.state.users.find((user) => user.id === this.state.selectedUser?.id)
  //     this.setState({ selectedUser: newUser })
  //   }
  // }}

  const rightSidebarComponent = selectedCompany && (
    <AdminCompanySidebar
      company={selectedCompany}
      onEdit={onEdit}
      onDidDelete={onDidDelete}
    />
  )

  return (
    <ArkAdminPage
      onRightSidebarClose={() => setSelectedCompany(undefined)}
      rightSidebar={rightSidebarComponent}
    >
      <ArkManagerContentView
        title={OBJECT_COMPANY_NAME_PLURAL}
        breadcrumb={[{
          path: ROUTES.ADMIN,
          title: SECTION_ADMIN_NAME
        }]}
      >
        <ArkManagerListView
          loading={loading}
          items={companies}
          selectedItem={selectedCompany}
          itemRow={(company: Company, isSelected: boolean) => {
            return renderCompanyTableRowContent(company, isSelected, filter)
          }}
          topbarAddItemTitle={'CREATE ' + OBJECT_COMPANY_NAME}
          onAdd={() => showAddModal()}
          // FILTERING:
          filter={filter}
          filteredItems={filteredCompanies}
          filterForm={renderCompanyFilterForm()}
          onClearFilter={() => filterCompanies('') }
        />
        {renderCompanyFormModal()}
      </ArkManagerContentView>
    </ArkAdminPage>
  )
}
export default AdminCompaniesPage
